import React, { Component } from 'react';
import { SIZES } from 'constants/components';
import CircleLoader from 'components/common/CircleLoader';
import {
  ButtonElement,
  TextElement,
  CheckIcon,
  CheckImageIcon,
  ButtonWrapper,
  AnswerImage
} from './SwitchButton.styled';

interface Sizes {
  [SIZES.SMALL]: string;
  [SIZES.MEDIUM]: string;
}

type SwitchButtonProps = {
  multiple: boolean;
  selected: boolean;
  type?: string;
  text: string;
  imageUrl?: string;
  onClick(e?: any): void;
  size: keyof Sizes;
  onKeyDown(e?: any): void;
  ariaDisabled?: boolean;
  altAnswerImage?: string;
  tabIndex: number;
};

type SwitchButtonState = {
  isImageLoaded: boolean;
}

export class SwitchButton extends Component<SwitchButtonProps, SwitchButtonState> {
  constructor(props: SwitchButtonProps) {
    super(props);
    this.state = { isImageLoaded: false };
  }
  static defaultProps = {
    size: SIZES.MEDIUM
  };

  handleImageLoaded = () => {
    this.setState({ isImageLoaded: true });
  };

  render() {
    const {
      onClick,
      text,
      selected,
      multiple,
      imageUrl,
      onKeyDown,
      ariaDisabled = false,
      altAnswerImage,
      tabIndex,
      size
    } = this.props;
    const { isImageLoaded } = this.state;
    return (
      <ButtonWrapper
        selected={selected}
        imageUrl={imageUrl}
        onClick={onClick}
        onKeyDown={onKeyDown}
        ariaDisabled={ariaDisabled}
        roleValue={multiple ? 'checkbox' : 'radio'}
        ariaChecked={selected}
        tabIndex={tabIndex}
      >
        {imageUrl && !isImageLoaded && <CircleLoader />}
        {imageUrl && (
          <AnswerImage
            alt={altAnswerImage}
            src={`${imageUrl}?height=260&amp;width=260&amp;scaleBySmallerSide=true`}
            onLoad={() => this.handleImageLoaded()}
          />
        )}
        <ButtonElement
          size={size}
          imageUrl={imageUrl}
          multiple={multiple}
          selected={selected}
          className="show-on-hover"
        >
          <CheckIcon size={9} name="ok" />
          {imageUrl && <CheckImageIcon size={60} name="check-mark" />}
        </ButtonElement>
        {text && (
          <TextElement appearance="p" selected={selected}>
            {text}
          </TextElement>
        )}
      </ButtonWrapper>
    );
  }
}

export default SwitchButton;
