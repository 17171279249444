import React from 'react';
import { localize } from 'core/localization';
import { withTheme } from 'styled-components';
import { Text, Icon, Button } from 'components/common';
import { BUTTON, SIZES } from 'constants/components';
import DocumentContentParser, { DocumentContent } from './components/DocumentContentParser';
import requester from '../../../../core/http/requester';
import {
  Container,
  HeaderContainer,
  TitleContainer,
  IconWrapper,
  TextWrapper,
  ButtonContainer,
  IframeContainer,
  Iframe,
  NotFound
} from './Document.styled';

type DocumentProps = {
  content: string;
  theme: { [key: string]: any };
  inverted: boolean;
};

type DocumentState = {
  data: DocumentContent;
  exists: boolean;
  inverted: boolean;
};

export class Document extends React.Component<DocumentProps, DocumentState> {
  _isMounted = false;

  constructor(props: DocumentProps) {
    super(props);
    this.state = {
      data: DocumentContentParser.parse(props.content) || {},
      inverted: props.inverted || false,
      exists: true
    };
  }

  async fileExists(link: string) {
    try {
      if (link && link.length) {
        const response = await requester.get(link, {});
        const body = await response.json();
        if (body && this._isMounted) {
          this.setState({ exists: body.exists });
        }
      }
    } catch (e) {
      console.log(e);
      throw e;
    }
  }

  async componentDidMount() {
    const { data } = this.state;
    this._isMounted = true;

    if (data.link && data.link.length) {
      await this.fileExists(data.existsLink);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { data, inverted, exists } = this.state;
    return (
      <Container data-type={data.type} inverted={inverted}>
        {data.title && data.link && (
          <HeaderContainer>
            <TitleContainer>
              <IconWrapper>
                <Icon
                  name="document"
                  color={inverted ? 'coverBgOverlayTextColor' : 'textColor'}
                  size={34}
                  fontSize={9}
                  text={data.type}
                />
              </IconWrapper>
              <TextWrapper>
                <Text appearance="h4">{data.title}</Text>
              </TextWrapper>
            </TitleContainer>
            <ButtonContainer>
              <Button
                textTransform="uppercase"
                size={SIZES.SMALL}
                layout={BUTTON.LAYOUT.SECONDARY}
                link={data.link}
                inverted={inverted}
                disabled={!exists}
              >
                {localize('[download]')} {exists && `(${data.size})`}
              </Button>
            </ButtonContainer>
          </HeaderContainer>
        )}
        {exists && data.iframe.src && (
          <IframeContainer>
            <Iframe width={data.iframe.width} height={data.iframe.height} src={data.iframe.src} />
          </IframeContainer>
        )}
        {!exists && <NotFound>{localize('[404 file not found]')}</NotFound>}
      </Container>
    );
  }
}

export default withTheme(Document);
