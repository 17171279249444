import React from 'react';
import { localize } from 'core/localization';
import ResultsBlock from '../ResultsBlock/ResultsBlock';
import Header from '../Header/Header';
import SectionTreeNode from '../SectionTreeNode/SectionTreeNode';
import QuestionTreeNode from '../QuestionTreeNode/QuestionTreeNode';
import LearningObjectiveTreeNode from '../LearningObjectiveTreeNode/LearningObjectiveTreeNode';

import {
  Sections,
  ExpandedTocContainer,
  ProgressMasteryScoreWrapper,
  ProgressMasteryScore,
  ProgressCaption,
  TocWrapper
} from './ExpandedToc.styled';

type ExpandedTocProps = {
  isExpanded: boolean;
  courseStructure: {
    title: string;
    sections: any[];
  };
  activeSectionId: string;
  activeQuestionId: string;
  navigateToIndex(): void;
  navigateToQuestion(sectionId: string, questionId: string): void;
  navigateToLearningObjective(sectionId: string): void;
  navigateToResults(): void;
  isResultsActive: boolean;
  isLearningObjectiveView: boolean;
  courseProgress: number;
  masteryScore: number;
  isCourseProgress: boolean;
  affectProgressSectionsCount: number;
};

function ExpandedTocSection({ props }: any) {
  const {
    courseStructure,
    activeSectionId,
    activeQuestionId,
    navigateToQuestion,
    navigateToLearningObjective,
    isExpanded,
    isLearningObjectiveView
  } = props;
  return courseStructure.sections.map((section: any, sectionIndex: any) => (
    <SectionTreeNode
      key={sectionIndex}
      isActive={section.id === activeSectionId}
      isPassed={section.isPassed}
      score={section.score}
      questions={section.questions}
      affectsProgress={section.affectsProgress}
      title={section.title}
      tabIndex={isExpanded ? 0 : -1}
      sectionId={section.id}
    >
      {section.learningObjective && (
        <LearningObjectiveTreeNode
          role="menuitem"
          tabIndex={1}
          isActive={section.id === activeSectionId && isLearningObjectiveView}
          onClick={() => navigateToLearningObjective(section.id)}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              navigateToLearningObjective(section.id);
            }
          }}
        />
      )}
      <ExpandedTocItems props={{ section, activeQuestionId, isExpanded, navigateToQuestion }} />
    </SectionTreeNode>
  ));
}

function ExpandedTocItems({ props }: any) {
  const { activeQuestionId, section, navigateToQuestion, isExpanded } = props;
  return props.section.questions.map((question: any, questionIndex: any) => (
    <QuestionTreeNode
      role="menuitem"
      key={questionIndex}
      question={question}
      isActive={question.id === activeQuestionId}
      tabIndex={isExpanded ? 0 : -1}
      {...(question.id !== activeQuestionId && {
        onClick: () => navigateToQuestion(section.id, question.id),
        onKeyDown: event => {
          if (event.key === 'Enter') {
            navigateToQuestion(section.id, question.id);
          }
        }
      })}
    />
  ));
}

class ExpandedToc extends React.Component<ExpandedTocProps, {}> {
  refContainer: any;
  constructor(props: ExpandedTocProps) {
    super(props);
    this.refContainer = React.createRef();
  }
  render() {
    const {
      courseStructure,
      activeSectionId,
      activeQuestionId,
      navigateToIndex,
      navigateToResults,
      navigateToQuestion,
      navigateToLearningObjective,
      isResultsActive,
      isExpanded,
      isLearningObjectiveView,
      courseProgress,
      masteryScore,
      isCourseProgress,
      affectProgressSectionsCount
    } = this.props;
    return (
      <React.Fragment>
        <TocWrapper>
          <ExpandedTocContainer
            ref={this.refContainer}
            isExpanded={isExpanded}
            aria-hidden={!isExpanded}
            ariaLabel={`${localize('[expanded nav section]')}`}
          >
            <Header
              courseTitle={courseStructure.title}
              navigateToIndex={navigateToIndex}
              tabIndex={isExpanded ? 0 : -1}
            />
            {isCourseProgress && affectProgressSectionsCount > 0 && (
              <ProgressMasteryScoreWrapper>
                <ProgressMasteryScore
                  progress={courseProgress}
                  passedMark={masteryScore}
                  passedMarkStyles={{
                    opacity: 0.3,
                    color: 'textColor'
                  }}
                  forwardedRef={this.refContainer}
                />
                <ProgressCaption>
                  {localize('[you complete x]', {
                    percentSuccess: courseProgress
                  })}
                </ProgressCaption>
              </ProgressMasteryScoreWrapper>
            )}

            <Sections role="menubar">
              <ExpandedTocSection
                props={{
                  courseStructure,
                  activeSectionId,
                  activeQuestionId,
                  navigateToIndex,
                  navigateToResults,
                  navigateToQuestion,
                  navigateToLearningObjective,
                  isResultsActive,
                  isExpanded,
                  isLearningObjectiveView
                }}
              />
            </Sections>
          </ExpandedTocContainer>
          <ResultsBlock
            ariaLabel={localize('[results]')}
            isActive={isResultsActive}
            navigateToResults={navigateToResults}
            tabIndex={isExpanded ? 0 : -1}
          />
        </TocWrapper>
      </React.Fragment>
    );
  }
}

export default ExpandedToc;
