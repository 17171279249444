import * as React from 'react';
import styled from 'styled-components';
import { getShortString } from 'utils/string';
import {
  Header1,
  Header2,
  Header3,
  Header4,
  Span,
  Paragraph,
  ellipsisMixin,
  sizeMixin,
  fontMixin
} from './Text.styled';

const components = {
  h1: Header1,
  h2: Header2,
  h3: Header3,
  h4: Header4,
  p: Paragraph,
  span: Span
};

interface TextAppearance {
  h1: string;
  h2: string;
  h3: string;
  h4: string;
  span: string;
  p: string;
}

type TextProps = {
  appearance: keyof TextAppearance;
  ellipsis?: boolean;
  children: React.ReactNode;
  maxLength?: number;
  size?: number;
  font?: string;
  title?: string;
  inverted?: any;
  theme?: any;
}

export class Text extends React.PureComponent<TextProps, {}> {
  refElement: any;
  children: string;

  constructor(props: TextProps) {
    super(props);
    this.refElement = React.createRef();
    this.children = '';
  }

  static defaultProps = {
    appearance: 'span'
  };

  componentDidMount() {
    if (
      this.props.appearance === 'h1' ||
      this.props.appearance === 'h2' ||
      this.props.appearance === 'h3'
    ) {
      this.refElement.current.focus();
    }
  }

  render() {
    const { appearance, children, maxLength, font, ...other } = this.props;
    if (maxLength && typeof children === 'string') {
      this.children = getShortString(children, maxLength);
    }
    let Wrapper = styled(components[appearance])`
      ${font && fontMixin(font)};
      ${(p: any) => p.ellipsis && ellipsisMixin};
      ${(p: any) => p.size && sizeMixin(p.size)};
    `;
    return (
      <Wrapper
        ref={this.refElement}
        {...other}
      >
        {children}
      </Wrapper>
    );
  }
}

export default Text;
