import * as React from 'react';
import Contents from 'components/contents/Contents';
import * as courseSelectors from 'store/course/selectors';
import { connect } from 'react-redux';

import AfterwordContainer from './courseAfterword.styled';
import { RootAppState } from 'store/types';

type AfterwordContainerProps = {
  isPassed: boolean;
  passedAfterwordsIds: string[];
  failedAfterwordsIds: string[];
};
export const CourseAfterword = (props: AfterwordContainerProps) => {
  const { isPassed, passedAfterwordsIds, failedAfterwordsIds } = props;
  let afterWordsIds = isPassed ? passedAfterwordsIds : failedAfterwordsIds;

  return (
    <React.Fragment>
      {afterWordsIds.length > 0 && (
        <AfterwordContainer>
          <Contents items={afterWordsIds} />
        </AfterwordContainer>
      )}
    </React.Fragment>
  );
};

function mapStateToProps(state: RootAppState) {
  return {
    isPassed: courseSelectors.isPassed(state),
    passedAfterwordsIds: courseSelectors.getPassedAfterwords(state),
    failedAfterwordsIds: courseSelectors.getFailedAfterwords(state)
  };
}

export default connect(mapStateToProps)(CourseAfterword);
