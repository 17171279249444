import styled from 'styled-components';
import media from 'components/mixins/media';
import { bodyBackground } from 'components/mixins/backgrounds';
import { BasicStyledSidebar, PageContent } from 'components/shell/Layout';
import { transparentize } from 'utils/color';
import { centerAbsoluteElement } from 'components/mixins/placement';
import Text from 'components/common/Text';
import { secondaryFont } from 'components/mixins/typography';
import { getCustomScrollbars } from 'components/mixins/customScrollbars';

export const AuthPageSidebar = styled(BasicStyledSidebar)``;

type AuthPageContentProps = {
  background: any;
};
export const AuthPageContent = styled(PageContent)<AuthPageContentProps>`
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  width: 50%;
  left: 50%;
  z-index: 100;
  overflow-y: auto;
  ${getCustomScrollbars()}
  ${props => bodyBackground(props.background.body)};
  ${media.tablet`
    position: relative;
    width: 100%;
    left: 0;
  `};
`;

export const AuthContainer = styled.div`
  width: 300px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  ${media.mobile`
    width: 260px;
  `};
`;

export const AuthSection = styled.section`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
`;

type CourseLogoProps = {
  altText: string;
  logoUrl: string;
};
export const CourseLogo = styled.img.attrs((props: CourseLogoProps) => ({
  alt: props.altText,
  src: props.logoUrl
}))<CourseLogoProps>`
  display: none;
  margin: 56px auto;
  max-width: 200px;
  max-height: 80px;

  ${media.tablet`
    display: block;
  `};
`;

export const CourseLogoSidebarWrapper = styled.div`
  width: 360px;
  height: 200px;
  position: absolute;
  ${centerAbsoluteElement};
`;

type CourseLogoSidebarProps = {
  altText: string;
  logoUrl: string;
};
export const CourseLogoSidebar = styled.img.attrs((props: CourseLogoSidebarProps) => ({
  alt: props.altText,
  src: props.logoUrl
}))<CourseLogoSidebarProps>`
  display: block;
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
`;

export const FormLabel = styled.label`
  ${secondaryFont};
  display: inline-block;
  font-size: 36px;
  font-weight: 300;
  line-height: 1.03;
  text-align: center;
  color: ${props => props.theme.colors.textColor};
  margin-top: 100px;

  ${media.tablet`
    margin-top: 0;
  `};
`;

export const AuthTextForm = styled(Text)`
  && {
    ${secondaryFont};
    font-size: 14px;
    text-align: center;
    font-weight: normal;
    margin: 32px auto;
    color: ${props => transparentize(props.theme.colors.textColor, 0.7)};
    line-height: 1.6;
    ${media.mobile`
      width: 220px;
    `};
  }
`;

export const AuthSocialLoginContainer = styled.div`
  position: relative;
  width: 320px;
`;

export const SwitcherFormContainer = styled.div`
  ${media.tablet`
    display: none;
  `};
  ${media.mobile`
    display: none;
  `};
`;
