import * as React from 'react';
import { Link } from 'react-router-dom';
import { localize } from 'core/localization';
import { LOGIN_PATH, SIGNUP_PATH } from 'constants/navigation';
import { LinksWrapper, ListElement, ListItemElements } from './SwitcherForm.styled';


type SwitcherFormProps = {
  signUp: boolean;
  logIn: boolean;
}
export class SwitcherForm extends React.Component<SwitcherFormProps, {}> {

  static defaultProps = {
    signUp: false,
    logIn: false
  };
  render() {
    const { signUp, logIn } = this.props;
    // eslint-disable-next-line
    return (
      <LinksWrapper>
        <ListElement>
          <ListItemElements
            ariaCurrent={signUp ? 'page' : false}
            className={signUp ? 'active' : 'inactive'}
          >
            <Link to={SIGNUP_PATH}>{localize('[signup]')}</Link>
          </ListItemElements>
          <ListItemElements
            ariaCurrent={logIn ? 'page' : false}
            className={logIn ? 'active' : 'inactive'}
          >
            <Link to={LOGIN_PATH}>{localize('[login]')}</Link>
          </ListItemElements>
        </ListElement>
      </LinksWrapper>
    );
  }
}

export default SwitcherForm;
