import { hasClass, findClosest } from 'utils/element';

const IMAGE_WRAPPER_CLASS_NAME = 'image-wrapper';

export default class ImageWrapper {
  static wrap(finder: any) {
    const images = finder.querySelectorAll('img');
    for (let i = 0; i < images.length; i++) {
      const image = images[i];
      if (findClosest(image, '.cropped-image')) {
        if (image.parentElement && hasClass(image.parentElement, IMAGE_WRAPPER_CLASS_NAME)) {
          return finder;
        }
      }
      const wrapper = document.createElement('figure');
      wrapper.classList.add(IMAGE_WRAPPER_CLASS_NAME);
      wrapper.style.float = image.style.cssFloat;
      if (
        image.parentElement &&
        image.parentElement.tagName === 'TD' &&
        image.parentElement.style.width === ''
      ) {
        wrapper.style.width = image.style.width;
        wrapper.style.height = image.style.height;
      }
      image.parentNode.insertBefore(wrapper, image);
      wrapper.appendChild(image);
    }
  }
}
