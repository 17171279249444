import { css } from 'styled-components';

export const TRANSITION_DURATION = '0.2s';

export const transition = (
  transitionProperty: any,
  transitionDuration: string | number = TRANSITION_DURATION,
  transitionFunction = 'ease-in-out',
  transitionDelay = 0
) => css`
  transition-property: ${transitionProperty};
  transition-timing-function: ${transitionFunction};
  transition-duration: ${toTimeString(transitionDuration)};
  transition-delay: ${toTimeString(transitionDelay)};
`;

function toTimeString(value: string | number) {
  return typeof value === 'string' ? value : `${value}ms`;
}

export const pointerOnHover = () => css`
  &:hover {
    cursor: pointer;
  }
`;

export const bounce = () => `
animation: bounce 0.5s linear;
@keyframes bounce{
  0%{
    transform-origin: center center;
    transform: scale(0, 0) translate(0,0,0);
  }
  30%{
    transform: scale(1.01, 1.01);
  }
  45%{
    transform: scale(0.95, 0.95);
  }
  60%{
    transform: scale(1.01, 1.01);
  }
  75%{
    transform: scale(0.95, 0.95);
  }
  100%{
    transform: scale(1, 1) translate(0,0,0);
  }
}`;

export const crossFade = (animateFrom: string) => {
  let position = '';
  if (animateFrom === 'fromTop') {
    position = '0, -16px';
  }
  if (animateFrom === 'fromRight') {
    position = '16px, 0';
  }
  if (animateFrom === 'fromBottom') {
    position = '0, 16px';
  }
  if (animateFrom === 'fromLeft') {
    position = '-16px, 0';
  }
  return `
  animation: crossFade${animateFrom} 0.2s ease-in-out;
  @keyframes crossFade${animateFrom}{
    0%{
      transform: translate(${position});
      opacity: 0;
    }
    100%{
      transform: translate(0, 0);
      opacity: 1;
    }
  }`;
};

export const shake = (animateFrom: string) => {
  let position = '';
  if (animateFrom === 'horizontal') {
    position = '3px, 0';
  }
  if (animateFrom === 'vertical') {
    position = '0, -3px';
  }
  return `
  animation: shake${animateFrom} 1s infinite ease-in-out;
  @keyframes shake${animateFrom}{
    0% {
      transform: translate(0, 0);
    }
    50% {
      transform: translate(${position});
    }
    100% {
      transform: translate(0, 0);
    }
  }
  `;
};

export const pulse = (color: any) => {
  const start = color.start || 'rgba(255,255,255, 0.5)';
  const middle = color.middle || 'rgba(255,255,255, 0.3)';
  const end = color.end || 'rgba(255,255,255, 0.05)';
  return `
  animation: pulse 1s infinite;
  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 ${start};
    }
    50% {
      box-shadow: 0 0 0 6px ${middle};
    }
    100% {
      box-shadow: 0 0 0 8px ${end};
    }
  }
  `;
};
