import * as React from 'react';
import { localize } from 'core/localization';
import { INPUT_TYPE } from 'constants/components';
import Input from '../Input';

type EmailInputProps = {
  userEmail: string;
  email?: string;
  textError: string;
  isUserExist?: boolean;
  validationStatusBeforeSubmit: boolean;
  checkIsUserExist?(email: string): void;
  onChange(e: any): void;
  setRefs(n: any): void;
  onFocus(e: any): void;
};

export class EmailInput extends React.Component<EmailInputProps, {}> {

  static defaultProps = {
    onFocus: () => { }
  };

  render() {
    const {
      userEmail,
      email,
      isUserExist,
      checkIsUserExist,
      onChange,
      setRefs,
      textError,
      validationStatusBeforeSubmit,
      onFocus
    } = this.props;
    return (
      <Input
        idInput="email"
        type={INPUT_TYPE.EMAIL}
        refInput={(node: any) => setRefs(node)}
        name="email"
        labelText={localize('[email]')}
        defaultValue={userEmail}
        placeholder="john@example.com"
        autoComplete="new-email"
        onChange={(e: any) => onChange(e)}
        validIcon={true}
        validationStatusBeforeSubmit={validationStatusBeforeSubmit}
        textError={textError}
        onBlur={checkIsUserExist ? (() => checkIsUserExist(email || '')) : (() => {})}
        isUserExist={isUserExist}
        onFocus={(e: any) => onFocus(e)}
      />
    );
  }
}

export default EmailInput;
