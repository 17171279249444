import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getEmail, getName } from 'store/user/selectors';
import { Form } from './AuthForm.styled';
import { RootAppState } from 'store/types';

type AuthFormProps = {
  renderContent: any;
  onSubmit(e: any, data: any): Promise<void>;
  formId: string;
  userEmail: string;
  userName: string;
  shortTermAccess?: boolean;
  isEmailPasswordCombinationNotExists?: boolean;
  isUserExist?: boolean;
  resetPasswordSendMessage?: boolean;
  checkIsUserExist?(email: string): void;
  hideMessage?(): void;
};
type AuthFormState = {
  [key: string]: any;
  email: string;
  name: string;
  password: string;
  validationStatusBeforeSubmit: any;
  fieldRefs: { [key: string]: any };
};

export class AuthForm extends Component<AuthFormProps, AuthFormState> {
  fieldRefs: { [key: string]: any };
  constructor(props: AuthFormProps) {
    super(props);

    this.state = {
      email: '',
      name: '',
      password: '',
      validationStatusBeforeSubmit: {
        name: true,
        email: true,
        password: true
      },
      fieldRefs: {},
      shortTermAccess: true
    };
    this.fieldRefs = {};
  }

  componentDidMount() {
    const { userEmail, userName } = this.props;

    if (userEmail || userName) {
      this.setState({
        email: userEmail || '',
        name: userName || ''
      });
    }

    this.setState({
      fieldRefs: this.fieldRefs
    });
  }

  onChange = (event: any) => {
    event.target.classList.add('changed');
    this.setState({ [event.target.name]: event.target.value });
  };

  beforeSubmitValidation = (event: any, onSubmit: any) => {
    event.preventDefault();
    const { hideMessage, isUserExist } = this.props;
    let validationStatusBeforeSubmit: { [key: string]: boolean } = {};
    for (const ref in this.fieldRefs) {
      validationStatusBeforeSubmit = {
        ...validationStatusBeforeSubmit,
        [ref]: this.returnValidationStatusElem(this.fieldRefs[ref])
      };
    }

    this.setState({
      validationStatusBeforeSubmit
    });

    for (const ref in validationStatusBeforeSubmit) {
      if (!validationStatusBeforeSubmit[ref]) {
        return;
      }
    }

    if (typeof hideMessage === 'function') {
      hideMessage();
    }

    if (!isUserExist) {
      onSubmit(event, {
        ...this.state,
        email: this.state.email.toLowerCase(),
        shortTermAccess: this.props.shortTermAccess
      });
    }
  };

  returnValidationStatusElem = (elem: any) => {
    return elem ? elem.validity.valid : false;
  };

  setValidationStatusElem = (elem: any) => {
    const elemValid = elem ? elem.validity.valid : false;
    this.setState({ validationStatusBeforeSubmit: { [elem.name]: elemValid } });
  };

  setRefs = (element: any) => {
    if (element) {
      this.fieldRefs[element.name] = element;
    }
  };

  render() {
    const { fieldRefs } = this.state;
    const {
      formId,
      onSubmit,
      resetPasswordSendMessage,
      shortTermAccess,
      renderContent,
      isEmailPasswordCombinationNotExists,
      isUserExist
    } = this.props;
    const props = {
      refEmail: fieldRefs.email,
      onChange: this.onChange,
      setRefs: this.setRefs,
      setValidationStatusElem: this.setValidationStatusElem,
      validationStatusBeforeSubmit: this.state.validationStatusBeforeSubmit,
      email: this.state.email,
      isEmailPasswordCombinationNotExists,
      resetPasswordSendMessage,
      shortTermAccess,
      isUserExist
    };
    return (
      <Form
        idElement={formId}
        onSubmit={event => {
          this.beforeSubmitValidation(event, onSubmit);
        }}
        noValidate
      >
        {renderContent(props)}
      </Form>
    );
  }
}

function mapStateToProps(state: RootAppState) {
  return {
    userEmail: getEmail(state),
    userName: getName(state)
  };
}

export default connect(mapStateToProps)(AuthForm);
