import * as React from 'react';
import { createContainer } from 'components/common/helpers/containerComponentCreator';

import { Container, ContentContainer, IconContainer, Header, Body } from './ContentBlock.styled';

type ContentBlockProps = {
  role: string;
  tabIndex?: number;
  ariaLabel?: string;
  contentRef?(): void;
  children: any[];
  isCorrect?: boolean;
  isLoading?: boolean;
  value?: string;
  title?: string;
};

export const ContentBlock = ({ contentRef, children, role, tabIndex, ariaLabel }: ContentBlockProps) => {
  const { Icon, Content, Title } = getChildrenObject(children);
  children.forEach((child: React.ReactChildren) => {
    validationChildren(child)
  })
  return (
    <Container ariaLabel={ariaLabel || ''} role={role} tabIndex={tabIndex} ref={contentRef}>
      <Header>
        <IconContainer>{Icon}</IconContainer>
        {Title}
      </Header>
      {Content && (
        <Body>
          <ContentContainer tabIndex={tabIndex}>{Content}</ContentContainer>
        </Body>
      )}
    </Container>
  );
};

const typeNames = {
  icon: 'icon',
  title: 'title',
  content: 'content'
};
ContentBlock.Icon = createContainer(typeNames.icon);
ContentBlock.Title = createContainer(typeNames.title);
ContentBlock.Content = createContainer(typeNames.content);

function validationChildren(children: any) {
  const childrenObj = getChildrenObject(children);

  if (!childrenObj.Icon) {
    return new Error(`ContentBlock: Invalid Prop children, must contain ContentBlock.Icon`);
  }
  if (!childrenObj.Title) {
    return new Error(`Page: Invalid Prop children, must contain ContentBlock.Title`);
  }
}

function getChildrenObject(children: any[]) {
  return React.Children.toArray(children).reduce((acc: any, child: any) => {
    switch (child.type.typeName) {
      case typeNames.icon: {
        acc.Icon = child;
        break;
      }
      case typeNames.content: {
        acc.Content = child;
        break;
      }
      case typeNames.title: {
        acc.Title = child;
        break;
      }
      default: {
        break;
      }
    }
    return acc;
  }, {});
}

export default ContentBlock;
