import styled from 'styled-components';
import { transition } from 'components/mixins/interactivity';
import { transparentize } from 'utils/color';
import { secondaryFont } from 'components/mixins/typography';

type ActionButtonsContainerProps = {
  isHidden: boolean;
}
export const ActionButtonsContainer = styled.div<ActionButtonsContainerProps>`
  ${transition('opacity', '0.2s')};
  opacity: ${props => (props.isHidden ? 0 : 1)};
  position: relative;
  display: flex;
  flex-wrap: wrap;

  & > button {
    margin-bottom: 20px;
    margin-right: 24px;
  }

  & > .tooltip {
    bottom: 105%;
    left: 0;
  }
`;

type NavButtonsBlockProps = {
  tabIndex: number;
}
export const NavButtonsBlock = styled.div.attrs((props: NavButtonsBlockProps) => ({
  tabIndex: props.tabIndex
})) <NavButtonsBlockProps>`
  display: flex;
  flex-wrap: wrap;
  margin-top: 49px;

  & > button {
    margin: 0 24px 20px 0;
  }
  &:focus {
    outline: none;
  }
`;

export const SurveyHint = styled.p`
  ${secondaryFont};
  font-size: 12px;
  line-height: 24px;
  margin-bottom: 20px;
  color: ${props => transparentize(props.theme.colors.textColor, 0.5)};
  align-self: center;
`;
