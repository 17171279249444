import * as React from 'react';
import { connect } from 'react-redux';
import { getContents } from 'store/contents/selectors';
import ContentList from '../../ContentList/ContentList';

import {
  Wrapper,
  ExpandableBlock,
  ExpandableBlockWrapper,
  ExpandableContentWrapper,
  ExpandIcon,
  ExpandIconWrapper,
  ContentListWrapper
} from './ShowMore.styled';
import { RootAppState } from 'store/types';

type ShowMoreProps = {
  content: string;
  childrenContents: any[];
  inverted: boolean;
  childrenElements: any;
}

type ShowMoreState = {
  isExpanded: boolean
}

export class ShowMore extends React.Component<ShowMoreProps, ShowMoreState> {
  constructor(props: ShowMoreProps) {
    super(props);
    this.state = {
      isExpanded: false
    };
  }

  static defaultProps = {
    childrenContents: []
  }

  expandBlock = () => {
    const { isExpanded } = this.state;
    this.setState({
      isExpanded: !isExpanded
    });
  };

  handleOnKeyDown = (event: any) => {
    if (event.keyCode === 32 || event.keyCode === 13) {
      event.preventDefault();
      this.expandBlock();
    }
  };

  render() {
    const { isExpanded } = this.state;
    const { childrenContents, content, inverted } = this.props;
    return (
      <Wrapper isExpanded={isExpanded} inverted={inverted}>
        <ExpandableBlockWrapper
          role="button"
          tabIndex={1}
          onClick={this.expandBlock}
          onKeyDown={this.handleOnKeyDown}
        >
          <ExpandableBlock
            dangerouslySetInnerHTML={{
              __html: content
            }}
            inverted={inverted}
          />
          <ExpandIconWrapper isExpanded={isExpanded}>
            <ExpandIcon size={isExpanded ? 2 : 16} name={isExpanded ? 'minus' : 'plus-tiny'} />
          </ExpandIconWrapper>
        </ExpandableBlockWrapper>

        <ExpandableContentWrapper
          className={isExpanded ? 'active' : ''}
          height={isExpanded ? 'auto' : 0}
          duration={300}
        >
          <ContentListWrapper>
            {childrenContents.length > 0 && (
              <ContentList items={childrenContents} isLoading={false} inverted={inverted} />
            )}
          </ContentListWrapper>
        </ExpandableContentWrapper>
      </Wrapper>
    );
  }
}

function mapStateToProps(state: RootAppState, ownProps: ShowMoreProps) {
  return { childrenContents: getContents(state, ownProps.childrenElements) };
}

export default connect(mapStateToProps)(ShowMore);
