import * as React from 'react';
import { localize } from 'core/localization';
import ContentBlock from 'components/contents/ContentBlock';
import Contents from 'components/contents/Contents';
import { Icon, Separator, Collapse } from 'components/common';

import { ControlContainer, Button, ButtonSeparator, TitleText, ContainerHint } from './Hint.styled';

type HintProps = {
  items: Array<string>
};

type HintState = {
  isExpanded: boolean;
}

export class Hint extends React.PureComponent<HintProps, HintState> {

  constructor(props: HintProps) {
    super(props);
    this.state = { isExpanded: false };
  }

  componentDidUpdate(prevProps: HintProps) {
    if (prevProps.items !== this.props.items) {
      this.setState({ isExpanded: false });
    }
  }

  toggleIsExpanded = () => {
    this.setState(prevState => ({ isExpanded: !prevState.isExpanded }));
  };

  render() {
    const { items } = this.props;
    const { isExpanded } = this.state;
    return (
      <ContainerHint>
        <ControlContainer>
          <ButtonSeparator opacity={0.1} color="textColor" />
          <Button
            tabIndex={1}
            layout="secondary"
            onClick={this.toggleIsExpanded}
            textTransform="uppercase"
            size="small"
            aria-expanded={isExpanded}
          >
            {localize(isExpanded ? '[hide hint]' : '[show hint]')}
          </Button>
          <ButtonSeparator opacity={0.1} color="textColor" />
        </ControlContainer>
        <Collapse
          isExpanded={isExpanded}
          scrollIntoView={true}
          duration={300}
          opacityDuration="500"
        >
          <Separator margin={32} opacity={0.1} color="textColor" />
          <ContentBlock title="Hint" role="status">
            <ContentBlock.Icon>
              <Icon name="hint" color="mainColor" size={22} />
            </ContentBlock.Icon>
            <ContentBlock.Title>
              <TitleText>{localize('[hint]')}</TitleText>
            </ContentBlock.Title>
            <ContentBlock.Content>
              <Contents items={items} />
            </ContentBlock.Content>
          </ContentBlock>
        </Collapse>
      </ContainerHint>
    );
  }
}
export default Hint;
