import styled from 'styled-components';
import { transparentize } from 'utils/color';

export const ScenarioIframeSection = styled.div`
  width: 100%;
  & > iframe {
    width: 100%;
  }
`;
export const ScenarioResults = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props => transparentize(props.theme.colors.textColor, 0.02)};
  & > p {
    color: ${props => transparentize(props.theme.colors.textColor, 0.6)};
    font-size: 18px;
    font-weight: bold;
    margin-left: 15px;
  }
`;

export const IconContainer = styled.div`
  width: 29px;
  height: 29px;
  border: 2px solid ${props => transparentize(props.theme.colors.textColor, 0.6)};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  & > .icon {
    color: ${props => transparentize(props.theme.colors.textColor, 0.6)};
    font-size: 13px;
  }
`;
