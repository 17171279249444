import { INDEX_PATH } from 'constants/navigation';
import eventEmitter, { events } from 'core/events/eventEmitter';
import contentLoader from 'core/http/contentLoader';
import courseMapper from 'core/mappers/course';
import progressStorage from 'core/progressStorage';
import * as contentsActions from 'store/contents/actions';
import {
  getAttemptId,
  getCourse,
  getPreviewQuestionId,
  hasBeenContinued,
  isCourseAccessLimited
} from 'store/course/selectors';
import { initializeScorm, initializeXapi, subscribeXapi, unsubscribeXapi } from 'store/modules/actions';
import { isXapiInitialized, shouldInitializeXapi } from 'store/modules/selectors';
import * as questionActions from 'store/questions/actions';
import { getAffectProgressQuestions, getQuestions, getQuestionSection } from 'store/questions/selectors';
import * as sectionActions from 'store/sections/actions';
import { getAffectProgressSections, getSections } from 'store/sections/selectors';
import { getMasteryScoreValue, isOverallMasteryScore, isPreviewMode, isScormMode } from 'store/settings/selectors';
import { getUser, isAnonymous } from 'store/user/selectors';
import { getQuestionUrl } from 'utils/navigation';
import { guidWithHyphens } from 'utils/object';
import { getValueFromUrl } from 'utils/window';
import resourceLoader from '../../core/http/resourceLoader';
import { isShowContentPagesEnabled } from '../settings/selectors';
import { ThunkResult } from '../types';
import { isReviewMode } from './../settings/selectors';
import { ActionTypes } from './types';

export const markCourseAsPassed = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  const affectProgressSections = getAffectProgressSections(getState());
  const isCoursePassed =
    getMasteryScoreValue(getState()) === 0 || affectProgressSections.length === 0;

  if (isCoursePassed) {
    affectProgressSections.forEach(section =>
      dispatch(sectionActions.onSectionIsPassed(section.id))
    );

    await dispatch({
      type: ActionTypes.COURSE_IS_PASSED,
      payload: {
        isPassed: true
      }
    });
  }
};

export const updateScorePerCourse = (): ThunkResult<Promise<void>> => async (
  dispatch,
  getState
) => {
  const affectProgressQuestions = getAffectProgressQuestions(getState());
  const masteryScore = getMasteryScoreValue(getState());

  const correctQuestions = affectProgressQuestions.filter(question => question.isAnsweredCorrectly);

  const score =
    affectProgressQuestions.length > 0
      ? Math.floor((correctQuestions.length * 100) / affectProgressQuestions.length)
      : 100;

  const coursePassed = score >= masteryScore;
  dispatch({
    type: ActionTypes.COURSE_SCORE_UPDATED,
    payload: {
      score,
      isPassed: coursePassed
    }
  });
};

export const updateScorePerSection = (): ThunkResult<Promise<void>> => async (
  dispatch,
  getState
) => {
  const sections = getAffectProgressSections(getState());
  const totalScore = sections.reduce((sum, section) => sum + section.score, 0);
  const score = sections.length > 0 ? Math.floor(totalScore / sections.length) : 100;
  const coursePassed = sections.length
    ? sections.every(section => section.isPassed === true)
    : true;
  dispatch({
    type: ActionTypes.COURSE_SCORE_UPDATED,
    payload: {
      score,
      isPassed: coursePassed
    }
  });
};

export const updateScore = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  const masteryScorePerCourse = isOverallMasteryScore(getState());

  if (masteryScorePerCourse) {
    dispatch(updateScorePerCourse());
  } else {
    dispatch(updateScorePerSection());
  }
};

export const updateProgress = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  dispatch(updateScore());
  eventEmitter.emit(events.COURSE_PROGRESSED, {
    ...getState(),
    course: getCourse(getState())
  });
};

export const load = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  dispatch({ type: ActionTypes.COURSE_DATA_LOADING_STARTED });
  try {
    const showContentPages = isShowContentPagesEnabled(getState());
    const data = await contentLoader.loadCourseData();
    const { course, sections, questions, contents } = courseMapper.map(data, showContentPages);
    if (getValueFromUrl('questionId')) {
      course.previewQuestionId = getValueFromUrl('questionId');
    }
    resourceLoader.cacheBuster = course.createdOn;
    dispatch(questionActions.questionsLoaded(questions));
    dispatch(sectionActions.sectionsLoaded(sections));
    dispatch(contentsActions.contentsLoaded(contents));
    await dispatch({ type: ActionTypes.COURSE_DATA_LOADED, payload: course });
    eventEmitter.emit(events.APP_INITIALIZED, {
      state: getState(),
      isScormMode: isScormMode(getState())
    });
  } catch (e) {
    dispatch({ type: ActionTypes.COURSE_DATA_LOADING_FAILED, reason: e });
    throw e;
  }
};

export const restoreProgress = (): ThunkResult<Promise<boolean>> => async (
  dispatch,
  getState
): Promise<boolean> => {
  const isProgressRestored = await progressStorage.restoreProgress();
  if (!isProgressRestored) {
    return false;
  }
  const questions = getQuestions(getState());
  const answers = progressStorage.getAnswers(questions);
  answers.forEach(({ id, response }: any) => {
    dispatch(questionActions.restoreProgress(id, response));
  });

  dispatch({
    type: ActionTypes.COURSE_PROGRESS_RESTORED,
    payload: { attemptId: progressStorage.attemptId }
  });
  return true;
};

export const onCourseLaunched = (attemptId?: string): ThunkResult => dispatch => {
  dispatch({
    type: ActionTypes.COURSE_LAUNCHED,
    payload: { attemptId }
  });
  eventEmitter.emit(events.COURSE_LAUNCHED, attemptId);
};

export const onCourseStarted = (): ThunkResult<Promise<void>> => async dispatch => {
  await dispatch({
    type: ActionTypes.COURSE_STARTED
  });

  await eventEmitter.emit(events.COURSE_STARTED);
};

export const launch = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  if (isCourseAccessLimited(getState())) {
    return INDEX_PATH;
  }

  await dispatch(markCourseAsPassed());

  if (isPreviewMode(getState()) || isReviewMode(getState())) {
    dispatch(onCourseLaunched());
    dispatch(onCourseStarted());
    const previewQuestionId = getPreviewQuestionId(getState());
    if (previewQuestionId) {
      const previewSectionId = getQuestionSection(getState(), previewQuestionId);
      const questionUrl = getQuestionUrl(previewSectionId, previewQuestionId);
      dispatch(clearPreviewQuestionId());
      return questionUrl;
    }
    return INDEX_PATH;
  }

  if (isScormMode(getState())) {
    await dispatch(initializeScorm());
  } else {
    if (isAnonymous(getState())) {
      await eventEmitter.emit(events.APP_INITIALIZED, {
        state: getState(),
        isScormMode: isScormMode(getState())
      });
    }
    await eventEmitter.emit(events.USER_AUTHENTICATED, getUser(getState()));
  }

  const restoreStatus = await dispatch(restoreProgress());

  let attemptId = getAttemptId(getState());
  if (!attemptId) {
    attemptId = guidWithHyphens();
  }

  // TODO: events
  dispatch(onCourseLaunched(attemptId));

  if (!restoreStatus) {
    await eventEmitter.emit(events.COURSE_ATTEMPT_STARTED, getState());
  }

  const state = getState();
  if (shouldInitializeXapi(state)) {
    await dispatch(initializeXapi());
    await dispatch(subscribeXapi());
  }

  if (hasBeenContinued(getState())) {
    return progressStorage.url || INDEX_PATH;
  }

  await dispatch({
    type: ActionTypes.COURSE_STARTED
  });

  await eventEmitter.emit(events.COURSE_STARTED);

  if (
    getMasteryScoreValue(getState()) === 0 ||
    getAffectProgressSections(getState()).length === 0
  ) {
    await eventEmitter.emit(events.COURSE_PROGRESSED, {
      ...getState(),
      course: getCourse(getState())
    });
  }

  return INDEX_PATH;
};

export const cleanup = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  dispatch({
    type: ActionTypes.COURSE_CLEANUP
  });

  const sections = getSections(getState());
  sections.forEach(section => {
    dispatch(sectionActions.cleanup(section.id));
  });
  const questions = getQuestions(getState());
  questions.forEach(question => {
    dispatch(questionActions.cleanup(question.id));
  });

  if (isXapiInitialized(getState())) {
    await dispatch(unsubscribeXapi());
  }
};

export const finished = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  dispatch({
    type: ActionTypes.COURSE_FINISHED
  });
  eventEmitter.emit(events.COURSE_FINISHED, getCourse(getState()));
};

export const finalized = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  dispatch({
    type: ActionTypes.COURSE_FINALIZED
  });
  eventEmitter.emit(events.COURSE_FINALIZED, getCourse(getState()));
};

export const startNewAttempt = (): ThunkResult<Promise<void>> => async dispatch => {
  await dispatch(cleanup());
  await progressStorage.removeProgress();

  await dispatch(launch());
};

export const clearPreviewQuestionId = (): ThunkResult => dispatch => {
  dispatch({ type: ActionTypes.CLEAR_PREVIEW_QUESTION_ID });
};

export const certificateDownloaded = (
  isCertificateDownloaded: boolean
): ThunkResult => dispatch => {
  dispatch({
    type: ActionTypes.CERTIFICATE_DOWNLOADED,
    payload: { isCertificateDownloaded }
  });
};

export const evaluate = (data: { score: number; response: any }) => () => {
  const { score, response } = data;
  eventEmitter.emit(events.COURSE_EVALUATED, {
    score,
    response
  });
};
