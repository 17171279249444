export default class TableWrapper {
  static wrap(finder: any) {
    const tables = finder.querySelectorAll('table');
    for (let i = 0; i < tables.length; i++) {
      const table = tables[i];
      const wrapper = document.createElement('figure');
      wrapper.classList.add('table-wrapper');
      wrapper.style.float = table.getAttribute('align');
      table.parentNode.insertBefore(wrapper, table);
      wrapper.appendChild(table);
    }
  }
}
