import { css } from 'styled-components';

const commonCSS = css`
  * {
    box-sizing: border-box;
    word-wrap: break-word;
    -webkit-overflow-scrolling: touch; /* smooth scrolling on safari */
  }
  html,
  body,
  #root {
    width: 100%;
    height: 100%;
    font-size: 16px;
    line-height: 32px;
  }
`;

export default commonCSS;
