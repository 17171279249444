import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { localize } from 'core/localization';
import Trophy from 'assets/figures/Trophy';
import Rocket from 'assets/figures/Rocket';
import withNavigation from 'components/hocs/withNavigation';
import { StatusTooltip, Button, Link, CourseLogo } from 'components/common';
import * as courseSelectors from 'store/course/selectors';
import * as courseActions from 'store/course/actions';
import * as popupActions from 'store/popup/actions';
import CloseCoursePopup from 'components/popups/closeCourse';
import NpsPopup from 'components/popups/nps';
import startNewAttempt from 'components/popups/startNewAttempt';
import {
  POPUP_ID_NPS_POPUP,
  POPUP_ID_CLOSE_COURSE,
  POPUP_ID_START_NEW_ATTEMPT
} from 'constants/popups';
import * as settingSelectors from 'store/settings/selectors';
import { isAuthorized } from 'store/user/selectors';
import { getAffectProgressSectionsCount, getSectionsPassedCount } from 'store/sections/selectors';
import certificateLoader from 'core/documentLoaders/certificateLoader';
import {
  getAffectProgressQuestionsCount,
  getCorrectlyAffectProgressAnsweredQuestionsCount
} from 'store/questions/selectors';
import { TOOLTIP, LINK } from 'constants/components';
import CourseAfterword from 'components/common/courseAfterword';
import { isLtiInitialized } from 'store/modules/selectors';
import { RootAppState } from 'store/types';
import {
  Container,
  Figure,
  Header,
  StyledText,
  ButtonWrapper,
  SeparatorElement,
  WidthLimiterContainer
} from './ResultsPage.styled';

type ResultsPageProps = {
  isPassed?: boolean;
  isPreviewMode: boolean;
  isReviewMode: boolean;
  navigateToIndex(): void;
  navigateToPrevPage(): void;
  checkHasPrevPage(): any;
  affectProgressQuestionsCount: number;
  affectProgressSectionsCount: number;
  correctlyAnsweredQuestionsCount: number;
  title: string;
  isScormMode: boolean;
  isLti: boolean;
  courseActions: { [key: string]: any };
  courseId: string;
  templateId: string;
  score: number;
  logoUrl: string;
  logoShown: boolean;
  isCrossDeviceSavingEnabled: boolean;
  learnStorageUrl: string;
  selectedLanguage: string;
  courseHasBeenContinued: boolean;
  userAuthorized: boolean;
  isAllowedDownloadCertificate: boolean;
  certificateDownloaded: boolean;
  popupActions: { [key: string]: any };
  isNpsEnabled: boolean;
  isOverallMasteryScore: boolean;
  sectionsPassedCount: number;
};

type ResultsPageState = {
  isCertificateLoading: boolean;
  showCertificateLoadedError: boolean;
  npsPopupWasOpened: boolean;
};
export class ResultsPage extends PureComponent<ResultsPageProps, ResultsPageState> {
  constructor(props: ResultsPageProps) {
    super(props);
    this.state = {
      isCertificateLoading: false,
      showCertificateLoadedError: false,
      npsPopupWasOpened: false
    };
  }

  continueCourse = () => {
    const { checkHasPrevPage, navigateToPrevPage, navigateToIndex } = this.props;
    if (checkHasPrevPage()) {
      navigateToPrevPage();
    } else {
      navigateToIndex();
    }
  };

  openNpsPopup({ certificateButton = false } = {}) {
    this.props.popupActions.openPopup({
      popupId: POPUP_ID_NPS_POPUP,
      popupAriaLabelKey: '[aria label close nps popup]',
      component: NpsPopup,
      fromDownloadCertificateButton: certificateButton,
      disablePopupClosing: true,
      disableBottomLine: true
    });
    this.setState({ npsPopupWasOpened: true });
  }

  openStartNewAttemptPopup = () => {
    const { navigateToIndex } = this.props;
    this.props.popupActions.openPopup({
      popupId: POPUP_ID_START_NEW_ATTEMPT,
      popupAriaLabelKey: '[aria label start new attempt popup]',
      component: startNewAttempt,
      newAttempt: this.props.courseActions.startNewAttempt,
      navigateToIndex,
      disablePopupClosing: false,
      disableBottomLine: true
    });
  };

  submitResults = async () => {
    await this.props.courseActions.finished();

    if (!this.props.isNpsEnabled) {
      this.props.popupActions.openPopup({
        popupId: POPUP_ID_CLOSE_COURSE,
        popupAriaLabelKey: '[aria label close course popup]',
        component: CloseCoursePopup,
        disablePopupClosing: true
      });
      await this.props.courseActions.finalized();
    }

    if (this.props.isNpsEnabled) {
      this.openNpsPopup();
    }
  };

  downloadCertificate = async () => {
    const data = {
      courseId: this.props.courseId,
      templateId: this.props.templateId,
      courseTitle: this.props.title,
      score: this.props.score,
      logoUrl: this.props.logoUrl
    };
    this.setState({ isCertificateLoading: true });

    const isCertificateDownloaded = await certificateLoader.downloadCertificate(
      data,
      this.props.learnStorageUrl,
      this.props.selectedLanguage
    );
    await this.props.courseActions.certificateDownloaded(isCertificateDownloaded);
    this.setState({
      isCertificateLoading: false,
      showCertificateLoadedError: !isCertificateDownloaded
    });

    if (this.props.isNpsEnabled && !this.state.npsPopupWasOpened) {
      this.openNpsPopup({ certificateButton: true });
    }
  };

  closeCourse = () => {
    const { isNpsEnabled, userAuthorized } = this.props;
    this.props.popupActions.openPopup({
      popupId:
        isNpsEnabled && !this.state.npsPopupWasOpened ? POPUP_ID_NPS_POPUP : POPUP_ID_CLOSE_COURSE,
      popupAriaLabelKey:
        isNpsEnabled && !this.state.npsPopupWasOpened
          ? '[aria label close nps popup]'
          : '[aria label close course popup]',
      component:
        isNpsEnabled && userAuthorized && !this.state.npsPopupWasOpened
          ? NpsPopup
          : CloseCoursePopup,
      disablePopupClosing: true,
      disableBottomLine: isNpsEnabled
    });
  };

  hidePopover = async () => {
    this.setState({ showCertificateLoadedError: false });
  };

  componentDidMount() {
    document.title = `${localize('[title for results page]')} | ${this.props.title}`;
  }

  renderDownloadCertificateButton() {
    const { courseHasBeenContinued, certificateDownloaded } = this.props;
    return (
      <ButtonWrapper>
        <StatusTooltip
          popoverPosition={'top'}
          layout={TOOLTIP.INCORRECT}
          showTooltip={this.state.showCertificateLoadedError}
          message={localize('[certificate failed load]')}
          popoverButton={
            <Button
              type="submit"
              layout={courseHasBeenContinued || certificateDownloaded ? 'secondary' : 'primary'}
              tabIndex={1}
              onClick={this.downloadCertificate}
              isLoading={this.state.isCertificateLoading}
              onBlur={this.hidePopover}
            >
              {localize('[download certificate]')}
            </Button>
          }
        />
      </ButtonWrapper>
    );
  }

  renderCloseCourseButton() {
    return (
      <ButtonWrapper>
        <Button layout={'secondary'} tabIndex={1} onClick={this.closeCourse}>
          {localize('[close course]')}
        </Button>
      </ButtonWrapper>
    );
  }

  renderContinueCourseButton() {
    return (
      <ButtonWrapper>
        <Button tabIndex={1} onClick={this.continueCourse}>
          {localize('[continue course]')}
        </Button>
      </ButtonWrapper>
    );
  }

  renderStartNewAttemptButton() {
    return (
      <Link
        text={localize('[start new attempt]')}
        layout={LINK.LAYOUT.FORM}
        onClick={this.openStartNewAttemptPopup}
      />
    );
  }

  renderSubmitResultsButton() {
    const { isPassed } = this.props;

    return (
      <ButtonWrapper>
        <Button
          layout={isPassed ? 'primary' : 'secondary'}
          tabIndex={1}
          onClick={this.submitResults}
        >
          {localize('[submit results]')}
        </Button>
      </ButtonWrapper>
    );
  }

  render() {
    const {
      isPassed,
      isPreviewMode,
      isReviewMode,
      affectProgressQuestionsCount,
      correctlyAnsweredQuestionsCount,
      isScormMode,
      isLti,
      isAllowedDownloadCertificate,
      userAuthorized,
      isCrossDeviceSavingEnabled,
      affectProgressSectionsCount,
      isOverallMasteryScore,
      sectionsPassedCount,
      logoUrl,
      logoShown
    } = this.props;
    return (
      <Container logoShown={logoShown}>
        {logoShown && <CourseLogo logoUrl={logoUrl} />}
        <Figure>{isPassed ? <Trophy /> : <Rocket />}</Figure>
        <Header appearance="h2">
          {localize(isPassed ? '[congratulations]' : '[course is not passed yet]')}
        </Header>
        <StyledText>
          {localize(
            isPassed
              ? '[you passed the course successfully]'
              : '[you have not passed the course yet]'
          )}
        </StyledText>
        &nbsp;
        {affectProgressQuestionsCount > 0 && isOverallMasteryScore && (
          <StyledText>
            {localize('[you correctly answered X questions out of Y]', {
              correctQuestionsCount: correctlyAnsweredQuestionsCount,
              totalQuestionsCount: affectProgressQuestionsCount
            })}
          </StyledText>
        )}
        {affectProgressSectionsCount > 0 && !isOverallMasteryScore && (
          <StyledText>
            {localize('[you passed X out of Y sections]', {
              correctSectionsCount: sectionsPassedCount,
              totalSectionsCount: affectProgressSectionsCount
            })}
          </StyledText>
        )}
        <Container>
          <WidthLimiterContainer>
            {isAllowedDownloadCertificate &&
              isPassed &&
              userAuthorized &&
              isCrossDeviceSavingEnabled &&
              !isScormMode &&
              this.renderDownloadCertificateButton()}
            {!isPassed && this.renderContinueCourseButton()}
            {isPassed && !isScormMode && !isLti && this.renderCloseCourseButton()}
            {!isPreviewMode && !isReviewMode && isPassed && this.renderStartNewAttemptButton()}
            {(isScormMode || isLti) && this.renderSubmitResultsButton()}
          </WidthLimiterContainer>
          <CourseAfterword />
        </Container>
        <SeparatorElement />
      </Container>
    );
  }
}

function mapStateToProps(state: RootAppState) {
  return {
    isPassed: courseSelectors.isPassed(state),
    isPreviewMode: settingSelectors.isPreviewMode(state),
    isReviewMode: settingSelectors.isReviewMode(state),
    title: courseSelectors.getCourseTitle(state),
    affectProgressQuestionsCount: getAffectProgressQuestionsCount(state),
    affectProgressSectionsCount: getAffectProgressSectionsCount(state),
    correctlyAnsweredQuestionsCount: getCorrectlyAffectProgressAnsweredQuestionsCount(state),
    isScormMode: settingSelectors.isScormMode(state),
    isLti: isLtiInitialized(state),
    courseId: courseSelectors.getCourseId(state),
    templateId: courseSelectors.getTemplateId(state),
    score: courseSelectors.getScore(state),
    logoUrl: settingSelectors.getLogo(state),
    logoShown: settingSelectors.isMainLogoShown(state),
    isCrossDeviceSavingEnabled: settingSelectors.isCrossDeviceSavingEnabled(state),
    learnStorageUrl: settingSelectors.getLearnServiceUrl(state),
    selectedLanguage: settingSelectors.getSelectedLanguage(state),
    courseHasBeenContinued: courseSelectors.hasBeenContinued(state),
    userAuthorized: isAuthorized(state),
    isAllowedDownloadCertificate: settingSelectors.isAllowedDownloadCertificate(state),
    certificateDownloaded: courseSelectors.isCertificateDownloaded(state),
    isNpsEnabled: settingSelectors.isNpsEnabled(state),
    isOverallMasteryScore: settingSelectors.isOverallMasteryScore(state),
    sectionsPassedCount: getSectionsPassedCount(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    courseActions: bindActionCreators(courseActions, dispatch),
    popupActions: bindActionCreators(popupActions, dispatch)
  };
}

export default withNavigation(connect(mapStateToProps, mapDispatchToProps)(ResultsPage));
