import styled, { css } from 'styled-components';
import { transparentize } from 'utils/color';
import { secondaryFont } from 'components/mixins/typography';
import Icon from 'components/common/Icon';
import Text from 'components/common/Text';

const variables = {
  colors: {
    facebook: '#4267b2',
    linkedin: '#0077b5',
    google: '#ea4335'
  }
};

export const IconElement = styled(Icon)`
  font-size: 13px;
  color: ${props => transparentize(props.theme.colors.textColor, 0.5)};
`;

type LinkElement = {
  href: string;
  ariaLabel: string;
  name: string;
}
export const LinkElement = styled.a.attrs((props: LinkElement) => ({
  href: props.href,
  'aria-label': props.ariaLabel
}))<LinkElement>`
  text-decoration: none;
  text-align: center;
  border-radius: 100px;
  padding: 8px;
  background-color: ${props => props.theme.colors.contentBodyColor};
  border: 1px solid ${props => transparentize(props.theme.colors.textColor, 0.1)};
  width: 53px;
  height: 31px;
  margin: 0 8px;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
  ${props =>
    props.name === 'facebook'
      ? css`
          &:hover,
          &:focus {
            border: 1px solid ${variables.colors.facebook};
          }
          &:hover ${IconElement}, &:focus ${IconElement} {
            color: ${variables.colors.facebook};
          }
          &:active {
            background-color: ${transparentize(variables.colors.facebook, 0.1)};
          }
        `
      : css``};
  ${props =>
    props.name === 'linkedin'
      ? css`
          &:hover,
          &:focus {
            border: 1px solid ${variables.colors.linkedin};
          }
          &:hover ${IconElement}, &:focus ${IconElement} {
            color: ${variables.colors.linkedin};
          }
          &:active {
            background-color: ${transparentize(variables.colors.linkedin, 0.1)};
          }
        `
      : css``};
  ${props =>
    props.name === 'google'
      ? css`
          &:hover,
          &:focus {
            border: 1px solid ${variables.colors.google};
          }
          &:hover ${IconElement}, &:focus ${IconElement} {
            color: ${variables.colors.google};
          }
          &:active {
            background-color: ${transparentize(variables.colors.google, 0.1)};
          }
        `
      : css``};
`;

export const SocialLoginContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const TextSocial = styled(Text)`
  ${secondaryFont};
  font-size: 14px;
  text-align: center;
  font-weight: normal;
  color: ${props => transparentize(props.theme.colors.textColor, 0.5)};
  line-height: 1;
  padding: 10px 16px;
  flex-shrink: 0;
`;

export const SeparatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;
