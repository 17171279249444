import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as actions from 'store/popup/actions';
import { getPopupSettings } from 'store/popup/selectors';
import { localize } from 'core/localization';
import {
  PopupWrapper,
  PopupContent,
  PopupOverlay,
  CloseBtnWrapper,
  CloseBtnIcon,
  CloseBtnText,
  BottomLine
} from './Popup.styled';
import { RootAppState } from 'store/types';

type PopupContainerProps = {
  popupSettings: { [key: string]: any };
  actions: { [key: string]: any };
};
export class PopupContainer extends React.Component<PopupContainerProps, {}> {
  containerElement: any;
  focusedElementBeforeDialogOpened: any;
  constructor(props: PopupContainerProps) {
    super(props);
    this.containerElement = React.createRef();
    this.focusedElementBeforeDialogOpened = null;
  }

  handleKeyDown = (evt: any) => {
    const { disablePopupClosing } = this.props.popupSettings;
    if (!disablePopupClosing && (evt.key === 'Escape' || evt.keyCode === 27)) {
      this.closePopup();
    }
  };

  componentDidMount() {
    this.focusedElementBeforeDialogOpened = document.activeElement;

    const { popupId } = this.props.popupSettings;

    const element = document.getElementById(popupId);
    if (element) {
      setTimeout(() => element.focus(), 0);
    }

    window.addEventListener('keyup', this.preventActions);
  }

  componentWillUnmount() {
    window.removeEventListener('keyup', this.preventActions);
  }

  preventActions = (event: any) => {
    if (event.key === 'Tab' || event.keyCode === 9) {
      const popupContainer: any = document.getElementById(this.props.popupSettings.popupId);
      const isElementInPopup = popupContainer.contains(event.target);
      if (!isElementInPopup) {
        popupContainer.focus();
      }
    }
  };

  closePopup = () => {
    this.props.actions.closePopup();
    if (this.focusedElementBeforeDialogOpened) {
      this.focusedElementBeforeDialogOpened.focus();
    }
  };

  render() {
    const {
      popupId,
      popupAriaLabelKey,
      disablePopupClosing,
      enableClosePopupByTap,
      disableBottomLine,
      component: ComponentToRender
    } = this.props.popupSettings;
    return (
      <PopupWrapper
        ref={this.containerElement}
        id={popupId}
        role="dialog"
        ariaModal={true}
        aria-label={localize(popupAriaLabelKey)}
        tabIndex={-1}
        onKeyDown={event => this.handleKeyDown(event)}
      >
        {disablePopupClosing && enableClosePopupByTap && (
          <PopupOverlay role="presentation" tabIndex={-1} onClick={() => this.closePopup()} />
        )}
        <PopupContent>
          {!disablePopupClosing && (
            <CloseBtnWrapper
              tabIndex={1}
              ariaLabel={localize('[close popup]')}
              onClick={() => this.closePopup()}
            >
              <CloseBtnIcon size={30} name="close-popup" />
              <CloseBtnText size={9} name="esc" />
            </CloseBtnWrapper>
          )}
          <ComponentToRender {...this.props} closePopup={this.closePopup} />
          {!disableBottomLine && <BottomLine />}
        </PopupContent>
      </PopupWrapper>
    );
  }
}

function mapStateToProps(state: RootAppState) {
  return {
    popupSettings: getPopupSettings(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PopupContainer);
