import { getCourseId, getTemplateId } from 'store/course/selectors';
import StorageInterface from '../storageInterface';

const constants = {
  progressKey: 'course_progress'
};

class LocalStorage extends StorageInterface {
  progressKey: string;
  constructor(state: any) {
    super();

    let courseId = getCourseId(state);
    let templateId = getTemplateId(state);
    this.progressKey = `${constants.progressKey}${courseId}${templateId}`;
  }

  async getProgress() {
    return new Promise(resolve => {
      const result = localStorage.getItem(this.progressKey) || '{}';
      resolve(JSON.parse(result));
    });
  }

  async saveProgress(progress: any) {
    return new Promise(resolve => {
      localStorage.setItem(this.progressKey, JSON.stringify(progress));
      resolve();
    });
  }

  async removeProgress() {
    return new Promise(resolve => {
      localStorage.removeItem(this.progressKey);
      resolve();
    });
  }
}

export default LocalStorage;
