import React, { PureComponent } from 'react';
import { localize } from 'core/localization';
import { LearningObjectiveItem, ItemTitle } from './LearningObjectiveTreeNode.styled';

type LearningObjectiveTreeNodeProps = {
  isActive: boolean;
  onClick(e?: any): void;
  onKeyDown(e?: any): void;
  role: string;
  tabIndex: number;
};

export default class LearningObjectiveTreeNode extends PureComponent<LearningObjectiveTreeNodeProps, {}> {
  render() {
    const { onClick, isActive, onKeyDown, role, tabIndex } = this.props;
    return (
      <LearningObjectiveItem
        isActive={isActive}
        tabIndex={tabIndex}
        role={role}
        ariaLabel={localize('[overview]')}
        {...(!isActive && { onClick, onKeyDown })}
      >
        <ItemTitle>{localize('[overview]')}</ItemTitle>
      </LearningObjectiveItem>
    );
  }
}
