import styled from 'styled-components';
import {
  AuthContainer,
  AuthTextForm,
  AuthSocialLoginContainer,
  AuthSection
} from '../auth/auth.styled';

export const LoginSection = styled(AuthSection)`
  justify-content: center;
  padding: 40px 0;
`;

export const LoginContainer = styled(AuthContainer)`
  justify-content: center;
`;

export const TextForm = styled(AuthTextForm)`
  width: 300px;
`;

export const SocialLoginContainer = styled(AuthSocialLoginContainer)`
  margin-bottom: 40px;
`;

export const SkipLinkContainer = styled.div`
  margin-top: 25px;
`;
