import styled from 'styled-components';
import { pointerOnHover } from 'components/mixins/interactivity';
import { Icon, Text } from 'components/common';
import { TOC } from 'constants/components';
import { transparentize } from 'utils/color';
import { defaultBoxShadowColor } from 'constants/common';
import { mainFontText } from 'components/mixins/typography';

export const ProgressPopoverWrapper = styled.div`
  position: relative;
`;

type SectionItemProps = {
  role: string;
  ariaLabel?: string;
}
export const SectionItem = styled.li.attrs((props: SectionItemProps) => ({
  role: props.role,
  'aria-label': props.ariaLabel
})) <SectionItemProps>`
  margin: 0 40px;
  padding: 16px 0 20px 0;
  box-shadow: 0 0 10px 0 ${transparentize(defaultBoxShadowColor, 0.05)};
  background: ${props => props.theme.colors.contentBodyColor};
  &:focus {
    outline: none;
  }
  @media (max-width: ${TOC.EXPANDED_WIDTH + TOC.TAIL_WIDTH}px) {
    margin: 0 10px;
  }
`;

export const TitleIcon = styled(Icon)`
  position: absolute;
  top: 9px;
  right: 20px;
  margin-left: 24px;
  color: ${props => transparentize(props.theme.colors.textColor, 0.4)};
`;

export const QuestionList = styled.ul.attrs((props: any) => ({
  role: props.role
}))`
  padding-bottom: 20px;
`;

type HeaderProps = {
  ariaLabel: string;
  role: string;
  tabIndex: number;
  ariaExpanded: any;
}
export const Header = styled.div.attrs((props: HeaderProps) => ({
  tabIndex: props.tabIndex,
  role: props.role,
  'aria-expanded': props.ariaExpanded,
  'aria-label': props.ariaLabel
})) <HeaderProps>`
  position: relative;
  padding: 0 20px;
  margin-bottom: 16px;
  ${pointerOnHover};
  &:focus {
    outline: none;
  }
`;

export const Footer = styled.div`
  display: flex;
  margin: 0 20px;
  justify-content: flex-end;
`;

export const StatusIcon = styled.div`
  display: flex;
  margin-right: 8px;
`;

export const SectionText = styled(Text)`
  word-break: break-word;
  ${mainFontText}
  color: ${props => props.theme.colors.textColor};
  text-decoration: none;
  font-style: normal;
  && {
    font-size: 18px;
  }
  line-height: 22px;
  margin-right: 25px;
`;

type StatusTextProps = {
  ariaLabel: string;
  tabIndex: number;
  role: string;
  isPassed: boolean;
}
export const StatusText = styled(Text).attrs((props: StatusTextProps) => ({
  tabIndex: props.tabIndex,
  role: props.role,
  'aria-label': props.ariaLabel
})) <StatusTextProps>`
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  color: ${props =>
    props.isPassed
      ? props.theme.colors.correctColor
      : transparentize(props.theme.colors.textColor, 0.4)};

  &:focus {
    outline: none;
  }
`;

export const Status = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;

  &:hover {
    ${StatusText} {
      text-decoration: underline;
    }
  }
`;
