import styled from 'styled-components';

export const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export const List = styled.ul``;

export const Item = styled.li.attrs({
  'data-test': 'content-item'
})`
  &:not(:last-child) {
    margin-bottom: 40px;
  }
`;
