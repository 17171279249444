import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { getUserAnswers } from 'store/questions/fillInTheBlank/selectors';
import * as actions from 'store/questions/fillInTheBlank/actions';
import Parser from 'html-react-parser';
import domToReact from 'html-react-parser/lib/dom-to-react';
import { localize } from 'core/localization';
import TextInput from '../../common/TextInput';
import DropdownInput from '../../common/DropdownInput';
import { AnswerContainer, AnswerContentWrapper } from './FillInTheBlank.styled';
import { RootAppState } from 'store/types';

type FillInTheBlankProps = {
  id: string;
  type: string;
  answerGroups?: { [key: string]: any };
  actions?: { [key: string]: any };
  children?: any;
  questionContent?: any;
  state?: { [key: string]: any };
  isAnswered: boolean;
};

type FillInTheBlankState = {
  answerGroups: any;
}
export class FillInTheBlank extends React.Component<FillInTheBlankProps, FillInTheBlankState> {
  constructor(props: FillInTheBlankProps) {
    super(props);
    this.state = {
      answerGroups: props.answerGroups
    };
  }

  componentDidMount() {
    const { answerGroups } = this.state;
    this.props.actions && this.props.actions.fillInTheBlankAnswer(answerGroups.id, answerGroups.userAnswers);
    this.wrappTables();
  }

  componentDidUpdate(prevProps: FillInTheBlankProps) {
    if (prevProps.id !== this.props.id || prevProps.answerGroups !== this.props.answerGroups) {
      this.setState({
        answerGroups: this.props.answerGroups
      });
    }
    this.wrappTables();
  }

  wrappTables = () => {
    const el: any = document.querySelectorAll('div>table');
    for (let i = 0; i < el.length; i++) {
      const wrapper = document.createElement('figure');
      el[i].parentNode.insertBefore(wrapper, el[i]);
      wrapper.setAttribute('class', 'fib-table-wrapper');
      wrapper.appendChild(el[i]);
    }
  };

  onSelectChange(name: string, option: any) {
    const { answerGroups } = this.state;
    answerGroups.userAnswers = { ...answerGroups.userAnswers, [name]: option.value };
    this.props.actions && this.props.actions.fillInTheBlankAnswer(answerGroups.id, answerGroups.userAnswers);
  }

  onInputFocusOut(event: any) {
    const { answerGroups } = this.state;
    answerGroups.userAnswers = {
      ...answerGroups.userAnswers,
      [event.target.name]: event.target.value
    };
    this.props.actions && this.props.actions.fillInTheBlankAnswer(answerGroups.id, answerGroups.userAnswers);
  }

  render() {
    const { answerGroups } = this.state;
    const { isAnswered } = this.props;

    const parseSelect = {
      replace: (data: { attribs: any, children: any }) => {
        const { attribs, children } = data;
        if (!attribs) {
          return;
        }
        const dataId = attribs['data-group-id'];
        const answerValue = answerGroups.userAnswers[dataId];

        if (attribs.class === 'blankSelect') {
          return (
            <DropdownInput
              popoverPosition={'bottom'}
              key={dataId}
              name={dataId}
              onChange={(event: any) => this.onSelectChange(dataId, event)}
              value={answerValue}
              placeholder={`${localize('[placeholder dropdown]')}`}
              answeredPlaceholder={`${localize('[label your choice is]')}`}
              data={domToReact(children, parseSelect).map((option: any) => ({
                value: option.props.value || ''
              }))}
              disabled={isAnswered}
            >
              {domToReact(children, parseSelect)}
            </DropdownInput>
          );
        }

        if (attribs.class === 'blankInput') {
          return (
            <TextInput
              tabIndex={1}
              key={dataId}
              placeholder={`${localize('[placeholder text input]')}`}
              name={dataId}
              onBlur={(event: any) => this.onInputFocusOut(event)}
              value={answerValue}
              disabled={isAnswered}
            />
          );
        }
      }
    };

    if (!answerGroups.questionContent) {
      answerGroups.questionContent = {
        value: ''
      }
    }

    if (!answerGroups.questionContent.value) {
      answerGroups.questionContent.value = ''
    }

    return (
      <AnswerContainer>
        <AnswerContentWrapper>
          {Parser(answerGroups.questionContent.value, parseSelect) || null}
        </AnswerContentWrapper>
      </AnswerContainer>
    );
  }
}

function mapStateToProps(state: RootAppState, ownProps: FillInTheBlankProps) {
  return {
    answerGroups: getUserAnswers(state, ownProps.id)
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FillInTheBlank);
