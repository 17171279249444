import styled from 'styled-components';
import { transparentize } from 'utils/color';
import media from 'components/mixins/media';
import { mainFontH1 } from 'components/mixins/typography';
import { Text, ProgressBar, ProgressResult } from 'components/common';
import { MEDIA_MEDIUM } from 'constants/common';
import { getCustomScrollbars } from 'components/mixins/customScrollbars';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  min-width: 0;
  min-height: 100vh;
  max-height: 100%;
  ${props => getCustomScrollbars(props.theme.colors.coverBgOverlayTextColor)};
  ${media.tablet`
    height: auto;
  `};
  @media (max-width: ${MEDIA_MEDIUM - 24}px) {
    flex: 0 0 auto;
    min-height: 0;
  }
`;

export const CourseInfoContainer = styled.div`
  position: relative;
  z-index: 10;
  width: 100%;
  padding: 56px 80px;
  ${media.large`
    padding: 56px 40px;
  `}
  ${media.tablet`
    height: auto;
  `};
  ${media.mobile`
    padding: 80px 30px;
  `};
`;

export const CourseInfoWrapper = styled.div`
  margin: 0 auto;

  h1 {
    ${mainFontH1}
    color: ${props => props.theme.colors.coverBgOverlayTextColor};
    text-decoration: none;
    font-style: normal;

    &:first-child {
      font-size: 48px;
      line-height: 1.2;
    }
  }

  ${media.medium`
    h1 {
      &:first-child {
        padding-right: 22px;
        font-size: 40px;
      }
    }
  `}
`;

export const ContentsContainer = styled.div`
  width: 100%;

  h2,
  h3,
  h4 {
    margin-top: 0;
  }
`;

export const LogoWrapper = styled.div`
  margin-bottom: 40px;
`;

type CourseLogoType = {
  logoUrl: string;
  alt: any;
};
export const CourseLogo = styled.img.attrs((props: CourseLogoType) => ({
  src: props.logoUrl,
  alt: props.alt
}))<CourseLogoType>`
  max-width: 240px;
  max-height: 100px;

  ${media.tablet`
    max-width: 200px;
    max-height: 80px;
  `};

  ${media.mobile`
    max-width: 160px;
    max-height: 60px;
  `};
`;

export const ProgressSectionsWrapper = styled.div`
  display: flex;
  margin-top: 56px;
  margin-bottom: 8px;
`;

export const ProgressPerSection = styled(ProgressBar)`
  height: 4px;
  margin: 0 3px;
  border-radius: 2px;
  background: ${props => transparentize(props.theme.colors.coverBgOverlayTextColor, 0.1)};

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

export const ProgressMasteryScore = styled(ProgressResult)`
  margin-top: 46px;
  height: 4px;
  border-radius: 2px;
  background: ${props => transparentize(props.theme.colors.coverBgOverlayTextColor, 0.1)};
`;

export const ProgressCaption = styled(Text)`
  padding-top: 4px;
  display: block;
  margin-bottom: 44px;
  font-size: 10px;
  font-weight: bold;
  line-height: 1;
  text-transform: uppercase;
  color: ${props => props.theme.colors.coverBgOverlayTextColor};
  opacity: 0.7;
`;
