import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as actions from 'store/contents/actions';
import { getContents } from 'store/contents/selectors';
import ContentList from './ContentList';
import { RootAppState } from 'store/types';

type ContentItem = {
  id: string;
  url: string;
}

type ContentsProps = {
  actions: {[key: string]: any},
  items: string[];
  contents?: ContentItem[];
  inverted?: boolean;
}

type ContentsState = {
  isLoading: boolean;
}

export class Contents extends React.Component<ContentsProps, ContentsState> {

  constructor(props: ContentsProps) {
    super(props);
    this.state = {
      isLoading: false
    }
  }

  static defaultProps = {
    actions: {}
  }

  componentDidMount() {
    this.loadData(this.props);
  }

  componentDidUpdate(prevProps: ContentsProps) {
    if (prevProps.items !== this.props.items) {
      this.loadData(this.props);
    }
  }

  async loadData(props: ContentsProps) {
    this.setState({ isLoading: true });
    await props.actions.loadContents(props.contents);
    this.setState({ isLoading: false });
  }

  render() {
    const { isLoading } = this.state;
    const { contents, inverted } = this.props;
    return <ContentList isLoading={isLoading} items={contents || []} inverted={inverted || false} />;
  }
}

function mapStateToProps(state: RootAppState, ownProps: ContentsProps) {
  return { contents: getContents(state, ownProps.items) };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Contents);
