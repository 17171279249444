import { events } from 'core/events/eventEmitter';
import BaseEventHandler from 'core/events/eventHandler';
import progressStorage from './progressStorage';

export default class EventHandler extends BaseEventHandler {
  handlers: any[];

  constructor() {
    super();
    this.handlers = [
      { event: events.APP_INITIALIZED, instance: this.appInitialize.bind(this) },
      { event: events.APP_NAVIGATED, instance: this.appNavigated.bind(this) },

      { event: events.COURSE_LAUNCHED, instance: this.courseLaunched.bind(this) },
      { event: events.COURSE_PROGRESSED, instance: this.courseProgressed.bind(this) },
      { event: events.COURSE_ATTEMPT_STARTED, instance: this.courseAttemptStarted.bind(this) },

      { event: events.QUESTION_ANSWERED, instance: this.updateQuestionProgress.bind(this) },
      {
        event: events.INFORMATION_CONTENT_EXPERIENCED,
        instance: this.updateQuestionProgress.bind(this)
      },
      { event: events.RESET_QUESTION_PROGRESS, instance: this.removeQuestionProgress.bind(this) },

      { event: events.USER_AUTHENTICATED, instance: this.userAuthorized.bind(this) },
      { event: events.USER_LOGOUT, instance: this.logout.bind(this) }
    ];
  }

  appInitialize({ state, isScormMode }: any) {
    progressStorage.initialize({ state, isScormMode });
  }

  appNavigated({ url, state }: { [key: string]: any }) {
    // fix for browser back button
    if (progressStorage.url === url) {
      return;
    }

    progressStorage.url = url;
    progressStorage.saveProgress(state);
  }

  courseLaunched(attemptId: any) {
    progressStorage.attemptId = attemptId;
  }

  courseProgressed(state: any) {
    progressStorage.saveProgress(state);
  }

  courseAttemptStarted(state: any) {
    progressStorage.saveProgress(state);
  }

  updateQuestionProgress([question]: any) {
    progressStorage.updateQuestionProgress(question);
  }

  removeQuestionProgress(questionId: any) {
    progressStorage.removeQuestionProgress(questionId);
  }

  userAuthorized(user: any) {
    progressStorage.user = user;
  }

  async logout({ state }: any) {
    await progressStorage.logout();
    this.appInitialize({ state });
  }
}
