import styled from 'styled-components';
import { transparentize } from 'utils/color';
import Icon from 'components/common/Icon';
import { defaultBoxShadowColor } from 'constants/common';

export const Wrapper = styled.div.attrs({
  'data-test': 'hotspot-on-image-wrapper'
})`
  width: 100%;
  max-width: 100%;
  display: inline-block;
  vertical-align: bottom;
  text-align: left;
  padding: 0;
`;

type ImageSpotsWrapper = {
  isImageNotFound: boolean;
  isImageLoading: boolean;
}
export const ImageSpotsWrapper = styled.div<ImageSpotsWrapper>`
  position: relative;
  width: 100%;
  max-width: 100%;
  display: inline-block;
  vertical-align: bottom;
  pointer-events: ${props => (props.isImageNotFound ? 'none' : 'auto')};

  height: ${props => (props.isImageLoading ? '182px' : 'auto')};
  overflow: ${props => (props.isImageLoading ? 'hidden' : 'auto')};
  background: ${props =>
    props.isImageNotFound || props.isImageLoading
      ? transparentize(props.theme.colors.textColor, 0.02)
      : 'transparent'};
  padding: ${props => (props.isImageNotFound ? '16px 0' : '0')};
`;

type ImageProps = {
  isImageNotFound: boolean;
  src: string;
  alt: string;
  tabIndex: number;
  role: string;
  onLoad: any;
  onClick: any;
  style: any;
}
export const Image = styled.img.attrs(props => ({
  'data-test': 'hotspot-image'
})) <ImageProps>`
  display: block;
  outline: none;
  margin: ${props => (props.isImageNotFound ? '0 auto' : '0')};
`;

export const CloseIcon = styled(Icon)`
  position: absolute;
  top: -10px;
  right: -8px;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  opacity: 0;
  cursor: pointer;
  color: ${props => transparentize(props.theme.colors.textColor, 0.6)};
  background-color: ${props => transparentize(props.theme.colors.contentBodyColor, 0.9)};
  box-shadow: 0 1px 3px 0 ${transparentize(defaultBoxShadowColor, 0.1)};

  &:before {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }
`;

export const Spot = styled.div`
  position: relative;
  width: 30px;
  height: 30px;
  display: block;
  cursor: pointer;
  animation: pulse 1.5s infinite;
  background-color: ${props => transparentize(props.theme.colors.contentBodyColor, 0.1)};
  box-shadow: 0 2px 10px 0 ${transparentize(defaultBoxShadowColor, 0.1)};
  border-radius: 50%;
  outline: none;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: 50%;
    right: 0px;
    bottom: 0;
    background: linear-gradient(
      to bottom,
      ${props => props.theme.colors.mainColor} 0%,
      ${props => props.theme.colors.mainColor} 50%,
      ${props => props.theme.colors.mainColor} 50%,
      ${props => props.theme.colors.contentBodyColor} 50%,
      ${props => props.theme.colors.contentBodyColor} 100%
    );
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: 50%;
    left: 0px;
    bottom: 0;
    background: linear-gradient(
      to top,
      ${props => props.theme.colors.mainColor} 0%,
      ${props => props.theme.colors.mainColor} 50%,
      ${props => props.theme.colors.mainColor} 50%,
      ${props => props.theme.colors.contentBodyColor} 50%,
      ${props => props.theme.colors.contentBodyColor} 100%
    );
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 ${props => transparentize(props.theme.colors.contentBodyColor, 0.5)};
    }
    50% {
      box-shadow: 0 0 0 6px ${props => transparentize(props.theme.colors.contentBodyColor, 0.3)};
    }
    100% {
      box-shadow: 0 0 0 8px ${props => transparentize(props.theme.colors.contentBodyColor, 0.05)};
    }
  }
`;

type SpotWrapperProps = {
  top: number;
  left: number;
}

export const SpotWrapper = styled.div.attrs(props => ({
  'data-test': 'spot-wrapper'
})) <SpotWrapperProps>`
  display: block;
  position: absolute;
  top: ${props => props.top}px;
  left: ${props => props.left}px;
  transform: translate(-50%, -50%);
  outline: none;

  &:hover,
  &:focus {
    ${Spot} {
      animation: none;
    }

    ${CloseIcon} {
      opacity: 1;

      &:hover {
        color: ${props => transparentize(props.theme.colors.textColor, 0.9)};
        background-color: ${props => props.theme.colors.contentBodyColor};
        opacity: 1;
      }
    }
  }
`;
