import styled, { css } from 'styled-components';
import { Text, ShapedIcon } from 'components/common';
import { transparentize } from 'utils/color';
import { pointerOnHover } from 'components/mixins/interactivity';

const highlighted = css`
  background-color: ${props => transparentize(props.theme.colors.textColor, 0.02)};
`;

type QuestionIconProps = {
  ariaLabel?: string;
  name: string;
  size: any;
  shape: any;
  shapeColor: any;
}
export const QuestionIcon = styled(ShapedIcon).attrs((props: QuestionIconProps) => ({
  'aria-label': props.ariaLabel
})) <QuestionIconProps>`
  flex-shrink: 0;
  margin-right: 9px;
`;

export const CollapsedQuestionIcon = styled(ShapedIcon)`
  flex-shrink: 0;
`;

export const TitleText = styled(Text)`
  font-size: 12px;
  font-weight: 600;
  line-height: 1.6;
  word-break: break-word;
`;

type QuestionItemProps = {
  ariaLabel: string;
  role: string;
  tabIndex: number;
  isActive: boolean;
  isPassed?: boolean;
}
export const QuestionItem = styled.li.attrs((props: QuestionItemProps) => ({
  'aria-label': props.ariaLabel,
  role: props.role,
  tabIndex: props.tabIndex
})) <QuestionItemProps>`
  display: flex;
  align-items: flex-start;
  padding: 8px 20px;
  position: relative;
  &:focus {
    outline: none;
  }
  ${props =>
    props.isActive &&
    css`
      ${highlighted};
      ${TitleText} {
        color: ${props.theme.colors.textColor};
      }
      &::before {
        content: '';
        position: absolute;
        width: 2px;
        height: 100%;
        top: 0;
        left: 0;
        background-color: ${props.theme.colors.mainColor};
      }
    `};
  ${props =>
    !props.isActive &&
    css`
      ${pointerOnHover};
      ${TitleText} {
        color: ${props.isPassed
        ? transparentize(props.theme.colors.textColor, 0.6, { fullColor: true })
        : props.theme.colors.textColor};
      }
      &:hover,
      &:focus {
        ${highlighted};
      }
      &:hover ${TitleText}, &:focus ${TitleText} {
        text-decoration: underline;
      }
    `};
`;
