import * as React from 'react';
import { localize } from 'core/localization';
import { NO_SELECTED_ID } from 'constants/common';
import DropdownInput from 'components/common/DropdownInput';
import CircleLoader from 'components/common/CircleLoader';
import { DragDropMobileWrapper, DragDropMobile } from './DragAndDropText.styles';

type DragAndDropMobileProps = {
  id: string;
  background?: any;
  moveItem(arg1: any, arg2: any, arg3: any): void;
  listsOfItems: any[];
  dropspots?: any[];
  response?: any[];
  actions?: { [key: string]: any };
  setPositionDependingImageScale?(): void;
  handleImageLoaded(options1: any, options2: any): void;
  isImageLoaded: boolean;
  isImageNotFound: boolean;
};

type DragAndDropMobileState = {
  listsOfItems: any[];
}

export class DragAndDropMobile extends React.Component<DragAndDropMobileProps, DragAndDropMobileState> {
  imageRefs: any;
  constructor(props: DragAndDropMobileProps) {
    super(props);
    this.imageRefs = React.createRef();
    this.state = {
      listsOfItems: props.listsOfItems
    };
  }

  onSelectElement = (value: any, element: any, listsOfItems: any) => {
    let droppableElement: any = [];
    let destination: any = [];
    if (element.current) {
      return;
    }
    listsOfItems.forEach((list: any, index: any) => {
      if (list.id === value) {
        destination = index;
      }
    });

    if (element.selected === undefined) {
      if (listsOfItems[destination].items.length) {
        listsOfItems[0].items.push(listsOfItems[destination].items[0]);
      }
      listsOfItems[0].items.forEach((item: any, index: any) => {
        if (item.id === element.id) {
          droppableElement = listsOfItems[0].items.splice(index, 1);
        }
      });
    } else {
      listsOfItems.forEach((item: any, index: any) => {
        if (item.id === value && item.items.length) {
          listsOfItems[0].items.push(listsOfItems[index].items[0]);
        }
        if (item.selected === element.value) {
          droppableElement = listsOfItems[index].items.splice(0, 1);
        }
      });
      if (element.selected === '') {
        listsOfItems[0].items.forEach((item: any, index: any) => {
          if (item.id === element.id) {
            droppableElement = listsOfItems[0].items.splice(index, 1);
          }
        });
      }
    }

    listsOfItems[destination].items = droppableElement;
    this.setState({ listsOfItems });
    this.props.moveItem(this.state.listsOfItems, this.props.id, this.props.actions);
  };

  render() {
    const { dropspots = [], background = '', isImageLoaded, isImageNotFound } = this.props;
    const { listsOfItems } = this.state;
    const originalDropspots = dropspots.map(dropspot => ({
      id: dropspot.id,
      text: dropspot.text,
      x: dropspot.x,
      y: dropspot.y,
      selected: ''
    }));
    const listsOfItemsMobile = Array.from(listsOfItems);
    listsOfItemsMobile.forEach(element => {
      if (element.items.length && element.id !== NO_SELECTED_ID) {
        originalDropspots.forEach(item => {
          if (item.text === element.items[0].text) {
            item.selected = item.text;
          }
        });
        element.selected = element.items[0].text;
        element.isDropDisabled = true;
      } else {
        element.selected = '';
        element.isDropDisabled = false;
      }
    });
    return (
      <DragDropMobileWrapper isImageLoaded={isImageLoaded} isImageNotFound={isImageNotFound}>
        {!isImageNotFound &&
          listsOfItemsMobile.map(
            item =>
              item.id !== NO_SELECTED_ID && (
                <DragDropMobile
                  key={item.id}
                  position={item.position}
                  isImageLoaded={isImageLoaded}
                >
                  <DropdownInput
                    popoverPosition={'right'}
                    isMatching
                    key={item.id}
                    name={item.id}
                    onChange={(event: any) => this.onSelectElement(item.id, event, listsOfItems)}
                    value={''}
                    placeholder={''}
                    answeredPlaceholder={''}
                    matchingAnswer={item.selected}
                    data={originalDropspots.map(
                      option =>
                        option.id !== NO_SELECTED_ID && {
                          value: option.text,
                          id: option.id,
                          selected: option.selected,
                          current: item.selected.length ? item.selected === option.selected : false
                        }
                    )}
                  />
                </DragDropMobile>
              )
          )}
        {background && !isImageLoaded && <CircleLoader />}
        <img
          src={background}
          ref={this.imageRefs}
          onLoad={() =>
            this.props.handleImageLoaded(this.imageRefs.current, this.state.listsOfItems)
          }
          alt={localize('[drag and drop question image]')}
        />
      </DragDropMobileWrapper>
    );
  }
}

export default DragAndDropMobile;
