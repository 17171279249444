import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { localize } from 'core/localization';
import * as questionActions from 'store/questions/actions';
import { BUTTON, TOOLTIP } from 'constants/components';
import { INFORMATION_CONTENT, SCENARIO } from 'constants/questionTypes';
import { Button, CssAnimation, StatusTooltip } from 'components/common';
import {
  isQuestionAnswered,
  isQuestionAnsweredCorrectly,
  isFeedbackAnimating
} from 'store/questions/selectors';

import { ActionButtonsContainer, NavButtonsBlock, SurveyHint } from './ActionsBlock.styled';
import { RootAppState } from 'store/types.js';

type ActionBlocksState = {
  isJustSubmitted: boolean;
  showTooltip: boolean;
  isInformationContent: any;
  canGoNext: boolean;
}

type ActionBlocksProps = {
  actions?: { [key: string]: any };
  question: { [key: string]: any };
  goToNextPage(): void;
  isNextPageResults: boolean;
  feedbackAnimated?: boolean;
  questionCountInSection: number;
  questionIndex: number;
};
export class ActionBlocks extends React.Component<ActionBlocksProps, ActionBlocksState> {
  constructor(props: ActionBlocksProps) {
    super(props);
    this.state = {
      isJustSubmitted: false,
      showTooltip: false,
      isInformationContent: this.props.question.type === INFORMATION_CONTENT,
      canGoNext: false
    };
  }

  componentDidMount() {
    this.setState({
      canGoNext: this.canGoToNextQuestion()
    });
  }

  componentDidUpdate(prevProps: ActionBlocksProps) {
    if (prevProps.question.id !== this.props.question.id) {
      this.setState({ isJustSubmitted: false });
    }
  }

  render() {
    const { isJustSubmitted, isInformationContent } = this.state;
    const { question, feedbackAnimated= false } = this.props;
    const { isSurvey } = question;
    const canGoNext = this.canGoToNextQuestion();
    return (
      <ActionButtonsContainer isHidden={feedbackAnimated}>
        {!isInformationContent && (!question.isAnswered || feedbackAnimated) && (
          <React.Fragment>
            {question.type === SCENARIO ? (
              <StatusTooltip
                popoverPosition={'top'}
                layout={TOOLTIP.INCORRECT}
                showTooltip={this.state.showTooltip}
                message={localize('[scenario tooltip text]')}
                popoverButton={
                  <Button tabIndex={1} onClick={this.submit} onBlur={this.hidePopover}>
                    {localize('[submit]')}
                  </Button>
                }
              />
            ) : (
                <React.Fragment>
                  <Button tabIndex={1} onClick={this.submit}>
                    {localize('[submit]')}
                  </Button>
                  {isSurvey && !canGoNext && (
                    <SurveyHint>{localize('[survey question hint]')}</SurveyHint>
                  )}
                </React.Fragment>
              )}
          </React.Fragment>
        )}
        {!feedbackAnimated &&
          (isJustSubmitted ? (
            <CssAnimation type={CssAnimation.AnimationType.slideInDown}>
              {this.renderNavButtonsBlock()}
            </CssAnimation>
          ) : (
              this.renderNavButtonsBlock()
            ))}
      </ActionButtonsContainer>
    );
  }

  renderNavButtonsBlock() {
    const { question, questionIndex, questionCountInSection, goToNextPage } = this.props;
    const { isAnsweredCorrectly, isSurvey } = question;
    const isLastPageInSection = questionIndex + 1 === questionCountInSection;
    const isInformationContent = question.type === INFORMATION_CONTENT;
    const canGoNext = this.canGoToNextQuestion();
    return (
      <NavButtonsBlock tabIndex={-1}>
        {this.canTryAgainQuestion() && (
          <Button tabIndex={1} onClick={this.tryAgain}>
            {localize('[try again]')}
          </Button>
        )}
        {canGoNext && (
          <Button
            data-test="button-next"
            layout={
              isInformationContent || isAnsweredCorrectly || isSurvey
                ? BUTTON.LAYOUT.PRIMARY
                : BUTTON.LAYOUT.SECONDARY
            }
            onClick={goToNextPage}
            tabIndex={1}
            suffixIcon="arrow-down"
          >
            {this.getNextNavigationText(isLastPageInSection)}
          </Button>
        )}
      </NavButtonsBlock>
    );
  }

  hidePopover = async () => {
    this.setState({ showTooltip: false });
  };

  submit = () => {
    const { question } = this.props;
    if (question.type === SCENARIO) {
      if (question.response.isFinished) {
        this.props.actions && this.props.actions.answer(question.id);
        this.props.actions && this.props.actions.animateFeedback({ questionId: question.id, isAnimating: true });
        this.setState({ isJustSubmitted: true, showTooltip: false });
      } else {
        this.setState({ showTooltip: true });
      }
    } else {
      this.props.actions && this.props.actions.answer(question.id);
      this.props.actions && this.props.actions.animateFeedback({ questionId: question.id, isAnimating: true });
      this.setState({
        isJustSubmitted: true,
        canGoNext: this.canGoToNextQuestion()
      });
    }
  };

  getNextNavigationText = (isLastPageInSection: boolean) => {
    const { isNextPageResults } = this.props;
    if (isNextPageResults) return localize('[go to results]');
    return localize(isLastPageInSection ? '[next section]' : '[next]');
  };

  canGoToNextQuestion() {
    return this.props.question.type === INFORMATION_CONTENT ? true : this.props.question.isAnswered;
  }

  canTryAgainQuestion() {
    const { question } = this.props;
    return (
      question.type !== INFORMATION_CONTENT &&
      question.isAnswered &&
      !question.isAnsweredCorrectly &&
      !question.isSurvey
    );
  }

  tryAgain = () => {
    const { question } = this.props;
    this.props.actions && this.props.actions.resetProgress(question);
    this.setState({ isJustSubmitted: false });
    this.props.actions && this.props.actions.animateFeedback({ questionId: question.id, isAnimating: true });
  };
}

function mapStateToProps(state: RootAppState, ownProps: ActionBlocksProps) {
  const questionId = ownProps.question.id;
  return {
    isAnswered: isQuestionAnswered(state, questionId),
    isAnsweredCorrectly: isQuestionAnsweredCorrectly(state, questionId),
    feedbackAnimated: isFeedbackAnimating(state, questionId)
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return { actions: bindActionCreators(questionActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionBlocks);
