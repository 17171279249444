import styled from 'styled-components';
import media from 'components/mixins/media';

export const SwitchBlock = styled.div`
  width: auto;
  display: flex;
  align-self: flex-start;
  position: absolute;
  top: 50%;
  left: 10px;
  margin-top: -15px;
  ${media.mobile`
    align-self: auto;
    position: static;
    margin-top: 0;
  `};
`;

export default SwitchBlock;
