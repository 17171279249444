import styled from 'styled-components';

type ProgressContainerProps = {
  height: number;
}
export const ProgressContainer = styled.div<ProgressContainerProps>`
  position: relative;
  width: 100%;
  height: ${props => (props.height ? props.height : 2)}px;
`;

type ProgressProps = {
  progress: number;
}
export const Progress = styled.div<ProgressProps>`
  height: 100%;
  width: ${props => (props.progress ? props.progress : 0)}%;
  background-color: ${props => props.theme.colors.correctColor};
  border-radius: inherit;
  transition: width 0.2s;
`;
