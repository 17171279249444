import requester from 'core/http/requester';
import progressStorage from 'core/progressStorage';
import { localize } from 'core/localization';
import { CONTENT_TYPES } from 'constants/common';
import { IdToUuid } from 'utils/string';
import fileLoader from './fileLoader';

class CertificateLoader {
  getNewLogoUrl(logoUrl: string) {
    const urlCheckPattern = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-/]))?/;
    if (logoUrl && !urlCheckPattern.test(logoUrl)) {
      if (logoUrl.match(/\/\//g)) {
        return `https:${logoUrl}`;
      }
      return `https://${logoUrl}`;
    }
    return logoUrl;
  }

  async getCertificateUrl(data: any, learnStorageUrl: any, selectedLanguage: any) {
    const headers: any = progressStorage.getHeaders({
      contentType: CONTENT_TYPES.JSON,
      bearerToken: progressStorage.getToken()
    });
    headers['X-UI-Culture'] = selectedLanguage;

    const { courseId, templateId, logoUrl } = data;
    const logo = this.getNewLogoUrl(logoUrl);
    const response: any = await requester.get(
      `${learnStorageUrl}/api/learner/me/courses/${IdToUuid(courseId)}/attempts/last/certificate`,
      {
        query: {
          templateId,
          logoUrl: logo
        },
        headers
      } as any
    );
    if (response.status !== 200) {
      const error = response.json();
      console.error(error.message);
      return false;
    }
    const responseData = await response.json();
    return responseData.url;
  }

  async downloadCertificate(data: any, learnStorageUrl: any, selectedLanguage: any) {
    const downloadUrl = await this.getCertificateUrl(data, learnStorageUrl, selectedLanguage);
    const fileName = `${localize('[certificate file name]')}.pdf`;
    if (downloadUrl) {
      return fileLoader.loadFile(downloadUrl, fileName);
    }
    return false;
  }
}

export default new CertificateLoader();
