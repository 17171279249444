
export function getDOMNode(embedCode: string):any {
  const nodeElement = new DOMParser().parseFromString(embedCode, 'text/html');
  return nodeElement.body.firstChild;
}

export function getAttributesNodeElement(elem: any) {
  const attributes = elem.attributes; // eslint-disable-line
  const length = attributes.length; // eslint-disable-line

  const result = new Array(length);
  for (let i = 0; i < length; i++) {
    result[i] = attributes[i].name;
  }
  return result;
}
