import { schema } from 'normalizr';
import question from './question';

const section = new schema.Entity(
  'sections',
  {
    questions: [question]
  },
  {
    processStrategy: (value: any) => ({
      ...value,
      score: 0,
      isPassed: false
    })
  }
);

export default section;
