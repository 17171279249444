import * as React from 'react';
import { localize } from 'core/localization';
import Reviewable from 'components/review/Reviewable';
import { SectionsList, SectionsListItem, SectionsButton } from './SectionsList.styled';
import SectionLearningObjective from '../SectionLearningObjective';
import SectionImage from '../SectionImage';
import SectionContent from '../SectionContent';
import { REVIEW_COMMENT_PROPERTIES, REVIEW_COMMENT_TYPES } from 'constants/review';

export const COUNT_OF_DUMMY_ELEMENTS = 3;

export const getDummyElements = (length: any) => {
  let dummyElements: Array<any> = [];
  if (length < COUNT_OF_DUMMY_ELEMENTS) {
    dummyElements = Array.from({ length: COUNT_OF_DUMMY_ELEMENTS - length }, (v, index) => (
      <SectionsListItem key={index + length} isDummy={true} />
    ));
  }
  return dummyElements;
};

type SectionsListComponentProps = {
  sections: Array<any>;
  navigateToQuestion(id: string, question: string): void;
  learningObjective?: string;
  navigateToLearningObjective(id: string): void;
};

export const SectionsListComponent = ({
  sections,
  navigateToQuestion,
  navigateToLearningObjective
}: Readonly<SectionsListComponentProps>) => {
  const { length } = sections;
  const dummyElements = getDummyElements(length);
  return (
    <SectionsList>
      {sections.map((section, index) => {
        const questionId = section.questions[0];
        const {
          id,
          imageUrl,
          isPassed,
          learningObjective,
          score,
          title,
          affectsProgress
        } = section;
        return (
          <SectionsListItem key={index}>
            <Reviewable context={{ type: REVIEW_COMMENT_TYPES.SECTION, property: REVIEW_COMMENT_PROPERTIES.TITLE, id, title }}>
              <SectionsButton
                isDummy={false}
                ariaLabel={`${localize('[aria label start section]')} ${title} ${localize(
                  isPassed ? '[passed]' : '[not passed yet]'
                )}`}
                isPassed={isPassed}
                tabIndex={1}
                onClick={() =>
                  learningObjective
                    ? navigateToLearningObjective(id)
                    : navigateToQuestion(id, questionId)
                }
              >
                <SectionImage imageUrl={imageUrl} />
                <SectionContent
                  progress={score}
                  title={title}
                  isPassed={isPassed}
                  affectsProgress={affectsProgress}
                />
              </SectionsButton>
              {learningObjective && (
                <SectionLearningObjective learningObjective={learningObjective} />
              )}
            </Reviewable>
          </SectionsListItem>
        );
      })}
      {dummyElements.length !== 0 && dummyElements.map(dummy => dummy)}
    </SectionsList>
  );
};

export default SectionsListComponent;
