import styled from 'styled-components';
import media from 'components/mixins/media';
import { MEDIA_XSMALL } from 'constants/common';
import { transparentize } from 'utils/color';

type ContainerProps = {
  isBgColorTransparent?: boolean;
}
export const Container = styled.figure<ContainerProps>`
  flex: 0 0 auto;
  width: 120px;
  height: inherit;
  background-color: ${props => transparentize(props.theme.colors.textColor, 0.02)};
  ${media.mobile`
    width: 100%;
    height: 170px;
    padding: 0 30px;
    background: transparent;
  `};
  @media (max-width: ${MEDIA_XSMALL + 55}px) {
    width: 100%;
    height: 130px;
  }
`;

type BackgroundImageProps = {
  bgSize: any;
  url: string;
}

export const BackgroundImage = styled.div<BackgroundImageProps>`
  width: 100%;
  height: 100%;
  background-size: ${props => (props.bgSize ? props.bgSize : 'initial')};
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(${props => props.url});
`;

export const DefaultSectionImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const DefaultSectionImageIcon = styled.div`
  font-size: 30px;
  color: ${props => props.theme.colors.textColor};
  opacity: 0.3;
`;
