import styled from 'styled-components';
import { transparentize } from 'utils/color';
import media from 'components/mixins/media';

type ContainerProps = {
  ariaLabel: string;
  role: string;
}
export const Container = styled.div.attrs((props: ContainerProps) => ({
  'aria-label': props.ariaLabel,
  role: props.role
}))<ContainerProps>`
  background-color: ${props => transparentize(props.theme.colors.textColor, 0.02)};
  padding: 24px 0px;
  &:focus {
    outline: none;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  color: ${props => props.theme.colors.textColor};
`;

export const IconContainer = styled.div`
  margin: 0 20px 0 40px;
  text-align: center;
  ${media.xsmall`
    margin-left: 16px;
    text-align: left;
  `};
  & .icon {
    line-height: inherit;
  }
`;

export const Body = styled.div`
  margin: 32px 40px 0;
  ${media.xsmall`
    margin: 0 16px;
  `};
`;

export const ContentLoaderContainer = styled.div`
  display: flex;
  flex: 1;
  margin-bottom: 20px;
`;

export const ContentContainer = styled.div.attrs(props => ({
  tabIndex: props.tabIndex
}))`
  &:focus {
    outline: none;
  }
`;
