import { ActionTypes } from './types';
import { ThunkResult } from '../../types';

export const toggleAnswerIsSelected = (questionId: string, answerId: string): ThunkResult => dispatch => {
  dispatch({
    type: ActionTypes.QUESTION_MULTIPLECHOICE_TOGGLE_ANSWER_IS_SELECTED,
    payload: { questionId, answerId }
  });
};

export const setSingleAnswerSelected = (questionId: string, answerId: string, questionType: string): ThunkResult => dispatch => {

  switch (questionType) {
    case ActionTypes.QUESTION_MULTIPLECHOICE_SET_SINGLE_ANSWER_SELECTED:
      dispatch({
        type: ActionTypes.QUESTION_MULTIPLECHOICE_SET_SINGLE_ANSWER_SELECTED,
        payload: { questionId, answerId }
      });
      break;
    case ActionTypes.SET_SINGLE_ANSWER_SELECTED:
      dispatch({
        type: ActionTypes.SET_SINGLE_ANSWER_SELECTED,
        payload: { questionId, answerId }
      });
      break;
    default:
      dispatch({
        type: ActionTypes.QUESTION_MULTIPLECHOICE_SET_SINGLE_ANSWER_SELECTED,
        payload: { questionId, answerId }
      });
  }
};
