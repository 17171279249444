import * as React from 'react';
import { withTheme } from 'styled-components'
import { INPUT_TYPE } from 'constants/components';
import { localize } from 'core/localization';
import Icon from 'components/common/Icon';
import {
  InputContainer,
  InputElement,
  InputLabel,
  ValidIcon,
  TextError,
  ValidationMessageBlock,
  EyeIcon
} from './Input.styled';

interface InputTypes {
  [INPUT_TYPE.TEXT]: string;
  [INPUT_TYPE.PASSWORD]: string;
  [INPUT_TYPE.EMAIL]: string;
}

type InputProps = {
  type: keyof InputTypes;
  name: string;
  tabIndex?: number;
  labelText: string;
  idInput: string;
  onChange(e: any): void;
  placeholder: string;
  validIcon?: boolean;
  refInput(node: any): void;
  validationStatusBeforeSubmit: boolean;
  showValidationMessage?: boolean;
  onBlur?(e: any): void;
  onFocus?(e: any): void;
  defaultValue?: string;
  autoComplete?: string;
  isUserExist?: boolean;
  pattern?: string;
  textError?: string;
  theme?: { [key: string]: any }
};

type InputState = {
  hasValue: boolean;
  validValue: boolean;
  showErrorText: boolean;
  showPassword: boolean;
  showPassValidationBlock: boolean;
  isSpaceInPass: boolean;
  isPatternPass: boolean;
  startInputPass: boolean;
}

export class Input extends React.Component<InputProps, InputState> {
  constructor(props: InputProps) {
    super(props);
    this.state = {
      hasValue: false,
      validValue: true,
      showErrorText: false,
      showPassword: false,
      showPassValidationBlock: false,
      isSpaceInPass: false,
      isPatternPass: false,
      startInputPass: false
    };
  }

  static defaultProps = {
    validIcon: false,
    showValidationMessage: true,
    tabIndex: 0,
  };

  componentDidMount() {
    const { defaultValue } = this.props;

    if (defaultValue) {
      this.setState({ hasValue: true });
    }
  }

  onBlurValidationInput(event: any, onBlur: any) {
    event.target.classList.remove('changed');
    this.setState({
      validValue: !event.target.validity.typeMismatch && !event.target.validity.patternMismatch,
      showPassValidationBlock: event.target.value.length !== 0
    });
    if (onBlur) {
      onBlur(event);
    }
  }

  validationPassword = (event: any, onChange: any) => {
    const isSpaceInPass = /\s/.test(event.target.value);
    let isPatternPass = false;
    if (this.props.pattern) {
      isPatternPass = new RegExp(this.props.pattern).test(event.target.value);
    }

    if (event.target.value !== 0) {
      this.setState({
        startInputPass: event.target.value !== 0,
        isSpaceInPass,
        isPatternPass
      });
    }

    if (!isSpaceInPass && isPatternPass && onChange) {
      onChange(event);
    }
  };

  showErrorText = () => {
    this.setState({ showErrorText: !this.state.validValue || !this.state.hasValue });
  };

  showHidePassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  setTypePassword() {
    return this.state.showPassword ? INPUT_TYPE.TEXT : INPUT_TYPE.PASSWORD;
  }

  showPassValidationBlock() {
    this.setState({ showPassValidationBlock: true });
  }

  render() {
    const {
      idInput,
      type,
      name,
      tabIndex = 0,
      placeholder,
      labelText,
      onChange,
      validIcon,
      textError,
      refInput,
      defaultValue,
      autoComplete,
      pattern,
      validationStatusBeforeSubmit,
      showValidationMessage,
      isUserExist,
      onBlur,
      onFocus
    } = this.props;
    return (
      <InputContainer theme={this.props.theme} isUserExist={isUserExist || false}>
        <InputElement
          id={idInput}
          ref={refInput}
          type={type === INPUT_TYPE.PASSWORD ? this.setTypePassword() : type}
          name={name}
          placeholder={placeholder}
          className={!this.state.hasValue ? 'is-empty' : 'full'}
          tabIndex={tabIndex}
          defaultValue={defaultValue}
          autoComplete={autoComplete || ''}
          pattern={pattern}
          onBlur={event => {
            this.setState({ hasValue: !!event.target.value });
            this.onBlurValidationInput(event, onBlur);
          }}
          onFocus={(event: any) => {
            if (type === INPUT_TYPE.PASSWORD) {
              this.showPassValidationBlock();
            }
            if (onFocus) {
              onFocus(event)
            };
          }}
          onChange={
            type === INPUT_TYPE.PASSWORD
              ? (event: any) => this.validationPassword(event, onChange)
              : onChange
          }
          onInvalid={this.showErrorText}
          validationStatusBeforeSubmit={validationStatusBeforeSubmit}
          theme={this.props.theme}
        />
        <InputLabel theme={this.props.theme} htmlFor={idInput}>{labelText}</InputLabel>
        {validIcon && <ValidIcon theme={this.props.theme} name="check-mark-2" size={12} />}
        {textError && showValidationMessage && (
          <ValidationMessageBlock>
            <TextError theme={this.props.theme} className={!this.state.validValue ? 'active' : ''}>{textError}</TextError>
          </ValidationMessageBlock>
        )}
        {type === INPUT_TYPE.PASSWORD && (
          <EyeIcon
            role="button"
            tabIndex={0}
            ariaLabel={
              this.state.showPassword
                ? localize('[label eye icon hide]')
                : localize('[label eye icon show]')
            }
            name={this.state.showPassword ? 'eye-close' : 'eye-open'}
            onClick={() => {
              this.showHidePassword();
            }}
            onKeyDown={() => {
              this.showHidePassword();
            }}
            theme={this.props.theme}
          />
        )}
        {type === INPUT_TYPE.PASSWORD && showValidationMessage && (
          <ValidationMessageBlock className={this.state.showPassValidationBlock ? 'show' : 'hide'}>
            <TextError
              theme={this.props.theme}
              className={
                this.state.startInputPass && (this.state.isSpaceInPass ? 'invalid' : 'valid')
              }
            >
              <Icon name="check-mark-2" />
              {localize('[validation pass space]')}
            </TextError>
            <TextError
              className={
                this.state.startInputPass && (this.state.isPatternPass ? 'valid' : 'invalid')
              }
              theme={this.props.theme}
            >
              <Icon name="check-mark-2" />
              {localize('[validation pass count char]')}
            </TextError>
          </ValidationMessageBlock>
        )}
      </InputContainer>
    );
  }
}

export default withTheme(Input);
