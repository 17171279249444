import * as React from 'react';
import { localize } from 'core/localization';
import { INPUT_TYPE } from 'constants/components';
import Input from '../Input';

type NameInputProps = {
  userName: string;
  validationStatusBeforeSubmit: boolean;
  onChange(): void;
  setRefs(node: any): void;
  onFocus?(e: any): void;
};

export class NameInput extends React.Component<NameInputProps, {}> {
  render() {
    const { userName, onChange, setRefs, validationStatusBeforeSubmit, onFocus } = this.props;

    return (
      <Input
        idInput="name"
        type={INPUT_TYPE.TEXT}
        refInput={(node: any) => setRefs(node)}
        name="name"
        labelText={localize('[name]')}
        defaultValue={userName}
        placeholder="John Doe"
        onChange={onChange}
        validIcon={true}
        pattern="(([a-zA-Z]|[\xC0-\uFFFF]|\d|-)+\s?)+"
        validationStatusBeforeSubmit={validationStatusBeforeSubmit}
        onFocus={onFocus}
      />
    );
  }
}

export default NameInput;
