import styled from 'styled-components';
import { Text, Separator } from 'components/common';
import media from 'components/mixins/media';

type ContainerProps = {
  logoShown?: boolean
}
export const Container = styled.div<ContainerProps>`
  text-align: center;
  margin-top: ${props => (props.logoShown ? '0' : '40px')};
`;

export const Figure = styled.div`
  margin-bottom: 40px;
`;

export const Header = styled(Text)`
  margin-bottom: 32px;

  ${media.small`
    margin-bottom: 16px;
  `};
`;

export const StyledText = styled(Text)`
  font-size: 16px;
  color: ${props => props.theme.colors.textColor};

  ${media.xlarge`
  font-size: 16px;
  `}

  ${media.large`
  font-size: 14px;
  `}

  ${media.mobile`
  font-size: 13px;
  `}
`;

export const ButtonWrapper = styled.div`
  margin-bottom: 20px;
  position: relative;
  & div[role='presentation'] {
    display: block;
  }
  & button {
    width: 100%;
  }
`;

export const SeparatorElement = styled(Separator)`
  margin-top: 80px;
  margin-bottom: 0;

  ${media.mobile`
    display: none;
  `};
`;

export const WidthLimiterContainer = styled.div`
  display: inline-block;
`;

export const AfterwordContainer = styled.div`
  margin-top: 56px;
`;
