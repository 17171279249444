import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { getUserAnswers } from 'store/questions/openQuestion/selectors';
import * as actions from 'store/questions/openQuestion/actions';
import { localize } from 'core/localization';
import EditableTextarea from 'components/common/EditableTextarea';
import { EditableContainer } from './OpenQuestion.styled';
import { RootAppState } from 'store/types';

type OpenQuestionProps = {
  id: string;
  type: string;
  answers?: { [key: string]: any };
  actions?: { [key: string]: any };
  isAnswered?: boolean;
};

type OpenQuestionState = {
  answers: { [key: string]: any };
}

export class OpenQuestion extends React.PureComponent<OpenQuestionProps, OpenQuestionState> {
  constructor(props: OpenQuestionProps) {
    super(props);
    this.state = {
      answers: this.props.answers || {}
    };
  }


  componentDidUpdate(prevProps: OpenQuestionProps) {
    if (this.props.id !== prevProps.id || this.props.answers !== prevProps.answers) {
      this.setState({
        answers: this.props.answers || {}
      });
    }
  }

  onChangeHandler(event: any) {
    const { answers = {} } = this.props;
    answers.answeredText = event.target.value;
    this.setState({
      answers
    });
  }

  onFocusOutTextArea() {
    const { answers } = this.state;
    this.props.actions && this.props.actions.openQuestionAnswerIsSelected(answers.id, answers.answeredText);
  }

  render() {
    const { isAnswered = false, answers = {} } = this.props;
    return (
      <EditableContainer>
        <EditableTextarea
          placeholder={`${localize('[placeholder textarea]')}`}
          onResize={() => { }}
          onBlur={() => this.onFocusOutTextArea()}
          onChange={(e: any) => this.onChangeHandler(e)}
          defaultValue={answers.answeredText}
          disabled={isAnswered}
          tabIndex={1}
        />
      </EditableContainer>
    );
  }
}

function mapStateToProps(state: RootAppState, ownProps: OpenQuestionProps) {
  return {
    answers: getUserAnswers(state, ownProps.id)
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OpenQuestion);
