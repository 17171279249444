import styled from 'styled-components';
import { transparentize } from 'utils/color';
import { Z_INDEX } from 'constants/components';
import media from 'components/mixins/media';
import { Icon } from 'components/common';
import { pointerOnHover } from 'components/mixins/interactivity';
import buttonDefaultStyle from 'components/mixins/normalize';

export const TailIcon = styled(Icon)`
  color: ${props => transparentize(props.theme.colors.textColor, 0.5)};
`;

type TailProps = {
  tabIndex: number;
  ariaLabel: string;
  isExpanded: boolean;
}
export const Tail = styled.button.attrs((props: TailProps) => ({
  tabIndex: props.tabIndex,
  'aria-label': props.ariaLabel,
  'data-test': 'hamburger-button'
})) <TailProps>`
  ${buttonDefaultStyle};
  position: absolute;
  top: 16px;
  right: -54px;
  padding: 16px;
  z-index: ${Z_INDEX.TOC_TAIL};
  ${pointerOnHover};
  &:hover ${TailIcon}, &:focus ${TailIcon} {
    color: ${props => props.theme.colors.textColor};
  }

  ${media.small`
    top: 8px;
    right: -45px;
  `};

  ${props =>
    props.isExpanded &&
    media.mobile`
      top: 8px;
      right: -38px;
  `}};
`;
