import * as React from 'react';
import {
  SINGLE_CHOICE,
  MULTIPLE_CHOICE,
  SINGLE_CHOICE_IMAGE,
  STATEMENT_CHOICE,
  OPEN_QUESTION,
  RANKING_TEXT,
  SCENARIO,
  FILL_IN_THE_BLANK,
  DRAG_AND_DROP_TEXT,
  TEXT_MATCHING,
  HOTSPOT
} from 'constants/questionTypes';
import MultipleChoice from './MultipleChoice';
import SingleChoiceImage from './SingleChoiceImage';
import { QuestionContainer, QuestionContainerOverlay } from './Question.styled';
import StatementChoice from './Statement';
import OpenQuestion from './OpenQuestion';
import RankingText from './RankingText';
import Scenario from './Scenario';
import FillInTheBlank from './FillInTheBlank';
import DragAndDropText from './DragAndDropText';
import TextMatching from './TextMatching';
import Hotspot from './Hotspot';

type QuestionProps = {
  id: string;
  type: string;
  isAnswered: boolean;
  bodyBackground: { [key: string]: any };
};

export class Question extends React.PureComponent<QuestionProps, {}> {
  render() {
    const { id, type, isAnswered, bodyBackground } = this.props;
    return (
      <QuestionContainer>
        {renderQuestion(id, type, isAnswered)}
        <QuestionContainerOverlay isShown={isAnswered} bodyBackground={bodyBackground} />
      </QuestionContainer>
    );
  }
}

function renderQuestion(id: string, type: string, isAnswered: boolean) {
  switch (type) {
    case SINGLE_CHOICE:
      return <MultipleChoice id={id} type={type} isSingleAnswer isAnswered={isAnswered} />;
    case SINGLE_CHOICE_IMAGE:
      return <SingleChoiceImage id={id} type={type} isAnswered={isAnswered} />;
    case MULTIPLE_CHOICE:
      return <MultipleChoice id={id} type={type} isAnswered={isAnswered} />;
    case STATEMENT_CHOICE:
      return <StatementChoice id={id} type={type} isAnswered={isAnswered} />;
    case OPEN_QUESTION:
      return <OpenQuestion id={id} type={type} isAnswered={isAnswered} />;
    case RANKING_TEXT:
      return <RankingText id={id} type={type} />;
    case SCENARIO:
      return <Scenario id={id} type={type} isAnswered={isAnswered} />;
    case FILL_IN_THE_BLANK:
      return <FillInTheBlank id={id} type={type} isAnswered={isAnswered} />;
    case DRAG_AND_DROP_TEXT:
      return <DragAndDropText id={id} type={type} />;
    case TEXT_MATCHING:
      return <TextMatching id={id} type={type} />;
    case HOTSPOT:
      return <Hotspot id={id} type={type} />;
    default:
      return null;
  }
}

export default Question;
