import styled, { css } from 'styled-components';
import { transparentize } from 'utils/color';
import media from 'components/mixins/media';

type DragDropMobileWrapperProps = {
  isImageLoaded: boolean;
  isImageNotFound: boolean;
}
export const DragDropMobileWrapper = styled.div<DragDropMobileWrapperProps>`
  height: ${props => (props.isImageLoaded ? 'auto' : '182px')};
  background: ${props =>
    props.isImageNotFound || !props.isImageLoaded
      ? transparentize(props.theme.colors.textColor, 0.02)
      : 'transparent'};
  padding: ${props => (props.isImageNotFound ? '16px 0' : '0')};

  img {
    display: block;
    opacity: ${props => (props.isImageLoaded ? '1' : '0')};
    max-width: 100%;
    margin: ${props => (props.isImageNotFound ? '0 auto' : '0')};
  }

  ${media.xlarge`
    overflow-x: auto;
    -webkit-overflow-scrolling: auto;
    position: relative;
    display: block;
    width: 100%;

    img {
      max-width: none;
    }
  `};
`;

type DragDropMobileProps = {
  position: any;
  isImageLoaded: boolean;
}
export const DragDropMobile = styled.div<DragDropMobileProps>`
  ${props =>
    props.position
      ? css`
          position: absolute;
          top: ${props.position.y}px;
          left: ${props.position.x}px;
          transition: 0.3s;
          transform: ${!props.isImageLoaded ? 'scale(0)' : ''};
          opacity: ${!props.isImageLoaded ? '0.8' : '1'};
        `
      : css``};
`;
