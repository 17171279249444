import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import withNavigation from 'components/hocs/withNavigation';
import { Button, Separator, CourseLogo } from 'components/common';
import { getLogo, isMainLogoShown } from 'store/settings/selectors';
import { BUTTON } from 'constants/components';
import Reviewable from 'components/review/Reviewable';
import { localize } from 'core/localization';
import * as sectionSelectors from 'store/sections/selectors';
import {
  PageTitle,
  LearningObjectiveSectionTitle,
  LearningObjectiveContent,
  LearningObjectiveWrapper,
  LearningObjectiveTitle,
  LearningObjectiveText,
  LearningObjectiveResult,
  LearningObjectiveIcon
} from './LearningObjective.styled';
import withFallbackToNotFound from '../../components/hocs/withFallbackToNotFound';
import { RootAppState } from 'store/types';
import { REVIEW_COMMENT_PROPERTIES, REVIEW_COMMENT_TYPES } from 'constants/review';

type LearningObjectiveProps = {
  section: { [key: string]: any };
  navigateToNextPage(): void;
  logoUrl: string;
  logoShown: boolean;
};
export class LearningObjective extends Component<LearningObjectiveProps, {}> {
  navigateToNextPage = () => {
    const { navigateToNextPage } = this.props;
    navigateToNextPage();
  };

  render() {
    const { section, logoUrl, logoShown } = this.props;
    const {
      affectedProgressCount,
      learningObjective,
      title,
      id,
      affectedProgressCorrectlyAnsweredQuestionsCount
    } = section;
    return (
      <Fragment>
        {logoShown && <CourseLogo logoUrl={logoUrl} />}
        <PageTitle>{localize('[section overview]')}</PageTitle>
        <Reviewable context={{ type: REVIEW_COMMENT_TYPES.SECTION, property: REVIEW_COMMENT_PROPERTIES.TITLE, id, title }}>
          <LearningObjectiveSectionTitle appearance="h1">{title}</LearningObjectiveSectionTitle>
        </Reviewable>
        <LearningObjectiveWrapper>
          <LearningObjectiveContent>
            <LearningObjectiveIcon size={24} name="learning-objective" />
            <LearningObjectiveTitle appearance={'p'}>
              {localize('[learning objective]')}
            </LearningObjectiveTitle>
            <LearningObjectiveText appearance={'p'}>{learningObjective}</LearningObjectiveText>
            {!!affectedProgressCount && (
              <LearningObjectiveResult appearance={'p'}>
                {localize('[you need to answer X questions out of Y]', {
                  correctQuestionsCount: affectedProgressCorrectlyAnsweredQuestionsCount,
                  totalQuestionsCount: affectedProgressCount
                })}
              </LearningObjectiveResult>
            )}
          </LearningObjectiveContent>
        </LearningObjectiveWrapper>
        <Separator margin={48} opacity={0.1} color="textColor" />
        <Button
          layout={BUTTON.LAYOUT.PRIMARY}
          onClick={this.navigateToNextPage}
          tabIndex={1}
          suffixIcon="arrow-down"
        >
          {localize('[start section]')}
        </Button>
      </Fragment>
    );
  }
}

function mapStateToProps(state: RootAppState, ownProps: any) {
  const { sectionId } = ownProps.match.params;
  const { title, learningObjective } = sectionSelectors.getSection(state, sectionId);
  return {
    logoUrl: getLogo(state),
    logoShown: isMainLogoShown(state),
    section: {
      id: sectionId,
      title,
      learningObjective,
      affectedProgressCount: sectionSelectors.getAffectProgressQuestionsCountInSection(
        state,
        sectionId
      ),
      affectedProgressCorrectlyAnsweredQuestionsCount: sectionSelectors.getAffectCorrectlyAnsweredQuestionsCount(
        state,
        sectionId
      )
    }
  };
}

function predicateForRedirect(props: any) {
  return !props.section;
}

export default withNavigation(
  connect(mapStateToProps)(withFallbackToNotFound(LearningObjective, predicateForRedirect))
);
