export type CourseState = {
    title: string;
    id: string;
    templateId: string;
    createdBy: string;
    createdOn: Date;
    hasIntroductionContent: boolean;
    isLoadingFailed: boolean;
    attemptId: string | null;
    hasBeenContinued: boolean;
    result: number;
    isCompleted: boolean;
    isCertificateDownloaded: boolean;
    score: number;
    isPassed: boolean;
    sections: string[];
    introductions?: string[];
    passedAfterwords: string[];
    failedAfterwords: string[];
    previewQuestionId?: string;
}

export enum ActionTypes {
    COURSE_DATA_LOADING_STARTED = 'COURSE_DATA_LOADING_STARTED',
    COURSE_DATA_LOADED = 'COURSE_DATA_LOADED',
    COURSE_DATA_LOADING_FAILED = 'COURSE_DATA_LOADING_FAILED',
    COURSE_SCORE_UPDATED = 'COURSE_SCORE_UPDATED',
    COURSE_LAUNCHED = 'COURSE_LAUNCHED',
    COURSE_STARTED = 'COURSE_STARTED',
    COURSE_PROGRESS_RESTORED = 'COURSE_PROGRESS_RESTORED',
    COURSE_CLEANUP = 'COURSE_CLEANUP',
    COURSE_FINISHED = 'COURSE_FINISHED',
    COURSE_FINALIZED = 'COURSE_FINALIZED',
    CLEAR_PREVIEW_QUESTION_ID = 'CLEAR_PREVIEW_QUESTION_ID',
    CERTIFICATE_DOWNLOADED = 'CERTIFICATE_DOWNLOADED',
    COURSE_IS_PASSED = 'COURSE_PASSED'
}

interface CourseIsPassed {
    type: ActionTypes.COURSE_IS_PASSED,
    payload: {
        isPassed: boolean
    }
}

interface CourseScoreUpdate {
    type: ActionTypes.COURSE_SCORE_UPDATED,
    payload: {
        score: number,
        isPassed: boolean
    }
}

interface CourseDataLoadingStarted {
    type: ActionTypes.COURSE_DATA_LOADING_STARTED
}

interface CourseDataLoaded {
    type: ActionTypes.COURSE_DATA_LOADED,
    payload: CourseState
}

interface CourseDataLoadedFailed {
    type: ActionTypes.COURSE_DATA_LOADING_FAILED;
    reason: any;
}

interface CourseProgressRestored {
    type: ActionTypes.COURSE_PROGRESS_RESTORED;
    payload: {
        attemptId: string;
    }
}

interface CourseLaunched {
    type: ActionTypes.COURSE_LAUNCHED;
    payload: {
        attemptId?: string;
    }
}

interface CourseStarted {
    type: ActionTypes.COURSE_STARTED;
}

interface CourseCleanUp {
    type: ActionTypes.COURSE_CLEANUP;
}

interface CourseFinished {
    type: ActionTypes.COURSE_FINISHED;
}

interface CourseFinalized {
    type: ActionTypes.COURSE_FINALIZED;
}

interface ClearPreviewQuestionId {
    type: ActionTypes.CLEAR_PREVIEW_QUESTION_ID;
}

interface CertificateDownloaded {
    type: ActionTypes.CERTIFICATE_DOWNLOADED;
    payload: any;
}

export type CourseActionTypes = CourseIsPassed | CourseScoreUpdate | CourseDataLoadingStarted | CourseDataLoadedFailed | CourseProgressRestored | CourseLaunched | CourseStarted | CourseCleanUp | CourseFinished | CourseFinalized | ClearPreviewQuestionId | CertificateDownloaded | CourseDataLoaded;
