import React from 'react';
import { SIZES, ICON } from 'constants/components';
import { transparentize } from 'utils/color';
import { localize } from 'core/localization';
import { NavigationItemIcon } from './NavigationItem.styled';

type NavigationLearningItemProps = {
  sectionTitle?: string;
  onClick(e?: any): void;
  onKeyDown(e?: any): void;
  isActive: boolean;
  role: string;
  tabIndex: number;
}
export const NavigationLearningItem = ({
  sectionTitle,
  onClick,
  onKeyDown,
  isActive,
  role,
  tabIndex
}: NavigationLearningItemProps) =>
  getNavigationItemIcon({
    ariaLabel: sectionTitle,
    title: sectionTitle,
    isActive,
    onClick,
    onKeyDown,
    size: SIZES.MEDIUM,
    role,
    tabIndex,
    ...getContentIconProps(isActive)
  });

type NavigationItemProps = {
  question: {
    title: string;
    isInformationContent: boolean;
    isAnswered: boolean;
    isAnsweredCorrectly: boolean;
    isSurvey: boolean;
  };
  isActive: boolean;
  onClick?(e?: any): void;
  onKeyDown?(e?: any): void;
  role: string;
  tabIndex: number;
};
export const NavigationItem = ({ question, onClick, onKeyDown, isActive, role, tabIndex }: NavigationItemProps) => {
  const iconProps = question.isInformationContent
    ? getContentIconProps(isActive)
    : getQuestionIconProps(isActive, question);
  let ariaLabel = question.isAnswered
    ? question.isAnsweredCorrectly
      ? `${localize('[aria label question icon ok]')} ${question.title}`
      : `${localize('[aria label question icon non]')} ${question.title}`
    : `${localize('[aria label question icon]')} ${question.title}`;
  return getNavigationItemIcon({
    ariaLabel: question.isInformationContent ? question.title : ariaLabel,
    title: question.title || ariaLabel,
    isActive,
    onClick,
    onKeyDown,
    size: SIZES.MEDIUM,
    role,
    tabIndex,
    ...iconProps
  });
};

function getNavigationItemIcon(configs: any) {
  return <NavigationItemIcon {...configs} />;
}

function getContentIconProps(isActive: boolean) {
  return {
    name: 'point',
    fontSize: 4,
    shape: isActive ? ICON.SHAPES.ROUND_SHADED : ICON.SHAPES.ROUND_FULL_COLOR,
    getColor: (colors: any) => getDefaultQuestionColor(isActive, colors),
    getShapeColor: (colors: any) => transparentize(colors.textColor, 0.1),
    ...(!isActive && {
      getHoverColor: (colors: any) => colors.textColor,
      hoverShape: ICON.SHAPES.ROUND_SHADED
    })
  };
}

function getQuestionIconProps(isActive: boolean, question: any) {
  let name = question.isAnswered ? (question.isAnsweredCorrectly ? 'ok' : 'close') : 'question';
  if (question.isSurvey) {
    name = 'survey';
  }
  return {
    name,
    fontSize: question.isAnswered ? 5 : 8,
    getColor: (colors: any) =>
      question.isAnswered
        ? getAnsweredQuestionColor(question, colors)
        : getDefaultQuestionColor(isActive, colors),
    getShapeColor: (colors: any) =>
      question.isAnswered
        ? getAnsweredQuestionColor(question, colors)
        : transparentize(colors.textColor, 0.1),
    shape: isActive
      ? ICON.SHAPES.ROUND_SHADED
      : question.isAnswered
        ? ICON.SHAPES.ROUND_OUTLINED
        : ICON.SHAPES.ROUND_FULL_COLOR,
    ...(!isActive && {
      hoverShape: ICON.SHAPES.ROUND_SHADED
    }),
    ...(!isActive &&
      !question.isAnswered && {
      getHoverColor: (colors: any) => colors.textColor
    })
  };
}

function getAnsweredQuestionColor(question: any, colors: any) {
  return question.isAnsweredCorrectly || question.isSurvey
    ? colors.correctColor
    : colors.incorrectColor;
}

function getDefaultQuestionColor(isActive: any, colors: any) {
  return isActive ? colors.mainColor : transparentize(colors.textColor, 0.5);
}

export default NavigationItem;
