export type HotSpotOnImageContent = {
  image: HTMLImageElement,
  spots: any[]
}
export function HotspotOnImageContentParser(contentHtml: string): HotSpotOnImageContent {
  const wrapper = document.createElement('div');
  wrapper.innerHTML = contentHtml;
  const container = wrapper.getElementsByClassName('hotspotOnImage')[0];
  const image = container.getElementsByTagName('img')[0];
  const spot = container.getElementsByClassName('spot');

  return {
    image,
    spots: [...spot]
  };
}

export default HotspotOnImageContentParser;
