import { TweenMax, TimelineMax } from 'gsap';
import { transparentize } from 'utils/color';
import { TOC } from 'constants/components';
import { getNodes } from './domHelper';
import { isHomeView, isCourseOverviewView } from './viewChecker';

export const animateToHome = (nodes: any, background: any) => {
  const timeLine = new TimelineMax();
  const duration = 0.2;
  timeLine
    .staggerTo([nodes.sidebarChild, nodes.contentChild], 0.3, { y: -200, opacity: 0 }, 0.1)
    .to(
      nodes.content,
      duration,
      {
        width: '50%',
        left: '50%'
      },
      '-=0.1'
    )
    .to(
      nodes.sidebar,
      duration,
      {
        width: '50%',
        left: 0,
        backgroundColor: background.header.coverOverlay.enabled
          ? transparentize(
            background.header.coverOverlay.color,
            background.header.coverOverlay.opacity
          )
          : 'transparent'
      },
      `-=${duration}`
    )
    .to(nodes.layout, 0, { opacity: 0 }, '+=0.2');

  return timeLine;
};

export const animateToCourseOverview = (nodes: any, isTocExpanded: any, { body, header }: { [key: string]: any }) => {
  const timeLine = new TimelineMax();
  const duration = 0.5;
  const getSidebarBackgroundColor = () => {
    if (body.enabled && body.color) {
      return transparentize(body.color, 0.9);
    }
    if (header.coverOverlay.enabled) {
      return transparentize(header.coverOverlay.color, header.coverOverlay.opacity);
    }
    return 'transparent';
  };

  timeLine
    .staggerTo([nodes.sidebarChild, nodes.contentChild], 0.3, { y: -200, opacity: 0 }, 0.1)
    .to(
      nodes.content,
      duration,
      {
        width: isTocExpanded ? window.innerWidth - TOC.EXPANDED_WIDTH : window.innerWidth,
        left: isTocExpanded ? TOC.EXPANDED_WIDTH : 0
      },
      '-=0.1'
    )
    .to(
      nodes.sidebar,
      duration,
      {
        width: isTocExpanded ? TOC.EXPANDED_WIDTH : 0,
        left: isTocExpanded ? 0 : -TOC.EXPANDED_WIDTH + 150,
        delay: 0,
        backgroundColor: getSidebarBackgroundColor()
      },
      `-=${duration - 0.2}`
    )
    .to(nodes.layout, 0, { opacity: 0 }, '+=0.1');

  return timeLine;
};

export const useDesktopTransitionForNextPage = (
  pathname: any,
  nodes: any,
  theme: any,
  isTocExpanded: any,
  background: any
) => {
  if (isHomeView(pathname)) {
    return animateToHome(nodes, background);
  }
  if (isCourseOverviewView(pathname)) {
    return animateToCourseOverview(nodes, isTocExpanded, background);
  }
};

export const useDesktopTransition = (node: any) => {
  TweenMax.to(node, 0, { opacity: 1 });
  document.body.removeAttribute('style');
  return {
    appear: () => {
      const nodes: any = getNodes(node);
      if (!nodes.content) {
        return;
      }

      const timeLine = new TimelineMax();
      timeLine
        .to(nodes.content, 0, { zIndex: 9999 })
        .from(nodes.content, 0.2, { width: '100%', left: 0, delay: 0.1 })
        .staggerFrom([nodes.sidebarChild, nodes.contentChild], 0.2, { y: -200, opacity: 0 }, 0.1)
        .eventCallback('onComplete', () => {
          for (const n in nodes) {
            nodes[n].removeAttribute('style');
          }
        });
    },
    enter: () => {
      const nodes = getNodes(node);
      if (!nodes.content) {
        return;
      }
      TweenMax.staggerFrom(
        [nodes.sidebarChild, nodes.contentChild],
        0.2,
        { y: -300, opacity: 0, delay: 1 },
        0.1,
        () => {
          nodes.sidebarChild.removeAttribute('style');
          nodes.contentChild.removeAttribute('style');
        }
      );
    },
    exit: (location: any, theme: any, isTocExpanded: any, background: any) => {
      const nodes = getNodes(node);
      if (!nodes.content) {
        return;
      }
      if (nodes.content && isTocExpanded) {
        nodes.content.style.zIndex = 9999;
      }
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useDesktopTransitionForNextPage(location.pathname, nodes, theme, isTocExpanded, background);
    }
  };
};
