import { getSizeString } from 'utils/string';

type DocumentContentIframe = {
  src: string;
  width: string;
  height: string;
};

export type DocumentContent = {
  id: string;
  title: string;
  type: string;
  size: string;
  link: string;
  existsLink: string;
  iframe: DocumentContentIframe;
};
export default class DocumentContentParser {
  static parse(contentHtml: string): DocumentContent {
    const wrapper = document.createElement('div');
    wrapper.innerHTML = contentHtml;
    const container = wrapper.getElementsByClassName('document-container')[0] as HTMLElement;
    const titleContainer = container.getElementsByClassName('document-title')[0] as HTMLElement;
    const linkContainer = container.getElementsByClassName(
      'download-document-btn'
    )[0] as HTMLAnchorElement;
    const iframe = container.getElementsByTagName('iframe')[0];
    const existsLink =
      linkContainer && linkContainer.href ? linkContainer.href.replace('/content', '/exists') : '';

    return {
      id: container.dataset.documentId || '',
      title: titleContainer && titleContainer.innerText,
      type: container.dataset.documentType ? container.dataset.documentType : '',
      size: getSizeString(
        container && container.dataset.documentSize ? +container.dataset.documentSize : 0
      ),
      link: linkContainer && linkContainer.href,
      existsLink,
      iframe: {
        src: iframe.src !== 'about:blank' ? iframe.src : '',
        width: iframe.width,
        height: iframe.height
      }
    };
  }
}
