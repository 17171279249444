import styled from 'styled-components';
import Icon from 'components/common/Icon';
import buttonDefaultStyle from 'components/mixins/normalize';
import media from 'components/mixins/media';
import { transparentize } from 'utils/color';

export const BottomLine = styled.div`
  width: 60%;
  height: 2px;
  background-color: ${props => transparentize(props.theme.colors.textColor, 0.08)};
  margin: 160px auto 0;
  ${media.mobile`
  margin: 80px auto 80px;
  `};
`;

type PopupWrapperProps = {
  ariaModal: any;
  role: string;
  tabIndex: number;
}
export const PopupWrapper = styled.div.attrs((props: PopupWrapperProps) => ({
  'aria-modal': props.ariaModal,
  'aria-label': props.ariaModal,
  tabIndex: props.tabIndex,
  role: props.role
})) <PopupWrapperProps>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
  background: ${props => transparentize(props.theme.colors.contentBodyColor, 0.98)};
  z-index: 200;
  overflow: auto;
`;

export const PopupContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: max-content;
  height: 100%;
`;

type PopupOverlayProps = {
  tabIndex: number;
  role: string;
}
export const PopupOverlay = styled.div.attrs((props: PopupOverlayProps) => ({
  tabIndex: props.tabIndex,
  role: props.role
})) <PopupOverlayProps>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

type CloseBtnWrapperProps = {
  tabIndex: number;
  ariaLabel: string;
}
export const CloseBtnWrapper = styled.button.attrs((props: CloseBtnWrapperProps) => ({
  tabIndex: props.tabIndex,
  'aria-label': props.ariaLabel
})) <CloseBtnWrapperProps>`
  ${buttonDefaultStyle};
  position: fixed;
  top: 20px;
  right: 20px;
  color: ${props => transparentize(props.theme.colors.textColor)};
  text-align: center;
  opacity: 0.4;
  transition: opacity 0.2s linear;
  cursor: pointer;
  z-index: 2;

  &:hover {
    opacity: 1;
  }
`;

type CloseBtnIconProps = {
  size: number;
  name: string;
}
export const CloseBtnIcon = styled(Icon) <CloseBtnIconProps>`
  color: ${props => transparentize(props.theme.colors.textColor)};
`;

export const CloseBtnText = styled(Icon)`
  display: block;
  color: ${props => transparentize(props.theme.colors.textColor)};
  padding-top: 6px;
  ${media.medium`
    display: none;
  `};
`;
