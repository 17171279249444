import download from 'downloadjs';

export default {
  async loadFile(downloadUrl: any, fileName: any) {
    const response: any = await fetch(`${downloadUrl}`).catch(er => console.error(er));
    const blob = await response.blob();
    const isChrome = navigator.userAgent.match('CriOS');
    const isFirefox = navigator.userAgent.match('FxiOS');
    // For ios and firefox chrome (Remove when webkit patch with fix is in production)
    if (!isChrome && !isFirefox) {
      return download(blob, fileName, blob.type);
    }
    const reader = new FileReader();
    const out = new Blob([blob], { type: blob.type });
    reader.onload = () => {
      const win: any = window.open();
      win.document.write(
        `<iframe src="${reader.result}" frameborder="0" style="display: block; width: 100vw; height: 100vh; max-width: 100%;" allowfullscreen />`
      );
    };
    return reader.readAsDataURL(out);
  }
};
