import * as React from 'react';
import { imageResizerQueryPattern, supportedBgSizes } from 'constants/common';
import { getImageDimensions, getThumbnailUrl } from 'utils/image';
import { Container, BackgroundImage } from './SectionImage.styled';

type SectionImageProps = {
  imageUrl: string
};

type SectionImageState = {
  bgSize: any;
  isImageLoading: boolean;
  imageUrl: string;
}

export class SectionImage extends React.Component<SectionImageProps, SectionImageState> {
  isUnmounted: boolean;
  imageDimensions: any;

  constructor(props: SectionImageProps) {
    super(props);
    this.isUnmounted = false;
    this.imageDimensions = {
      width: 255,
      height: 170
    };
    this.state = {
      bgSize: supportedBgSizes.initial,
      isImageLoading: true,
      imageUrl: props.imageUrl
    };
  }

  _getOriginalImageUrl() {
    const { imageUrl } = this.props;
    let originalImage = imageUrl;
    const coincidences = imageResizerQueryPattern.exec(imageUrl);
    if (coincidences && coincidences.length) {
      originalImage = imageUrl.substring(0, coincidences.index);
    }
    return originalImage;
  }

  async getBgProps() {
    try {
      const { width, height } = this.imageDimensions;
      let imageUrl = this._getOriginalImageUrl();
      const size: any = await getImageDimensions(imageUrl);
      let bgSize = 'initial';
      if (size.width >= width || size.height >= height) {
        imageUrl = getThumbnailUrl(imageUrl, width, height);
        bgSize = 'contain';
      }
      if (size.width >= width && size.height >= height) {
        imageUrl += '&scaleBySmallerSide=true';
        bgSize = 'cover';
      }

      if (!this.isUnmounted) {
        this.setState({ bgSize, isImageLoading: false, imageUrl });
      }
    } catch (e) {
      if (!this.isUnmounted) {
        this.setState({ bgSize: supportedBgSizes.initial, isImageLoading: false });
      }
    }
  }

  componentDidMount() {
    this.getBgProps();
  }

  componentWillUnmount() {
    this.isUnmounted = true;
  }

  render() {
    const { bgSize, isImageLoading, imageUrl } = this.state;
    if (isImageLoading) {
      return <Container />;
    }
    return (
      <Container isBgColorTransparent>
        <BackgroundImage url={imageUrl} bgSize={bgSize} />
      </Container>
    );
  }
}

export default SectionImage;
