export interface UserState {
  account: any | null; //TODO: change type any
  email: string;
  name: string;
  password: string | null;
  shortTermAccess: boolean;
  authorizationSkipped: boolean;
}

export enum ActionTypes {
  USER_AUTHENTICATED = 'USER_AUTHENTICATED',
  USER_UNAUTHENTICATED = 'USER_UNAUTHENTICATED',
  USER_AUTHENTICATION_SKIPPED = 'USER_AUTHENTICATION_SKIPPED',
  USER_AUTHENTICATION_NOT_SKIPPED = 'USER_AUTHENTICATION_NOT_SKIPPED'
}
type UserAuthenticated = {
  type: ActionTypes.USER_AUTHENTICATED;
  payload: any;
};

type UserUnauthenticated = {
  type: ActionTypes.USER_UNAUTHENTICATED;
};

type UserUserAuthenticationSkipped = {
  type: ActionTypes.USER_AUTHENTICATION_SKIPPED;
};

type UserUserAuthenticationNotSkipped = {
  type: ActionTypes.USER_AUTHENTICATION_NOT_SKIPPED;
};

export type UserActionTypes =
  | UserAuthenticated
  | UserUnauthenticated
  | UserUserAuthenticationSkipped
  | UserUserAuthenticationNotSkipped;
