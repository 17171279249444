import * as React from 'react';
import { Link } from 'react-router-dom';
import { localize } from 'core/localization';
import {
  MobileFooterContainer,
  Note,
  LinkToPageContainer,
  LinkToPageText
} from './MobileFooter.styled';

type MobileFooterProps = {
  noteTextKey?: string;
  navigationTextKey: string;
  navigateTo: string;
  navigationToTextKey: string;
};

export class MobileFooter extends React.Component<MobileFooterProps, {}> {
  render() {
    const { noteTextKey, navigationTextKey, navigateTo, navigationToTextKey } = this.props;
    return (
      <MobileFooterContainer>
        {noteTextKey && <Note appearance="p">{localize(noteTextKey)}</Note>}
        <LinkToPageContainer>
          <LinkToPageText>
            {localize(navigationTextKey)}
            <Link to={navigateTo}>{localize(navigationToTextKey)}</Link>
          </LinkToPageText>
        </LinkToPageContainer>
      </MobileFooterContainer>
    );
  }
}

export default MobileFooter;
