import styled from 'styled-components';

type MobileAnswerSeparatorProps = {
  margin?: number;
  height?: number;
  opacity?: number;
  color: string;
};

const DEFAULT_PROPS = {
  MARGIN: 32,
  HEIGHT: 2,
  OPACITY: 0.08
}

export const MobileAnswerSeparator = styled.div<MobileAnswerSeparatorProps>`
  position: relative;
  width: 60px;
  height: ${props => (props.height || DEFAULT_PROPS.HEIGHT) + (props.margin || DEFAULT_PROPS.MARGIN) * 2}px;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: ${props => props.height || DEFAULT_PROPS.HEIGHT}px;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    background-color: ${props =>
    props.theme.colors[props.color]
      ? props.theme.colors[props.color]
      : props.theme.colors.ctaButtonColor};
    opacity: ${props => (props.opacity ? props.opacity : 0.08)};
  }
`;

export default MobileAnswerSeparator;
