import { createSelector } from 'reselect';
import { objectToArray } from 'utils/object';
import {
  MULTIPLE_CHOICE,
  SINGLE_CHOICE,
  SINGLE_CHOICE_IMAGE,
  STATEMENT_CHOICE,
  OPEN_QUESTION,
  RANKING_TEXT,
  SCENARIO,
  FILL_IN_THE_BLANK,
  DRAG_AND_DROP_TEXT,
  TEXT_MATCHING,
  HOTSPOT,
  INFORMATION_CONTENT
} from 'constants/questionTypes';
import * as multipleChoiceSelectors from './multipleChoice/selectors';
import * as statementChoiceSelectors from './statementChoice/selectors';
import * as openQuestionSelector from './openQuestion/selectors';
import * as rankingTextSelector from './rankingText/selectors';
import * as scenarioSelector from './scenario/selectors';
import * as fillInTheBlankSelector from './fillInTheBlank/selectors';
import * as dragAndDropTextSelector from './dragAndDropText/selectors';
import * as textMatching from './textMatching/selectors';
import * as hotspot from './hotspot/selectors';
import * as informationContent from './informationContent/selectors';
import { RootAppState } from '../types';

export const getQuestion = (state: RootAppState, questionId: string) => state.questions[questionId];

export const getQuestionSection = (state: RootAppState, questionId: string) => state.questions[questionId].sectionId;

export const getQuestions = createSelector(
  (state: RootAppState) => state.questions,
  questions => objectToArray(questions)
);

export const getQuestionAnswers = (state: RootAppState, questionId: string) => state.questions[questionId].answers;

export const getQuestionResponse = (state: RootAppState, questionId: string) => state.questions[questionId].response;

export const getAffectProgressQuestionsCount = createSelector(
  getQuestions,
  questions => questions.filter(question => question.affectsProgress).length
);

export const getCorrectlyAffectProgressAnsweredQuestionsCount = createSelector(
  getQuestions,
  questions =>
    questions.filter(question => question.affectsProgress && question.isAnsweredCorrectly).length
);

export const isQuestionAnswered = createSelector(
  getQuestion,
  question => question && question.isAnswered
);

export const isQuestionAnsweredCorrectly = createSelector(
  getQuestion,
  question => question && question.isAnsweredCorrectly
);

export const hasBeenOpened = createSelector(
  getQuestion,
  question => question && question.hasBeenOpened
);

// TODO: should be Question type
export const getScore = (state: RootAppState, question: any) => { 
  switch (question.type) {
    case SINGLE_CHOICE_IMAGE:
    case SINGLE_CHOICE:
    case MULTIPLE_CHOICE:
      return multipleChoiceSelectors.getScore(state, question);
    case STATEMENT_CHOICE:
      return statementChoiceSelectors.getScore(state, question);
    case OPEN_QUESTION:
      return openQuestionSelector.getScore(state, question);
    case RANKING_TEXT:
      return rankingTextSelector.getScore(state, question);
    case SCENARIO:
      return scenarioSelector.getScore(state, question);
    case FILL_IN_THE_BLANK:
      return fillInTheBlankSelector.getScore(state, question);
    case DRAG_AND_DROP_TEXT:
      return dragAndDropTextSelector.getScore(state, question);
    case TEXT_MATCHING:
      return textMatching.getScore(state, question);
    case HOTSPOT:
      return hotspot.getScore(state, question);
    case INFORMATION_CONTENT:
      return informationContent.getScore(state, question);
    default:
      throw new Error(`Unable to calculate score for ${question.type} question`);
  }
};

export const getAffectProgressQuestions = createSelector(
  getQuestions,
  questions => questions.filter(question => question.affectsProgress)
);

export const questionExists = (state: RootAppState, questionId: string) =>
  questionId && Object.keys(state.questions).includes(questionId);

export const isFeedbackAnimating = createSelector(
  getQuestion,
  question => question.isFeedbackAnimating
);
