import { createSelector } from 'reselect';
import { getSections, getQuestions, affectsProgress } from 'store/sections/selectors';
import { isAccessLimited, getAllowedUsers } from 'store/settings/selectors';
import { getEmail, isAnonymous } from 'store/user/selectors';
import { INFORMATION_CONTENT } from 'constants/questionTypes';

import { RootAppState } from '../types';

export const getCourseTitle = (state: RootAppState) => state.course.title;

export const getCourseIntroductions = (state: RootAppState) => state.course.introductions;

export const getCourse = (state: RootAppState) => state.course;

export const isPassed = (state: RootAppState) => state.course.isPassed;

export const getCourseId = (state: RootAppState) => state.course.id;

export const getTemplateId = (state: RootAppState) => state.course.templateId;

export const getAttemptId = (state: RootAppState) => state.course.attemptId;

export const hasBeenContinued = (state: RootAppState) => state.course.hasBeenContinued;

export const getPreviewQuestionId = (state: RootAppState) => state.course.previewQuestionId;

export const getCourseStructure = createSelector(
  (state: RootAppState) => state,
  (state: RootAppState) => ({
    title: getCourseTitle(state),
    sections: getSections(state).map(section => ({
      title: section.title,
      id: section.id,
      isPassed: section.isPassed,
      score: section.score,
      learningObjective: section.learningObjective,
      affectsProgress: affectsProgress(state, section.id),
      questions: getQuestions(state, section.id).map((question: any) => ({
        id: question.id,
        title: question.title,
        isInformationContent: question.type === INFORMATION_CONTENT,
        isAnswered: question.isAnswered,
        isAnsweredCorrectly: question.isAnsweredCorrectly,
        affectsProgress: question.affectsProgress,
        isSurvey: question.isSurvey,
        hasBeenOpened: question.hasBeenOpened,
        type: question.type
      }))
    }))
  })
);

export const getQuestionsStructure = createSelector(
  (state: RootAppState) => getCourseStructure(state),
  ({ sections }) => {
    let questionsSchema: string[] = [];

    sections.forEach(({ questions }) => {
      questions.forEach(({ id }: {[key: string]: any}) => {
        questionsSchema.push(id);
      });
    });

    return questionsSchema;
  }
);

export const isCourseAccessLimited = (state: RootAppState) => {
  let email = getEmail(state);
  return (
    (!!email || isAnonymous(state)) &&
    isAccessLimited(state) &&
    !getAllowedUsers(state).some((item: any) => item.email === email)
  );
};

export const getScore = (state: RootAppState) => state.course.score;

export const isCertificateDownloaded = (state: RootAppState) => state.course.isCertificateDownloaded;

export const getPassedAfterwords = (state: RootAppState) => state.course.passedAfterwords;

export const getFailedAfterwords = (state: RootAppState) => state.course.failedAfterwords;

export const isFinalized = (state: RootAppState) => state.course.score === 100;
