import eventEmitter, { events } from 'core/events/eventEmitter';
import { INFORMATION_CONTENT } from 'constants/questionTypes';
import { getAffectProgressQuestions, getSection } from './selectors';
import { isOverallMasteryScore, getMasteryScoreValue } from '../settings/selectors';

import { ActionTypes } from './types';
import { ThunkResult } from '../types';


export const updateScore = (sectionId: string): ThunkResult => (dispatch, getState) => {
  const scorePerCourse = isOverallMasteryScore(getState());
  const masteryScore = getMasteryScoreValue(getState());
  const questions = getAffectProgressQuestions(getState(), sectionId);

  const correctQuestions = questions.filter((question: any) =>
    question.type === INFORMATION_CONTENT ? question.hasBeenOpened : question.isAnsweredCorrectly
  );
  const score = questions.length
    ? Math.floor((correctQuestions.length * 100) / questions.length)
    : 100;

  const sectionIsPassed = scorePerCourse ? score === 100 : score >= masteryScore;

  dispatch({
    type: ActionTypes.SECTION_SCORE_UPDATED,
    payload: {
      id: sectionId,
      score,
      isPassed: sectionIsPassed
    }
  });
};

export const updateProgress = (sectionId: string): ThunkResult => (dispatch, getState) => {
  dispatch(updateScore(sectionId));
  eventEmitter.emit(events.SECTION_PROGRESSED, getSection(getState(), sectionId));
};

export const onSectionIsPassed = (sectionId: string): ThunkResult => dispatch => {
  dispatch({
    type: ActionTypes.SECTION_IS_PASSED,
    payload: {
      id: sectionId,
      isPassed: true
    }
  });
};

export const sectionsLoaded = (sections: any): ThunkResult => dispatch => {
  dispatch({ type: ActionTypes.SECTIONS_LOADED, payload: sections });
};

export const cleanup = (sectionId: string): ThunkResult => dispatch => {
  dispatch({
    type: ActionTypes.SECTION_SCORE_UPDATED,
    payload: {
      id: sectionId,
      score: 0,
      isPassed: false
    }
  });
};
