import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import shuffle from 'utils/shuffle';
import { getUserAnswers, getUserResponse } from 'store/questions/rankingText/selectors';
import * as actions from 'store/questions/rankingText/actions';
import { DragDrop } from 'components/common';
import { RootAppState } from 'store/types';

type RankingTextProps = {
  id: string;
  type: string;
  answers?: Array<any>;
  actions?: { [key: string]: any };
  isMultipleList?: boolean;
  response?: Array<any>;
};

export class RankingText extends React.Component<RankingTextProps, {}> {
  moveItem(items: any, id: string, action: any) {
    action.rankingAnswersAreSelected(items, id);
  }

  render() {
    const { answers, response = [] } = this.props;
    const elements = response.length ? response : shuffle(answers);
    const listsOfItems = [{ id: this.props.id, items: elements }];
    return (
      <DragDrop
        moveItem={this.moveItem}
        actions={this.props.actions}
        id={this.props.id}
        isMultipleList={false}
        listsOfItems={listsOfItems}
      />
    );
  }
}

function mapStateToProps(state: RootAppState, ownProps: RankingTextProps) {
  return {
    answers: getUserAnswers(state, ownProps.id),
    response: getUserResponse(state, ownProps.id)
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RankingText);
