import styled from 'styled-components';
import { Text } from 'components/common';
import media from 'components/mixins/media';

export const Figure = styled.div`
  margin-bottom: 55px;
  text-align: center;
`;

export const TextElement = styled(Text)`
  font-weight: normal;
  color: ${props => props.theme.colors.textColor};
  text-align: center;
  ${media.mobile`
  padding: 0 65px;
`};
`;

export const ButtonsWrapper = styled.div`
  display: inline-block;
  margin-top: 40px;

  & > button {
    width: 100%;
    display: block;
    margin: 0 0 20px;
  }
`;

export const Wrapper = styled.div`
  text-align: center;
  z-index: 1;
`;
