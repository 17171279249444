import * as React from 'react';
import { withTheme } from 'styled-components';
import { getAttributeFromHtml } from 'utils/element';
import { Container } from './ContentItem.styled';
import ContentWrapper from '../ContentWrapper';
import Document from './Document';
import HotspotOnImage from './HotspotOnImage';
import ShowMore from './ShowMore';
import LinkCuration from './LinkCuration';
import AudioWrapper from './Audio/Audio.styled';
import VideoWrapper from './Video/Video.styled';

type ContentItem = {
  value: string;
  childrenElements: any[];
  inverted: boolean;
  theme: { [key: string]: any };
};

export const ContentItem = ({ value, inverted, theme, childrenElements }: ContentItem) =>
  (<Container inverted={inverted}>
    {renderContent(value, childrenElements, theme, inverted)}
  </Container>)
  ;

export default withTheme(ContentItem);

function renderContent(contentHtml: any, childrenElements: any[], theme: { [key: string]: any }, inverted: boolean) {
  let type = getAttributeFromHtml(contentHtml, 'data-type');

  switch (type) {
    case 'document':
      return <Document content={contentHtml} inverted={inverted} />;
    case 'hotspot':
      return <HotspotOnImage content={contentHtml} />;
    case 'expandableBlock':
      return (
        <ShowMore content={contentHtml} childrenElements={childrenElements} inverted={inverted} />
      );
    case 'linkCuration':
      return <LinkCuration content={contentHtml} />;
    case 'singleAudio':
      return <AudioWrapper dangerouslySetInnerHTML={ContentWrapper.wrap(contentHtml, theme)} />;
    case 'videoInTheLeft':
    case 'videoWithText':
    case 'videoInTheRight':
    case 'singleVideo':
      return <VideoWrapper dangerouslySetInnerHTML={ContentWrapper.wrap(contentHtml, theme)} />;
    default:
      return (
        <div
          data-test="content-default"
          dangerouslySetInnerHTML={ContentWrapper.wrap(contentHtml, theme)}
        />
      );
  }
}
