import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { localize } from 'core/localization';
import { getBackground, getLogo } from 'store/settings/selectors';
import { INDEX_PATH } from 'constants/navigation';
import { BUTTON } from 'constants/components';
import {
  NotFoundPageSidebar,
  NotFoundPageContent,
  Logo,
  Header,
  Description,
  HomeButton
} from './NotFound.styled';
import { RootAppState } from 'store/types';

type NotFoundProps = {
  background: {[key: string]: any};
  logoUrl: string
};

const NotFound = (props: NotFoundProps) => {
  useEffect(() => {
    document.title = `${localize('[not found title]')}`;
  }, []);

  return (
    <Fragment>
      <NotFoundPageSidebar background={props.background} />
      <NotFoundPageContent background={props.background}>
        <Logo altText={localize('[alt logo login]')} logoUrl={props.logoUrl} />
        <Header>404</Header>
        <Description>{localize('[not found text]')}</Description>
        <HomeButton link={`#${INDEX_PATH}`} target="_self" layout={BUTTON.LAYOUT.PRIMARY}>
          {localize('[home]')}
        </HomeButton>
      </NotFoundPageContent>
    </Fragment>
  );
};

function mapStateToProps(state: RootAppState) {
  return {
    background: getBackground(state),
    logoUrl: getLogo(state)
  };
}

export default connect(
  mapStateToProps,
  {}
)(NotFound);
