import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { getUserAnswers } from 'store/questions/multipleChoice/selectors';
import { MultipleChoiceItems } from 'components/common';
import * as actions from 'store/questions/multipleChoice/actions';
import { RootAppState } from 'store/types';

interface MultipleChoiceProps {
  id: string,
  type: string,
  isSingleAnswer: boolean,
  answers?: any[],
  actions?: { [key: string]: any },
  isAnswered: boolean
}

export class MultipleChoice extends React.Component<MultipleChoiceProps, {}> {
  static defaultProps = {
    isSingleAnswer: false,
    answers: []
  }
  selectItem = (id: string, itemId: string, isMultiple: boolean) => {
    if (isMultiple ) {
      this.props.actions && this.props.actions.toggleAnswerIsSelected(id, itemId);
    } else {
      this.props.actions && this.props.actions.setSingleAnswerSelected(
        id,
        itemId,
        'QUESTION_MULTIPLECHOICE_SET_SINGLE_ANSWER_SELECTED'
      );
    }
  };

  render() {
    const { answers = [], isAnswered } = this.props;
    return (
      <MultipleChoiceItems
        items={answers}
        isMultiple={!this.props.isSingleAnswer}
        selectItem={this.selectItem}
        id={this.props.id}
        ariaDisabled={isAnswered}
        tabIndex={1}
      />
    );
  }
}

function mapStateToProps(state: RootAppState, ownProps: MultipleChoiceProps) {
  return {
    answers: getUserAnswers(state, ownProps.id)
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MultipleChoice);
