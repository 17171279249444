import styled, { css } from 'styled-components';
import media from 'components/mixins/media';
import { Z_INDEX, TOC } from 'constants/components';
import { MEDIA_TABLET, MEDIA_LARGE } from 'constants/screenResolutions';
import { getCustomScrollbars } from 'components/mixins/customScrollbars';
import { transition, pointerOnHover } from 'components/mixins/interactivity';
import { PageSidebar, PageContent } from 'components/shell/Layout';
import { PageContentChild } from 'components/shell/Layout/Layout.styled';
import { bodyBackground } from 'components/mixins/backgrounds';
import { transparentize } from 'utils/color';

type BlockoutProps = {
  ariaHidden: any;
  isTreeOfContentExpanded: boolean;
}
export const Blockout = styled.div.attrs((props: BlockoutProps) => ({
  'aria-hidden': props.ariaHidden
})) <BlockoutProps>`
  position: absolute;
  right: 0;
  top: 0;
  z-index: ${Z_INDEX.BLOCKOUT};
  opacity: 0;
  background-color: ${props => transparentize(props.theme.colors.contentBodyColor, 0.9)};
  ${transition('opacity')};
  ${pointerOnHover()};
  ${(props: BlockoutProps) =>
    props.isTreeOfContentExpanded &&
    css`${media.tablet`
      width: 100%;
      height: 100vh;
      opacity: 1;
    `}`};
`;

type CourseOverviewSidebarProps = {
  isTreeOfContentExpanded: boolean;
  background: any;
}
export const CourseOverviewSidebar = styled(PageSidebar) <CourseOverviewSidebarProps>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${Z_INDEX.TOC};
  width: ${TOC.EXPANDED_WIDTH}px;
  height: 100%;
  ${transition('left, transform')};
  ${props => bodyBackground(props.background.body)};
  ${media.small`
    position: fixed;
  `};
  ${media.tablet`
    & [data-sidebar-child]{
      height: 100%;
    }
  `};
  ${props =>
    props.isTreeOfContentExpanded &&
    css`
      @media (max-width: ${TOC.EXPANDED_WIDTH + TOC.TAIL_WIDTH}px) {
        width: auto;
        right: 30px;
      }
    `};
  ${props =>
    !props.isTreeOfContentExpanded &&
    css`
      left: -${TOC.EXPANDED_WIDTH}px;
      ${media.xsmall`
        left: 0;
        transform: translateX(-100%);
      `};
    `};
`;

type CourseOverviewContentProps = {
  background: any;
  isTreeOfContentExpanded: boolean;
}
export const CourseOverviewContent = styled(PageContent) <CourseOverviewContentProps>`
  position: absolute;
  top: 0;
  left: ${TOC.EXPANDED_WIDTH}px;
  width: calc(100% - ${TOC.EXPANDED_WIDTH}px);
  height: 100%;
  ${props => bodyBackground(props.background.body)};
  ${transition('left, width')};
  ${props =>
    !props.isTreeOfContentExpanded &&
    css`
      left: 0 !important;
      width: 100% !important;
    `};
  ${media.tablet`
    width: 100% !important;
    left: 0 !important;
  `};

  & ${PageContentChild} {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const PageContainer = styled.div`
  max-height: 100vh;
  margin: 0 auto;
`;

type ContentContainerProps = {
  logoShown: boolean;
  isTreeOfContentExpanded: boolean;
}
export const ContentContainer = styled.section<ContentContainerProps>`
  width: 880px;
  padding: ${props => (props.logoShown ? ' 0 0 120px 0' : '70px 0 120px 0')};
  margin-right: auto !important;
  margin-left: auto !important;
  position: relative;

  &:focus {
    outline: none;
  }

  ${media.xlarge`
    width: 750px;
  `}

  @media (max-width: ${props =>
    props.isTreeOfContentExpanded ? MEDIA_LARGE - 40 : MEDIA_TABLET}px) {
    width: 560px;
  }

  ${media.small`
    width: 100%;
  `};

  ${props => props.logoShown ? css`
    ${media.small`
      padding: 0 30px 80px 30px;
    `}
  ` : css`
    ${
      media.small`
      padding: 50px 30px 80px 30px;
    `}
  `}

`;

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${getCustomScrollbars()}
  overflow-x: hidden;
  background-color: ${props => props.theme.colors.secondaryColor};
  -webkit-overflow-scrolling: touch;
  ${media.small`
    position: fixed;
  `};
`;
