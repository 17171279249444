import * as React from 'react';
import { connect } from 'react-redux';
import { localize } from 'core/localization';
import progressStorage from 'core/progressStorage';
import { getEmail, getName } from 'store/user/selectors';
import { BUTTON } from 'constants/components';
import AuthForm from '../AuthForm';
import EmailInput from '../AuthForm/EmailInput';
import NameInput from '../AuthForm/NameInput';
import PasswordInput from '../AuthForm/PasswordInput';
import { FormContentWrapper } from '../AuthForm/AuthForm.styled';
import { ButtonSubmitSingUp, InputContainer } from './SignUpForm.styled';
import { RootAppState } from 'store/types';

type SignUpProps = {
  onSubmit(e: any, data: any): Promise<void>;
  userEmail?: string;
  userName?: string;
  formId: string;
  validationStatusBeforeSubmit?: any;
};

type SignUpState = {
  isUserExist: boolean;
}

export class SignUp extends React.Component<SignUpProps, SignUpState> {
  constructor(props: SignUpProps) {
    super(props);

    this.state = {
      isUserExist: false
    };
  }

  checkIsUserExist = async (email: string) => {
    let status = await progressStorage.userExists(email.toLowerCase());
    this.setState({ isUserExist: status.exists });
  };

  renderContent = (props: any) => {
    const { userEmail='', userName='' } = this.props;
    const { onChange, setRefs, validationStatusBeforeSubmit, email, isUserExist } = props;

    return (
      <FormContentWrapper>
        <InputContainer size={40}>
          <NameInput
            userName={userName}
            onChange={onChange}
            setRefs={setRefs}
            validationStatusBeforeSubmit={validationStatusBeforeSubmit.name}
          />
        </InputContainer>
        <InputContainer size={40}>
          <EmailInput
            userEmail={userEmail}
            isUserExist={isUserExist}
            checkIsUserExist={this.checkIsUserExist}
            onChange={onChange}
            setRefs={setRefs}
            email={email}
            textError={isUserExist ? localize('[user exist]') : localize('[error email input]')}
            validationStatusBeforeSubmit={validationStatusBeforeSubmit.email}
          />
        </InputContainer>
        <InputContainer size={17}>
          <PasswordInput
            onChange={onChange}
            setRefs={setRefs}
            validationStatusBeforeSubmit={validationStatusBeforeSubmit.password}
            pattern="\S{7,}"
            showValidationMessage={true}
          />
        </InputContainer>
        <ButtonSubmitSingUp layout={BUTTON.LAYOUT.PRIMARY} suffixIcon="arrow-right" type="submit">
          {localize('[sign up button]')}
        </ButtonSubmitSingUp>
      </FormContentWrapper>
    );
  };

  render() {
    const { isUserExist } = this.state;
    const { onSubmit, formId } = this.props;

    return (
      <AuthForm
        isUserExist={isUserExist}
        checkIsUserExist={this.checkIsUserExist}
        formId={formId}
        onSubmit={onSubmit}
        renderContent={this.renderContent}
      />
    );
  }
}

function mapStateToProps(state: RootAppState) {
  return {
    userEmail: getEmail(state),
    userName: getName(state)
  };
}

export default connect(mapStateToProps)(SignUp);
