export type QuestionsState = {
    [key: string]: any
}
export enum ActionTypes {
    QUESTIONS_LOADED = 'QUESTIONS_LOADED',
    QUESTION_ANSWERED = 'QUESTION_ANSWERED',
    QUESTION_PROGRESS_RESET = 'QUESTION_PROGRESS_RESET',
    QUESTION_OPENED = 'QUESTION_OPENED',
    QUESTION_PROGRESS_RESTORE = 'QUESTION_PROGRESS_RESTORE',
    QUESTION_RESTORE_RESPONSE = 'QUESTION_RESTORE_RESPONSE',
    FEEDBACK_ANIMATING = 'FEEDBACK_ANIMATING'
}

type QuestionLoaded = {
    type: ActionTypes.QUESTIONS_LOADED,
    payload: {
        questions: any // TODO: add type
    }
}

type QuestionAnswered = {
    type: ActionTypes.QUESTION_ANSWERED,
    payload: {
        id: string,
        score: number
    }
}

type QuestionOpened = {
    type: ActionTypes.QUESTION_OPENED,
    payload: {
        hasBeenOpened: boolean,
        questionId: string
    }
}

type QuestionProgressReset = {
    type: ActionTypes.QUESTION_PROGRESS_RESET,
    payload: {
        id: string,
        type: string
    }
}

type QuestionRestoreResponse = {
    type: ActionTypes.QUESTION_RESTORE_RESPONSE,
    payload: {
        id: string,
        response: any
    }
}

type QuestionProgressRestore = {
    type: ActionTypes.QUESTION_PROGRESS_RESTORE,
    payload: {
        id: string,
        score: number
    }
}

type FeedbackAnimating = {
    type: ActionTypes.FEEDBACK_ANIMATING,
    payload: {
        id: string,
        isFeedbackAnimating: boolean
    }
}
export type QuestionActionTypes = FeedbackAnimating | QuestionOpened | QuestionLoaded | QuestionAnswered |
    QuestionProgressReset | QuestionRestoreResponse | QuestionProgressRestore;
