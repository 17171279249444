import styled from 'styled-components';
import { Button, Text } from 'components/common';
import buttonDefaultStyle from 'components/mixins/normalize';
import { transparentize } from 'utils/color';

export const ButtonElement = styled(Button)`
  ${buttonDefaultStyle};
  flex: 0 0 auto;
  width: 227px;
  background-color: ${props => props.theme.colors.mainColor};
  font-size: 14px;
  margin-bottom: 24px;
  &:hover,
  &:focus {
    background-color: ${props => transparentize(props.theme.colors.mainColor, 0.9)};
  }
`;

export const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  margin-bottom: 40px;
`;

export const TitleElement = styled(Text)`
  font-weight: 300;
  line-height: 1.43;
  text-align: center;
  margin-bottom: 48px;
  color: ${props => props.theme.colors.textColor};
`;

export const ContinueLaterWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
`;
