import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TransitionGroup, Transition } from 'react-transition-group';
import { TweenMax } from 'gsap';
import { getChain } from 'store/navigation/selectors';
import { history } from 'store';
import { CourseOverviewContent } from './CourseOverview.styled';
import { RootAppState } from 'store/types';

export const COURSE_OVERVIEW_TRANSITION_DURATION = 0.4;

export const COURSE_OVERVIEW_TRANSITION_TIMEOUT = 1000;

type CourseOverviewRouteTransitionProps = {
  children: any;
  location: { [key: string]: any };
  isTreeOfContentExpanded: boolean;
  background: { [key: string]: any };
  urlsChain: any[];

}
export class CourseOverviewRouteTransition extends Component<CourseOverviewRouteTransitionProps, {}> {
  prevUrl: string;
  unlistenHistory: any;
  constructor(props: CourseOverviewRouteTransitionProps) {
    super(props);
    this.prevUrl = '';
    this.unlistenHistory = '';
  }

  componentDidMount() {
    const { location } = this.props;
    this.prevUrl = location.pathname;
    this.unlistenHistory = history.listen(listenLocation => {
      this.prevUrl = listenLocation.pathname;
    });
  }

  componentWillUnmount() {
    this.unlistenHistory();
  }

  onEnter = (node: any, isAppearing: any) => {
    const { location, urlsChain } = this.props;
    if (isAppearing) {
      return undefined;
    }
    if (urlsChain.indexOf(this.prevUrl) < urlsChain.indexOf(location.pathname)) {
      TweenMax.fromTo(
        node,
        COURSE_OVERVIEW_TRANSITION_DURATION,
        { opacity: 0, y: 300 },
        { opacity: 1, y: 0, delay: COURSE_OVERVIEW_TRANSITION_DURATION, clearProps: 'transform' }
      );
    } else {
      TweenMax.fromTo(
        node,
        COURSE_OVERVIEW_TRANSITION_DURATION,
        { opacity: 0, y: -300 },
        { opacity: 1, y: 0, delay: COURSE_OVERVIEW_TRANSITION_DURATION, clearProps: 'transform' }
      );
    }
  };

  onExit = (node: any) => {
    const { location, urlsChain } = this.props;
    if (urlsChain.indexOf(this.prevUrl) < urlsChain.indexOf(location.pathname)) {
      TweenMax.fromTo(
        node,
        COURSE_OVERVIEW_TRANSITION_DURATION,
        { opacity: 1, y: 0 },
        { opacity: 0, y: -300, clearProps: 'transform' }
      );
    } else {
      TweenMax.fromTo(
        node,
        COURSE_OVERVIEW_TRANSITION_DURATION,
        { opacity: 1, y: 0 },
        { opacity: 0, y: 300, clearProps: 'transform' }
      );
    }
  };

  render() {
    const { children, location, isTreeOfContentExpanded, background } = this.props;
    return (
      <TransitionGroup
        component={CourseOverviewContent}
        isTreeOfContentExpanded={isTreeOfContentExpanded}
        background={background}
      >
        <Transition
          key={location.pathname}
          in
          appear={false}
          onEnter={this.onEnter}
          onExit={this.onExit}
          timeout={COURSE_OVERVIEW_TRANSITION_TIMEOUT}
        >
          {children}
        </Transition>
      </TransitionGroup>
    );
  }
}

function mapStateToProps(state: RootAppState) {
  return {
    urlsChain: getChain(state)
  };
}

export default connect(mapStateToProps)(CourseOverviewRouteTransition);
