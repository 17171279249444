export function hasClass(element: Element, selector: string) {
  const className = ` ${selector} `;
  return ` ${element.className} `.replace(/[\n\t\r]/g, ' ').indexOf(className) > -1;
}

export function findClosest(element: Element, css: string) {
  if (window.Element.prototype.closest) {
    return element.closest(css);
  }

  if (!window.Element.prototype.matches) {
    window.Element.prototype.matches = window.Element.prototype.msMatchesSelector;
  }

  let node: Element | null = element;
  while (node) {
    if (node.matches(css)) {
      return node;
    }
    node = node.parentElement;
  }
  return null;
}

export function getStyles(theme: any) {
  return JSON.stringify({
    '@main-color': theme.colors.mainColor,
    '@content-body-color': theme.colors.contentBodyColor,
    '@text-color': theme.colors.textColor,
    '@design-type': 1
  });
}

export function getAttributeFromHtml(plainHtml: string, attribute: any) {
  if (!plainHtml || !attribute) {
    return null;
  }
  const regex = new RegExp(`${attribute}="(.*?)"`);
  const match = regex.exec(plainHtml);
  return match && match[1];
}
