import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { localize } from 'core/localization';
import { getUserAnswers } from 'store/questions/statementChoice/selectors';
import * as actions from 'store/questions/statementChoice/actions';
import Switch from './Switch/index';
import Option from './Option/index';
import MobileAnswerSeparator from './MobileAnswerSeparator';
import {
  AnswersList,
  AnswerListItem,
  AnswerText,
  CaptionContainer,
  CaptionMobileContainer,
  CaptionWrapper,
  Caption,
  TextWrapper
} from './Statement.styled';
import { RootAppState } from 'store/types';

type StatementChoiceProps = {
  id: string;
  type: string;
  answers?: Array<any>;
  actions?: { [key: string]: any };
  isAnswered: boolean;
};

type StatementChoiceState = {
  answers: Array<any>;
  isAnswered: boolean;
}

export class StatementChoice extends React.Component<StatementChoiceProps, StatementChoiceState> {
  constructor(props: StatementChoiceProps) {
    super(props);
    this.state = {
      answers: this.props.answers || [],
      isAnswered: this.props.isAnswered
    };
  }

  statementAnswerIsSelected = (answer: any, isTrue: boolean) => {
    const { id } = this.props;
    const { answers } = this.state;
    answers.forEach(a => {
      if (answer.id === a.id) {
        answer.userSelected = true;
        answer.isTrue = isTrue;
      }
    });
    this.props.actions && this.props.actions.statementAnswerIsSelected(id, answer.id, answer.isTrue);
    this.setState({ answers });
  };

  componentDidUpdate(prevProps: StatementChoiceProps) {
    if (this.props.id !== prevProps.id || this.props.answers !== prevProps.answers) {
      this.setState({
        answers: this.props.answers || [],
        isAnswered: this.props.isAnswered
      });
    }
  }

  render() {
    const { answers, isAnswered } = this.state;
    const trueText = localize('[statement question true text]', 'True');
    const falseText = localize('[statement question false text]', 'False');
    return (
      <AnswersList>
        <CaptionContainer ariaHidden={true}>
          <CaptionWrapper>
            <Caption>
              <TextWrapper title={trueText}>{trueText}</TextWrapper>
            </Caption>
            <Caption>
              <TextWrapper title={falseText}>{falseText}</TextWrapper>
            </Caption>
          </CaptionWrapper>
        </CaptionContainer>
        {answers.map((answer: any, index:any) => (
          <AnswerListItem key={index} role="radiogroup" ariaLabel={`answer${index}`} aria-labelledby={`answer${index}`}>
            <CaptionMobileContainer>
              <CaptionWrapper>
                <Caption>
                  <TextWrapper title={trueText}>{trueText}</TextWrapper>
                </Caption>
                <Caption>
                  <TextWrapper title={falseText}>{falseText}</TextWrapper>
                </Caption>
              </CaptionWrapper>
            </CaptionMobileContainer>
            <Switch>
              <Option
                onClick={
                  isAnswered ? undefined : () => this.statementAnswerIsSelected(answer, true)
                }
                ariaRole="radio"
                ariaLabel="true"
                selected={answer.isTrue && answer.userSelected}
                tabIndex={1}
                ariaDisable={isAnswered}
              />
              <Option
                onClick={
                  isAnswered ? undefined : () => this.statementAnswerIsSelected(answer, false)
                }
                ariaRole="radio"
                ariaLabel="false"
                selected={!answer.isTrue && answer.userSelected}
                tabIndex={1}
                ariaDisable={isAnswered}
              />
            </Switch>
            <AnswerText id={`answer${index}`} appearance="p">
              {answer.text}
            </AnswerText>
            <MobileAnswerSeparator opacity={0.1} color="textColor" />
          </AnswerListItem>
        ))}
      </AnswersList>
    );
  }
}

function mapStateToProps(state: RootAppState, ownProps: StatementChoiceProps) {
  return {
    answers: getUserAnswers(state, ownProps.id)
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatementChoice);
