import * as React from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import { bindActionCreators, Dispatch } from 'redux';
import * as userActions from 'store/user/actions';
import { shouldSaveCrossDevice } from 'store/user/selectors';
import {
  isScormMode,
  isNpsEnabled,
  getLearnServiceUrl,
  isPreviewMode
} from 'store/settings/selectors';
import { isFinalized } from 'store/course/selectors';
import * as popupActions from 'store/popup/actions';
import * as courseActions from 'store/course/actions';
import * as navigationActions from 'store/navigation/actions';
import Icon from 'components/common/Icon';
import { localize } from 'core/localization';
import {
  POPUP_ID_CONTINUE_LATER,
  POPUP_ID_CONTINUE_LATER_IN_SCORM,
  POPUP_ID_CLOSE_COURSE,
  POPUP_ID_NPS_POPUP
} from 'constants/popups';
import { Tooltip } from 'components/common';
import ContinueLater from '../popups/continueLater';
import ContinueLaterInScorm from '../popups/continueLaterInScorm';
import CloseCoursePopup from '../popups/closeCourse';
import NpsPopup from '../popups/nps';

import {
  MenuContainer,
  ExpandableWrapper,
  AvatarLetterContainer,
  AvatarLetterElement,
  IconContainer,
  ActionListContainer,
  ListItem,
  ItemText
} from './UserMenu.styled';
import { getAvatarLetter } from '../../store/user/selectors';
import { RootAppState } from 'store/types';
import { isLtiInitialized } from 'store/modules/selectors';

const constants = {
  defaultAvatarLetter: 'A'
};

const closeCoursePopupSettings = {
  popupId: POPUP_ID_CLOSE_COURSE,
  popupAriaLabelKey: '[aria label close course popup]',
  component: CloseCoursePopup,
  disablePopupClosing: true
};

const npsPopupSettings = {
  popupId: POPUP_ID_NPS_POPUP,
  popupAriaLabelKey: '[aria label close nps popup]',
  component: NpsPopup,
  fromDownloadCertificateButton: false,
  disablePopupClosing: true,
  disableBottomLine: true
};

type UserMenuProps = {
  actions: { [key: string]: any };
  courseAction: { [key: string]: any };
  saveCrossDevice: boolean;
  avatarLetter: string;
  isScorm: boolean;
  isLti: boolean;
  previewMode: boolean;
  courseIsFinalized: boolean;
  npsEnabled: boolean;
  popupActions: { [key: string]: any };
  navigationActions: { [key: string]: any };
  theme?: any;
  ariaHidden?: any;
  learnServiceUrl?: string;
};

type UserMenuState = {
  isExpanded: boolean;
};

export class UserMenu extends React.Component<UserMenuProps, UserMenuState> {
  templateTheme: any;
  constructor(props: UserMenuProps) {
    super(props);
    this.templateTheme = props.theme;
    this.state = {
      isExpanded: false
    };
  }

  toggle = () => {
    this.setState({
      isExpanded: !this.state.isExpanded
    });
  };

  closePopup = () => {
    this.setState({
      isExpanded: false
    });
  };

  onLogout = async () => {
    this.props.actions.notSkipAuthentication();
    await this.props.actions.logout();
    this.toggle();
  };

  onContinueLater = () => {
    this.props.popupActions.openPopup({
      popupId: POPUP_ID_CONTINUE_LATER,
      popupAriaLabelKey: '[continue later aria label]',
      component: ContinueLater
    });
    this.toggle();
  };

  onContinueLaterInScorm = () => {
    this.props.popupActions.openPopup({
      popupId: POPUP_ID_CONTINUE_LATER_IN_SCORM,
      popupAriaLabelKey: '[continue later aria label]',
      component: ContinueLaterInScorm
    });
    this.toggle();
  };

  onSubmitResult = async () => {
    const { npsEnabled } = this.props;
    await this.props.courseAction.finished();

    this.props.popupActions.openPopup(npsEnabled ? npsPopupSettings : closeCoursePopupSettings);

    if (!npsEnabled) {
      await this.props.courseAction.finalized();
    }
    this.toggle();
  };

  renderListItem(clickAction: any, itemTextKey: any, iconSettings?: any, additionalProps?: any) {
    return (
      <ListItem theme={this.templateTheme} {...additionalProps} onClick={clickAction}>
        {iconSettings && (
          <IconContainer>
            <Icon
              theme={this.templateTheme}
              name={iconSettings.name}
              size={iconSettings.size}
              color={iconSettings.color}
            />
          </IconContainer>
        )}
        <ItemText theme={this.templateTheme} appearance="span" size={13}>
          {localize(itemTextKey)}
        </ItemText>
      </ListItem>
    );
  }

  render() {
    const {
      avatarLetter,
      saveCrossDevice,
      isScorm,
      isLti,
      previewMode,
      courseIsFinalized,
      ariaHidden,
      navigationActions,
      learnServiceUrl
    } = this.props;
    const { isExpanded } = this.state;
    return (
      <MenuContainer ariaHidden={ariaHidden}>
        <Tooltip
          trigger={'manual'}
          open={isExpanded}
          onRequestClose={this.closePopup}
          arrow={true}
          position={'bottom-end'}
          style={{ display: 'block' }}
          interactive
          html={
            <ActionListContainer theme={this.templateTheme}>
              {!isScorm &&
                !previewMode && !isLti &&
                this.renderListItem(
                  () => navigationActions.goToUrl(learnServiceUrl),
                  '[my courses]',
                  null,
                  {
                    'data-test': 'my-courses-button'
                  }
                )}
              {!isScorm &&
                saveCrossDevice &&
                this.renderListItem(this.onContinueLater, '[continue later with link]', null, {
                  'data-test': 'continue-later-button'
                })}

              {!isScorm &&
                this.renderListItem(
                  this.onLogout,
                  '[logout]',
                  {
                    name: 'logout',
                    size: 10,
                    color: 'textColor'
                  },
                  {
                    'data-test': 'logout-button'
                  }
                )}

              {isScorm &&
                (courseIsFinalized
                  ? this.renderListItem(this.onSubmitResult, '[user menu submit result]')
                  : this.renderListItem(this.onContinueLaterInScorm, '[continue course later]'))}
            </ActionListContainer>
          }
        >
          <ExpandableWrapper ariaHidden={false} onClick={this.toggle} tabIndex={0}>
            <AvatarLetterContainer>
              <AvatarLetterElement>
                {avatarLetter || constants.defaultAvatarLetter}
              </AvatarLetterElement>
            </AvatarLetterContainer>
            <IconContainer>
              <Icon name={isExpanded ? 'chevron-up' : 'chevron-down'} size={5} color="textColor" />
            </IconContainer>
          </ExpandableWrapper>
        </Tooltip>
      </MenuContainer>
    );
  }
}

function mapStateToProps(state: RootAppState) {
  return {
    avatarLetter: getAvatarLetter(state),
    saveCrossDevice: shouldSaveCrossDevice(state),
    isScorm: isScormMode(state),
    isLti: isLtiInitialized(state),
    courseIsFinalized: isFinalized(state),
    npsEnabled: isNpsEnabled(state),
    learnServiceUrl: getLearnServiceUrl(state),
    previewMode: isPreviewMode(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  let data = {
    actions: bindActionCreators(userActions, dispatch),
    courseAction: bindActionCreators(courseActions, dispatch),
    navigationActions: bindActionCreators(navigationActions, dispatch),
    popupActions: bindActionCreators(popupActions, dispatch)
  };
  return data;
}

export default withTheme(connect(mapStateToProps, mapDispatchToProps)(UserMenu));
