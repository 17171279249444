import styled, { css } from 'styled-components';
import { transparentize } from 'utils/color';
import { defaultBoxShadowColor } from 'constants/common';
import { transition } from 'components/mixins/interactivity';

type SpotAreaProps = {
  width: string;
  height: string;
  naturalWidth: number;
  naturalHeight: number;
  top: string;
  left: string;
  key: string;
}
const SpotArea = styled.div<SpotAreaProps>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  ${props =>
    /%/.test(props.width)
      ? css`
          width: ${props.width};
          height: ${props.height};
          top: ${props.top};
          left: ${props.left};
        `
      : css`
          width: ${((parseFloat(props.width) * 100) / parseFloat(`${props.naturalWidth}`)).toFixed(4)}%;
          height: ${((parseFloat(props.height) * 100) / parseFloat(`${props.naturalHeight}`)).toFixed(4)}%;
          top: ${((parseFloat(props.top) * 100) / parseFloat(`${props.naturalHeight}`)).toFixed(4)}%;
          left: ${((parseFloat(props.left) * 100) / parseFloat(`${props.naturalWidth}`)).toFixed(4)}%;
        `
  };

  

  &:hover {
    background-color: ${props => transparentize(props.theme.colors.contentBodyColor, 0.1)};
    box-shadow: 0 0 20px 0 ${transparentize(defaultBoxShadowColor, 0.05)};

    button {
      background-color: ${props => props.theme.colors.contentBodyColor};
      animation: none;

      &::after {
        visibility: visible;
        background-color: ${props => transparentize(props.theme.colors.mainColor, 0.1)};
        ${transition('0.3s')};
      }
    }
  }

  &.active {
    background-color: ${props => transparentize(props.theme.colors.contentBodyColor, 0.1)};
    box-shadow: 0 0 20px 0 ${transparentize(defaultBoxShadowColor, 0.05)};

    button {
      animation: none;
      background-color: ${props => props.theme.colors.mainColor};
      ${transition('0.3s')};
      color: ${props => props.theme.colors.contentBodyColor};
    }
  }
`;

export default SpotArea;
