import styled from 'styled-components';
import { transparentize } from 'utils/color';
import { TOC } from 'constants/components';
import { Text, ProgressResult } from 'components/common';
import { getCustomScrollbars } from 'components/mixins/customScrollbars';
import { defaultBoxShadowColor } from 'constants/common';
import { SectionItem } from '../SectionTreeNode/SectionTreeNode.styled';

type SectionsProps = {
  role: string;
};
export const Sections = styled.ul.attrs((props: SectionsProps) => ({
  role: props.role,
  'data-test': 'expanded-navigation-block'
}))<SectionsProps>`
  flex: 1 0 auto;
  margin-top: 44px;
  margin-bottom: 50px;

  ${SectionItem} + ${SectionItem} {
    margin-top: 2px;
  }
`;

type ExpandedTocContainer = {
  ariaLabel: string;
  isExpanded: boolean;
};
export const ExpandedTocContainer = styled.nav.attrs((props: ExpandedTocContainer) => ({
  'aria-label': props.ariaLabel
}))<ExpandedTocContainer>`
  display: flex;
  flex-direction: column;
  position: relative;
  height: calc(100vh - 57px);
  ${getCustomScrollbars()};
  box-shadow: inset -2px 0 6px 0 ${transparentize(defaultBoxShadowColor, 0.04)};
  background-color: ${props => transparentize(props.theme.colors.textColor, 0.02)};

  @media (max-width: ${TOC.EXPANDED_WIDTH + TOC.TAIL_WIDTH}px) {
    padding-top: 32px;
  }
  &:focus {
    outline: none;
  }
  & ${Sections} {
    margin-top: 32px;
  }
`;

export const ProgressMasteryScoreWrapper = styled.div`
  margin: 16px 40px 0;
`;

export const ProgressMasteryScore = styled(ProgressResult)`
  height: 4px;
  border-radius: 2px;
  background: ${props => transparentize(props.theme.colors.textColor, 0.06)};
`;

export const ProgressCaption = styled(Text)`
  display: block;
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  line-height: 1;
  color: ${props => props.theme.colors.textColor};
  opacity: 0.7;
`;

export const TocWrapper = styled.div`
  overflow: auto;
  height: 100%;
`;
