export const getImageDimensions = (url: string) =>
  new Promise((resolve, reject) => {
    let img = new Image(); // eslint-disable-line no-undef

    img.onload = loadHandler;
    img.onerror = errorHandler;
    img.src = url;

    function loadHandler(this: any) {
      resolve({ width: this.width, height: this.height });
    }
    function errorHandler() {
      reject(new Error(`Can't load image by url ${url}`));
    }
  });

export const getThumbnailUrl = (url: string, width: number, height: number) => `${url}?width=${width}&height=${height}`;

export const getImageNameByUrl = (url: string) => {
  let imageName = url.substring(url.indexOf('image/') + 6);
  imageName = imageName.substring(
    0,
    imageName.length - (imageName.length - imageName.indexOf('?width'))
  );
  return imageName;
};
