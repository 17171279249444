import * as React from 'react';
import { connect } from 'react-redux';
import { localize } from 'core/localization';
import { withTheme } from 'styled-components';
import { getEmail, getName, shouldSaveCrossDevice } from 'store/user/selectors';
import progressStorage from 'core/progressStorage';
import { STATUS_OK } from 'constants/progressStorage';
import { Link, SwitchButton, StatusTooltip } from 'components/common';
import { BUTTON, LINK, SIZES, TOOLTIP } from 'constants/components';
import AuthForm from '../AuthForm';
import EmailInput from '../AuthForm/EmailInput';
import NameInput from '../AuthForm/NameInput';
import PasswordInput from '../AuthForm/PasswordInput';
import { InputContainer } from '../SignUpForm/SignUpForm.styled';
import { FormContentWrapper } from '../AuthForm/AuthForm.styled';
import {
  ButtonSubmit,
  ControlContainer,
  MessageText,
  CheckboxContainer,
  LinkWrapper,
  MessageTextWrapper
} from './LoginForm.styled';
import { RootAppState } from 'store/types';

type LoginFormProps = {
  onSubmit(e: any, data: any): Promise<void>;
  formId: string;
  userEmail: string;
  userName: string;
  saveCrossDevice: boolean;
  isEmailPasswordCombinationNotExists: boolean;
  resetStatuses(): void;
  theme?: any;
};

type LoginFormState = {
  shortTermAccess: boolean;
  resetPasswordSendMessage: boolean;
};

export class LoginForm extends React.Component<LoginFormProps, LoginFormState> {
  templateTheme: any;
  constructor(props: LoginFormProps) {
    super(props);
    this.templateTheme = props.theme;
    this.state = {
      shortTermAccess: true,
      resetPasswordSendMessage: false
    };
  }

  static defaultProps = {
    isEmailPasswordCombinationNotExists: false
  };

  hideMessage = () => {
    this.setState({ resetPasswordSendMessage: false });
  };

  onPasswordRestore = async (refEmail: any, setValidationStatusElem: any) => {
    const emailValid = refEmail ? refEmail.validity.valid : false;
    setValidationStatusElem(refEmail);

    if (!emailValid) {
      return;
    }

    let status = await progressStorage.resetPassword(refEmail.value.toLowerCase());
    this.setState({ resetPasswordSendMessage: status === STATUS_OK });
  };

  renderContent = (props: any) => {
    const { saveCrossDevice, resetStatuses, userEmail, userName } = this.props;
    const {
      refEmail,
      isEmailPasswordCombinationNotExists,
      onChange,
      setRefs,
      validationStatusBeforeSubmit,
      setValidationStatusElem,
      resetPasswordSendMessage,
      shortTermAccess
    } = props;

    return (
      <FormContentWrapper>
        <InputContainer size={40}>
          <EmailInput
            userEmail={userEmail}
            onChange={onChange}
            setRefs={setRefs}
            textError={localize('[error email input]')}
            validationStatusBeforeSubmit={validationStatusBeforeSubmit.email}
            onFocus={resetStatuses}
          />
        </InputContainer>
        {!saveCrossDevice && (
          <InputContainer size={40}>
            <NameInput
              userName={userName}
              onChange={onChange}
              setRefs={setRefs}
              validationStatusBeforeSubmit={validationStatusBeforeSubmit.name}
              onFocus={resetStatuses}
            />
          </InputContainer>
        )}
        {saveCrossDevice && (
          <InputContainer size={12}>
            <PasswordInput
              onChange={onChange}
              setRefs={setRefs}
              validationStatusBeforeSubmit={validationStatusBeforeSubmit.password}
              pattern="\S+"
              showValidationMessage={false}
              onFocus={resetStatuses}
            />
            <ControlContainer>
              {this.renderWithTooltip(
                TOOLTIP.CORRECT,
                resetPasswordSendMessage,
                localize('[reset password link sent success]'),
                <LinkWrapper>
                  <Link
                    text={localize('[forgot pass]')}
                    layout={LINK.LAYOUT.FORM}
                    size={12}
                    onClick={() => this.onPasswordRestore(refEmail, setValidationStatusElem)}
                    onBlur={this.hideMessage}
                    data-test="forgot-password-link"
                    opacity={0.7}
                  />
                </LinkWrapper>
              )}
              <CheckboxContainer>
                <SwitchButton
                  multiple={true}
                  text={localize('[remember me]')}
                  selected={!shortTermAccess}
                  aria-checked={!shortTermAccess}
                  size={SIZES.SMALL}
                  tabIndex={0}
                  onClick={() => this.setState({ shortTermAccess: !shortTermAccess })}
                  onKeyDown={(event: any) => {
                    if (event.key === ` ` || event.key === 'Enter') {
                      this.setState({ shortTermAccess: !shortTermAccess });
                    }
                  }}
                />
              </CheckboxContainer>
            </ControlContainer>
          </InputContainer>
        )}
        {this.renderWithTooltip(
          TOOLTIP.INCORRECT,
          isEmailPasswordCombinationNotExists,
          <MessageTextWrapper>
            <MessageText theme={this.templateTheme} appearance="span">
              {localize('[error login email]')}
            </MessageText>
            <MessageText theme={this.templateTheme} appearance="span">
              {localize('[error login pass]')}
            </MessageText>
          </MessageTextWrapper>,
          <ButtonSubmit layout={BUTTON.LAYOUT.PRIMARY} suffixIcon="arrow-right" type="submit">
            {localize('[login button]')}
          </ButtonSubmit>
        )}
      </FormContentWrapper>
    );
  };

  render() {
    const { resetPasswordSendMessage, shortTermAccess } = this.state;
    const { onSubmit, formId, isEmailPasswordCombinationNotExists } = this.props;

    return (
      <AuthForm
        shortTermAccess={shortTermAccess}
        resetPasswordSendMessage={resetPasswordSendMessage}
        formId={formId}
        onSubmit={onSubmit}
        isEmailPasswordCombinationNotExists={isEmailPasswordCombinationNotExists}
        hideMessage={this.hideMessage}
        renderContent={this.renderContent}
      />
    );
  }

  renderWithTooltip = (status: any, showTooltip: any, message: any, actionElement: any) => {
    return (
      <StatusTooltip
        popoverPosition={'top'}
        layout={status}
        showTooltip={showTooltip}
        message={message}
        popoverButton={actionElement}
        transitionDuration={0.2}
        eventHandler={'click'}
      />
    );
  };
}

function mapStateToProps(state: RootAppState) {
  return {
    userEmail: getEmail(state),
    userName: getName(state),
    saveCrossDevice: shouldSaveCrossDevice(state)
  };
}

export default withTheme(connect(mapStateToProps)(LoginForm));
