import styled, { css } from 'styled-components';
import { TOC, Z_INDEX } from 'constants/components';
import media from 'components/mixins/media';
import { transparentize } from 'utils/color';
import { transition } from 'components/mixins/interactivity';
import { CourseBackButton } from '../Header/Header.styled';

type CollapsedTocContainerProps = {
  ariaLabel: string;
  ariaHidden?: any;
  isExpanded: boolean;
}
/* eslint-disable import/prefer-default-export */
export const CollapsedTocContainer = styled.nav.attrs((props: CollapsedTocContainerProps) => ({
  'aria-label': props.ariaLabel,
  'aria-hidden': props.ariaHidden
})) <CollapsedTocContainerProps>`
  position: absolute;
  top: 0;
  height: 100%;
  width: ${TOC.COLLAPSED_WIDTH}px;
  left: -${TOC.COLLAPSED_WIDTH}px;
  z-index: ${Z_INDEX.COLLAPSED_TOC};
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  background-color: ${props => props.theme.colors.mainBackground};
  ${transition('left')};
  transition-delay: '0.15s';
  visibility: hidden;
  & ${CourseBackButton} {
    top: 80px;
    left: 16px;
    position: absolute;
  }

  &:focus {
    outline: none;
  }

  ${props =>
    !props.isExpanded &&
    css`
      left: 100%;
      visibility: visible;
    `};

  ${media.small`
    display: none;
  `};
`;

type SectionProgressProps = {
  isNavigationExist: boolean;
}
export const SectionProgress = styled.div<SectionProgressProps>`
  width: 24px;
  height: 51px;
  padding-top: 16px;
  position: relative;
  margin: ${props => (props.isNavigationExist ? '60px auto 30px' : '10px auto 30px')};

  &:before {
    content: '';
    display: block;
    width: 16px;
    height: 1px;
    position: absolute;
    top: 0;
    left: 4px;
    background: ${props => transparentize(props.theme.colors.textColor, 0.1)};
  }

  &:hover {
    .CircularProgressbar-trail {
      stroke: ${props => transparentize(props.theme.colors.textColor, 0.2)} !important;
    }
  }
`;

export const StatusIcon = styled.div`
  width: 24px;
  height: 24px;
  background: ${props => props.theme.colors.correctColor};
  border-radius: 50%;
  flex-shrink: 0;
  align-self: self-start;
  display: flex;
  justify-content: center;
  align-items: center;
`;
