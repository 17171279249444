import React, { Component } from 'react';
import { Icon } from 'components/common';
import { minCountTocNavigationElements, maxCountTocNavigationElements } from 'constants/common';
import throttle from 'lodash.throttle';
import { MEDIA_SMALL } from 'constants/screenResolutions';
import { Container, ItemsLimiter, Items, NavButton } from './NavigationBlock.styled';

const itemHeight = 24;
const itemDistance = 20;

type NavigationBlockProps = {
  children: any[];
  role: string;
  navigationElementsCount: number;
  activeItemIndex: number;
};
type NavigationBlockState = {
  hasNextPage: boolean;
  hasPrevPage: boolean;
  startIndex: number,
  endIndex: number,
  countTocNavigationElements: number;
  windowHeight: number;
}
export default class NavigationBlock extends Component<NavigationBlockProps, NavigationBlockState> {
  constructor(props: NavigationBlockProps) {
    super(props);
    this.state = {
      hasNextPage: false,
      hasPrevPage: false,
      startIndex: -1,
      endIndex: -1,
      countTocNavigationElements: maxCountTocNavigationElements,
      windowHeight: window.innerHeight
    };
  }

  componentDidMount() {
    this.setTocNavigationElements(this.state.windowHeight);
    window.addEventListener('resize', this.windowResizeHandler);

    this.updatePagingState(this.props.navigationElementsCount, this.props.activeItemIndex);
  }

  componentDidUpdate(prevProps: NavigationBlockProps) {
    const index = prevProps.activeItemIndex;
    const { activeItemIndex, navigationElementsCount } = this.props;
    if (
      (index !== activeItemIndex && !this.isIndexInView(index)) ||
      this.props.navigationElementsCount !== prevProps.navigationElementsCount
    ) {
      this.updatePagingState(navigationElementsCount, activeItemIndex);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowResizeHandler);
  }

  windowResizeHandler = throttle(() => {
    this.setState({ windowHeight: window.innerHeight });
    this.setTocNavigationElements(this.state.windowHeight);
  }, 500);

  setTocNavigationElements = (height: any) => {
    if (height <= MEDIA_SMALL) {
      this.setState({ countTocNavigationElements: minCountTocNavigationElements });
    } else {
      this.setState({ countTocNavigationElements: maxCountTocNavigationElements });
    }
  };

  isIndexInView = (index: any) => {
    const { startIndex, endIndex } = this.state;
    return index >= startIndex && index <= endIndex;
  };

  nextPage = () => {
    const totalItems = this.props.navigationElementsCount;
    const { startIndex, countTocNavigationElements } = this.state;
    this.updatePagingState(totalItems, startIndex + countTocNavigationElements);
  };

  prevPage = () => {
    const totalItems = this.props.navigationElementsCount;
    const { startIndex, countTocNavigationElements } = this.state;
    this.updatePagingState(totalItems, startIndex - countTocNavigationElements);
  };

  updatePagingState = (totalItems: any, index: any) => {
    const { countTocNavigationElements } = this.state;
    this.setState(calculatePaging(totalItems, index, countTocNavigationElements));
  };

  render() {
    const { children, role } = this.props;
    const { hasNextPage, hasPrevPage, startIndex, countTocNavigationElements } = this.state;
    const limiterHeight =
      countTocNavigationElements * itemHeight + (countTocNavigationElements - 1) * itemDistance;
    const topShift = startIndex * itemHeight + startIndex * itemDistance;
    return (
      <Container role={role}>
        {hasPrevPage && (
          <NavButton tabIndex={1} onClick={this.prevPage} prev>
            <Icon name="chevron-up" />
          </NavButton>
        )}
        <ItemsLimiter height={limiterHeight}>
          <Items topShift={topShift}>{children}</Items>
        </ItemsLimiter>
        {hasNextPage && (
          <NavButton tabIndex={1} onClick={this.nextPage} next>
            <Icon name="chevron-down" />
          </NavButton>
        )}
      </Container>
    );
  }
}

function calculatePaging(totalItems: any, index: any, countTocNavigationElements: any) {
  const range = getRange(totalItems, index, countTocNavigationElements);
  const startIndex = range.start;
  const endIndex = range.end;
  const hasNextPage =
    totalItems - startIndex !== countTocNavigationElements &&
    totalItems > countTocNavigationElements;
  const hasPrevPage = startIndex > 0;
  return { hasPrevPage, hasNextPage, startIndex, endIndex };
}

function getRange(totalItems: any, index: any, countTocNavigationElements: any) {
  if (index <= 0) {
    return {
      start: 0,
      end: totalItems >= countTocNavigationElements ? countTocNavigationElements : totalItems
    };
  }

  const postDelta = totalItems - 1 - index;
  if (postDelta >= countTocNavigationElements) {
    return { start: index, end: index + countTocNavigationElements };
  }

  const preDelta = countTocNavigationElements - postDelta;
  const pre = index - preDelta + 1;
  return { start: pre >= 0 ? pre : 0, end: index + postDelta };
}
