import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { POPUP_ID_CLOSE_COURSE } from 'constants/popups';
import { localize } from 'core/localization';
import { Link } from 'components/common';
import { LINK } from 'constants/components';
import * as popupActions from 'store/popup/actions';
import * as courseActions from 'store/course/actions';
import { closeWindow } from 'utils/window';
import CloseCoursePopup from '../closeCourse';
import DescriptionWithBackground from '../descriptionWithBackground';
import { TitleElement, ButtonElement, ContinueLaterWrapper } from './ContinueLaterInScorm.styled';

type ContinueLaterInScormProps = {
  popupAction: { [key: string]: any };
  courseAction: { [key: string]: any };
  closePopup(): void
};

export class ContinueLaterInScorm extends React.PureComponent<ContinueLaterInScormProps, {}> {
  closeCourse = async () => {
    const { courseAction, popupAction } = this.props;
    this.props.closePopup();
    popupAction.openPopup({
      popupId: POPUP_ID_CLOSE_COURSE,
      popupAriaLabelKey: '[aria label close course popup]',
      component: CloseCoursePopup,
      disablePopupClosing: true
    });

    await courseAction.finalized();
    closeWindow();
  };

  render() {
    return (
      <ContinueLaterWrapper>
        <TitleElement appearance="span" size={35}>
          {localize('[note that]')}
        </TitleElement>
        <DescriptionWithBackground
          iconName="rounded-info"
          iconSize={12}
          descriptionText={localize('[note]')}
        />
        <ButtonElement tabIndex={1} onClick={this.closeCourse}>
          {localize('[ok close course]')}
        </ButtonElement>
        <Link
          onClick={() => this.props.closePopup()}
          text={localize('[cancel go back to the course]')}
          layout={LINK.LAYOUT.FORM}
        />
      </ContinueLaterWrapper>
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    courseAction: bindActionCreators(courseActions, dispatch),
    popupAction: bindActionCreators(popupActions, dispatch)
  };
}

export default connect(
  null,
  mapDispatchToProps
)(ContinueLaterInScorm);
