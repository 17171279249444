import styled from 'styled-components';
import buttonDefaultStyle from 'components/mixins/normalize';
import Icon from 'components/common/Icon';
import { transparentize } from 'utils/color';
import { transition } from 'components/mixins/interactivity';
import { defaultBoxShadowColor } from 'constants/common';

export const InfoIcon = styled(Icon)`
  line-height: 22px;
  color: ${props => props.theme.colors.mainColor};

  .active & {
    color: ${props => props.theme.colors.contentBodyColor};
  }
`;

const InfoButton = styled.button`
  ${buttonDefaultStyle};
  width: 26px;
  height: 26px;
  cursor: pointer;
  animation: pulse 1.5s infinite;
  padding: 0;
  border-radius: 50%;
  box-shadow: 0 2px 10px 0 ${transparentize(defaultBoxShadowColor, 0.1)};
  background-color: ${props => props.theme.colors.contentBodyColor};
  border: 2px solid ${props => props.theme.colors.mainColor};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .active &,
  .active &:hover {
    animation: none;
    background-color: ${props => props.theme.colors.mainColor};
    ${transition('0.3s')};

    &::after {
      visibility: visible;
      background-color: ${props => transparentize(props.theme.colors.mainColor, 0.1)};
      ${transition('0.3s')};
    }
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0px ${props => transparentize(props.theme.colors.mainColor, 0.5)};
    }
    75% {
      box-shadow: 0 0 0 7px ${props => transparentize(props.theme.colors.mainColor, 0.3)};
    }
    100% {
      box-shadow: 0 0 0 12px ${props => transparentize(props.theme.colors.mainColor, 0.05)};
    }
  }
`;

export default InfoButton;
