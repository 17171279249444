import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { INFORMATION_CONTENT } from 'constants/questionTypes';
import { REVIEW_COMMENT_PROPERTIES, REVIEW_COMMENT_TYPES } from 'constants/review';
import {
  getSection,
  getQuestionIndex,
  getQuestionCount,
  sectionExists
} from 'store/sections/selectors';

import { getBackground, getLogo, isMainLogoShown } from 'store/settings/selectors';
import { Separator, CourseLogo } from 'components/common';
import Contents from 'components/contents/Contents';
import withFallbackToNotFound from 'components/hocs/withFallbackToNotFound';
import withNavigation from 'components/hocs/withNavigation';
import Question from 'components/questions/Question';
import Reviewable from 'components/review/Reviewable';
import { localize } from 'core/localization';
import * as questionActions from 'store/questions/actions';
import {
  getQuestion,
  isQuestionAnswered,
  isQuestionAnsweredCorrectly,
  questionExists,
  getQuestionResponse
} from 'store/questions/selectors';
import PageCounter from 'components/question/PageCounter';
import Feedback from 'components/question/Feedback';
import Hint from 'components/question/Hint';
import VoiceOver from 'components/question/VoiceOver';
import { getCourseTitle } from 'store/course/selectors';

import ActionBlock from './actionsBlock/ActionsBlock';

import { QuestionTitle, QuestionTitleWrapper } from './QuestionPage.styled';
import { RootAppState } from 'store/types';

type QuestionPageProps = {
  section: { [key: string]: any };
  question: { [key: string]: any };
  actions: { [key: string]: any };
  navigateToNextPage(): void;
  checkIsNextPageResults(): boolean;
  questionIndex: number;
  questionCountInSection: number;
  bodyBackground: { [key: string]: any };
  logoUrl: string;
  logoShown: boolean;
  title: string;
  questionResponse: any[];
};

type QuestionPageState = {
  showTooltip: boolean;
  feedbackContentList: any[];
}
export class QuestionPage extends Component<QuestionPageProps, QuestionPageState> {
  constructor(props: QuestionPageProps) {
    super(props);
    this.state = {
      showTooltip: false,
      feedbackContentList: []
    };
  }

  componentDidMount() {
    this.props.actions.markAsOpened(this.props.question.id);
    this.props.actions.loadFeedback(this.props.question.id);
    this.setTitlePage();
  }

  componentDidUpdate(prevProps: QuestionPageProps) {
    if (prevProps.question.id !== this.props.question.id) {
      this.props.actions.loadFeedback(this.props.question.id);
    }
  }

  hidePopover = async () => {
    this.setState({ showTooltip: false });
  };

  getNextNavigationText = (isLastPageInSection: boolean) => {
    const { checkIsNextPageResults } = this.props;
    if (checkIsNextPageResults()) {
      return localize('[go to results]');
    }
    return localize(isLastPageInSection ? '[next section]' : '[next]');
  };

  setTitlePage = () => {
    const { question } = this.props;
    const isInformationContent = question.type === INFORMATION_CONTENT;
    if (isInformationContent) {
      document.title = `${localize('[content page title]')} | ${this.props.title}`;
    } else {
      document.title = `${localize('[questions page title]')} | ${this.props.title}`;
    }
  };

  render() {
    const {
      question,
      questionIndex,
      questionCountInSection,
      bodyBackground,
      logoUrl,
      logoShown
    } = this.props;
    const questionType = question.type === INFORMATION_CONTENT ? INFORMATION_CONTENT : 'question';
    return (
      <Fragment>
        {logoShown && <CourseLogo logoUrl={logoUrl} />}
        <PageCounter index={questionIndex + 1} total={questionCountInSection} />
        <QuestionTitleWrapper>
          <Reviewable context={{ type: questionType, id: question.id, title: question.title }}>
            <QuestionTitle appearance="h1">{question.title}</QuestionTitle>
          </Reviewable>
        </QuestionTitleWrapper>
        {this.renderInstructions()}
        <Question
          id={question.id}
          type={question.type}
          isAnswered={question.isAnswered}
          bodyBackground={bodyBackground}
        />
        {this.renderHints()}
        {this.renderFeedback()}
        <ActionBlock
          question={question}
          goToNextPage={this.navigateToNextPage}
          isNextPageResults={this.props.checkIsNextPageResults()}
          questionCountInSection={questionCountInSection}
          questionIndex={questionIndex}
        />
        {this.renderVoiceOver()}
      </Fragment>
    );
  }

  navigateToNextPage = () => {
    const { navigateToNextPage } = this.props;
    navigateToNextPage();
  };

  renderHints() {
    const { question } = this.props;
    if (question.hints.length === 0) {
      return <Separator margin={32} opacity={0.1} color="textColor" />;
    }
    if (question.type === INFORMATION_CONTENT) {
      return <Contents items={question.hints} />;
    }
    return (
      <Reviewable
        context={{
          type: REVIEW_COMMENT_TYPES.QUESTION,
          property: REVIEW_COMMENT_PROPERTIES.LEARNING_CONTENT,
          id: question.id,
          title: question.title
        }}
      >
        <Hint items={question.hints} />
      </Reviewable>
    );
  }

  renderFeedback() {
    const { question } = this.props;
    if (question.type === INFORMATION_CONTENT) {
      return;
    }

    return <Feedback question={question} animate={true} delay={200} />;
  }

  renderInstructions() {
    const { question } = this.props;
    const { instructions, type } = question;
    return (
      <React.Fragment>
        {instructions.length > 0 && <Contents items={instructions} />}
        {type !== INFORMATION_CONTENT && instructions.length !== 0 && (
          <Separator opacity={0.1} color="textColor" />
        )}
      </React.Fragment>
    );
  }

  renderVoiceOver() {
    const { question } = this.props;
    return (
      !!question.voiceOver && (
        <Reviewable
          isVoiceOver={true}
          context={{
            type: REVIEW_COMMENT_TYPES.INFORMATION_CONTENT,
            property: REVIEW_COMMENT_PROPERTIES.VOICE_OVER,
            id: question.id,
            title: question.title
          }}
        >
          <VoiceOver tabIndex={1} embedCode={question.voiceOver} />
        </Reviewable>
      )
    );
  }
}

function mapStateToProps(state: RootAppState, ownProps: any) {
  const { sectionId, questionId } = ownProps.match.params;
  return {
    logoUrl: getLogo(state),
    logoShown: isMainLogoShown(state),
    section: getSection(state, sectionId),
    questionIndex: getQuestionIndex(state, sectionId, questionId),
    questionCountInSection: getQuestionCount(state, sectionId),
    question: getQuestion(state, questionId),
    isAnswered: isQuestionAnswered(state, questionId),
    isAnsweredCorrectly: isQuestionAnsweredCorrectly(state, questionId),
    bodyBackground: getBackground(state).body,
    title: getCourseTitle(state),
    sectionExists: sectionExists(state, sectionId),
    questionExists: questionExists(state, questionId),
    questionResponse: getQuestionResponse(state, questionId)
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return { actions: bindActionCreators(questionActions, dispatch) };
}

function predicateForRedirect(props: any) {
  return !props.sectionExists || !props.questionExists;
}

export default withNavigation(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withFallbackToNotFound(QuestionPage, predicateForRedirect))
);
