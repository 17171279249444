import * as React from 'react';
import { connect } from 'react-redux';
import { getQuestionUrl, getLearningObjectiveUrl } from 'utils/navigation';
import withNavigation from 'components/hocs/withNavigation';
import { getBackground } from 'store/settings/selectors';
import {
  getCourseTitle,
  getCourseIntroductions
} from 'store/course/selectors';
import { getSections, affectsProgress } from 'store/sections/selectors';
import SectionsListComponent from 'components/sections/SectionsList';
import CourseInformation from 'components/sections/CourseInformation';
import { localize } from 'core/localization';
import {
  SectionsPageContainer,
  SectionListContainer,
  SectionsPageSidebar,
  SectionsPageContent
} from './SectionsPage.styled';
import { RootAppState } from 'store/types';

type SectionsProps = {
  sections: any[];
  navigateToUrl(url: string): void;
  isAccessLimited: boolean;
  theme: { [key: string]: any };
  background: { [key: string]: any };
  title: string;
};
export class Sections extends React.Component<SectionsProps, {}> {
  navigateToQuestion = (sectionId: string, questionId: string) => {
    this.props.navigateToUrl(getQuestionUrl(sectionId, questionId));
  };

  navigateToLearningObjective = (sectionId: string) => {
    this.props.navigateToUrl(getLearningObjectiveUrl(sectionId));
  };

  componentDidMount() {
    document.title = `${localize('[section page title]')} | ${this.props.title}`;
  }

  render() {
    const { sections, background } = this.props;
    return (
      <SectionsPageContainer>
        <SectionsPageSidebar background={background}>
          <CourseInformation sections={sections} />
        </SectionsPageSidebar>
        <SectionsPageContent background={background}>
          <SectionListContainer>
            <SectionsListComponent
              sections={sections}
              navigateToQuestion={this.navigateToQuestion}
              navigateToLearningObjective={this.navigateToLearningObjective}
            />
          </SectionListContainer>
        </SectionsPageContent>
      </SectionsPageContainer>
    );
  }
}

function mapStateToProps(state: RootAppState) {
  return {
    title: getCourseTitle(state),
    introductions: getCourseIntroductions(state),
    sections: getSections(state).map(section => ({
      ...section,
      affectsProgress: affectsProgress(state, section.id)
    })),
    background: getBackground(state)
  };
}

export default withNavigation(connect(mapStateToProps)(Sections));
