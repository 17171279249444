/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import { Tooltip } from 'components/common';
import { withTheme } from 'styled-components';
import { PopoverContent } from './DropdownInputPopover.styled';

type DropdownInputPopoverProps = {
  data: any[];
  theme: { [key: string]: any };
  popoverPosition: any;
  isPopoverOpen: boolean;
  isMatching: boolean;
  getMenuProps(): void;
  getItemProps(options: any): void;
  selectedAnswer: any;
  highlightedIndex: number | null;
  setHighlightedIndex(index: any): void;
  children: any;
};

export class DropdownInputPopover extends React.Component<DropdownInputPopoverProps, {}> {
  classes: any;
  constructor(props: DropdownInputPopoverProps) {
    super(props);
    this.classes = {
      baseSelected: 'selected',
      matchingSelected: 'selected current',
      matchingSelectedCurrent: 'current-selected'
    };
  }

  addClasses(item: any) {
    const { isMatching, selectedAnswer } = this.props;
    const { baseSelected, matchingSelected, matchingSelectedCurrent } = this.classes;

    const { value, selected, current } = item;

    if (!isMatching) {
      if (selectedAnswer === value) {
        return baseSelected;
      }
      return '';
    }
    if (selected && selected.length) {
      if (current) {
        return matchingSelectedCurrent;
      }
      return matchingSelected;
    }
    return '';
  }

  render() {
    const { isPopoverOpen, isMatching, popoverPosition, data, highlightedIndex, setHighlightedIndex, getItemProps, children } = this.props;
    const templateTheme = this.props.theme;
    return (
      <Tooltip
        trigger={'manual'}
        open={isPopoverOpen}
        distance={isMatching ? 0 : 16}
        classNames="dropdown"
        tooltipTheme={'light'}
        position={popoverPosition}
        html={
          < PopoverContent tabIndex={1} isMatching={isMatching} theme={templateTheme}>
            <div data-test="dropdown-content" className="downshift-dropdown">
              {data.map((item: any, index: any) => (
                <div
                  key={index}
                  tabIndex={1}
                  role="button"
                  className={`dropdown-item ${this.addClasses(item)}`}
                  data-test="dropdown-item"
                  style={{
                    color: highlightedIndex === index ? templateTheme.colors.mainColor : null
                  }}
                  onMouseLeave={() => setHighlightedIndex(null)}
                  {...getItemProps({
                    key: index,
                    index,
                    item,
                    'aria-label': `${index + 1} of ${data.length}`
                  })}
                >
                  {item.value}
                </div>
              ))}
            </div>
          </PopoverContent>
        }
      >
        {children}
      </Tooltip >

    )
  }
}

export default withTheme(DropdownInputPopover);
