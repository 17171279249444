export type SettingsState = {
    isLoadingFailed: boolean;
    template: any;
    publish: any;
    manifest: any;
    translations: any;
    publishMode: any;
}
export enum ActionTypes {
    SETTINGS_LOADED = 'SETTINGS_LOADED',
    SETTINGS_LOADING_FAILED = 'SETTINGS_LOADING_FAILED'
}

type SettingsLoaded = {
    type: ActionTypes.SETTINGS_LOADED;
    settings: {
        manifest: {[key: string]: any};
        publishSettings: {[key: string]: any};
        settings: {[key: string]: any};
        customization: {[key: string]: any};
        translations: {[key: string]: any};
        publishMod: {[key: string]: any};
    }
}

type SettingsLoadedFailed = {
    type: ActionTypes.SETTINGS_LOADING_FAILED;
    reason: any
}

export type SettingsActionTypes = SettingsLoaded | SettingsLoadedFailed;
