import styled from 'styled-components';
import { transparentize } from 'utils/color';

export const QuestionContainer = styled.section.attrs({
  'data-test': 'question-container'
})`
  position: relative;

  &:focus {
    outline: none;
  }
`;

type QuestionContainerOverlayProps = {
  isShown: boolean;
  bodyBackground: any;
}
export const QuestionContainerOverlay = styled.div.attrs(props => ({
  'data-test': 'question-overlay'
})) <QuestionContainerOverlayProps>`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: ${props => (props.isShown ? 'block' : 'none')};
  background-color: ${props =>
    transparentize(
      props.bodyBackground && props.bodyBackground.color ? props.bodyBackground.color : '#fff',
      0.5
    )};
`;
