import styled from 'styled-components';
import media from 'components/mixins/media';

export default styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  width: 50%;
  z-index: 106;
  ${media.tablet`
    position: relative;
    width: 100%;
    left: 0;
    min-height: 500px;
  `};
`;
