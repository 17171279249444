import styled from 'styled-components';
import {
  QuestionItem,
  TitleText
} from 'components/treeOfContent/QuestionTreeNode/QuestionTreeNode.styled';

type LearningObjectiveItemProps = {
  isActive: boolean;
  tabIndex: number;
  role: string;
  ariaLabel: string;
  onClick?(e?: any): void;
  onKeyDown?(e?: any): void;
}
export const LearningObjectiveItem = styled(QuestionItem)<LearningObjectiveItemProps>``;

export const ItemTitle = styled(TitleText)``;
