import styled from 'styled-components';
import media from 'components/mixins/media';
import { transparentize } from 'utils/color';
import Text from 'components/common/Text';
import { secondaryFont } from 'components/mixins/typography';

export const MobileFooterContainer = styled.div`
  display: none;
  width: 100%;
  ${media.tablet`
    display: block;
    padding-bottom: 100px;
  `};
  ${media.mobile`
    display: block;
    padding-bottom: 70px;
  `};
`;

export const Note = styled(Text)`
  ${secondaryFont};
  position: absolute;
  bottom: 47px;
  color: ${props => props.theme.colors.buttonTextColor};
  width: 400px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: normal;
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  ${MobileFooterContainer} & {
    position: static;
    color: ${props => transparentize(props.theme.colors.textColor, 0.5)};
    transform: translateX(0);
    font-size: 10px;
    margin: 35px auto;
    ${media.mobile`
      padding: 0 30px;
      width: 100%;
    `};
  }
`;

export const LinkToPageContainer = styled.div`
  width: 100%;
  height: 100px;
  background: ${props => props.theme.colors.contentBodyColor};
  display: flex;
  position: fixed;
  bottom: 0;
  justify-content: center;
  align-items: center;
  font-weight: normal;
  & a {
    color: ${props => props.theme.colors.mainColor};
    margin-left: 5px;
  }
  ${media.mobile`
    height: 70px;
  `};
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${props => transparentize(props.theme.colors.textColor, 0.02)};
    z-index: -1;
  }
`;

export const LinkToPageText = styled(Text)`
  color: ${props => props.theme.colors.textColor};
`;
