import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { getUserAnswers } from 'store/questions/multipleChoice/selectors';
import { MultipleChoiceItems } from 'components/common';
import * as actions from 'store/questions/multipleChoice/actions';
import { RootAppState } from 'store/types';

type SingleChoiceImageProps = {
  id: string;
  type: string;
  answers?: Array<any>;
  actions?: { [key: string]: any };
  isAnswered: boolean;
};
export class SingleChoiceImage extends React.Component<SingleChoiceImageProps, {}> {

  selectItem = (id: string, itemId: string) => {
    this.props.actions && this.props.actions.setSingleAnswerSelected(id, itemId, 'SET_SINGLE_ANSWER_SELECTED');
  };

  render() {
    const { answers, isAnswered } = this.props;
    return (
      <MultipleChoiceItems
        items={answers || []}
        selectItem={this.selectItem}
        id={this.props.id}
        imageQuestion
        ariaDisabled={isAnswered}
        tabIndex={1}
        isMultiple={false}
      />
    );
  }
}

function mapStateToProps(state: RootAppState, ownProps: SingleChoiceImageProps) {
  return {
    answers: getUserAnswers(state, ownProps.id)
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SingleChoiceImage);
