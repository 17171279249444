import React, { Component } from 'react';
import { connect } from 'react-redux';
import Popup from 'components/common/Popup';
import { getPopupVisibility } from 'store/popup/selectors';
import { getBackground, isTrackingEnabled, isScormMode } from 'store/settings/selectors';
import { isAuthenticated } from 'store/user/selectors';
import { isReviewEnabled } from 'store/modules/selectors';
import { isCourseAccessLimited } from 'store/course/selectors';
import Reviewable from 'components/review/Reviewable';
import { HINT_FOR_GENERAL_COMMENT } from 'constants/review';
import UserMenu from '../../userMenu';
import {
  LayoutContainer,
  BackgroundContainer,
  PageContainer,
  PageContentContainer,
  PageContentChild,
  PageSidebarContainer,
  PageSidebarChild,
  BasicStylesSidebar
} from './Layout.styled';
import { RootAppState } from 'store/types';

type PageSidebarProps = {
  children: any;
  className?: string;
  props?: any
};
export const PageSidebar = ({ children, className, ...props }: PageSidebarProps) => (
  <PageSidebarContainer className={className} {...props}>
    <PageSidebarChild>{children}</PageSidebarChild>
  </PageSidebarContainer>
);

type BasicStyledSidebarProps = {
  children?: any;
  className?: string;
  background?: any;
};
export const BasicStyledSidebar = ({ children, className = '', ...props }: BasicStyledSidebarProps) => (
  <BasicStylesSidebar className={className} {...props}>
    <PageSidebarChild>{children}</PageSidebarChild>
  </BasicStylesSidebar>
);

type PageContentProps = {
  children: any;
  className?: string;
  props?: any;
};
export const PageContent = ({ children, ...props }: PageContentProps) => (
  <PageContentContainer {...props}>
    <PageContentChild>{children}</PageContentChild>
  </PageContentContainer>
);

type LayoutProps = {
  children: any;
  background: { [key: string]: any };
  isUserAuthenticated: boolean;
  popupExpanded: boolean;
  trackingEnabled: boolean;
  isAccessLimited: boolean;
  isScorm: boolean;
  reviewMode: boolean;
  className?: string;
};
export class Layout extends Component<LayoutProps, {}> {
  render() {
    const { background, children, popupExpanded, className } = this.props;
    const bgHeader = background.header;
    return (
      <LayoutContainer className={className}>
        <Reviewable isGeneral={true} hintName={HINT_FOR_GENERAL_COMMENT}>
          {this.shouldRenderUserMenu() && <UserMenu ariaHidden={popupExpanded} />}
          <BackgroundContainer background={bgHeader} aria-hidden={popupExpanded} />
          <PageContainer aria-hidden={popupExpanded}>{children}</PageContainer>
          {popupExpanded && <Popup />}
        </Reviewable>
      </LayoutContainer>
    );
  }

  shouldRenderUserMenu() {
    const { isUserAuthenticated, trackingEnabled, isAccessLimited, isScorm } = this.props;
    return (isUserAuthenticated && trackingEnabled && !isAccessLimited) || isScorm;
  }
}

function mapStateToProps(state: RootAppState) {
  return {
    background: getBackground(state),
    popupExpanded: getPopupVisibility(state),
    isUserAuthenticated: isAuthenticated(state),
    trackingEnabled: isTrackingEnabled(state),
    isAccessLimited: isCourseAccessLimited(state),
    isScorm: isScormMode(state),
    reviewMode: isReviewEnabled(state)
  };
}

export default connect(mapStateToProps)(Layout);
