import { IMAGE_DEFAULT_DOMAIN } from 'constants/common';
import { getImageNameByUrl } from 'utils/image';
import requester from './requester';

const getImage = async (relativeUrl: any) =>
  requester.get(`${relativeUrl}`, { mode: 'no-cors', noCache: true });

const getImageCheckUrl = (imageUrl: any) => {
  const imageName = getImageNameByUrl(imageUrl);
  const imageFolderUrl = imageUrl.substring(0, imageUrl.indexOf('image/') + 6);

  return imageFolderUrl.indexOf(IMAGE_DEFAULT_DOMAIN) !== -1
    ? `${imageFolderUrl}exist/${imageName}`
    : imageUrl;
};

export default {
  async isImageExists(imageUrl: any) {
    const requestUrl = getImageCheckUrl(imageUrl);
    const response = await getImage(requestUrl);
    if (response.status === 404) {
      return false;
    }
    return true;
  }
};
