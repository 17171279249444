import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { getEmbedCode, getProjectId, getUserAnswers } from 'store/questions/scenario/selectors';
import * as actions from 'store/questions/scenario/actions';
import Text from 'components/common/Text';
import Icon from 'components/common/Icon';
import { localize } from 'core/localization';
import BranchtrackProvider from './BranchTrackProvider/branchtrackProviderPlugin';
import { ScenarioIframeSection, ScenarioResults, IconContainer } from './Scenario.styled';
import { RootAppState } from 'store/types';

type ScenarioProps = {
  actions?: { [key: string]: any };
  answers?: { [key: string]: any };
  embedCode?: any;
  questionId?: string;
  projectId?: string;
  isAnsweredCorrectly?: boolean;
  isAnswered: boolean;
  id: string;
  type: string;
};

export class Scenario extends React.Component<ScenarioProps, {}> {
  question: any;
  instance: any;
  refFrameScenario: any;
  instanceId: string;
  embedCode: any;
  score: number;
  branchtrack: any;

  constructor(props: ScenarioProps) {
    super(props);
    this.question = null;
    this.instance = null;
    this.refFrameScenario = React.createRef();
    this.instanceId = props.projectId || '';
    this.embedCode = props.embedCode;
    this.score = this.getScoreBranchtrack();
    this.branchtrack = BranchtrackProvider;
  }

  getEmbedHtml() {
    return { __html: this.embedCode };
  }

  resetInstace() {
    if (this.instance) {
      this.instance.score = 0;
      this.instance.isFinished = false;
    }
  }

  branchTrackEventHandler = () => {
    const { answers = {} } = this.props;
    if (this.instance) {
      answers.score = this.instance.score;
      answers.isFinished = this.instance.isFinished;
      this.props.actions && this.props.actions.scenarioBranchtrackData(
        answers.score,
        answers.isFinished,
        this.props.questionId
      );
    }
  };

  initBranchtrack() {
    if (this.instanceId) {
      this.instance = this.branchtrack.createInstance(this.instanceId);
      this.props.answers && (this.props.answers.isFinished = this.instance.isFinished);
    }
  }

  getScoreBranchtrack() {
    if (this.instance) {
      return this.instance.score;
    }

    return 0;
  }

  componentDidMount() {
    this.initBranchtrack();
    if (this.refFrameScenario.current) {
      this.refFrameScenario.current.getElementsByTagName('iframe')[0].setAttribute('tabIndex', 1);
    }
    window.addEventListener('message', this.branchTrackEventHandler);
  }

  componentDidUpdate() {
    if (!this.props.isAnsweredCorrectly && this.props.isAnswered) {
      this.resetInstace();
    }
  }

  componentWillUnmount() {
    this.branchtrack.destroyInstance(this.instance);
    window.removeEventListener('message', this.branchTrackEventHandler);
  }

  render() {
    return !this.props.isAnsweredCorrectly && !this.props.isAnswered ? (
      <ScenarioIframeSection
        ref={this.refFrameScenario}
        dangerouslySetInnerHTML={this.getEmbedHtml()}
      />
    ) : (
        <ScenarioResults>
          <IconContainer>
            <Icon name="info" />
          </IconContainer>
          <Text appearance="p">{localize('[scenario submitted]')}</Text>
        </ScenarioResults>
      );
  }
}

function mapStateToProps(state: RootAppState, ownProps: ScenarioProps) {
  return {
    embedCode: getEmbedCode(state, ownProps.id),
    projectId: getProjectId(state, ownProps.id),
    answers: getUserAnswers(state, ownProps.id),
    questionId: ownProps.id
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Scenario);
