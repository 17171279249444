import * as React from 'react';
import { localize } from 'core/localization';
import { Link, CssAnimation } from 'components/common';
import { BUTTON, LINK } from 'constants/components';
import DescriptionWithBackground from '../descriptionWithBackground';
import {
  DescriptionWrapper,
  TitleElement,
  ContinueLaterWrapper,
  ButtonElement
} from './StartNewAttempt.styled';

type StartNewAttemptProps = {
  closePopup(): void;
  popupSettings: { [key: string]: any };
};

export const StartNewAttempt: React.FunctionComponent<StartNewAttemptProps> = (props: StartNewAttemptProps) => {
  const [restoreAttemptStarted, setRestoreAttemptStarted] = React.useState<boolean>(false);

  const startNewAttemptHandler = async () => {
    if (!restoreAttemptStarted) {
      setRestoreAttemptStarted(true);
      const { newAttempt, navigateToIndex } = props.popupSettings;
      const { closePopup } = props;
      await newAttempt();
      closePopup();
      navigateToIndex();
    }
  };

  const renderDescription = () => (
    <CssAnimation type={CssAnimation.AnimationType.fadeInDown}>
      <DescriptionWrapper>
        <DescriptionWithBackground
          iconName="rounded-info"
          iconSize={12}
          descriptionText={localize('[start new attempt text]')}
        />
      </DescriptionWrapper>
    </CssAnimation>
  );

  return (
    <CssAnimation type={CssAnimation.AnimationType.fadeInDown}>
      <ContinueLaterWrapper>
        <TitleElement appearance="span" size={35}>
          {localize('[start new attempt title]')}
        </TitleElement>
        {renderDescription()}
        <ButtonElement layout={BUTTON.LAYOUT.PRIMARY} onClick={startNewAttemptHandler}>
          {localize('[start new attempt]')}
        </ButtonElement>
        <Link
          text={localize('[back to the course]')}
          layout={LINK.LAYOUT.FORM}
          onClick={props.closePopup}
        />
      </ContinueLaterWrapper>
    </CssAnimation>
  );
};

export default StartNewAttempt;
