import styled, { css } from 'styled-components';
import * as typography from 'components/mixins/typography';
import media from 'components/mixins/media';
import { transparentize } from 'utils/color';

type ContainerProps = {
  inverted: boolean;
  theme: { [key: string]: any };
};
/* eslint-disable import/prefer-default-export */
export const Container = styled.div<ContainerProps>`
  & > *:first-child {
    margin-top: 0 !important;
  }
  ${typography.secondaryFontText};
  h1,
  h2,
  h3 {
    margin-bottom: 16px;
    margin-top: 0;
  }
  h1 {
    ${typography.mainFontH1};
  }
  h2 {
    ${typography.mainFontH2};
  }
  h3 {
    ${typography.mainFontH3};
  }
  h4 {
    ${typography.mainFontH4};
  }

  h1.h2,
  h3,
  h4,
  p {
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
  cite {
    display: block;
    margin: 16px 0;
    padding-left: 30px;
    border-left: 2px solid ${props => props.theme.colors.mainColor};
    &:last-child {
      margin-bottom: 0;
    }
    ${typography.quote};
    ${media.mobile`
      padding-left: 0;
      border-left: 0;
      text-align: center;
      &:before{
        content: '';
        display: block;
        width: 25%;
        height: 2px;
        margin: 0 auto 16px;
        background-color: ${(props: ContainerProps) => props.theme.colors.mainColor};
      }
    `};
  }
  ol,
  ul {
    margin: 16px 0 20px 0;
    padding-left: 50px;
    ${media.mobile`
      padding-left: 44px;
    `};

    &:last-child {
      margin-bottom: 0;
    }

    & > li {
      margin-bottom: 12px;
      > h1,
      > h2,
      > h3,
      > h4,
      > p {
        display: inline;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    & ol,
    & ul {
      margin: 0;
    }
  }
  ul {
    & > li {
      margin-bottom: 12px;
      &:before {
        content: '';
        display: inline-block;
        width: 4px;
        height: 4px;
        margin: 0 27px 0 -32px;
        border-radius: 50%;
        background-color: ${props =>
          props.inverted
            ? props.theme.colors.coverBgOverlayTextColor
            : props.theme.colors.textColor};
        vertical-align: middle;
        ${media.mobile`
          margin: 0 25px 0 -30px;
        `};
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  ol {
    counter-reset: ol-counter;
    & > li {
      margin-bottom: 12px;
      &:before {
        content: counter(ol-counter) '.';
        display: inline-block;
        counter-increment: ol-counter;
        width: 40px;
        margin: 0 25px 0 -65px;
        text-align: right;
        ${media.mobile`
          width: 20px;
          margin: 0 16px 0 -45px;
        `};
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  mark {
    ${typography.highlighted};
  }
  a {
    ${typography.links};
    transition: color 0.2s linear;
  }
  img {
    display: block;
    max-width: 100%;
    height: auto !important;
    margin: 40px auto;
    &[style*='float:right'],
    &[style*='float: right'] {
      margin-right: 0;
      margin-left: 40px;
    }
    &[style*='float:left'],
    &[style*='float: left'] {
      margin-left: 0;
      margin-right: 40px;
    }
  }
  figure {
    &[style*='float'] {
      max-width: 60%;
      img {
        max-width: calc(100% - 40px);
      }
    }
    ${media.xsmall`
      &[style*='float'] {
        max-width: 100%;
        img {
          max-width: 100%;
        }
      }
    `}
  }
  em {
    font-style: italic;
  }
  strong {
    font-weight: 700;
  }
  sup,
  sub {
    font-size: 50%;
  }
  sup {
    vertical-align: super;
  }
  sub {
    vertical-align: sub;
  }
  iframe {
    max-width: 100%;
    border: none;
  }
  .table-wrapper {
    margin: 36px 0;
    max-width: 100%;
    line-height: normal;
    vertical-align: top;
    overflow-x: auto;

    table {
      border-collapse: collapse;
      &[align='center'] {
        margin: 0 auto;
      }

      &.fr-dashed-borders {
        thead {
          tr {
            th {
              border-right-style: dashed;

              &:last-child {
                border-right-style: solid;
              }
            }
          }
        }

        td {
          border-style: dashed;
        }
      }

      &.fr-alternate-rows tbody tr:nth-child(2n) {
        background: whitesmoke;
      }

      td.fr-highlighted,
      th.fr-highlighted {
        border: 1px double red;
      }

      td.fr-thick,
      th.fr-thick {
        border-width: 2px;
      }

      caption {
        margin-bottom: 16px;
        ${typography.mainFontH4};
      }

      thead,
      tbody {
        tr {
          th,
          td {
            padding: 12px 24px;
            border: 1px solid ${props => transparentize(props.theme.colors.textColor, 0.08)};
          }
        }
      }

      thead,
      tbody {
        tr {
          th {
            background-color: ${props => props.theme.colors.mainColor};
            color: ${props => props.theme.colors.contentBodyColor};
            border-top-color: ${props => props.theme.colors.mainColor};
            border-bottom-color: ${props => props.theme.colors.mainColor};

            &:first-child {
              border-left-color: ${props => props.theme.colors.mainColor};
            }

            &:last-child {
              border-right-color: ${props => props.theme.colors.mainColor};
            }
          }
        }
      }
    }
  }

  .eg-content-editor {
    & > *:first-child {
      margin-top: 0 !important;
    }
    .column {
      & > *:first-child {
        margin-top: 0 !important;
      }
      display: inline-block;
      width: 100%;
      padding: 0 20px;
      vertical-align: top;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      /* two columns */
      &:nth-child(1):nth-last-child(2),
      &:nth-child(2):nth-last-child(1) {
        width: 50%;
      }

      ${media.small`
        width: 100% !important;
        padding: 0 !important;
        margin-bottom: 30px;

        &:last-child{
          margin-bottom: 0;
        }
      `};

      .row {
        & > *:first-child {
          margin-top: 0 !important;
        }
        &:not(:last-child) {
          margin-bottom: 20px;
        }
      }

      ol > li::before {
        width: auto;
        min-width: 28px;
      }
    }

    /* three columns */
    & > .column:nth-child(2) {
      padding: 0 20px;
    }

    & > .column:last-child {
      padding-right: 0;
    }

    img {
      margin: 0;
    }

    &[data-type='textEditorTwoColumns'] {
      ${getListStylesForTextBlocks(2)};
    }
    &[data-type='textEditorThreeColumns'] {
      ${getListStylesForTextBlocks(3)};
    }
    &[data-type='textEditorTwoColumns'],
    &[data-type='textEditorThreeColumns'] {
      cite {
        margin: 44px 0;
        padding-left: 0;
        border-left: 0;
        text-align: center;
        &:before {
          content: '';
          display: block;
          width: 25%;
          height: 2px;
          margin: 0 auto 20px;
          background-color: ${props => props.theme.colors.mainColor};
        }
      }
    }
  }
`;
/* eslint-disable */

function getListStylesForTextBlocks(divider: any) {
  return css`
    ol,
    ul {
      margin: ${division(56, divider)}px 0;
      padding-left: ${division(88, divider)}px;
      ${media.mobile`
      padding-left: ${division(44, divider)}px;
    `};
    }
    ul {
      & > li {
        &:before {
          margin: 0 ${division(40, divider)}px 0 -${division(40, divider)}px;
          ${media.mobile`
          margin: 0 ${division(20, divider)}px 0 -${division(30, divider)}px;
        `};
        }
      }
    }
    ol {
      & > li {
        &:before {
          width: ${division(40, divider)}px;
          margin: 0 ${division(32, divider)}px 0 -${division(70, divider)}px;
          ${media.mobile`
          width: ${division(20, divider)}px;
          margin: 0 ${division(16, divider)}px 0 -${division(45, divider)}px;
        `};
        }
      }
    }
  `;
}

function division(value: any, divider: any) {
  return Math.round(value / divider || 1);
}
