import fetchRetry from 'fetch-retry';

const defaultRetryOptions = {
  retries: 20,
  retryDelay: 5000,
  retryOn: [503, 500]
};

type RequestData = { [key: string]: any };

const getRequestOptions = (requestOptions: any, retryOptions: any) =>
  retryOptions
    ? { ...defaultRetryOptions, ...retryOptions, ...requestOptions }
    : { ...requestOptions };

const defaultCredentials = 'same-origin';

export default {
  async get(
    url: string,
    {
      headers,
      cacheBuster,
      credentials = defaultCredentials,
      mode,
      query,
      retryOptions
    }: RequestData
  ) {
    const queryString = this._buildQueryString(query, cacheBuster);
    const requestOptions = {
      method: 'GET',
      headers: this._buildHeaders(headers, cacheBuster),
      credentials,
      mode
    };

    return fetchRetry(url + queryString, getRequestOptions(requestOptions, retryOptions));
  },
  async post(
    url: string,
    { headers, cacheBuster, credentials = defaultCredentials, data, retryOptions }: RequestData
  ) {
    const body = JSON.stringify(data);

    if ('Content-Type' in headers) {
      headers['Content-Length'] = body.length;
    }

    const requestOptions = {
      method: 'POST',
      headers: this._buildHeaders(headers, cacheBuster),
      body,
      credentials
    };

    return fetchRetry(url, getRequestOptions(requestOptions, retryOptions));
  },
  async put(
    url: string,
    {
      headers,
      cacheBuster,
      credentials = defaultCredentials,
      data,
      query,
      retryOptions
    }: RequestData
  ) {
    const queryString = this._buildQueryString(query, cacheBuster);
    const body = JSON.stringify(data);
    if ('Content-Type' in headers) {
      headers['Content-Length'] = body.length;
    }

    const requestOptions = {
      method: 'PUT',
      headers: this._buildHeaders(headers, cacheBuster),
      body,
      credentials
    };

    return fetchRetry(url + queryString, getRequestOptions(requestOptions, retryOptions));
  },
  _buildQueryString(query: any, cacheBuster: any) {
    if (!query) {
      query = {};
    }
    let queryString = '';

    query.v = cacheBuster || +new Date();
    for (let key of Object.keys(query)) {
      queryString += `&${encodeURIComponent(key)}=${encodeURIComponent(query[key])}`;
    }
    if (queryString) {
      queryString = queryString.replace('&', '?');
    }

    return queryString;
  },
  _buildHeaders(headers: { [key: string]: any } = {}, cacheBuster = '') {
    const _headers = Object.assign({}, headers);
    const CACHE_EXPIRATION_DATE_IN_SECONDS = 2678400;
    if (cacheBuster) {
      _headers['Cache-Control'] = 'public';
      _headers.Pragma = 'public';
      _headers.Expires = CACHE_EXPIRATION_DATE_IN_SECONDS;
    } else {
      _headers['Cache-Control'] = 'no-cache';
      _headers.Pragma = 'no-cache';
      _headers.Expires = 0;
    }

    return _headers;
  }
};
