import * as settingsActions from 'store/settings/actions';
import * as courseActions from 'store/course/actions';
import * as userActions from 'store/user/actions';
import * as modulesActions from 'store/modules/actions';
import * as tocActions from 'store/treeOfContent/actions';
import { isReviewMode } from 'store/settings/selectors';
import { isLowerResolution, clearQueryString, getValueFromUrl } from 'utils/window';
import logger from 'utils/logger';
import { MEDIA_TABLET } from 'constants/screenResolutions';
import { LTI_CALLBACK_URL_PARAMETER_NAME } from 'constants/common';

import { isAppLoaded } from './selectors';

import { ActionTypes } from './types';
import { ThunkResult } from 'store/types';

export const load = (): ThunkResult => async (dispatch, getState) => {
  if (isAppLoaded(getState())) return;

  try {
    await dispatch(settingsActions.load());
    await dispatch(modulesActions.subscribeProgressStorage());
    await dispatch(courseActions.load());
    if (isReviewMode(getState())) {
      await dispatch(modulesActions.enableReview());
    }
    await dispatch(userActions.load());
    clearQueryString();

    const ltiCallbackUrl = getValueFromUrl(LTI_CALLBACK_URL_PARAMETER_NAME);
    if (ltiCallbackUrl) {
      dispatch(modulesActions.subscribeLti(ltiCallbackUrl));
    }
    dispatch({ type: ActionTypes.APP_LOADED });
  } catch (e) {
    logger.error(e);
    dispatch({ type: ActionTypes.APP_LOADING_FAILED, reason: e });

  }
};

export const resolutionChanged = (): ThunkResult => dispatch => {
  dispatch({
    type: ActionTypes.APP_RESOLUTION_CHANGED,
    payload: {
      isLowResolution: isLowerResolution(MEDIA_TABLET)
    }
  });
  dispatch(tocActions.adaptToResolution());
};
