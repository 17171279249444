import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';
import withNavigation from 'components/hocs/withNavigation';
import { getCurrentLocationHash } from 'utils/window';
import { getQuestionUrl, getLocationParams, getLearningObjectiveUrl } from 'utils/navigation';
import {
  INDEX_PATH,
  RESULTS_PATH,
  QUESTION_PATH,
  LEARNING_OBJECTIVE_PATH
} from 'constants/navigation';
import { getCourseStructure } from 'store/course/selectors';
import { getTreeOfContentVisibility } from 'store/treeOfContent/selectors';
import { getMasteryScoreValue, isOverallMasteryScore } from 'store/settings/selectors';
import { getAffectProgressSectionsCount } from 'store/sections/selectors';
import { isLowResolution } from 'store/app/selectors';
import * as actions from 'store/treeOfContent/actions';
import { localize } from 'core/localization';
import ExpandedToc from './ExpandedToc';
import CollapsedToc from './CollapsedToc';
import { isLearningObjectiveView } from '../shell/RouteTransition/viewChecker';

import { Tail, TailIcon } from './TreeOfContent.styled';
import { RootAppState } from 'store/types';

type TreeOfContentProps = {
  isExpanded: boolean;
  courseStructure: {
    title: string;
    sections: {[key: string]: any}[];
  },
  isAppInLowResolution: boolean;
  activeSectionId: string;
  activeQuestionId: string;
  navigateToIndex(): void;
  navigateToUrl(url: string): void;
  actions: {[key: string]: any};
  ariaLabel: string;
  isLearningObjectiveView: boolean;
  state: {[key: string]: any};
  masteryScore: number;
  isCourseProgress: boolean;
  affectProgressSectionsCount: number;
};
export class TreeOfContent extends Component<TreeOfContentProps, {}> {
  navigateToQuestion = (sectionId: string, questionId: string) => {
    this.navigate(getQuestionUrl(sectionId, questionId));
  };

  navigateToResults = () => {
    this.navigate(RESULTS_PATH);
  };

  navigateToIndex = () => {
    this.navigate(INDEX_PATH);
  };

  navigateToLearningObjective = (sectionId: string) => {
    this.navigate(getLearningObjectiveUrl(sectionId));
  };

  navigate = (url: string) => {
    if (this.props.isAppInLowResolution) {
      this.toggleTreeOfContentVisibility();
    }
    this.props.navigateToUrl(url);
  };

  toggleTreeOfContentVisibility = () => {
    this.props.actions.toggleTreeOfContentVisibility();
  };

  render() {
    const { isExpanded } = this.props;
    const tocProps = {
      courseStructure: this.props.courseStructure,
      activeSectionId: this.props.activeSectionId,
      activeQuestionId: this.props.activeQuestionId,
      navigateToIndex: this.navigateToIndex,
      navigateToResults: this.navigateToResults,
      navigateToQuestion: this.navigateToQuestion,
      navigateToLearningObjective: this.navigateToLearningObjective,
      isResultsActive: !this.props.activeSectionId,
      isExpanded,
      isLearningObjectiveView: this.props.isLearningObjectiveView,
      courseProgress: this.props.state.course.score,
      masteryScore: this.props.masteryScore,
      isCourseProgress: this.props.isCourseProgress,
      affectProgressSectionsCount: this.props.affectProgressSectionsCount
    };
    return (
      <React.Fragment>
        <ExpandedToc {...tocProps} />
        <Tail
          isExpanded={isExpanded}
          onClick={this.toggleTreeOfContentVisibility}
          tabIndex={0}
          ariaLabel={localize(
            isExpanded ? '[hamburger aria label expanded]' : '[hamburger aria label collapse]'
          )}
        >
          <TailIcon name="hamburger" size={12} />
        </Tail>
        <CollapsedToc {...tocProps} />
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: RootAppState) {
  let params = getLocationParams(QUESTION_PATH);
  let learningObjectiveParams = getLocationParams(LEARNING_OBJECTIVE_PATH);
  return {
    isExpanded: getTreeOfContentVisibility(state),
    courseStructure: getCourseStructure(state),
    isAppInLowResolution: isLowResolution(state),
    isLearningObjectiveView: isLearningObjectiveView.test(
      getCurrentLocationHash().replace('#', '')
    ),
    activeSectionId: params.sectionId || learningObjectiveParams.sectionId,
    activeQuestionId: params.questionId,
    masteryScore: getMasteryScoreValue(state),
    isCourseProgress: isOverallMasteryScore(state),
    affectProgressSectionsCount: getAffectProgressSectionsCount(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default withRouter(
  withNavigation(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(TreeOfContent)
  )
);
