import styled from 'styled-components';
import { Text, Icon, Button } from 'components/common';
import { transparentize } from 'utils/color';
import * as typography from 'components/mixins/typography';
import media from 'components/mixins/media';

export const CollapseWrapper = styled.div`
  ${typography.secondaryFontText};
  background: ${props => transparentize(props.theme.colors.textColor, 0.02)};
  border-left: 2px solid ${props => props.theme.colors.mainColor};
  position: relative;
  font-size: 12px;
  ${media.mobile`
    margin-top: -50px;
  `};
`;

export const CollapseInnerWrapper = styled.div`
  padding: 34px;
`;

export const CollapseButton = styled(Button)`
  position: absolute;
  display: block;
  top: 85px;
  left: 135px;
  padding: 15px 15px 39px 15px;
  border: 0;
  opacity: 0.5;
  background: none;
  user-select: none;
  transition: opacity 0.2s;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
  &:hover {
    opacity: 1;
  }
  &:hover,
  &:focus {
    background: none;
  }
  ${media.large`
    left: 117px;
  `};
  ${media.mobile`
    position: relative;
    top: -55px;
    left: 30px;
    padding: 0;
  `};
  > span {
    display: flex;
  }
`;

export const ButtonText = styled(Text)`
  font-family: ${props => props.theme.fonts.secondaryFont.fontFamily};
  color: ${props => props.theme.colors.textColor};
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
`;

export const IconClose = styled(Icon)`
  position: absolute;
  top: 17px;
  right: 17px;
  padding: 10px;
  opacity: 0.3;
  transition: opacity 0.2s;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  &:focus {
    outline: 0;
  }
`;

export const IconCircleObjective = styled(Icon)`
  font-size: 12px;
  margin-right: 5px;
`;

export const LearningObjectiveText = styled(Text)`
  ${typography.secondaryFontText};
  padding-bottom: 8px;
  font-size: 12px;
  font-weight: 600;
`;

export const CollapseContent = styled(Text)`
  ${typography.secondaryFontText};
  font-size: 12px;
  line-height: 1.92;
`;
