import styled from 'styled-components';
import * as typography from 'components/mixins/typography';
import media from 'components/mixins/media';
import { transparentize } from 'utils/color';

export const EditableContainer = styled.div`
  background-color: ${props => transparentize(props.theme.colors.textColor, 0.02)};
  margin: auto 0;
  box-sizing: border-box;
  position: relative;
  z-index: 9;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 40px;
    border-left: 1px solid ${props => transparentize(props.theme.colors.mainColor, 0.7)};
    transform: translate(-50%);
  }

  textarea {
    ${typography.secondaryFontText};
    box-sizing: border-box;
    padding: 0 60px 0 70px;
    line-height: 39px;
    ${media.mobile`
      padding: 0 29px 0 40px
    `};
  }
  ${media.mobile`
    &:after {
      left: 30px;
    }
  `};
`;

export default EditableContainer;
