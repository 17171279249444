import styled, { css } from 'styled-components';
import { transparentize } from 'utils/color';
import { transition } from 'components/mixins/interactivity';

type WrapperProps = {
  isImageLoading: boolean;
}
export const Wrapper = styled.div.attrs(props => ({
  'data-test': 'content-hotspot-on-image'
})) <WrapperProps>`
  text-align: center;
  ${transition('all', '0.3s')};

  ${props =>
    props.isImageLoading
      ? css`
          background-color: ${transparentize(props.theme.colors.ctaButtonColor, 0.02)};
          min-height: 60px;
        `
      : ''};
`;

export const ImageSpotsWrapper = styled.div`
  max-width: 100%;
  position: relative;
  display: inline-block;
  vertical-align: bottom;
`;

type ImageProps = {
  style: React.CSSProperties
}
export const Image = styled.img<ImageProps>`
  max-width: 100% !important;
  margin: 0 auto !important;
`;
