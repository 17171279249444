import { ActionTypes } from './types';
import { ThunkResult } from '../../types';

export const dragAndDropAnswersAreSelected = (
  answers: any,
  questionId: string
): ThunkResult => dispatch => {
  dispatch({
    type: ActionTypes.QUESTION_DRAG_AND_DROP_ANSWERS_ARE_SELECTED,
    payload: { answers, questionId }
  });
};
