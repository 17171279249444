import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import throttle from 'lodash.throttle';
import { ThemeProvider } from 'styled-components';
import { getColors, getFonts, getBackground } from 'store/settings/selectors';
import {
  INDEX_PATH,
  SECTIONS_PATH,
  SECTION_PATH,
  RESULTS_PATH,
  SIGNUP_PATH,
  LOGIN_PATH
} from 'constants/navigation';
import { IS_NO_ACCESS, IS_NOT_AUTHORIZED, IS_SUCCESSFUL_ACCESS } from 'constants/typeAccess';
import { withRouter, Switch, Route } from 'react-router-dom';
import Sections from 'pages/sections';
import SignUp from 'pages/signUp';
import Login from 'pages/login';
import CourseOverview from 'pages/courseOverview';
import NoAccess from 'pages/noAccess';
import * as actions from 'store/app/actions';
import * as courseActions from 'store/course/actions';
import { isAppLoading, isAppLoadingFailed } from 'store/app/selectors';
import withScrollOnRedirect from 'components/hocs/withScrollOnRedirect';
import NotFoundPage from 'pages/notFound/NotFoundPage';
import { getTreeOfContentVisibility } from 'store/treeOfContent/selectors';
import { isAuthenticated } from 'store/user/selectors';
import { isCourseAccessLimited } from 'store/course/selectors';
import { CircleLoader, PrivateRoute, RouteWithRedirect } from '../common';
import Layout from './Layout';
import RouteTransition from './RouteTransition';
import { RootAppState } from 'store/types';
import withNavigation from 'components/hocs/withNavigation';


type ShellProps = {
  colors: { [key: string]: any };
  fonts: { [key: string]: any };
  background: { [key: string]: any };
  actions: { [key: string]: any };
  isLoading: boolean;
  isLoadingFailed: boolean;
  isLowResolution: boolean;
  isTocExpanded: boolean;
  isUserAuthenticated: boolean;
  location: { [key: string]: any };
  userAccessIsLimited: boolean;
  navigateToUrl(url: string): void;
  courseActions: { [key: string]: any };
}

type ShellState = {
  hasError: boolean;
}
export class Shell extends React.Component<ShellProps, ShellState> {
  constructor(props: ShellProps) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  windowResizeHandler = throttle(() => {
    this.props.actions.resolutionChanged();
  }, 500);

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowResizeHandler);
  }

  async componentDidMount() {
    window.addEventListener('resize', this.windowResizeHandler);
    this.props.actions.resolutionChanged();
    await this.props.actions.load();
    if (this.props.isUserAuthenticated) {
      const urlToNavigate: any = await this.props.courseActions.launch();
      this.props.navigateToUrl(urlToNavigate);
    }
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error(`Component did catch: ${error}, ${errorInfo}`);
    this.setState({ hasError: true });
  }

  render() {
    const { isLoading, isLoadingFailed } = this.props;
    const { hasError } = this.state;

    if (isLoading) {
      return <CircleLoader iconSize={96} />;
    }

    if (isLoadingFailed || hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return this.renderRoute();
  }

  renderRoute() {
    const {
      isLowResolution,
      colors,
      fonts,
      isTocExpanded,
      background,
      isUserAuthenticated,
      userAccessIsLimited
    } = this.props;

    let typeAccess = IS_SUCCESSFUL_ACCESS;

    const forbidRoute = userAccessIsLimited || !isUserAuthenticated;

    if (forbidRoute) {
      typeAccess = userAccessIsLimited && isUserAuthenticated ? IS_NO_ACCESS : IS_NOT_AUTHORIZED;
    }

    return (
      <Route
        render={({ location }) => {
          return (
            <ThemeProvider theme={{ colors, fonts }}>
              <RouteTransition
                location={location}
                isLowResolution={isLowResolution}
                isTocExpanded={isTocExpanded}
                background={background}
              >
                <Layout className="layout">
                  <Switch location={location}>
                    <PrivateRoute
                      exact
                      path={INDEX_PATH}
                      component={Sections}
                      componentNoAccess={NoAccess}
                      typeAccess={typeAccess}
                    />
                    <PrivateRoute
                      exact
                      path={SECTIONS_PATH}
                      component={Sections}
                      componentNoAccess={NoAccess}
                      typeAccess={typeAccess}
                    />
                    <PrivateRoute
                      exact
                      path={RESULTS_PATH}
                      component={CourseOverview}
                      componentNoAccess={NoAccess}
                      typeAccess={typeAccess}
                    />
                    <PrivateRoute
                      path={SECTION_PATH}
                      component={CourseOverview}
                      componentNoAccess={NoAccess}
                      typeAccess={typeAccess}
                    />
                    <RouteWithRedirect
                      exact
                      path={LOGIN_PATH}
                      shouldRedirect={!forbidRoute && isUserAuthenticated}
                      component={Login}
                    />
                    <RouteWithRedirect
                      exact
                      path={SIGNUP_PATH}
                      shouldRedirect={!forbidRoute && isUserAuthenticated}
                      component={SignUp}
                    />
                    <Route path="*" exact component={NotFoundPage} />
                  </Switch>
                </Layout>
              </RouteTransition>
            </ThemeProvider>
          )
        }
        }
      />
    );
  }
}

function mapStateToProps(state: RootAppState) {
  return {
    colors: getColors(state),
    fonts: getFonts(state),
    isLoading: isAppLoading(state),
    isLoadingFailed: isAppLoadingFailed(state),
    isLowResolution: state.app.isLowResolution,
    isTocExpanded: getTreeOfContentVisibility(state),
    background: getBackground(state),
    isUserAuthenticated: isAuthenticated(state),
    userAccessIsLimited: isCourseAccessLimited(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
    courseActions: bindActionCreators(courseActions, dispatch)
  };
}

export default withRouter(
  withNavigation(connect(mapStateToProps, mapDispatchToProps)(withScrollOnRedirect(Shell)))
);
