import * as  React from 'react';
import { connect } from 'react-redux';
import { withTheme } from 'styled-components';
import { Tooltip } from 'components/common';
import { localize } from 'core/localization';
import { transparentize } from 'utils/color';
import CircularProgressbar from 'react-circular-progressbar';
import { getColors } from 'store/settings/selectors';
import {
  getAffectProgressQuestionsCountInSection,
  getCorrectlyAffectProgressQuestionsCountInSection,
  getAffectCorrectlyAnsweredQuestionsCount
} from 'store/sections/selectors';

import {
  PopoverContentWrapper,
  PopoverContent,
  ClosePopoverIcon,
  QuestionsProgress,
  CongratsMessageWrapper,
  CongratsMessageTitle,
  NotPassedMessageWrapper,
  PopoverButton,
  QuestionProgressText
} from './ProgressPopover.styled';
import { RootAppState } from 'store/types';

type ProgressPopoverProps = {
  score: number;
  isPassed: boolean;
  affectProgressQuestionsCountInSection?: number;
  colors?: { [key: string]: any };
  popoverPosition: any;
  children?: any;
  correctlyAffectProgressQuestionsCountInSection?: number;
  affectCorrectlyAnsweredQuestionsCount?: number;
  sectionId: string;
  questions?: any; 
  theme?: any;
};

type ProgressPopoverState = {
  isExpanded: boolean;
}
export class ProgressPopover extends React.Component<ProgressPopoverProps, ProgressPopoverState> {
  constructor(props: ProgressPopoverProps) {
    super(props);
    this.state = {
      isExpanded: false
    };
  }

  toggle = () => {
    this.setState({
      isExpanded: !this.state.isExpanded
    });
  };

  closePopup = () => {
    this.setState({
      isExpanded: false
    });
  };

  handleKeyDown = (evt: any) => {
    if (evt.key === 'Escape' || evt.keyCode === 27) {
      this.closePopup();
    }
  };

  componentDidUpdate() {
    setTimeout(() => {
      const element = document.getElementById('progress-popover');
      if (element) {
        element.focus();
      }
    }, 0);
  }

  shouldComponentUpdate(nextProps: ProgressPopoverProps, nextState: ProgressPopoverState) {
    if (this.state.isExpanded !== nextState.isExpanded) {
      return true;
    }
    return false;
  }

  render() {
    const { isExpanded } = this.state;
    const { popoverPosition } = this.props;

    return (
      <Tooltip
        style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
        trigger={'click'}
        open={isExpanded}
        onRequestClose={this.closePopup}
        position={popoverPosition}
        tooltipTheme={'light'}
        arrow={true}
        interactive
        html={this.renderPopoverContent()}
      >
        <PopoverButton tabIndex={1} onKeyDown={this.toggle} onClick={this.toggle} />
      </Tooltip>
    );
  }

  renderPopoverContent = () => {
    const {
      isPassed,
      correctlyAffectProgressQuestionsCountInSection,
      affectProgressQuestionsCountInSection,
      score,
      colors,
      affectCorrectlyAnsweredQuestionsCount
    } = this.props;

    const templateTheme = this.props.theme;
    
    return (
      <PopoverContentWrapper
        id="progress-popover"
        role="dialog"
        ariaModal={true}
        tabIndex={-1}
        onKeyDown={event => this.handleKeyDown(event)}
        theme={templateTheme}
      >
        <PopoverContent>
          <ClosePopoverIcon
            role="button"
            ariaLabel={localize('[close popover]')}
            tabIndex={1}
            onKeyDown={this.toggle}
            onClick={this.toggle}
            size={14}
            name="close-popup"
            theme={templateTheme}
          />
          <QuestionsProgress isPassed={isPassed} theme={templateTheme}>
            <QuestionProgressText>
              {correctlyAffectProgressQuestionsCountInSection}/
              {affectProgressQuestionsCountInSection}
            </QuestionProgressText>
            <CircularProgressbar
              strokeWidth={2}
              percentage={score}
              styles={{
                path: {
                  stroke: colors && colors.mainColor,
                  strokeLinecap: 'butt',
                  transition: 'stroke-dashoffset 0.5s ease 0s'
                },
                trail: {
                  stroke: colors && transparentize(colors.textColor, 0.02)
                }
              }}
            />
          </QuestionsProgress>
          {isPassed ? (
            <CongratsMessageWrapper>
              <CongratsMessageTitle theme={templateTheme} appearance='h4'>
                {localize('[passed section title]')}
              </CongratsMessageTitle>
              {localize('[passed section]')}
            </CongratsMessageWrapper>
          ) : (
              <NotPassedMessageWrapper>
                {localize('[you need to answer X questions out of Y]', {
                  correctQuestionsCount: affectCorrectlyAnsweredQuestionsCount,
                  totalQuestionsCount: affectProgressQuestionsCountInSection
                })}
              </NotPassedMessageWrapper>
            )}
        </PopoverContent>
      </PopoverContentWrapper>
    );
  };
}

function mapStateToProps(state: RootAppState, ownProps: ProgressPopoverProps) {
  return {
    colors: getColors(state),
    affectProgressQuestionsCountInSection: getAffectProgressQuestionsCountInSection(
      state,
      ownProps.sectionId
    ),
    correctlyAffectProgressQuestionsCountInSection: getCorrectlyAffectProgressQuestionsCountInSection(
      state,
      ownProps.sectionId
    ),
    affectCorrectlyAnsweredQuestionsCount: getAffectCorrectlyAnsweredQuestionsCount(
      state,
      ownProps.sectionId
    )
  };
}

export default withTheme(connect(mapStateToProps)(ProgressPopover));
