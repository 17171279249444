import * as React from 'react';
import Separator from 'components/common/Separator';
import { localize } from 'core/localization';
import progressStorage from 'core/progressStorage';
import {
  LinkElement,
  SocialLoginContainer,
  IconElement,
  TextSocial,
  SeparatorContainer
} from './SocialLogin.styled';

type SocialLoginProps = {
  title: string;
};
export class SocialLogin extends React.Component<SocialLoginProps, {}> {
  render() {
    const { title } = this.props;
    return (
      <React.Fragment>
        <SeparatorContainer>
          <Separator opacity={0.05} color="textColor" />
          <TextSocial appearance="span">{title}</TextSocial>
          <Separator opacity={0.05} color="textColor" />
        </SeparatorContainer>
        <SocialLoginContainer>
          <LinkElement
            name="facebook"
            ariaLabel={localize('[btn FB label]')}
            href={progressStorage.getSocialNetworkAuthLink('facebook')}
          >
            <IconElement name="facebook" />
          </LinkElement>
          <LinkElement
            name="linkedin"
            ariaLabel={localize('[btn LI label]')}
            href={progressStorage.getSocialNetworkAuthLink('linkedin')}
          >
            <IconElement name="linkedin" />
          </LinkElement>
          <LinkElement
            name="google"
            ariaLabel={localize('[btn G label]')}
            href={progressStorage.getSocialNetworkAuthLink('google')}
          >
            <IconElement name="google" />
          </LinkElement>
        </SocialLoginContainer>
      </React.Fragment>
    );
  }
}

export default SocialLogin;
