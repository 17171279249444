import styled from 'styled-components';
import { transparentize } from 'utils/color';
import { defaultBoxShadowColor } from 'constants/common';

type VideoWrapperProps = {
  'data-test': string
}
const VideoWrapper = styled.div.attrs((props: VideoWrapperProps) => ({
  'data-test': 'content-video'
}))`
  .eg-content-editor {
    &[data-type*='video'],
    &[data-type*='Video'] {
      .video-editor {
        position: relative;
        padding-bottom: 56.25% !important;
        margin: 15px 0;
        height: 0;
        width: 100%;
        border: 1px solid transparent;

        iframe {
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100% !important;
          height: 100% !important;
        }
      }

      iframe {
        box-shadow: 0 0 4px -1px ${transparentize(defaultBoxShadowColor, 0.2)};
      }
    }

    &[data-type='videoInTheLeft'] {
      .column:first-child {
        width: 60%;

        & + .column {
          width: 40%;
        }
      }
    }

    &[data-type='videoInTheRight'] {
      .column:first-child {
        width: 40%;

        & + .column {
          width: 60%;
        }
      }
    }
  }
`;

export default VideoWrapper;
