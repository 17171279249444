import React from 'react';
import { withTheme } from 'styled-components';
import { transparentize } from 'utils/color';
import { localize } from 'core/localization';
import { Figure } from './Figure.styled';

type RocketProps = {
  theme: { [key: string]: any }
};
export const Rocket = (props: RocketProps) => (
  <Figure
    width="260px"
    height="123px"
    viewBox="0 0 260 123"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    arialabel={`${localize('[aria label rocket]')}`}
  >
    <title>22A00D1A-3369-4516-81AB-CC2786BABD7B</title>
    <desc>Created with sketchtool.</desc>
    <defs>
      <filter
        x="0.0%"
        y="0.0%"
        width="100.0%"
        height="100.0%"
        filterUnits="objectBoundingBox"
        id="filter-1"
      >
        <feGaussianBlur stdDeviation="0" in="SourceGraphic" />
      </filter>
      <filter
        x="0.0%"
        y="0.0%"
        width="100.0%"
        height="100.0%"
        filterUnits="objectBoundingBox"
        id="filter-2"
      >
        <feGaussianBlur stdDeviation="0" in="SourceGraphic" />
      </filter>
    </defs>
    <g
      id="styles-simple-course"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      transform="translate(-372.000000, -580.000000)"
    >
      <g id="Group-16" transform="translate(372.000000, 581.000000)">
        <path
          d="M74.4791667,20.4233129 L20.9852982,20.4233129 C20.9852982,20.4233129 11.6071429,21.4348819 11.6071429,28.8895706 C11.6071429,36.3442592 
          20.9852982,37.3558282 20.9852982,37.3558282 L52.8125,37.4163015 C52.8125,37.4163015 60.2604167,38.8676599 60.2604167,45.398773 
          C60.2604167,51.9298861 52.8125,53.8650307 52.8125,53.8650307 L36.3690476,53.8650307 C36.3690476,53.8650307 23.0208333,54.6797948 
          23.0208333,62.7546012 C23.0208333,70.8294077 36.3690476,72.0674847 36.3690476,72.0674847 L9.47916667,72.0674847 C9.47916667,72.0674847 
          5.06725961e-16,73.7002629 0,80.4732691 C0,87.2462752 9.47916667,89 9.47916667,89 L217.344853,89 C217.344853,89 229.047619,87.6695881 
          229.047619,80.4732691 C229.047619,73.27695 217.344853,72.0674847 217.344853,72.0674847 L197.020332,72.0674847 C197.020332,72.0674847 
          185.152433,71.3602005 184.940476,64.0245399 C184.728519,56.6888793 197.020332,55.3163891 197.020332,55.3163891 L220.606061,54.5 
          C220.606061,54.5 232.916667,56.3142786 232.916667,46.1244522 C232.916667,35.9346259 221.590909,37.3558282 221.590909,37.3558282 
          L249.84375,37.4163015 C249.84375,37.4163015 260,36.8060045 260,28.8895706 C260,20.9731366 249.84375,20 249.84375,20 L74.4791667,20.4233129 Z"
          id="Path-8"
          fill={props.theme.colors.textColor}
          opacity="0.0199999996"
          filter="url(#filter-1)"
        />
        <g
          id="startup"
          transform="translate(130.500000, 71.500000) scale(-1, 1) rotate(-315.000000) translate(-130.500000, -71.500000) translate(80.000000, 21.000000)"
        >
          <g id="Layer_1" transform="translate(-0.000000, 0.000000)">
            <path
              d="M51.8696722,47.9219537 L47.922099,47.7701072 L48.0739455,51.7176803 C48.225792,58.2464987 53.6916849,63.7123916 60.2205033,63.8642381 
              L64.1680764,64.0160846 L64.0162299,60.0685115 C63.8643834,53.5396931 58.3983452,48.0738002 51.8696722,47.9219537 Z"
              id="Shape"
              fill={transparentize(props.theme.colors.textColor, 0.8, { solidColor: true })}
              fillRule="nonzero"
              opacity="0.600000024"
            />
            <path
              d="M73.1260029,44.4297747 C72.3669157,43.0633015 71.3039902,41.8486748 70.24121,40.7857493 C67.5082636,38.0528028 64.1679311,36.2307901 
              60.5239057,35.3198564 C53.6915396,33.6496902 46.099941,35.4717029 40.7857493,40.7857493 C35.4715576,46.0997957 33.6496902,53.6913943 
              35.3198564,60.5239057 C36.2307901,64.0160846 38.0528028,67.5081183 40.7857493,70.2410647 C41.8485295,71.3038449 43.2150027,72.3667704 
              44.4297747,73.1258576 C36.5344831,73.4295506 28.3356438,70.5447577 22.2623649,64.4714788 C17.4037128,59.6128266 14.6707663,53.6913943 
              13.7598326,47.4662689 C12.5452059,38.5081971 15.4299988,29.2464322 22.2623649,22.4140661 C29.094731,15.5817 38.5083424,12.6969071 
              47.3145677,13.9115338 C53.5396931,14.8224675 59.612972,17.5555593 64.3197776,22.4140661 C70.544903,28.3356438 73.4296959,36.5346284 
              73.1260029,44.4297747 Z"
              id="Shape"
              fill={props.theme.colors.textColor}
              fillRule="nonzero"
              opacity="0.600000024"
            />
            <path
              d="M60.0685115,44.5816212 C55.8172453,18.6184848 33.497989,-0.512285575 5.86454101,0.246801632 L0.398648135,0.398648135 
              L0.246801632,5.86454101 C-0.512285575,33.497989 18.6184848,55.8172453 44.5816212,60.0685115 L60.0685115,44.5816212 Z"
              id="Shape"
              strokeOpacity="0.3"
              stroke={props.theme.colors.textColor}
              strokeWidth="1.6031746"
              fill={props.theme.colors.contentBodyColor}
              fillRule="nonzero"
            />
            <g id="Group" transform="translate(0.225005, 0.225005)" />
            <path
              d="M44.5816212,60.0685115 C46.099941,60.3722045 47.7701072,60.524051 49.2884269,60.6758975 L60.5239057,49.4404187 
              C60.3720592,47.7702525 
              60.2202127,46.2519328 59.9165196,44.7336131 L44.5816212,60.0685115 Z"
              id="Shape"
              fillOpacity="0.3"
              fill={props.theme.colors.textColor}
              fillRule="nonzero"
              opacity="0.300000012"
            />
            <circle
              id="Oval"
              fillOpacity="0.6"
              fill={props.theme.colors.textColor}
              fillRule="nonzero"
              cx="25.8475427"
              cy="25.9124952"
              r="10.8214286"
            />
            <circle
              id="Oval"
              fill={props.theme.colors.contentBodyColor}
              fillRule="nonzero"
              cx="25.5833715"
              cy="25.648324"
              r="8.19883931"
            />
            <path
              d="M20.4404975,21.6551242 C23.3252905,18.7703313 27.8802496,18.7703313 30.7650426,21.6551242 C33.497989,24.3880707 
              33.6498355,28.7911833 31.0687356,31.6759763 C31.2205821,31.5241298 31.3724286,31.5241298 31.3724286,31.3722833 C34.5609145,28.1837973 
              34.5609145,23.0215974 31.3724286,19.8331115 C28.1839426,16.6446256 23.0217428,16.6446256 19.8332568,19.8331115 C16.6447709,23.0215974 
              16.6447709,28.1837973 19.8332568,31.3722833 C19.9851033,31.5241298 20.1369498,31.5241298 20.1369498,31.6759763 C17.7075511,28.7911833 
              17.7075511,24.3880707 20.4404975,21.6551242 Z"
              id="Shape"
              fillOpacity="0.1"
              fill={props.theme.colors.textColor}
              fillRule="nonzero"
            />
          </g>
        </g>
        <ellipse
          id="Oval-6-Copy"
          fill={props.theme.colors.textColor}
          opacity="0.100000001"
          filter="url(#filter-2)"
          cx="130"
          cy="118.5"
          rx="26"
          ry="3.5"
        />
      </g>
    </g>
  </Figure>
);

export default withTheme(Rocket);
