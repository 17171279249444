import { schema } from 'normalizr';
import section from './section';
import afterword from './afterword';
import introduction from './introductions';

const course = new schema.Entity(
  'course',
  {
    sections: [section],
    introductions: [introduction],
    passedAfterwords: [afterword],
    failedAfterwords: [afterword]
  },
  {
    processStrategy: value => ({
      ...value,
      score: 0,
      isPassed: false
    })
  }
);

export default course;
