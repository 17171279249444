import styled from 'styled-components';

type FormProps = {
  idElement: string;
}
export const Form = styled.form.attrs((props: FormProps) => ({
  id: props.idElement
}))<FormProps>`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

export const FormContentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;
