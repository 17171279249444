import styled, { css } from 'styled-components';
import media from 'components/mixins/media';

type WrapperProps = {
  isVoiceOver: boolean;
};
export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  height: 100%;
  width: inherit;
  ${props =>
    props.isVoiceOver &&
    css`
      position: fixed;
      height: 84px;
      top: calc(100% - 110px);
      ${media.small`
        height: 57px;
        width: 100%;
        left: 0;
        top: 100%;
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
        z-index: 10;
      `};
    `};
`;

export default Wrapper;
