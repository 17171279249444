import styled from 'styled-components';
import { Text } from 'components/common';
import media from 'components/mixins/media';

export const QuestionTitleWrapper = styled.div`
  & > .reviewable-container {
    height: auto;
  }
`;

export const QuestionTitle = styled(Text).attrs({
  tabindex: 1,
  'data-test': 'question-title'
})`
  margin: 12px 0 32px;

  ${media.medium`
    padding-right: 22px;
  `}
`;
