import styled, { css } from 'styled-components';
import { transparentize } from 'utils/color';
import { Text } from 'components/common';
import media from 'components/mixins/media';
import { secondaryFont, bold } from 'components/mixins/typography';
import Switch from './Switch/Switch.styled';
import MobileAnswerSeparator from './MobileAnswerSeparator';

type AnswerTextProps = {
  id: string;
}
export const AnswerText = styled(Text) <AnswerTextProps>`
  width: 100%;
  line-height: 1.5;
  padding-left: 138px;
  color: ${props => props.theme.colors.textColor};
  ${media.mobile`
    padding-left: 0;
  `};
`;

export const CaptionMobileContainer = styled.div`
  width: auto;
  display: flex;
  align-self: flex-start;
  ${media.mobile`
    display: none;
    align-self: auto;
  `};
`;

export const CaptionWrapper = styled.div`
  width: auto;
  padding-right: 6px;
  min-width: 119px;
  display: flex;
  justify-content: space-between;
`;

export const Caption = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  align-self: self-start;
  justify-content: center;
  width: 50px;
  height: 24px;
  margin-right: 7px;
  margin-bottom: 18px;
  padding: 2px 3px 4px;
  background-color: ${props => transparentize(props.theme.colors.ctaButtonColor, 0.5)};
  border-radius: 5px;
  ${secondaryFont};
  font-size: 12px;
  ${bold};
  text-align: center;
  color: ${props => props.theme.colors.buttonTextColor};

  &:first-of-type {
    margin-right: 0;
  }
  &:last-of-type {
    margin-left: 9px;
  }
  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -4px;
    border: 4px solid transparent;
    border-top: 4px solid ${props => transparentize(props.theme.colors.ctaButtonColor, 0.5)};
  }
  ${media.mobile`
    margin-bottom: 2px;
  `};
`;

export const TextWrapper = styled.span`
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: normal;
`;

export const AnswersList = styled.ul`
  width: 100%;
`;

type CaptionContainerProps = {
  ariaHidden: boolean;
}
export const CaptionContainer = styled.li.attrs((props: CaptionContainerProps) => ({
  'aria-hidden': props.ariaHidden
})) <CaptionContainerProps>`
  display: flex;
  align-self: flex-start;
  width: auto;
  ${media.mobile`
    display: none;
    align-self: auto;
  `};
`;

type AnswerListItemProps = {
  tabIndex?: number;
  ariaLabel: string;
  role: string;
  key: any;
}
export const AnswerListItem = styled.li.attrs((props: AnswerListItemProps) => ({
  tabIndex: props.tabIndex || 0,
  'aria-label': props.ariaLabel,
  role: props.role
})) <AnswerListItemProps>`
  display: flex;
  align-items: center;
  min-height: 50px;
  padding: 10px 0;
  transition: background-color 0.2s linear;
  position: relative;
  & ${Switch} {
    &:before {
      opacity: 0;
      transition: opacity 0.2s linear;
    }
  }
  &:hover,
  &:focus {
    background-color: ${props => transparentize(props.theme.colors.textColor, 0.06)};
  }
  &:hover ${Switch}, &:focus ${Switch} {
    &:before {
      opacity: 1;
    }
  }
  ${MobileAnswerSeparator} {
    display: none;
  }
  &:last-of-type {
    ${MobileAnswerSeparator} {
      display: none;
    }
  }
  ${CaptionMobileContainer} {
    display: none;
  }
  ${media.mobile`
    flex-direction: column;
    p {
      text-align: center;
    }
    ${css`{
      ${MobileAnswerSeparator} {
        display: block;
      }
      ${CaptionMobileContainer} {
        width: auto;
        display: flex;
        margin-bottom: 10px;
        ${CaptionWrapper} {
          width: auto;
          min-width: 119px;
          padding-right: 6px;
          display: flex;
          justify-content: space-between;
  
          ${Caption} {
            &:first-of-type {
              margin-right: 0;
            }
            &:last-of-type {
              margin-left: calc(30% - 20px);
              margin-right: calc(22% - 21px);
            }
          }
        }
      }
    }`}
  `};
`;
