import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { IS_NO_ACCESS, IS_NOT_AUTHORIZED } from 'constants/typeAccess';
import { LOGIN_PATH } from 'constants/navigation';

type PrivateRouteProps = {
  component: any,
  componentNoAccess: any,
  typeAccess: string;
  exact?: any;
  path: any;
};

export const PrivateRoute = ({
  component: Component,
  componentNoAccess: ComponentNoAccess,
  typeAccess,
  ...rest
}: PrivateRouteProps) => (
    <Route
      {...rest}
      render={props => {
        if (typeAccess === IS_NO_ACCESS) {
          return <ComponentNoAccess {...props} />;
        }
        if (typeAccess === IS_NOT_AUTHORIZED) {
          return <Redirect to={LOGIN_PATH} />;
        }
        return <Component {...props} />;
      }}
    />
  );

export default PrivateRoute;
