import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { SIGNUP_PATH } from 'constants/navigation';
import withNavigation from 'components/hocs/withNavigation';
import {
  getBackground,
  getLogo,
  getAlternativeLogo,
  isLoginViaSocialMediaEnabled,
  isAccessLimited,
  isAllowToSkipTrackingAndTracingEnabled,
  getAllowedUsers
} from 'store/settings/selectors';
import { getCourseTitle, getCourseIntroductions } from 'store/course/selectors';
import { localize } from 'core/localization';
import * as userActions from 'store/user/actions';
import * as courseActions from 'store/course/actions';
import { isAuthenticated, shouldSaveCrossDevice } from 'store/user/selectors';
import LoginForm from 'components/login/LoginForm';
import SocialLogin from 'components/login/SocialLogin';
import SwitcherForm from 'components/login/SwitcherForm';
import MobileFooter from 'components/login/MobileFooter';
import { LINK } from 'constants/components';
import LinkText from 'components/common/Link';
import {
  EMAIL_PASSWORD_COMBINATION_NOT_EXISTS,
  UNKNOWN_ERROR,
  STATUS_OK
} from 'constants/progressStorage';
import {
  AuthPageContent,
  AuthPageSidebar,
  CourseLogo,
  CourseLogoSidebar,
  CourseLogoSidebarWrapper,
  FormLabel,
  SwitcherFormContainer
} from '../auth/auth.styled';
import {
  LoginSection,
  TextForm,
  SocialLoginContainer,
  SkipLinkContainer,
  LoginContainer
} from './LoginPage.styled';
import { RootAppState } from 'store/types';

type LoginPageProps = {
  navigateToUrl(url: string): void;
  userActions: { [key: string]: any };
  courseActions: { [key: string]: any };
  isUserAuthenticated: boolean;
  theme: { [key: string]: any };
  background: { [key: string]: any };
  logoUrl: string;
  alternativeLogoUrl: string;
  saveCrossDevice: boolean;
  loginViaSocialMediaEnabled: boolean;
  title: string;
  accessLimitationEnable: boolean;
  allowToSkipTrackingAndTracingEnabled: boolean;
  allowedUsers: any[];
};
type LoginPageState = {
  isEmailPasswordCombinationNotExists: boolean;
  isUnknownError: boolean;
};

export class LoginPage extends React.Component<LoginPageProps, LoginPageState> {
  constructor(props: LoginPageProps) {
    super(props);
    const { isUserAuthenticated } = props;

    this.state = {
      isEmailPasswordCombinationNotExists: false,
      isUnknownError: false
    };

    if (isUserAuthenticated) {
      this.launchCourse();
    }
  }

  resetStatuses = () => {
    this.setState({
      isEmailPasswordCombinationNotExists: false,
      isUnknownError: false
    });
  };

  onSubmit = async (event: any, signInData: any) => {
    event.preventDefault();

    const status = await this.props.userActions.authorizeUser(signInData);

    if (status === STATUS_OK) {
      await this.launchCourse();
      return;
    }

    this.setState({
      isEmailPasswordCombinationNotExists: status === EMAIL_PASSWORD_COMBINATION_NOT_EXISTS,
      isUnknownError: status === UNKNOWN_ERROR
    });
  };

  async launchCourse() {
    const urlToNavigate = await this.props.courseActions.launch();
    this.props.navigateToUrl(urlToNavigate);
  }

  onAuthorizationSkip = async () => {
    await this.props.userActions.skipAuthentication();
    await this.launchCourse();
  };

  componentDidMount() {
    document.title = `${localize('[title for login page]')} | ${this.props.title}`;
  }

  render() {
    const {
      background,
      logoUrl,
      alternativeLogoUrl,
      saveCrossDevice,
      loginViaSocialMediaEnabled,
      allowToSkipTrackingAndTracingEnabled,
      accessLimitationEnable
    } = this.props;
    const signInFormId = 'formLogin';
    return (
      <React.Fragment>
        <AuthPageSidebar background={background}>
          <CourseLogoSidebarWrapper>
            <CourseLogoSidebar
              altText={localize('[alt logo login]')}
              logoUrl={alternativeLogoUrl}
            />
          </CourseLogoSidebarWrapper>
        </AuthPageSidebar>
        <AuthPageContent background={background}>
          <LoginSection>
            <LoginContainer>
              {saveCrossDevice && (
                <SwitcherFormContainer>
                  <SwitcherForm logIn />
                </SwitcherFormContainer>
              )}
              <CourseLogo altText={localize('[alt logo login]')} logoUrl={logoUrl} />
              <FormLabel htmlFor={signInFormId}>
                {saveCrossDevice ? localize('[welcome]') : localize('[welcome without LS]')}
              </FormLabel>
              <TextForm appearance="p">
                {saveCrossDevice
                  ? localize('[login form text]')
                  : localize('[login form text without LS]')}
              </TextForm>
              <LoginForm
                formId={signInFormId}
                onSubmit={this.onSubmit}
                isEmailPasswordCombinationNotExists={this.state.isEmailPasswordCombinationNotExists}
                resetStatuses={this.resetStatuses}
              />
              {saveCrossDevice && loginViaSocialMediaEnabled && (
                <SocialLoginContainer>
                  <SocialLogin title={localize('[login with]')} />
                </SocialLoginContainer>
              )}
              {!accessLimitationEnable && allowToSkipTrackingAndTracingEnabled && (
                <SkipLinkContainer>
                  <LinkText
                    text={localize('[skip step]')}
                    layout={LINK.LAYOUT.FORM}
                    onClick={this.onAuthorizationSkip}
                    opacity={0.7}
                  />
                </SkipLinkContainer>
              )}
            </LoginContainer>
            <MobileFooter
              navigationTextKey="[dont have account]"
              navigateTo={SIGNUP_PATH}
              navigationToTextKey="[signup here]"
            />
          </LoginSection>
        </AuthPageContent>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: RootAppState) {
  return {
    title: getCourseTitle(state),
    introductions: getCourseIntroductions(state),
    background: getBackground(state),
    logoUrl: getLogo(state),
    alternativeLogoUrl: getAlternativeLogo(state),
    isUserAuthenticated: isAuthenticated(state),
    saveCrossDevice: shouldSaveCrossDevice(state),
    loginViaSocialMediaEnabled: isLoginViaSocialMediaEnabled(state),
    accessLimitationEnable: isAccessLimited(state),
    allowToSkipTrackingAndTracingEnabled: isAllowToSkipTrackingAndTracingEnabled(state),
    allowedUsers: getAllowedUsers(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch),
    courseActions: bindActionCreators(courseActions, dispatch)
  };
}

export default withNavigation(connect(mapStateToProps, mapDispatchToProps)(LoginPage));
