import React from 'react';
import { ProgressContainer, Progress } from './ProgressBar.styled';

export const MAX_PROGRESS = 100;

type ProgressBarProps = {
  height?: number;
  progress?: number;
  className?: string;
  children?: any;
};

export const ProgressBar = ({ height = 2, progress = 0, className="", children }: ProgressBarProps) => (
  <ProgressContainer className={className} height={height}>
    <Progress progress={progress > MAX_PROGRESS ? MAX_PROGRESS : progress} />
    {children}
  </ProgressContainer>
);


export default ProgressBar;
