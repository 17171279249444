import * as courseActions from 'store/course/actions';
import * as sectionActions from 'store/sections/actions';
import * as contentActions from 'store/contents/actions';
import { getContents } from 'store/contents/selectors';
import eventEmitter, { events } from 'core/events/eventEmitter';
import { getSection } from 'store/sections/selectors';
import { INFORMATION_CONTENT } from 'constants/questionTypes';
import { getQuestion, getScore, hasBeenOpened } from './selectors';
import { isScoringOfContentPagesAllowed } from '../settings/selectors';

import { ActionTypes } from './types';
import { ThunkResult } from '../types';

export const questionsLoaded = (questions: any): ThunkResult => (dispatch, getState) => {
  const scoreContentPages = isScoringOfContentPagesAllowed(getState());
  if (scoreContentPages && questions) {
    Object.keys(questions).forEach(key => {
      const question = questions[key];
      if (question.type === INFORMATION_CONTENT) {
        question.affectsProgress = scoreContentPages;
      }
    });
  }
  dispatch({
    type: ActionTypes.QUESTIONS_LOADED,
    payload: questions
  });
};

export const answer = (questionId: string): ThunkResult<Promise<void>> => async (
  dispatch,
  getState
) => {
  const question = getQuestion(getState(), questionId);
  const score = question.isSurvey ? 100 : getScore(getState(), question);

  dispatch({
    type: ActionTypes.QUESTION_ANSWERED,
    payload: { id: question.id, score }
  });

  const questionContent = getContents(getState(), question.questionContent);
  eventEmitter.emit(events.QUESTION_ANSWERED, [
    getQuestion(getState(), questionId),
    getSection(getState(), question.sectionId),
    questionContent
  ]);

  dispatch(sectionActions.updateProgress(question.sectionId));
  await dispatch(courseActions.updateProgress());
};

export const informationContentExperienced = (
  questionId: string
): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  if (!isScoringOfContentPagesAllowed(getState())) {
    return;
  }

  dispatch({
    type: ActionTypes.QUESTION_ANSWERED,
    payload: { id: questionId, score: 100 }
  });

  const question = getQuestion(getState(), questionId);
  const section = getSection(getState(), question.sectionId);
  eventEmitter.emit(events.INFORMATION_CONTENT_EXPERIENCED, [question, section]);

  dispatch(sectionActions.updateProgress(question.sectionId));
  await dispatch(courseActions.updateProgress());
};

export const markAsOpened = (questionId: string): ThunkResult<Promise<void>> => async (
  dispatch,
  getState
) => {
  if (hasBeenOpened(getState(), questionId)) {
    return;
  }

  dispatch({
    type: ActionTypes.QUESTION_OPENED,
    payload: { questionId, hasBeenOpened: true }
  });

  const questionType = getQuestion(getState(), questionId).type;
  if (questionType === INFORMATION_CONTENT) {
    dispatch(informationContentExperienced(questionId));
  }
};

export const resetProgress = (question: any): ThunkResult => dispatch => {
  const initialScore = question.score;
  dispatch({
    type: ActionTypes.QUESTION_PROGRESS_RESET,
    payload: { id: question.id, type: question.type }
  });

  eventEmitter.emit(events.RESET_QUESTION_PROGRESS, question.id);

  if (initialScore === 0) {
    return;
  }

  dispatch(sectionActions.updateProgress(question.sectionId));
  dispatch(courseActions.updateProgress());
};

export const cleanup = (questionId: string): ThunkResult => (dispatch, getState) => {
  const question = getQuestion(getState(), questionId);
  dispatch({
    type: ActionTypes.QUESTION_PROGRESS_RESET,
    payload: { id: question.id, type: question.type }
  });

  dispatch({
    type: ActionTypes.QUESTION_OPENED,
    payload: { questionId, hasBeenOpened: false }
  });
};

export const loadFeedback = (questionId: string): ThunkResult => (dispatch, getState) => {
  const state = getState();
  const question = getQuestion(state, questionId);
  if (question.correctFeedback.length) {
    dispatch(contentActions.loadContents(getContents(state, question.correctFeedback)));
  }
  if (question.incorrectFeedback.length) {
    dispatch(contentActions.loadContents(getContents(state, question.incorrectFeedback)));
  }
  if (question.explanationBlocks.length) {
    dispatch(contentActions.loadContents(getContents(state, question.explanationBlocks)));
  }
};

export const restoreProgress = (
  questionId: string,
  questionAnswers: any
): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  dispatch({
    type: ActionTypes.QUESTION_RESTORE_RESPONSE,
    payload: { id: questionId, response: questionAnswers }
  });

  const question = getQuestion(getState(), questionId);
  const score = getScore(getState(), question);
  dispatch({
    type: ActionTypes.QUESTION_PROGRESS_RESTORE,
    payload: { id: questionId, score }
  });

  dispatch(sectionActions.updateScore(question.sectionId));
  await dispatch(courseActions.updateScore());
};

export const animateFeedback = (data: {
  questionId: string;
  isAnimating: boolean;
}): ThunkResult => dispatch => {
  const { questionId, isAnimating } = data;
  dispatch({
    type: ActionTypes.FEEDBACK_ANIMATING,
    payload: { id: questionId, isFeedbackAnimating: isAnimating }
  });
};
