import { getQuestion } from 'store/questions/selectors';
import { RootAppState } from 'store/types';

export const getUserAnswers = (state: RootAppState, questionId: string) => {
  const question = getQuestion(state, questionId);
  return question.answers.map((answer: any) => ({
    id: answer.id,
    text: answer.text
  }));
};

export const getUserResponse = (state: RootAppState, questionId: string) => {
  const question = getQuestion(state, questionId);
  return question.response;
};

export const getScore = (state: RootAppState, question: any) => {
  if (JSON.stringify(question.answers) === JSON.stringify(question.response)) {
    return 100;
  }
  return 0;
};
