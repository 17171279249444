import styled from 'styled-components';
import { Text } from 'components/common';
import buttonDefaultStyle from 'components/mixins/normalize';
import media from 'components/mixins/media';
import { transparentize } from 'utils/color';

export const TextElement = styled(Text)`
  && {
    font-weight: 300;
    font-size: 16px;
    color: ${props => props.theme.colors.buttonTextColor};
  }
`;

export const Button = styled.button.attrs(props => ({
  tabIndex: props.tabIndex,
  'data-test': 'button-vote'
}))`
  ${buttonDefaultStyle};
  background-color: ${props => props.theme.colors.correctColor};
  border-radius: 2px;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  padding: 10px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  overflow: visible;
  ${media.mobile`
    border-radius: 30px;
    width: 160px;
    height: 44px;
    flex-flow: row nowrap;
    justify-content: center;
  `};

  &::before {
    content: '';
    display: block;
    position: absolute;
    width: inherit;
    height: inherit;
    top: 0;
    left: 0;
  }
  &:hover::before {
    background: rgba(255, 255, 255, 0.1);
  }
  &:active::before {
    background: rgba(0, 0, 0, 0.1);
  }
`;

export const TextLabel = styled(Text)`
  font-weight: normal;
  color: ${props => transparentize(props.theme.colors.textColor, 0.4)};
  position: relative;
  top: 30px;
  ${media.mobile`
    top: 0;
    color: ${(props: any) => props.theme.colors.buttonTextColor};
    font-weight: 600;
    margin-left: 10px;
  `};
`;
