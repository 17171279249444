import { ActionTypes } from './types';
import { ThunkResult } from '../../types';

export const openQuestionAnswerIsSelected = (
  questionId: string,
  answeredText: string
): ThunkResult => dispatch => {
  dispatch({
    type: ActionTypes.OPEN_QUESTION_ANSWER_IS_SELECTED,
    payload: { questionId, answeredText }
  });
};
