import styled from 'styled-components';
import { transparentize } from 'utils/color';
import media from 'components/mixins/media';
import Button from 'components/common/Button';
import { BasicStyledSidebar } from 'components/shell/Layout';
import { bodyBackground } from 'components/mixins/backgrounds';

type NotFoundPageSidebarProps = {
  background: any;
}
export const NotFoundPageSidebar = styled(BasicStyledSidebar) <NotFoundPageSidebarProps>`
  ${media.tablet`
    display: none;
  `};
`;
type NotFoundPageContentProps = {
  background: { [key: string]: any }
}
export const NotFoundPageContent = styled.div<NotFoundPageContentProps>`
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  ${props => bodyBackground(props.background.body)};
`;

type LogoProps = {
  altText: string;
  logoUrl: string;
}
export const Logo = styled.img.attrs((props: LogoProps) => ({
  alt: props.altText,
  src: props.logoUrl
})) <LogoProps>`
  margin: 0 auto 140px;
  max-width: 224px;
  max-height: 42px;

  ${media.mobile`
    margin-top: 40px;
  `};
`;

export const HomeButton = styled(Button)`
  background-color: ${props => props.theme.colors.mainColor};
  color: ${props => props.theme.colors.buttonTextColor};
  font-weight: 600;
  font-size: 14px;
  width: 198px;
  text-align: center;
  min-height: 50px;

  &:hover,
  &:focus {
    background-color: ${props => transparentize(props.theme.colors.mainColor, 0.9)};
  }
`;

export const Header = styled.h1`
  font-size: 120px;
  font-weight: 300;
  line-height: 0.42;
  opacity: 0.5;
  margin-bottom: 48px;
  font-family: ${props => props.theme.fonts.secondaryFont.fontFamily};
  color: ${props => props.theme.colors.textColor};
  ${media.mobile`
    margin-bottom: 60px;
    font-size: 100px;
    line-height: 0.36;
  `};
`;

export const Description = styled.h2`
  max-width: 440px;
  text-align: center;
  font-size: 14px;
  line-height: 2.29;
  margin-bottom: 24px;
  font-family: ${props => props.theme.fonts.secondaryFont.fontFamily};
  color: ${props => props.theme.colors.textColor};
  ${media.mobile`
    margin: 0 30px 32px;
    font-size: 13px;
    line-height: 1.85;
  `};
`;
