import * as React from 'react';
import { Text, Loader } from 'components/common';
import ContentItem from '../ContentItem';
import { Item, List, LoaderContainer } from './ContentList.styled';

type ContentListProps = {
  items: any[] ;
  isLoading: boolean;
  inverted: boolean;
}

export const ContentList = ({ items, isLoading, inverted }: ContentListProps) => {
  if (isLoading) return renderLoader();

  return renderList(items, inverted);
};

function renderLoader() {
  return (
    <LoaderContainer>
      <Loader />
    </LoaderContainer>
  );
}

function renderList(items: any, inverted: any) {
  const contents = items.filter((content: any) => content.value && filterContents(content.value));
  if (contents.length) {
    return (
      <List>
        {contents.map((content: any, index: any) => (
          <Item key={index}>
            <ContentItem
              inverted={inverted}
              value={content.value}
              childrenElements={
                content.children && content.children.length ? content.children : null
              }
            />
          </Item>
        ))}
      </List>
    );
  }
  // TODO: should remove when will be added support for all content types
  return <Text appearance="h2">No supported contents</Text>;
}

// TODO: should remove when will be added support for all content types
const contentEditorClassSelector = '.eg-content-editor';
const supportedContentTypesSelectors = [
  '[data-type="textEditorOneColumn"]',
  '[data-type="textEditorTwoColumns"]',
  '[data-type="textEditorThreeColumns"]',
  '[data-type="imageEditorOneColumn"]',
  '[data-type="imageEditorTwoColumns"]',
  '[data-type="imageInTheLeft"]',
  '[data-type="imageInTheRight"]',
  '[data-type="videoInTheLeft"]',
  '[data-type="videoWithText"]',
  '[data-type="videoInTheRight"]',
  '[data-type="singleVideo"]',
  '[data-type="singleAudio"]',
  '[data-type="document"]',
  '[data-type="linkCuration"]',
  '[data-type="hotspot"]',
  '[data-type="htmlBlock"]'
];

function filterContents(value: any) {
  const finder = createHTMLWrapper(value);
  const isContentEditorBlock =
    finder.querySelector(contentEditorClassSelector) ||
    finder.querySelector('[data-type="linkCuration"]');
  if (isContentEditorBlock) {
    for (let supportedContentType of supportedContentTypesSelectors) {
      if (finder.querySelector(supportedContentType)) {
        return true;
      }
    }
    return false;
  }
  return true;
}

function createHTMLWrapper(value: any) {
  const wrapper = document.createElement('div');
  wrapper.innerHTML = value;
  return wrapper;
}

export default ContentList;
