import { createSelector } from 'reselect';
import * as questionSelectors from 'store/questions/selectors';
import { getMasteryScoreValue, isOverallMasteryScore } from 'store/settings/selectors';
import { objectToArray } from 'utils/object';
import { RootAppState } from 'store/types';

export const getSectionQuestions = (state: RootAppState, sectionId: string) =>
  objectToArray(state.questions).filter(question => question.sectionId === sectionId);

export const getSection = (state: RootAppState, sectionId: string) => state.sections[sectionId];

export const getSections = createSelector(
  (state: RootAppState) => state.sections,
  sections => objectToArray(sections)
);

const getQuestionIds = createSelector(getSection, section => (section && section.questions) || []);

export const getQuestionCount = createSelector(
  getSection,
  section => section && section.questions.length
);

export const getQuestionIndex = createSelector(
  getQuestionIds,
  (state, sectionId, questionId) => questionId,
  (questionIds, questionId) => questionIds.indexOf(questionId)
);

export const affectsProgress = createSelector(
  getQuestionIds,
  state => state,
  (questionIds, state) =>
    questionIds.find(
      (questionId: string) => questionSelectors.getQuestion(state, questionId).affectsProgress
    ) !== undefined
);

export const getAffectProgressSections = createSelector(
  getSections,
  state => state,
  (sections, state) => sections.filter(section => affectsProgress(state, section.id))
);

export const getAffectProgressSectionsCount = (state: RootAppState) =>
  getAffectProgressSections(state).length;

export const getSectionsPassedCount = createSelector(
  getSections,
  sections => sections.filter(section => section.isPassed === true).length
);

export const getQuestions = createSelector(
  getQuestionIds,
  state => state,
  (questionIds, state) =>
    questionIds.reduce((result: any, questionId: string) => {
      result.push(questionSelectors.getQuestion(state, questionId));
      return result;
    }, [])
);

export const getAffectProgressQuestions = createSelector(getQuestions, questions =>
  questions.filter((question: any) => question.affectsProgress)
);
export const getSectionScore = (state: RootAppState) =>
  isOverallMasteryScore(state) ? 100 : getMasteryScoreValue(state);

export const getAffectProgressQuestionsCountInSection = (state: RootAppState, sectionId: string) =>
  getAffectProgressQuestions(state, sectionId).length;

export const getCorrectlyAffectProgressQuestionsCountInSection = createSelector(
  getQuestions,
  questions =>
    questions.filter((question: any) => question.isAnsweredCorrectly && question.affectsProgress)
      .length
);

export const getAffectCorrectlyAnsweredQuestionsCount = (state: RootAppState, sectionId: string) =>
  Math.ceil(
    getSectionScore(state) / (100 / getAffectProgressQuestionsCountInSection(state, sectionId))
  );

export const sectionExists = (state: RootAppState, sectionId: string) =>
  sectionId && Object.keys(state.sections).includes(sectionId);
