import initialState from 'store/initialState';
import { ActionTypes, CourseActionTypes } from './types';

export default function courseReducer(state = initialState.course, action: CourseActionTypes) {
  switch (action.type) {
    case ActionTypes.COURSE_DATA_LOADED:
      return { ...state, ...action.payload };
    case ActionTypes.COURSE_DATA_LOADING_FAILED:
      return { ...state, ...{ isLoadingFailed: true } };
    case ActionTypes.COURSE_PROGRESS_RESTORED:
      return { ...state, ...{ attemptId: action.payload.attemptId, hasBeenContinued: true } };
    case ActionTypes.COURSE_LAUNCHED:
      return { ...state, ...{ attemptId: action.payload.attemptId } };
    case ActionTypes.COURSE_SCORE_UPDATED:
      return {
        ...state,
        ...{
          score: action.payload.score,
          isPassed: action.payload.isPassed
        }
      };
    case ActionTypes.COURSE_CLEANUP:
      return {
        ...state,
        ...{
          score: 0,
          isPassed: false,
          attemptId: null,
          hasBeenContinued: false
        }
      };
    case ActionTypes.CLEAR_PREVIEW_QUESTION_ID:
      delete state.previewQuestionId;
      return state;
    case ActionTypes.CERTIFICATE_DOWNLOADED:
      return { ...state, ...action.payload };
    case ActionTypes.COURSE_IS_PASSED:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
