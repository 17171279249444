import { getStyles } from 'utils/element';
import { addQueryStringParameter } from 'utils/window';

export default class VideoWrapper {
  static wrap(finder: any, theme: any) {
    const videoFrames = finder.querySelectorAll('.video-editor iframe');
    const oldEditorVideoFrames = finder.querySelectorAll(
      'iframe[data-media-type="old-editor-video"]'
    );
    for (let i = 0; i < oldEditorVideoFrames.length; i++) {
      oldEditorVideoFrames[i].src = addQueryStringParameter(
        oldEditorVideoFrames[i].src,
        'style_variables',
        encodeURIComponent(getStyles(theme))
      );
    }
    for (let i = 0; i < videoFrames.length; i++) {
      const videoFrame = videoFrames[i];
      if (/easygenerator\.com|localhost/.test(videoFrame.src)) {
        videoFrame.src = addQueryStringParameter(
          videoFrame.src,
          'style_variables',
          encodeURIComponent(getStyles(theme))
        );
      }
    }
  }
}
