import styled from 'styled-components';
import Text from 'components/common/Text';
import { secondaryFont } from 'components/mixins/typography';
import {
  AuthContainer,
  AuthTextForm,
  AuthSocialLoginContainer,
  AuthSection
} from '../auth/auth.styled';

export const SignupSection = styled(AuthSection)`
  justify-content: space-between;
  padding-top: 40px;
`;

export const SignupContainer = styled(AuthContainer)`
  justify-content: space-between;
`;

export const TextForm = styled(AuthTextForm)`
  width: 320px;
`;

export const SocialLoginContainer = styled(AuthSocialLoginContainer)`
  margin-bottom: 50px;
`;

export const FootNoteText = styled(Text)`
  ${secondaryFont};
  && {
    position: absolute;
    bottom: 47px;
    color: ${props => props.theme.colors.coverBgOverlayTextColor};
    width: 400px;
    left: 50%;
    transform: translateX(-50%);
    font-weight: normal;
    font-size: 12px;
    line-height: 1.5;
    text-align: center;
  }
`;
