import React, { Component } from 'react';
import { localize } from 'core/localization';

import { CourseTitleText, CourseBackIcon, CourseBackButton } from './Header.styled';

type HeaderProps = {
  courseTitle?: string;
  collapsed?: boolean
  navigateToIndex(): void;
  tabIndex: number;
};

export class Header extends Component<HeaderProps, {}> {
  render() {
    const { navigateToIndex, courseTitle, collapsed = false, tabIndex } = this.props;
    return collapsed ? (
      <CourseBackButton
        onClick={navigateToIndex}
        tabIndex={tabIndex}
        ariaLabel={`${localize('[back home button]')}`}
        isCollapsed={collapsed}
      >
        <CourseBackIcon name="back-btn" isCollapsed />
      </CourseBackButton>
    ) : (
      <CourseBackButton
        onClick={navigateToIndex}
        tabIndex={tabIndex}
        ariaLabel={`${localize('[back home button]')}`}
        isCollapsed={collapsed}
      >
        <CourseBackIcon name="back-btn" />
        <CourseTitleText appearance="h4" maxLength={35}>
          {courseTitle}
        </CourseTitleText>
      </CourseBackButton>
    );
  }
}

export default Header;
