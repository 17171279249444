import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { localize } from 'core/localization';
import { ShapedIcon, Collapse, Delay, Text } from 'components/common';
import { ICON, SIZES } from 'constants/components';
import Contents from 'components/contents/Contents';
import ContentBlock from 'components/contents/ContentBlock';
import { OPEN_QUESTION } from 'constants/questionTypes';
import {
  isQuestionAnswered,
  isQuestionAnsweredCorrectly,
  getQuestionResponse
} from 'store/questions/selectors';
import * as questionActions from 'store/questions/actions';
import { RootAppState } from 'store/types';

type FeedbackProps = {
  actions?: { [key: string]: any };
  question: { [key: string]: any };
  delay: number;
  duration?: number;
  animate: boolean;
  onShown?(): void;
  onHidden?(): void;
  isAnswered?: boolean;
  isAnsweredCorrectly?: boolean;
  questionResponse?: Array<any>
};

export class Feedback extends React.PureComponent<FeedbackProps, {}> {
  refFeedback: any;
  constructor(props: FeedbackProps) {
    super(props);
    this.refFeedback = React.createRef();
  }

  static defaultProps = {
    delay: 0,
    duration: 200,
    animate: true
  };

  onShown = () => {
    const { onShown, animate } = this.props;
    if (onShown && this.refFeedback.current) {
      onShown();
      this.refFeedback.current.focus();
    }
    if (animate) {
      this.props.actions && this.props.actions.animateFeedback({
        questionId: this.props.question.id,
        isAnimating: false
      });
    }
  };

  onHidden = () => {
    const { onHidden, animate } = this.props;
    if (onHidden) onHidden();
    if (animate) {
      this.props.actions && this.props.actions.animateFeedback({
        questionId: this.props.question.id,
        isAnimating: false
      });
    }
  };

  render() {
    const { animate, delay, isAnswered } = this.props;

    if (!animate) {
      this.onShown();
      return this.renderContent();
    }

    return (
      <Delay wait={delay}>
        <Collapse
          delayOpacityTransition={true}
          isExpanded={isAnswered}
          onExpanded={this.onShown}
          onCollapsed={this.onHidden}
          scrollIntoView={true}
        >
          {this.renderContent()}
        </Collapse>
      </Delay>
    );
  }

  renderContent = () => {
    const { isAnsweredCorrectly, question } = this.props;
    const { isSurvey } = this.props.question;
    const isOpenQuestionType = question.type === OPEN_QUESTION;
    const isCorrect = isSurvey || isAnsweredCorrectly;
    const items = this.feedbackContentItems();
    return (
      <ContentBlock
        role="dialog"
        tabIndex={1}
        ariaLabel={getTitle(isCorrect, isSurvey, isOpenQuestionType)}
        contentRef={this.refFeedback}
      >
        <ContentBlock.Icon>
          <ShapedIcon
            name={isCorrect ? 'ok' : 'close'}
            color={'buttonTextColor'}
            shapeColor={isCorrect ? 'correctColor' : 'incorrectColor'}
            size={SIZES.LARGE}
            shape={ICON.SHAPES.ROUND_FULL_COLOR}
          />
        </ContentBlock.Icon>
        <ContentBlock.Title>
          <Text appearance="h4">{getTitle(isCorrect, isSurvey, isOpenQuestionType)}</Text>
        </ContentBlock.Title>
        {items.length > 0 && (
          <ContentBlock.Content>
            <Contents items={items} />
          </ContentBlock.Content>
        )}
      </ContentBlock>
    );
  };

  feedbackContentItems() {
    const {
      isAnsweredCorrectly,
      correctFeedback,
      incorrectFeedback,
      isGeneralFeedback,
      answers
    } = this.props.question;
    const { questionResponse = [] } = this.props;
    if (isGeneralFeedback !== undefined && !isGeneralFeedback) {
      let items: any[] = [];
      questionResponse.forEach(item => {
        const currentAnswer = answers.find((answer: any) => answer.id === item);
        if (currentAnswer.explanationBlocks) {
          currentAnswer.explanationBlocks.forEach((block: any) => items.push(block.id));
        }
      });

      return items;
    }
    return isAnsweredCorrectly ? correctFeedback : incorrectFeedback;
  }
}

function getTitle(isCorrect: boolean, isSurvey: boolean, isOpenQuestionType: boolean) {
  if (isOpenQuestionType) {
    return isCorrect
      ? localize('[your answer has been stored]')
      : localize('[your answer is empty and was not stored]');
  }

  return localize(
    isSurvey ? '[your answer has been stored]' : isCorrect ? '[thats correct]' : '[thats incorrect]'
  );
}

function mapDispatchToProps(dispatch: Dispatch) {
  return { actions: bindActionCreators(questionActions, dispatch) };
}

function mapStateToProps(state: RootAppState, ownProps: FeedbackProps) {
  const questionId = ownProps.question.id;
  return {
    isAnswered: isQuestionAnswered(state, questionId),
    isAnsweredCorrectly: isQuestionAnsweredCorrectly(state, questionId),
    questionResponse: getQuestionResponse(state, questionId)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Feedback);
