export * from './screenResolutions';
/* eslint-disable no-useless-escape */
export const imageResizerQueryPattern = /\?width=\d+\&height=\d+&scaleBySmallerSide=\w+/;
export const supportedBgSizes = {
  contain: 'contain',
  cover: 'cover',
  initial: 'initial'
};
export const CONTENT_TYPES = {
  JSON: 'application/json'
};

export const DEFAULT_LANGUAGE_CODE = 'en';
export const CUSTOM_LANGUAGE_CODE = 'xx';
export const NO_SELECTED_ID = 'no-selected-elements';
export const IMAGE_DEFAULT_DOMAIN = 'easygenerator.com';

export const maxCountTocNavigationElements = 8;
export const minCountTocNavigationElements = 5;

export const defaultBoxShadowColor = '#000';

export const MASTERY_SCORE = 50;

export const LTI_CALLBACK_URL_PARAMETER_NAME = 'ltiResultCallbackUrl';

export const FONT_WEIGHTS = ['300', '600'];

export const SECONDARY_FONT_KEY = 'secondary-font';

export const MEDIA_FONT_SIZES = {
  heading1: {
    MAXIMUM_VALUE: 36,
    MULTIPLIER: 0.94
  },
  heading2: {
    MAXIMUM_VALUE: 30,
    MULTIPLIER: 0.93
  },
  heading3: {
    MAXIMUM_VALUE: 26,
    MULTIPLIER: 0.91
  },
  heading4: {
    MAXIMUM_VALUE: 24,
    MULTIPLIER: 0.9
  },
  paragraph: {
    MAXIMUM_VALUE: 22,
    MULTIPLIER: 1
  },
  quote: {
    MAXIMUM_VALUE: 24,
    MULTIPLIER: 0.92
  }
};
