import * as React from 'react';
import { localize } from 'core/localization';
import { INPUT_TYPE } from 'constants/components';
import Input from '../Input';

type PasswordInputProps = {
  validationStatusBeforeSubmit: boolean;
  pattern: string;
  showValidationMessage: boolean;
  onChange(e: any): void;
  setRefs(node: any): void;
  onFocus?(e: any): void;
};

export class PasswordInput extends React.Component<PasswordInputProps, {}> {
  render() {
    const {
      onChange,
      setRefs,
      validationStatusBeforeSubmit,
      pattern,
      showValidationMessage,
      onFocus
    } = this.props;

    return (
      <Input
        idInput="password"
        type={INPUT_TYPE.PASSWORD}
        refInput={(node: any) => setRefs(node)}
        name="password"
        placeholder={localize('[password]')}
        labelText={localize('[password]')}
        autoComplete="new-password"
        onChange={onChange}
        showValidationMessage={showValidationMessage}
        pattern={pattern}
        validationStatusBeforeSubmit={validationStatusBeforeSubmit}
        onFocus={onFocus}
      />
    );
  }
}

export default PasswordInput;
