import * as React from 'react';
import { withTheme } from 'styled-components';
import { localize } from 'core/localization';
import CircleLoader from 'components/common/CircleLoader';
import HotspotOnImageContentParser, { HotSpotOnImageContent } from './components/HotspotOnImageContentParser';
import { Wrapper, ImageSpotsWrapper, Image } from './HotspotOnImage.styled';

import Spot from './components/Spot';

type HotspotOnImageProps = {
  content: string;
  theme: { [key: string]: any };
};

type HotspotOnImageState = {
  content: HotSpotOnImageContent;
  isImageLoading: boolean;
  imageNaturalWidth: number;
  imageNaturalHeight: number;
}

export class HotspotOnImage extends React.Component<HotspotOnImageProps, HotspotOnImageState> {
  constructor(props: HotspotOnImageProps) {
    super(props);
    this.state = {
      content: HotspotOnImageContentParser(props.content) || {},
      isImageLoading: true,
      imageNaturalWidth: 0,
      imageNaturalHeight: 0
    };
  }

  getImageNaturalSizes = (event: any) => {
    this.setState({
      imageNaturalWidth: event.target.naturalWidth,
      imageNaturalHeight: event.target.naturalHeight,
      isImageLoading: false
    });
  };

  render() {
    const data: HotSpotOnImageContent = this.state.content;
    const { image, spots } = data;
    const { imageNaturalWidth, imageNaturalHeight, isImageLoading } = this.state;
    return (
      <Wrapper isImageLoading={isImageLoading}>
        {image ? (
          <ImageSpotsWrapper>
            {isImageLoading ? <CircleLoader iconSize={18} /> : null}
            <Image
              src={image.src}
              alt={localize('[hotspot content image alt]')}
              onLoad={this.getImageNaturalSizes}
              style={isImageLoading ? { opacity: '0' } : {}}
            />
            {spots && image && !isImageLoading
              ? renderSpots({ spots, imageNaturalWidth, imageNaturalHeight, theme: this.props.theme })
              : null}
          </ImageSpotsWrapper>
        ) : null}
      </Wrapper>
    );
  }
}

function renderSpots(data: { spots: any[], imageNaturalWidth: number, imageNaturalHeight: number, theme: any }) {
  const { spots, imageNaturalWidth, imageNaturalHeight, theme } = data;
  return spots.map(spotData => {
    if (!spotData) {
      return null;
    }

    const {
      dataset: { id, text },
      style: { width, height, left, top }
    } = spotData;

    return (
      <Spot
        key={id}
        id={id}
        width={width}
        height={height}
        left={left}
        top={top}
        naturalWidth={imageNaturalWidth}
        naturalHeight={imageNaturalHeight}
        text={text}
        theme={theme}
      />
    );
  });
}

export default withTheme(HotspotOnImage);
