export const SIDEBAR_SELECTOR = 'data-sidebar';
export const CONTENT_SELECTOR = 'data-content';
export const SIDEBAR_CHILD_SELECTOR = 'data-sidebar-child';
export const CONTENT_CHILD_SELECTOR = 'data-content-child';

export const getNodeByDataAttribute = (selector: any, node: any) =>
  node ? node.querySelector(`[${selector}]`) : document.querySelector(`[${selector}]`);

export const getNodes = (parentNode: any) => ({
  layout: parentNode,
  sidebar: getNodeByDataAttribute(SIDEBAR_SELECTOR, parentNode),
  sidebarChild: getNodeByDataAttribute(SIDEBAR_CHILD_SELECTOR, parentNode),
  content: getNodeByDataAttribute(CONTENT_SELECTOR, parentNode),
  contentChild: getNodeByDataAttribute(CONTENT_CHILD_SELECTOR, parentNode)
});
