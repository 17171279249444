class BranchtrackProvider {
  public static instances: { [key: string]: any };
  private static messageProvider: string;
  private static eventOrigin: string;
  private static supportedMessageTypes: { [key: string]: any };
  constructor() {
    BranchtrackProvider.instances = {};
    BranchtrackProvider.messageProvider = 'branchtrack';
    BranchtrackProvider.eventOrigin = 'https://www.branchtrack.com';
    BranchtrackProvider.supportedMessageTypes = {
      // first time init
      init: 'branchtrack:player:init',
      // start of playing, t.i. before first scene appear, including on restart
      start: 'branchtrack:player:start',
      // new scene shown
      scene: 'branchtrack:player:scene',
      // user hit the choice
      choice: 'branchtrack:player:choice',
      // user reached last scene
      finish: 'branchtrack:player:finish',
      // user finished success
      success: 'branchtrack:player:finish:success'
    };
  }

  public createInstance(projectId: string) {
    if (!projectId) {
      return;
    }
    if (this.isEmpty(BranchtrackProvider.instances)) {
      this.subscribeMessageEvent();
    }

    const branchtrack = new BranchtrackInstance(projectId);

    BranchtrackProvider.instances[projectId] = branchtrack;
    return branchtrack;
  }

  // TODO: need to clarify

  public destroyInstance(instance: any) {
    if (typeof BranchtrackProvider.instances[instance.projectId] !== 'undefined') {
      delete BranchtrackProvider.instances[instance.projectId];
    }

    if (this.isEmpty(BranchtrackProvider.instances)) {
      this.unsubscribeMessageEvent();
    }
  }

  private subscribeMessageEvent() {
    window.addEventListener('message', this.messageEventHadler);
  }

  private unsubscribeMessageEvent() {
    window.removeEventListener('message', this.messageEventHadler);
  }

  private messageEventHadler(event: any) {
    let data = null;

    try {
      if (event.origin === BranchtrackProvider.eventOrigin) {
        data = JSON.parse(event.data);

        if (data.provider !== BranchtrackProvider.messageProvider) {
          return;
        }
        const messageDataType = data.type;
        const projectId =
          messageDataType !== BranchtrackProvider.supportedMessageTypes.success ? data.details.project.token : '';
        const branchtrackInstance = BranchtrackProvider.instances[projectId];
        if (typeof branchtrackInstance === 'undefined' || branchtrackInstance === null) {
          return;
        }
        if (messageDataType === BranchtrackProvider.supportedMessageTypes.scene) {
          if (typeof data.details.playlog.score === 'number' && data.details.playlog.score > 0) {
            branchtrackInstance.score = data.details.playlog.score;
          } else if (typeof data.details.scene.score === 'number' && data.details.scene.score > 0) {
            branchtrackInstance.score = data.details.scene.score;
          }
        }
        if (messageDataType === BranchtrackProvider.supportedMessageTypes.finish) {
          branchtrackInstance.isFinished = true;
        }
      }
    } catch (err) {
      return false;
    }
  }

  private isEmpty(obj: any) {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        return false;
      }
    }

    return true;
  }
}

class BranchtrackInstance {
  projectId: string;
  score: number;
  isFinished: boolean;

  constructor(projectId: string) {
    this.projectId = projectId;
    this.score = 0;
    this.isFinished = false;
  }
}

export default new BranchtrackProvider();
