import styled, { keyframes, css } from 'styled-components';

const defaultBorderColor = '#F6842C';

const getBorderColor = (theme: any) =>
  (theme && theme.colors && theme.colors.mainColor) || defaultBorderColor;

const circleRotate = keyframes`
  0% {
        transform: rotate(0deg);
  }
  100% {
        transform: rotate(360deg);
  }
`;

const strokeRotate = keyframes`
  0% {
        transform: rotate(0deg);
  }
  100% {
        transform: rotate(1080deg);
  }
`;

const strokeFix = keyframes`
  0% {
        transform: rotate(0deg);
  }
  50% {
        transform: rotate(135deg);
  }
  100% {
        transform: rotate(270deg);
  }
`;

const strokeLeftGrow = keyframes`
  0% {
        transform: rotate(-5deg);
  }
  50% {
        transform: rotate(-140deg);
  }
  100% {
        transform: rotate(-5deg);
  }
`;

const strokeRightGrow = keyframes`
  0% {
        transform: rotate(5deg);
  }
  50% {
        transform: rotate(140deg);
  }
  100% {
        transform: rotate(5deg);
  }
`;

export const getGeneralLineStyle = (width?: number, height?: number) => css`
  width: ${width || 24}px;
  height: ${height || 24}px;
  box-sizing: border-box;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -${width ? width / 2 : 12}px;
  margin-left: -${width ? width / 2 : 12}px;
`;

export const lineStyle = css`
  content: '';
  display: block;
  border-style: solid;
  border-width: 3px;
`;

export const getLineStyleStrokeRight = (iconSize?: number) => css`
  position: absolute;
  clip: rect(${iconSize ? `0 ${iconSize}px ${iconSize}px ${iconSize / 2}px` : `0 24px 24px 12px`});
`;

export const getLineStyleStrokeLeft = (iconSize?: number) => css`
  position: absolute;
  clip: rect(${iconSize ? `0 ${iconSize / 2}px ${iconSize}px 0` : `0 12px 24px 0`});
`;

export const getLineStyleStroke = (iconSize?: number) => css`
  position: absolute;
  clip: rect(
    ${iconSize ? `0 ${iconSize / 2}px ${iconSize / 2}px ${iconSize / 2}px` : `0 12px 12px 12px`}
  );
`;

export const generalAnimationCircle = css`
  animation: ${circleRotate} 1200ms linear infinite both;
`;

export const generalAnimation = css`
  animation: ${strokeFix} 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
`;

export const generalAnimationStroke = css`
  animation: ${strokeRotate} 5332ms steps(4) infinite both;
`;

export const generalAnimationRight = css`
  animation: ${strokeRightGrow} 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
`;

export const generalAnimationLeft = css`
  animation: ${strokeLeftGrow} 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
`;

interface LoaderProps {
  iconSize?: number
}

export const LoaderCircle = styled.div.attrs(() => ({
  'data-test': 'circle-loader'
}))`
  ${(props: LoaderProps) => getGeneralLineStyle(props.iconSize, props.iconSize)} ${generalAnimationCircle};
`;

export const LoaderStroke = styled.div<LoaderProps>`
  ${props => getGeneralLineStyle(props.iconSize, props.iconSize)}
  ${generalAnimationStroke}
  
  &:before{
    ${props => getGeneralLineStyle(props.iconSize, props.iconSize)}
    ${lineStyle}
    ${props => getLineStyleStroke(props.iconSize)}
    ${generalAnimation}
    border-color: ${props => getBorderColor(props.theme)}
`;

export const LoaderStrokeLeft = styled.div<LoaderProps>`
  ${props => getGeneralLineStyle(props.iconSize, props.iconSize)}
  ${props => getLineStyleStrokeLeft(props.iconSize)}
  ${generalAnimation}
  
  &:before{
    ${props => getGeneralLineStyle(props.iconSize, props.iconSize)}
    ${lineStyle}
    ${props => getLineStyleStrokeRight(props.iconSize)}
    ${generalAnimationLeft}
    border-color: ${props => getBorderColor(props.theme)}
  }
`;

export const LoaderStrokeRight = styled.div<LoaderProps>`
  ${props => getGeneralLineStyle(props.iconSize, props.iconSize)}
  ${props => getLineStyleStrokeRight(props.iconSize)}
  ${generalAnimation}
  
  &:before{
    ${props => getGeneralLineStyle(props.iconSize, props.iconSize)}
    ${lineStyle}
    ${props => getLineStyleStrokeLeft(props.iconSize)}
    ${generalAnimationRight}
    border-color: ${props => getBorderColor(props.theme)}
`;
