import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { LOGIN_PATH } from 'constants/navigation';
import withNavigation from 'components/hocs/withNavigation';
import * as courseActions from 'store/course/actions';
import {
  getBackground,
  getLogo,
  isLoginViaSocialMediaEnabled,
  getAlternativeLogo
} from 'store/settings/selectors';
import { getCourseTitle, getCourseIntroductions } from 'store/course/selectors';
import { localize } from 'core/localization';
import * as userActions from 'store/user/actions';
import { isAuthenticated } from 'store/user/selectors';
import SignUpForm from 'components/login/SignUpForm';
import SocialLogin from 'components/login/SocialLogin';
import SwitcherForm from 'components/login/SwitcherForm';
import MobileFooter from 'components/login/MobileFooter';
import { UNKNOWN_ERROR, STATUS_OK } from 'constants/progressStorage';
import { RootAppState } from 'store/types';
import {
  AuthPageContent,
  AuthPageSidebar,
  CourseLogo,
  CourseLogoSidebar,
  CourseLogoSidebarWrapper,
  FormLabel,
  SwitcherFormContainer
} from '../auth/auth.styled';
import {
  SignupSection,
  TextForm,
  SocialLoginContainer,
  FootNoteText,
  SignupContainer
} from './SignUpPage.styled';

type SignUpPageProps = {
  navigateToUrl(url: string): void;
  actions: { [key: string]: any };
  courseActions: { [key: string]: any };
  isUserAuthenticated: boolean;
  theme: { [key: string]: any };
  background: { [key: string]: any };
  logoUrl: string;
  alternativeLogoUrl: string;
  loginViaSocialMediaEnabled: boolean;
  title: string;
  courseAccessLimited: boolean;
};

type SignUpPageState = {
  isUnknownError: boolean;
};
export class SignUpPage extends React.Component<SignUpPageProps, SignUpPageState> {
  constructor(props: SignUpPageProps) {
    super(props);
    const { isUserAuthenticated } = props;

    this.state = {
      isUnknownError: false
    };

    if (isUserAuthenticated) {
      this.launchCourse();
    }
  }

  componentDidMount() {
    document.title = `${localize('[title for signup page]')} | ${this.props.title}`;
  }

  onSubmit = async (event: any, signUpData: any): Promise<void> => {
    event.preventDefault();

    const status = await this.props.actions.registerUser(signUpData);

    if (status === STATUS_OK && !this.props.courseAccessLimited) {
      await this.launchCourse();
      return;
    }

    this.setState({
      isUnknownError: status === UNKNOWN_ERROR
    });
  };

  async launchCourse() {
    const urlToNavigate = await this.props.courseActions.launch();
    this.props.navigateToUrl(urlToNavigate);
  }

  render() {
    const { background, logoUrl, loginViaSocialMediaEnabled, alternativeLogoUrl } = this.props;
    const signUpFormId = 'signUp';

    return (
      <React.Fragment>
        <AuthPageSidebar background={background}>
          <CourseLogoSidebarWrapper>
            <CourseLogoSidebar
              altText={localize('[alt logo login]')}
              logoUrl={alternativeLogoUrl}
            />
          </CourseLogoSidebarWrapper>
          <FootNoteText appearance="p">{localize('[footnote]')}</FootNoteText>
        </AuthPageSidebar>
        <AuthPageContent background={background}>
          <SignupSection>
            <SignupContainer>
              <SwitcherFormContainer>
                <SwitcherForm signUp />
              </SwitcherFormContainer>
              <CourseLogo altText={localize('[alt logo login]')} logoUrl={logoUrl} />
              <FormLabel htmlFor={signUpFormId}>{localize('[create account]')}</FormLabel>
              <TextForm appearance="p">{localize('[sign up form text]')}</TextForm>
              <SignUpForm formId={signUpFormId} onSubmit={this.onSubmit} />
              {loginViaSocialMediaEnabled && (
                <SocialLoginContainer>
                  <SocialLogin title={localize('[sign up with]')} />
                </SocialLoginContainer>
              )}
            </SignupContainer>
            <MobileFooter
              noteTextKey="[footnote]"
              navigationTextKey="[have account]"
              navigateTo={LOGIN_PATH}
              navigationToTextKey="[login]"
            />
          </SignupSection>
        </AuthPageContent>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: RootAppState) {
  return {
    title: getCourseTitle(state),
    introductions: getCourseIntroductions(state),
    background: getBackground(state),
    logoUrl: getLogo(state),
    alternativeLogoUrl: getAlternativeLogo(state),
    isUserAuthenticated: isAuthenticated(state),
    loginViaSocialMediaEnabled: isLoginViaSocialMediaEnabled(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    actions: bindActionCreators(userActions, dispatch),
    courseActions: bindActionCreators(courseActions, dispatch)
  };
}

export default withNavigation(connect(mapStateToProps, mapDispatchToProps)(SignUpPage));
