import styled from 'styled-components';
import media from 'components/mixins/media';
import { secondaryFont } from 'components/mixins/typography';
import { MEDIA_LARGE } from 'constants/screenResolutions';
import { bodyBackground } from 'components/mixins/backgrounds';
import { BasicStyledSidebar, PageContent } from 'components/shell/Layout';
import { transparentize } from 'utils/color';
import { centerAbsoluteElement } from 'components/mixins/placement';
import Text from 'components/common/Text';
import Icon from 'components/common/Icon';
import Button from 'components/common/Button';
import { getCustomScrollbars } from 'components/mixins/customScrollbars';

export const LoginPageSidebar = styled(BasicStyledSidebar)``;

export const FootNoteText = styled(Text)`
  position: absolute;
  bottom: 47px;
  color: ${props => props.theme.colors.coverBgOverlayTextColor};
  width: 400px;
  left: 50%;
  transform: translateX(-50%);
  font-weight: normal;
  line-height: 1.5;
  text-align: center;
`;

type LoginPageContentProps = {
  background: any
}
export const LoginPageContent = styled(PageContent)<LoginPageContentProps>`
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  width: 50%;
  z-index: 100;
  overflow-y: auto;
  ${getCustomScrollbars()}
  ${props => bodyBackground(props.background.body)};
  ${media.tablet`
    position: relative;
    width: 100%;
    left: 0;
  `};
`;

export const LoginSection = styled.section`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
`;

type CourseLogoProps = {
  altText: string;
  logoUrl: string;
}
export const CourseLogo = styled.img.attrs((props: CourseLogoProps) => ({
  alt: props.altText,
  src: props.logoUrl
})) <CourseLogoProps>`
  display: none;
  margin: 56px auto;
  max-width: 200px;
  max-height: 80px;

  ${media.tablet`
    margin: 36px auto;
    display: block;
  `};
`;

export const CourseLogoSidebar = styled.img.attrs((props: CourseLogoProps) => ({
  alt: props.altText,
  src: props.logoUrl
})) <CourseLogoProps>`
  display: block;
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
`;

export const CourseLogoSidebarWrapper = styled.div`
  width: 360px;
  height: 200px;
  position: absolute;
  ${centerAbsoluteElement};
`;

export const IconLimitAccess = styled(Icon)`
  opacity: 0.5;
`;

export const TextLimitAccess = styled(Text)`
  word-wrap: break-word;
  font-weight: 300;
  font-size: 35px;
  text-align: center;
  line-height: 1.5;
  margin: 40px 0;
  width: 100%;
  ${media.medium`
    & {
      font-size: 30px;
    }
  `};
  ${media.mobile`
    & {
      font-size: 22px;
    }
  `};
`;

export const ContainerLimitAccess = styled.div`
  width: 440px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 130px 0;
  ${media.medium`
    & {
      margin-top: 80px;
    }
  `};
  ${media.mobile`
    & {
      width: 100%;
      padding: 0 20px;
      margin-top: 40px;
    }
  `};
`;

export const ButtonLimitAccess = styled(Button)`
  background-color: ${props => props.theme.colors.mainColor};
  width: 198px;
  text-align: center;

  & span:first-child {
    margin-top: 0;
    ${secondaryFont};
    font-weight: 600;
    transition: all 0.1s ease-out;
  }

  & .icon {
    font-size: 8px;
    right: auto;
    left: 30px;
  }
  &:hover,
  &:focus {
    background-color: ${props => transparentize(props.theme.colors.mainColor, 0.9)};
  }
  @media (min-width: ${MEDIA_LARGE}px) {
    &:hover span:first-child,
    &:focus span:first-child {
      padding-right: 0;
      padding-left: 20px;
    }
  }
  &:active {
    background-color: ${props => transparentize(props.theme.colors.mainColor)};
  }
`;
