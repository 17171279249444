import React, { Component } from 'react';
import NotFound from '../notFound/NotFound';

type RedirectToProps = {
  match: { [index: string]: any };
}
export default function withFallbackToNotFound<T>(WrappedComponent: React.ComponentType<T>, predicateForNotFound: any) {
  return class RedirectTo extends Component<RedirectToProps & T, {}> {
    render() {
      if (typeof predicateForNotFound !== 'function') {
        return <WrappedComponent {...this.props} />;
      }
      if (predicateForNotFound(this.props)) {
        return <NotFound />;
      }

      return <WrappedComponent {...this.props} />;
    }
  };
}
