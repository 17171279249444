import * as React from 'react';
import { SIZES, ICON } from 'constants/components';
import { INFORMATION_CONTENT } from 'constants/questionTypes';
import { localize } from 'core/localization';
import { QuestionItem, TitleText, QuestionIcon } from './QuestionTreeNode.styled';

type QuestionTreeNodeProps = {
  question: {
    id: string;
    title: string;
    isInformationContent: boolean;
    isAnswered: boolean;
    isAnsweredCorrectly: boolean;
    isSurvey: boolean;
    type: string;
    hasBeenOpened: boolean;
  },
  isActive: boolean;
  onClick?(e?: any): void;
  onKeyDown?(e?: any): void;
  role: string;
  tabIndex: number;
};

export default class QuestionTreeNode extends React.PureComponent<QuestionTreeNodeProps, {}> {
  getTitleQuestion(isAnswered: boolean, isAnsweredCorrectly: boolean, title: string) {
    let ariaLabel = isAnswered
      ? isAnsweredCorrectly
        ? `${localize('[aria label question icon ok]')} ${title}`
        : `${localize('[aria label question icon non]')} ${title}`
      : `${localize('[aria label question icon]')} ${title}`;
    return ariaLabel;
  }

  render() {
    const { question, onClick, isActive, onKeyDown, role, tabIndex } = this.props;
    const {
      title,
      isInformationContent,
      isAnswered,
      isAnsweredCorrectly,
      isSurvey,
      type,
      hasBeenOpened
    } = question;
    const isPassed = type === INFORMATION_CONTENT ? hasBeenOpened : isAnswered;
    return (
      <QuestionItem
        isActive={isActive}
        isPassed={isPassed}
        tabIndex={tabIndex}
        role={role}
        ariaLabel={
          !isInformationContent
            ? this.getTitleQuestion(isAnswered, isAnsweredCorrectly, title)
            : title
        }
        {...(!isActive && { onClick, onKeyDown })}
      >
        {!isInformationContent && renderQuestionIcon(isAnswered, isAnsweredCorrectly, isSurvey)}
        <TitleText>{title}</TitleText>
      </QuestionItem>
    );
  }
}

function renderQuestionIcon(isAnswered: any, isAnsweredCorrectly: any, isSurvey: any) {
  let iconName = isAnswered ? (isAnsweredCorrectly ? 'ok' : 'close') : 'question';

  if (isSurvey) {
    iconName = 'survey';
  }

  const color = isAnswered
    ? isAnsweredCorrectly || isSurvey
      ? 'correctColor'
      : 'incorrectColor'
    : 'mainColor';
  return (
    <QuestionIcon
      name={iconName}
      size={SIZES.SMALL}
      shape={ICON.SHAPES.ROUND_FULL_COLOR}
      shapeColor={color}
      {...(!isAnswered && { fontSize: 7 })}
    />
  );
}
