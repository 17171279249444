import styled, { css } from 'styled-components';
import { transparentize } from 'utils/color';
import { centerAbsoluteElementY } from 'components/mixins/placement';
import * as typography from 'components/mixins/typography';
import media from 'components/mixins/media';
import AnimateHeight from 'react-animate-height';
import Icon from 'components/common/Icon';

type ExpandableBlockProps = {
  inverted: boolean;
}
export const ExpandableBlock = styled.div<ExpandableBlockProps>`
  margin-bottom: 20px;
  padding-right: 100px;
`;
export const ExpandableBlockWrapper = styled.div`
  position: relative;
  cursor: pointer;
  outline: none;
`;

export const ContentListWrapper = styled.div`
  display: block;
  min-height: 50px;
`;

export const ExpandableContentWrapper = styled(AnimateHeight).attrs({
  'data-test': 'expandable-block-content'
})`
  transition: 0.3s;
`;

type ExpandIconWrapperProps = {
  isExpanded: boolean;
}
export const ExpandIconWrapper = styled.div<ExpandIconWrapperProps>`
  ${centerAbsoluteElementY};
  position: absolute;
  top: calc(50% - 7px);
  right: 36px;

  ${props =>
    props.isExpanded &&
    css`
      top: 50%;
    `};

  ${media.mobile`
    right: 26px;
  `};
`;

export const ExpandIcon = styled(Icon)`
  position: absolute;
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

type WrapperProps = {
  inverted: boolean;
  isExpanded: boolean;
}
export const Wrapper = styled.div.attrs((props) => ({
  'data-test': 'content-expandable-block'
}))<WrapperProps>`
  position: relative;
  box-sizing: border-box;

  ul {
    margin: 0 auto;
    padding: 0;
    min-height: 80px;

    & > li {
      &:last-child {
        padding-bottom: 20px;

        p {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      ul {
        margin: 24px 0;
        padding-left: 50px;

        & > li {
          &:last-child {
            padding-bottom: 0;
          }

          &:before {
            content: '';
            display: inline-block;
            width: 4px;
            height: 4px;
            margin: 0 27px 0 -32px;
            border-radius: 50%;
            background-color: ${props =>
    props.inverted
      ? props.theme.colors.coverBgOverlayTextColor
      : props.theme.colors.textColor};
            vertical-align: middle;
            ${media.mobile`
              margin: 0 25px 0 -30px;
            `};
          }
        }
      }

      &:before {
        display: none;
      }
    }
  }
  ${props =>
    props.isExpanded &&
    css`
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -20px;
        bottom: 0;
        width: 2px;
        background-color: ${props.theme.colors.mainColor};

        ${media.mobile`
          left: -30px;
        `};
      }
    `};

  ${ExpandableContentWrapper} {
    border-top: 1px solid
      ${props =>
    props.inverted
      ? transparentize(props.theme.colors.coverBgOverlayTextColor, 0.1)
      : transparentize(props.theme.colors.textColor, 0.1)};
    ${props =>
    props.isExpanded &&
    css`
        border-top: 1px solid transparent;
      `}
  }

  ${ExpandIcon} {
    color: ${props =>
    props.inverted
      ? transparentize(props.theme.colors.coverBgOverlayTextColor, 0.5)
      : transparentize(props.theme.colors.textColor, 0.5)};
  }

  ${ExpandableBlock} {
    ${props =>
    props.isExpanded &&
    css`
        transition: 0.3s;
        margin-bottom: 16px;
      `}

    .eg-expandable-block {
      ${typography.mainFontH4};
      line-height: 1.5;
    }

    ${media.mobile`
      ${typography.mainFont};
      padding-right: 60px;
    `};
  }
`;
