import styled from 'styled-components';
import media from 'components/mixins/media';
import { MEDIA_MEDIUM } from 'constants/common';
import { getCustomScrollbars } from 'components/mixins/customScrollbars';
import { bodyBackground } from 'components/mixins/backgrounds';
import { BasicStyledSidebar, PageContent } from 'components/shell/Layout';

export const SectionsPageContainer = styled.div`
  height: 100%;
  overflow-y: auto;
`;

export const SectionsPageSidebar = styled(BasicStyledSidebar)`
  ${media.tablet`
    position: relative;
    width: 100% !important;
    left: 0 !important;
    height: auto;
  `};
`;

type SectionsPageContentProps = {
  background: any;
}
export const SectionsPageContent = styled(PageContent) <SectionsPageContentProps>`
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  width: 50%;
  left: 50%;
  z-index: 100;
  overflow-y: auto;
  ${props => bodyBackground(props.background.body)};
  ${media.tablet`
    position: relative;
    width: 100% !important;
    left: 0 !important;
    height: auto;
  `};
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  ${getCustomScrollbars()};
  @media (max-width: ${MEDIA_MEDIUM - 24}px) {
    flex-direction: column;
  }
`;

export const SectionListContainer = styled.section`
  flex: 1 1 50%;
  height: 100vh;
  min-width: 0;
  padding: 100px 80px 30px;
  ${getCustomScrollbars()};
  ${media.large`
    padding: 80px 40px 30px;
  `};
  ${media.medium`
    min-height: 0;
  `};
  ${media.small`
    flex: 0 0 auto;
    padding: 60px 40px 30px;
  `};
  ${media.tablet`
    height: auto;
    overflow: initial;
  `};
  ${media.mobile`
    padding: 48px 0 30px;
  `};
`;
