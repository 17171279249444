import styled from 'styled-components';
import media from 'components/mixins/media';

/* eslint-disable */
export const RouteContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  ${media.small`
    width: 100vw;
    height: 100%;
  `};
`;
