import { css } from 'styled-components';

const buttonDefaultStyle = () => css`
  background: none;
  border: none;
  outline: none;
  &:focus,
  &::-moz-focus-inner {
    outline: none;
    border: none;
  }
`;
export default buttonDefaultStyle;
