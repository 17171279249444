import initialState from 'store/initialState';
import { getQuestionUrl, getLearningObjectiveUrl } from 'utils/navigation';
import { RESULTS_PATH } from 'constants/navigation';
import { ActionTypes as SectionsActions, SectionsActionTypes } from 'store/sections/types';
import { ActionTypes, NavigationActionTypes } from './types';

export default function navigationReducer(state = initialState.navigation, action: NavigationActionTypes | SectionsActionTypes) {
  switch (action.type) {
    case SectionsActions.SECTIONS_LOADED: {
      let urls = [];
      for (let sectionId in action.payload) {
        if (action.payload[sectionId].learningObjective) {
          urls.push(getLearningObjectiveUrl(sectionId));
        }
        action.payload[sectionId].questions.forEach((questionId: string) => {
          urls.push(getQuestionUrl(sectionId, questionId));
        });
      }

      urls.push(RESULTS_PATH);
      return { ...state, ...{ chain: urls } };
    }
    case ActionTypes.NAVIGATED_INSIDE_THE_APP: {
      return { ...state, ...{ prevUrl: action.payload.prevUrl } };
    }
    default:
      return state;
  }
}
