import styled from 'styled-components';
import { transparentize } from 'utils/color';
import media from 'components/mixins/media';
import { defaultBoxShadowColor } from 'constants/common';

export const Container = styled.div`
  display: inline-block;
  width: inherit;
  height: 84px;
  background: ${props => props.theme.colors.contentBodyColor};
  box-shadow: 0 2px 10px 0 ${transparentize(defaultBoxShadowColor, 0.1)};
  padding: 0 15px 8px 15px;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  ${media.small`
    width: 100%;
    padding-bottom: 0;
  `};
`;

export const IframeElement = styled.iframe.attrs((props: any) => ({
  allow: props.allow,
  audioid: props.audioid
}))`
  height: 65px;
  width: 100%;
  border: none;
  ${media.small`
    height: 48px;
  `};
`;
