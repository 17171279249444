import styled from 'styled-components';
import { transparentize } from 'utils/color';
import { Button } from 'components/common';
import media from 'components/mixins/media';
import buttonDefaultStyle from 'components/mixins/normalize';

type InputContainerProps = {
  size: number;
}
export const InputContainer = styled.div<InputContainerProps>`
  margin-bottom: ${props => props.size}px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const ButtonSubmitSingUp = styled(Button)`
  ${buttonDefaultStyle};
  background-color: ${props => props.theme.colors.mainColor};
  color: ${props => props.theme.colors.buttonTextColor};
  margin-top: 48px;
  min-width: 198px;
  max-width: 320px;
  text-overflow: ellipsis;
  display: flex;
  justify-content: center;
  align-items: center;
  ${media.mobile`
    min-width: 160px;
    max-width: 260px;
  `};
  & span {
    font-size: 14px;
  }
  & .icon {
    font-size: 8px;
  }
  &:hover,
  &:focus {
    background-color: ${props => transparentize(props.theme.colors.mainColor, 0.9)};
  }
  &:active {
    background-color: ${props => transparentize(props.theme.colors.mainColor)};
  }
  & > span:first-child {
    position: relative;
    width: auto;
    padding: 0 10px;
  }
  & > span:first-child:after {
    content: '*';
    position: absolute;
    top: -3px;
    left: 91%;
  }
`;
