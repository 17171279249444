import styled from 'styled-components';
import * as typography from 'components/mixins/typography';
import media from 'components/mixins/media';
import { transparentize } from 'utils/color';
import { defaultBoxShadowColor } from 'constants/common';
import Icon from 'components/common/Icon';

export const LinkCurationContainer = styled.div.attrs({
  'data-test': 'content-link-curation'
})`
  display: flex;
  flex-direction: column;
`;

type LinkContentWrapperProps = {
  href: string;
}
export const LinkContentWrapper = styled.a.attrs((props) => ({
  href: props.href,
  className: 'link-curation-text-wrapper'
})) <LinkContentWrapperProps>`
  &.link-curation-text-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: calc(100% - 8px);
    height: 205px;
    box-shadow: 0 2px 10px 0 ${transparentize(defaultBoxShadowColor, 0.1)};
    background-color: ${props => props.theme.colors.contentBodyColor};
    box-sizing: border-box;
    margin: 4px 4px 6px;
    text-decoration: none;

    ${media.small`
      max-height: 200px;
  `}

    ${media.mobile`
        flex-direction: column;
        height: 100%;
        max-height: 372px;
    `}
  }
`;

export const CustomDescription = styled.div`
  margin-bottom: 20px;
  .custom-description {
    p {
      &:first-child {
        margin-top: 0;
      }
    }
  }
`;

export const ImageContainer = styled.div``;

export const ImageWrapper = styled.div`
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  margin: auto 40px auto 30px;

  &.parsed {
    overflow: hidden;
    position: relative;
  }

  &.deleted {
    padding-left: 20px;
  }

  ${media.mobile`
    &.parsed {
      margin: 20px;
    }

    &.deleted {
      padding-top: 20px;
    }
  `}
`;


type LinkImageProps = {
  src: string;
  alt: string;
}
export const LinkImage = styled.img.attrs(props => ({
  src: props.src,
  alt: props.alt,
  className: 'link-curation-image'
})) <LinkImageProps>`
  &.link-curation-image {
    margin: auto;
    max-width: 205px;
    max-height: 135px;

    @media (min-width: 580px) and (max-width: 1000px) {
      max-width: 160px;
      max-height: 107px;
    }
  }
`;

export const TextWrapper = styled.div`
  min-width: 1px;
  height: 100%;
  padding-right: 20px;

  ${media.mobile`
    width: auto;
    max-width: 100%;
    height: auto;
    padding: 0 20px 40px;
  `}
`;

export const Title = styled.h3.attrs({
  className: 'link-curation-title'
})`
  &.link-curation-title {
    height: auto;
    margin: 30px 0 16px;
    ${typography.mainFontH3};
    font-size: 22px;
    text-align: left;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    ${media.mobile`
      margin-top: 0;
    `}
  }
`;

export const Text = styled.p.attrs({
  className: 'link-curation-text'
})`
  &.link-curation-text {
    max-height: 60px;
    margin: 0 0 20px;
    ${typography.secondaryFontText};
    font-size: 18px;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;

    ${media.mobile`
    max-height: 78px;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 1.4;
    `}

    @media (min-width: 580px) and (max-width: 1000px) {
      max-height: 60px;
      margin-bottom: 16px;
      line-height: 1.5;
    }
  }
`;

export const StaticLink = styled.div`
  display: flex;
  margin-left: 39px;
  ${typography.links}
  font-size: 16px;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const LinkIcon = styled(Icon)`
  position: absolute;
  cursor: pointer;
  top: 25%;
`;

export const StaticLinkWrapper = styled.div`
  position: relative;
  max-height: 25px;
  span {
    color: ${props => transparentize(props.theme.colors.textColor, 0.2)};
  }
  &:hover {
    span {
      color: ${props => transparentize(props.theme.fonts.links.color, 0.8)};
    }
  }
`;
