import styled from 'styled-components';
import { Text, Icon } from 'components/common';
import media from 'components/mixins/media';
import {
  secondaryFont,
  secondaryFontText,
  secondaryFontSmallText,
  bold
} from 'components/mixins/typography';
import { transparentize } from 'utils/color';

export const PageTitle = styled(Text)`
  ${secondaryFont};
  color: ${props => transparentize(props.theme.colors.textColor, 0.4)};
  font-size: 12px;
  font-weight: 600;
`;

export const LearningObjectiveSectionTitle = styled(Text)`
  margin: 12px 0 32px 0;

  ${media.medium`
    padding-right: 22px;
  `}
`;

export const LearningObjectiveWrapper = styled.div`
  color: ${props => props.theme.colors.textColor};
  padding: 32px 32px 32px 68px;
  background: ${props => transparentize(props.theme.colors.textColor, 0.02)};
`;

export const LearningObjectiveContent = styled.div`
  position: relative;
`;

export const LearningObjectiveTitle = styled(Text)`
  ${secondaryFontText};
  ${bold};
  margin-bottom: 5px;
`;

export const LearningObjectiveText = styled(Text)`
  ${secondaryFontText};
  margin-bottom: 16px;
`;

export const LearningObjectiveResult = styled(Text)`
  ${secondaryFontSmallText};
  font-style: italic;
  font-weight: normal;
  opacity: 0.7;
`;

export const LearningObjectiveIcon = styled(Icon)`
  color: ${props => props.theme.colors.mainColor};
  left: -32px;
  position: absolute;
  top: 0;
`;
