import { connect } from 'react-redux';
import { getBackground, getLogo } from 'store/settings/selectors';
import * as React from 'react';
import NotFound from '../../components/notFound/NotFound';
import NotFoundPageContent from './NotFoundPage.styled';
import { RootAppState } from 'store/types';

type NotFoundPageProps = {
  background: {[key: string]: any};
  logoUrl: string;
};

const NotFoundPage = (props: NotFoundPageProps) => (
  <NotFoundPageContent>
    <NotFound {...props} />
  </NotFoundPageContent>
);

function mapStateToProps(state: RootAppState) {
  return {
    background: getBackground(state),
    logoUrl: getLogo(state)
  };
}

export default connect(
  mapStateToProps,
  {}
)(NotFoundPage);
