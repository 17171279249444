import { getStyles } from 'utils/element';
import { addQueryStringParameter } from 'utils/window';

export default class AudioWrapper {
  static wrap(finder: any, theme: any) {
    const audioFrames = finder.querySelectorAll('.audio-editor iframe');
    const oldEditorAudioFrames = finder.querySelectorAll(
      'iframe[data-media-type="old-editor-audio"]'
    );
    for (let i = 0; i < oldEditorAudioFrames.length; i++) {
      oldEditorAudioFrames[i].src = addQueryStringParameter(
        oldEditorAudioFrames[i].src,
        'style_variables',
        encodeURIComponent(getStyles(theme))
      );
    }
    for (let i = 0; i < audioFrames.length; i++) {
      const audioFrame = audioFrames[i];
      const wrapper = document.createElement('div');
      wrapper.classList.add('audio-wrapper');

      audioFrame.src = addQueryStringParameter(
        audioFrame.src,
        'style_variables',
        encodeURIComponent(getStyles(theme))
      );
      audioFrame.onload = () => {
        wrapper.appendChild(audioFrame.contentWindow.document);
      };

      audioFrame.parentNode.insertBefore(wrapper, audioFrame);
      wrapper.appendChild(audioFrame);
    }
  }
}
