import EventHandler from './EventHandler';
import progressStorage from './progressStorage';

class ProgressStorage {
  eventHandler: any;

  constructor() {
    this.eventHandler = new EventHandler();
  }

  subscribe() {
    this.eventHandler.on();
  }

  unsubscribe() {
    this.eventHandler.off();
  }

  use(storage: any) {
    progressStorage.use(storage);
  }

  get url() {
    return progressStorage.url;
  }

  get attemptId() {
    return progressStorage.attemptId;
  }

  getAnswers(questions: any) {
    return progressStorage.getAnswers(questions);
  }

  restoreProgress() {
    return progressStorage.restoreProgress();
  }

  identifyUser() {
    return progressStorage.identifyUser();
  }

  userExists(email: any) {
    return progressStorage.userExists(email);
  }

  resetPassword(email: any) {
    return progressStorage.resetPassword(email);
  }

  authorizeUser(userData: any) {
    return progressStorage.authorizeUser(userData);
  }

  registerUser(userData: any) {
    return progressStorage.registerUser(userData);
  }

  removeProgress() {
    return progressStorage.removeProgress();
  }

  sendSecretLink() {
    return progressStorage.sendSecretLink();
  }

  getSocialNetworkAuthLink(socialNetwork: any) {
    return progressStorage.getSocialNetworkAuthLink(socialNetwork);
  }

  isAuthenticated() {
    return progressStorage.isAuthenticated();
  }

  getHeaders({ contentType, bearerToken }: any) {
    return progressStorage.getHeaders({ contentType, bearerToken });
  }

  getToken() {
    return progressStorage.getToken();
  }
}

export default new ProgressStorage();
