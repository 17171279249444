import styled from 'styled-components';
import { transparentize } from 'utils/color';
import media from 'components/mixins/media';
import * as typography from 'components/mixins/typography';

export const HeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  ${media.mobile`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 20px;
    border: 2px solid ${(props: any) => transparentize(props.theme.colors.textColor, 0.02)};
  `};
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;

  ${media.mobile`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  `};
`;

export const IconWrapper = styled.div`
  position: relative;
  padding-right: 13px;
  margin-right: 12px;
  opacity: 0.4;

  &::after {
    content: '';
    position: absolute;
    display: block;
    right: 0;
    top: 8px;
    width: 1px;
    height: 16px;
  }

  ${media.mobile`
    padding-right: 0;
    margin-right: 0;
    margin-bottom: 20px;

    &::after {
      display: none;
    }
  `};
`;

export const TextWrapper = styled.div`
  width: 100%;

  h4 {
    margin: 0;
    margin-top: -4px;
    word-break: break-word;
  }

  ${media.mobile`
    margin: 0;
  `};
`;

export const ButtonContainer = styled.div`
  margin-left: 40px;
  margin-top: 6px;

  ${media.mobile`
    margin-top: 20px;
    margin-left: 0;
  `};
`;

export const IframeContainer = styled.div`
  margin-top: 32px;

  ${media.mobile`
    margin-top: 4px;
  `};
`;

export const Iframe = styled.iframe`
  iframe& {
    border: 1px solid ${props => transparentize(props.theme.colors.textColor, 0.02)};
  }
`;

type ContainerProps = {
  inverted: boolean;
};

export const Container = styled.div.attrs(props => ({
  'data-test': 'content-document'
}))<ContainerProps>`
  ${IconWrapper} {
    &::after {
      background: ${props =>
        props.inverted
          ? transparentize(props.theme.colors.coverBgOverlayTextColor, 0.2)
          : transparentize(props.theme.colors.textColor, 0.2)};
    }
  }
`;

export const NotFound = styled.div`
  ${typography.mainFontH3};
  display: flex;
  width: 100%;
  margin-top: 20px;
  padding: 24px;
  align-content: center;
  justify-content: center;
  text-align: center;
  color: ${props => transparentize(props.theme.colors.textColor, 0.5)};
  border: 1px solid ${props => transparentize(props.theme.colors.textColor, 0.1)};
`;
