import styled, { css } from 'styled-components';
import {
  Button as ButtonComponent,
  Separator as SeparatorComponent,
  Text
} from 'components/common';

type ControlContainerProps = {
  visible?: boolean
}
export const ControlContainer = styled.div.attrs(props => ({
  tabIndex: props.tabIndex
}))<ControlContainerProps>`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  &:focus {
    outline: none;
  }
  &.hideHint {
    transform: translate(0, 100%);
  }
  ${props =>
    props.visible &&
    css`
      visibility: visible;
    `};
  ${props =>
    props.visible &&
    css`
      visibility: hidden;
      opacity: 0;
    `};
`;

export const Button = styled(ButtonComponent)`
  flex-shrink: 0;
`;

export const ButtonSeparator = styled(SeparatorComponent)`
  margin: 0;
`;

export const TitleText = styled(Text)`
  font-weight: 600;
`;

export const ContainerHint = styled.div.attrs({
  'data-test': 'hint-container'
})`
  position: relative;
  padding-bottom: 69px;
`;
