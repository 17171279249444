import { DEFAULT_AUTH_URL, DEFAULT_LEARN_URL } from 'constants/progressStorage';
import getImageResizeUrl from 'components/mixins/images';
import { RootAppState } from 'store/types';

export const getFonts = (state: RootAppState) =>
  state.settings.template && state.settings.template.fonts;

export const getColors = (state: RootAppState) =>
  state.settings.template.branding && state.settings.template.branding.colors;
export const getLogo = (state: RootAppState) =>
  state.settings.template && getImageResizeUrl(state.settings.template.branding.mainLogo, 400, 160);

export const getAlternativeLogo = (state: RootAppState) =>
  state.settings.template &&
  getImageResizeUrl(state.settings.template.branding.alternativeLogo, 720, 400);

export const isMainLogoShown = (state: RootAppState) =>
  state.settings.template && state.settings.template.branding.allowToShowMainLogo;

export const getBackground = (state: RootAppState) =>
  state.settings.template && state.settings.template.background;

export const getTranslations = (state: RootAppState) => state.settings.translations;

export const isResultsTrackingEnabled = (state: RootAppState) =>
  state.settings.template.resultsTracking && state.settings.template.resultsTracking.enabled;

export const getXapiSettings = (state: RootAppState) =>
  state.settings.template && state.settings.template.xApi;

export const isScoringOfContentPagesAllowed = (state: RootAppState) =>
  state.settings.template.allowScoringOfContentPages &&
  state.settings.template.allowScoringOfContentPages.enabled;

export const isShowContentPagesEnabled = (state: RootAppState) =>
  state.settings.template.showContentPages.enabled;

export const getPublishSettings = (state: RootAppState) =>
  state.settings.template && state.settings.publish;

export const getAuthoringToolUrl = (state: RootAppState) =>
  state.settings.publish.hasOwnProperty('authoringToolUrl') &&
  state.settings.publish.authoringToolUrl;

export const isCrossDeviceSavingEnabled = (state: RootAppState) =>
  state.settings.template.saveProgressCrossDevice &&
  state.settings.template.saveProgressCrossDevice.enabled;

export const isNpsEnabled = (state: RootAppState) =>
  state.settings.template.conductLearnerSatisfactionSurvey &&
  state.settings.template.conductLearnerSatisfactionSurvey.enabled;

export const isAccessLimited = (state: RootAppState) =>
  (state.settings.publish &&
    state.settings.publish.accessLimitation &&
    state.settings.publish.accessLimitation.enabled) ||
  false;

export const isAllowToSkipTrackingAndTracingEnabled = (state: RootAppState) =>
  state.settings.template.allowUserToSkipTrackingAndTracing &&
  state.settings.template.allowUserToSkipTrackingAndTracing.enabled;

export const isTrackingEnabled = (state: RootAppState) =>
  isCrossDeviceSavingEnabled(state) ||
  isResultsTrackingEnabled(state) ||
  isNpsEnabled(state) ||
  isAccessLimited(state);

export const isLoginViaSocialMediaEnabled = (state: RootAppState) =>
  state.settings.template.loginViaSocialMedia &&
  state.settings.template.loginViaSocialMedia.enabled;

export const isScormMode = (state: RootAppState) =>
  state.settings.template && state.settings.publishMode && state.settings.publishMode.isScorm;
export const isScorm2004Mode = (state: RootAppState) =>
  state.settings.template && state.settings.publishMode && state.settings.publishMode.isScorm2004;
export const isPreviewMode = (state: RootAppState) =>
  state.settings.template && state.settings.publishMode && state.settings.publishMode.isPreview;
export const isReviewMode = (state: RootAppState) =>
  state.settings.template && state.settings.publishMode && state.settings.publishMode.isReview;
export const isPublishMode = (state: RootAppState) =>
  !(isScormMode(state) || isPreviewMode(state) || isReviewMode(state));

export const getAuthServiceUrl = (state: RootAppState) =>
  state.settings.publish && state.settings.publish.authServiceUrl
    ? `${state.settings.publish.authServiceUrl}`
    : DEFAULT_AUTH_URL;

export const getLearnServiceUrl = (state: RootAppState) =>
  state.settings.publish && state.settings.publish.learnServiceUrl
    ? `${state.settings.publish.learnServiceUrl}`
    : DEFAULT_LEARN_URL;

export const getAllowedUsers = (state: RootAppState) =>
  isAccessLimited(state) ? state.settings.publish.accessLimitation.allowedUsers : [];

export const getQuestionShortIds = (state: RootAppState) =>
  isScormMode(state) ? state.settings.publish.questionShortIds : [];

export const getSelectedLanguage = (state: RootAppState) =>
  state.settings.template.languages && state.settings.template.languages.selected;

export const isAllowedDownloadCertificate = (state: RootAppState) =>
  state.settings.template.allowUsersToDownloadCertificates &&
  state.settings.template.allowUsersToDownloadCertificates.enabled;

export const isOverallMasteryScore = (state: RootAppState) =>
  state.settings.template.masteryScore && state.settings.template.masteryScore.isOverall;

export const getMasteryScoreValue = (state: RootAppState) =>
  state.settings.template.masteryScore && state.settings.template.masteryScore.score;
