export enum ActionTypes {
    NAVIGATED_INSIDE_THE_APP = 'NAVIGATED_INSIDE_THE_APP'
}

type NavigatedInsideApp = {
    type: ActionTypes.NAVIGATED_INSIDE_THE_APP,
    payload: {
        prevUrl: string,
        currentUrl: string
    }
}

export type NavigationActionTypes = NavigatedInsideApp;
