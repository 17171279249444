export const COURSE_LAUNCHED = 'course:launched';
export const COURSE_STARTED = 'course:started';
export const COURSE_FINISHED = 'course:finished';
export const COURSE_FINALIZED = 'course:finalized';
export const COURSE_EVALUATED = 'course:evaluated';

export const COURSE_PROGRESSED = 'course:progressed';
export const COURSE_ATTEMPT_STARTED = 'course:attemptStarted';
export const SECTION_PROGRESSED = 'section:progressed';
export const QUESTION_ANSWERED = 'question:answered';
export const INFORMATION_CONTENT_EXPERIENCED = 'information-content:experienced';

export const APP_INITIALIZED = 'app:initialized';
export const APP_NAVIGATED = 'app:navigated';

export const RESET_QUESTION_PROGRESS = 'progress:resetQuestionProgress';

export const USER_AUTHENTICATED = 'user:authenticated';
export const USER_LOGOUT = 'user:logout';

export const REVIEW_INITIALIZED = 'review:initialized';
export const COMMENT_SENT = 'review:comment-sent';
export const REVIEWER_AUTHORIZED = 'review:reviewer-authorized';
export const REVIEW_RESTORE_USER = 'review:review-restore-user';
