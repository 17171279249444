import React, { Fragment, Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { getTreeOfContentVisibility } from 'store/treeOfContent/selectors';
import TreeOfContent from 'components/treeOfContent/TreeOfContent';
import { RESULTS_PATH, QUESTION_PATH, LEARNING_OBJECTIVE_PATH } from 'constants/navigation';
import * as actions from 'store/treeOfContent/actions';
import { getBackground, isMainLogoShown } from 'store/settings/selectors';
import QuestionPage from '../question/QuestionPage';
import LearningObjective from '../learningObjective/LearningObjective';
import ResultsPage from '../results/ResultsPage';
import CourseOverviewRouteTransition from './RouteTransition';
import NotFound from '../../components/notFound/NotFound';

import {
  Container,
  PageContainer,
  ContentContainer,
  CourseOverviewSidebar,
  Blockout
} from './CourseOverview.styled';
import { RootAppState } from 'store/types';

type CourseOverviewProps = {
  theme: {[key: string]: any};
  isLowResolution: boolean;
  isTreeOfContentExpanded: boolean;
  actions: {[key: string]: any};
  background: {[key: string]: any};
  logoShown: boolean;
};
export class CourseOverview extends Component<CourseOverviewProps, {}> {
  render() {
    const { isTreeOfContentExpanded, background, logoShown } = this.props;
    return (
      <Fragment>
        <CourseOverviewSidebar
          background={background}
          isTreeOfContentExpanded={isTreeOfContentExpanded}
        >
          <TreeOfContent />
        </CourseOverviewSidebar>
        <Blockout
          isTreeOfContentExpanded={isTreeOfContentExpanded}
          onClick={this.props.actions.toggleTreeOfContentVisibility}
          ariaHidden={true}
        />
        <Route
          render={({ location }) => (
            <CourseOverviewRouteTransition
              background={background}
              isTreeOfContentExpanded={isTreeOfContentExpanded}
              location={location}
            >
              <Container>
                <PageContainer>
                  <ContentContainer
                    isTreeOfContentExpanded={isTreeOfContentExpanded}
                    logoShown={logoShown}
                  >
                    <Switch location={location}>
                      <Route exact path={LEARNING_OBJECTIVE_PATH} component={LearningObjective} />
                      <Route exact path={QUESTION_PATH} component={QuestionPage} />
                      <Route exact path={RESULTS_PATH} component={ResultsPage} />
                      <Route exact path="*" component={NotFound} />
                    </Switch>
                  </ContentContainer>
                </PageContainer>
              </Container>
            </CourseOverviewRouteTransition>
          )}
        />
      </Fragment>
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

function mapStateToProps(state: RootAppState) {
  return {
    isLowResolution: state.app.isLowResolution,
    isTreeOfContentExpanded: getTreeOfContentVisibility(state),
    background: getBackground(state),
    logoShown: isMainLogoShown(state)
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseOverview);
