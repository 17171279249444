import React from 'react';
import { localize } from 'core/localization';
import { ShapedIcon } from 'components/common';
import {
  SectionContentWrapper,
  SectionTitle,
  Footer,
  SectionProgressStatusIconWrapper,
  SectionProgressStatusWrapper,
  SectionSubText,
  ProgressBar,
  IconArrowRight
} from './SectionContent.styled';

export const LIST_ITEM_TITLE_LENGTH = 45;

type SectionContentProps = {
  progress: number;
  title: string;
  isPassed: boolean;
  affectsProgress?: boolean;
};

function SectionContent({ title, isPassed, progress, affectsProgress }: SectionContentProps) {
  const maxLength = LIST_ITEM_TITLE_LENGTH;
  return (
    <SectionContentWrapper>
      <SectionTitle maxLength={maxLength} appearance='h4'>{title}</SectionTitle>
      <Footer>
        {affectsProgress && (
          <SectionProgressStatusWrapper isPassed={isPassed}>
            <SectionProgressStatusIconWrapper isPassed={isPassed}>
              <ShapedIcon
                name="ok"
                color="buttonTextColor"
                shapeColor="correctColor"
                size="small"
              />
            </SectionProgressStatusIconWrapper>
            <SectionSubText>{localize(isPassed ? '[passed]' : '[not passed yet]')}</SectionSubText>
          </SectionProgressStatusWrapper>
        )}
      </Footer>
      <Footer className="hover-state">
        <SectionSubText>{localize('[start section]')}</SectionSubText>
        <IconArrowRight name="arrow-right" size={8} color="textColor" />
      </Footer>
      <ProgressBar progress={progress} />
    </SectionContentWrapper>
  );
}

export default SectionContent;
