import React from 'react';
import styled from 'styled-components';
import media from 'components/mixins/media';
import { secondaryFont } from 'components/mixins/typography';
import { transparentize } from 'utils/color';
import { localize } from 'core/localization';

export const Container = styled.div`
  ${secondaryFont};
  color: ${props => transparentize(props.theme.colors.textColor, 0.5)};
  font-size: 14px;
  font-weight: 600;
  line-height: 1.4;

  ${media.mobile`
    font-size: 12px;
  `}
`;

type PageCounterProps = {
  index: number;
  total: number;
};

export const PageCounter = ({ index, total }: PageCounterProps) => (
  <Container>
    {localize('[page counter caption]', { index, total }, `${index} out of ${total}`)}
  </Container>
);

export default PageCounter;
