import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import withNavigation from 'components/hocs/withNavigation';
import { getBackground, getLogo, getAlternativeLogo } from 'store/settings/selectors';
import { getCourseTitle } from 'store/course/selectors';
import * as userActions from 'store/user/actions';
import { localize } from 'core/localization';
import { BUTTON } from 'constants/components';
import {
  LoginPageSidebar,
  LoginPageContent,
  CourseLogo,
  CourseLogoSidebar,
  CourseLogoSidebarWrapper,
  LoginSection,
  IconLimitAccess,
  TextLimitAccess,
  ContainerLimitAccess,
  ButtonLimitAccess,
  FootNoteText
} from './LimitAccessPage.styled';
import { RootAppState } from 'store/types';

type  LimitAccessPageProps = {
  actions: {[key: string]: any};
  navigateToUrl(): void;
  background:{[key: string]: any};
  logoUrl: string;
  alternativeLogoUrl: string;
  title: string;
};

export class LimitAccessPage extends PureComponent<LimitAccessPageProps, {}> {
  componentDidMount() {
    document.title = `${localize('[title for noaccess page]')} | ${this.props.title}`;
  }

  async backToLoginAction() {
    return this.props.actions.logout();
  }

  render() {
    const { background, logoUrl, alternativeLogoUrl } = this.props;
    return (
      <Fragment>
        <LoginPageSidebar background={background}>
          <CourseLogoSidebarWrapper>
            <CourseLogoSidebar
              altText={localize('[alt logo login]')}
              logoUrl={alternativeLogoUrl}
            />
          </CourseLogoSidebarWrapper>
          <FootNoteText appearance="p" font="secondaryFont" size={12}>
            {localize('[footnote]')}
          </FootNoteText>
        </LoginPageSidebar>
        <LoginPageContent background={background}>
          <LoginSection>
            <CourseLogo altText={localize('[alt logo login]')} logoUrl={logoUrl} />
            <ContainerLimitAccess>
              <IconLimitAccess name="attention" size={64} color="textColor" />
              <TextLimitAccess appearance="p" font="secondaryFont">
                {localize('[the owner of the course has limited the access]')}
              </TextLimitAccess>
              <ButtonLimitAccess
                suffixIcon="arrow-left"
                onClick={this.backToLoginAction.bind(this)}
                target="_self"
                layout={BUTTON.LAYOUT.PRIMARY}
              >
                {localize('[back to login]')}
              </ButtonLimitAccess>
            </ContainerLimitAccess>
          </LoginSection>
        </LoginPageContent>
      </Fragment>
    );
  }
}

function mapStateToProps(state: RootAppState) {
  return {
    title: getCourseTitle(state),
    background: getBackground(state),
    logoUrl: getLogo(state),
    alternativeLogoUrl: getAlternativeLogo(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    actions: bindActionCreators(userActions, dispatch)
  };
}

export default withNavigation(connect(mapStateToProps, mapDispatchToProps)(LimitAccessPage));
