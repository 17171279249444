export function LinkCurationContentParser(contentHtml: any) {
  const wrapper = document.createElement('div');
  wrapper.innerHTML = contentHtml;
  const container = wrapper.querySelectorAll('[data-type="linkCuration"]')[0];
  const customDescription = container.getElementsByClassName('custom-description')[0];
  const contentInnerWrapper = container.getElementsByClassName('content-inner-wrapper')[0];
  const textWrapper = contentInnerWrapper.getElementsByClassName('text-wrapper')[0];
  const title = textWrapper.getElementsByClassName('link-title')[0] as HTMLElement;
  const text = textWrapper.getElementsByClassName('link-content')[0] as HTMLElement;
  const staticLink = textWrapper.getElementsByClassName('link-url')[0] as HTMLAnchorElement;
  const imageWrapper = contentInnerWrapper.getElementsByClassName('images')[0];
  const image = contentInnerWrapper.getElementsByTagName('img')[0];
  return {
    customDescription,
    title: title.innerText,
    text: text.innerText,
    imgUrl: image.src || '',
    imgClasses: imageWrapper.classList.value || 'images',
    resourceUrl: staticLink.href || ''
  };
}

export default LinkCurationContentParser;
