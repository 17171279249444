import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import shuffle from 'utils/shuffle';
import { isTouchScreenDevice } from 'utils/window';
import imageLoader from 'core/http/imageLoader';
import * as dragAndDropActions from 'store/questions/dragAndDropText/actions';
import DragDrop from 'components/common/DragDrop';
import { NO_SELECTED_ID } from 'constants/common';
import {
  getUserAnswers,
  getBackground,
  getUserResponse
} from 'store/questions/dragAndDropText/selectors';
import DragAndDropMobile from './DragAndDropMobile';
import { RootAppState } from 'store/types';

type DragAndDropTextProps = {
  id: string;
  background?: string;
  isMultipleList?: boolean;
  dropspots?: any[];
  response?: any[];
  actions?: { [key: string]: any };
  type: string;
};

type DragAndDropTextState = {
  isImageLoaded: boolean;
  isImageNotFound: boolean;
  listsOfItems: any[];
}

export class DragAndDropText extends React.Component<DragAndDropTextProps, DragAndDropTextState> {
  constructor(props: DragAndDropTextProps) {
    super(props);
    this.state = {
      isImageLoaded: false,
      isImageNotFound: false,
      listsOfItems: []
    };
  }

  getPositionDependingImageScale = (image: any, originalListPosition: any, listsOfItems: any) => {
    const scale = image.width / image.naturalWidth;
    listsOfItems.forEach((list: any, index: any) => {
      if (list.position) {
        if (isTouchScreenDevice()) {
          listsOfItems[index].position.y = Math.round(originalListPosition[list.id].y * scale);
          listsOfItems[index].position.x = Math.round(originalListPosition[list.id].x * scale);
        } else {
          listsOfItems[index].position.y = Math.round(
            ((originalListPosition[list.id].y * scale) / image.height) * 100
          );
          listsOfItems[index].position.x = Math.round(
            ((originalListPosition[list.id].x * scale) / image.width) * 100
          );
        }
      }
    });
    return listsOfItems;
  };

  setPositionDependingImageScale = (image?: any, originalListPosition?: any, listsOfItems?: any) => {
    if (image !== null && image !== undefined) {
      this.setState({
        listsOfItems: this.getPositionDependingImageScale(image, originalListPosition, listsOfItems)
      });
    }
  };

  async changeImageState(imageUrl: any) {
    const isImageExists = await imageLoader.isImageExists(imageUrl);
    let imageNotFound = false;
    if (!isImageExists) imageNotFound = true;
    this.setState({
      isImageNotFound: imageNotFound,
      isImageLoaded: true
    });
  }

  handleImageLoaded = (img: any, listsOfItems: any) => {
    const { dropspots = [] } = this.props;
    const originalListPosition: any[] = [];
    dropspots.forEach(item => {
      originalListPosition[item.id] = { y: item.y, x: item.x };
    });
    this.setPositionDependingImageScale(img, originalListPosition, listsOfItems);
    window.addEventListener('resize', () =>
      this.setPositionDependingImageScale(img, originalListPosition, listsOfItems)
    );
    this.changeImageState(this.props.background);
  };

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.setPositionDependingImageScale());
  }

  moveItem(items: any, id: any, action: any) {
    action.dragAndDropAnswersAreSelected(items, id);
  }

  render() {
    const { background, dropspots = [], response = [], id, actions } = this.props;
    const { isImageLoaded, isImageNotFound } = this.state;
    let listsOfItems = [{ id: NO_SELECTED_ID, items: shuffle(dropspots), isDropDisabled: false }];
    if (response.length) {
      listsOfItems = response;
    } else {
      dropspots.forEach(element => {
        const list = {
          id: element.id,
          items: [],
          position: { y: element.y, x: element.x },
          isDropDisabled: false
        };
        listsOfItems.push(list);
      });
    }

    const props = {
      moveItem: this.moveItem,
      actions,
      background,
      id,
      listsOfItems,
      setPositionDependingImageScale: this.setPositionDependingImageScale,
      handleImageLoaded: this.handleImageLoaded,
      isImageLoaded,
      isImageNotFound
    };
    return (
      <div>
        {!isTouchScreenDevice() ? (
          <DragDrop {...props} isMultipleList={true} />
        ) : (
            <DragAndDropMobile {...props} dropspots={dropspots} />
          )}
      </div>
    );
  }
}

function mapStateToProps(state: RootAppState, ownProps: DragAndDropTextProps) {
  return {
    dropspots: getUserAnswers(state, ownProps.id),
    background: getBackground(state, ownProps.id),
    response: getUserResponse(state, ownProps.id)
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    actions: bindActionCreators(dragAndDropActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DragAndDropText);
