import * as React from 'react';
import { localize } from 'core/localization';
import { Collapse } from 'components/common';

import {
  CollapseWrapper,
  CollapseInnerWrapper,
  CollapseButton,
  ButtonText,
  IconCircleObjective,
  IconClose,
  LearningObjectiveText,
  CollapseContent
} from './SectionLearningObjective.styled';

type SectionLearningObjectiveProps = {
  learningObjective: string
};

type SectionLearningObjectiveState = {
  isExpanded: boolean;
}
class SectionLearningObjective extends React.Component<SectionLearningObjectiveProps, SectionLearningObjectiveState> {
  constructor(props: SectionLearningObjectiveProps) {
    super(props);
    this.state = { isExpanded: false };
  }

  toggleExpanded = (event: any) => {
    event.stopPropagation();
    this.setState((prevState: SectionLearningObjectiveState) => ({ isExpanded: !prevState.isExpanded }));
  };

  onKeyDown = (event: any) => {
    if (event.key === ` ` || event.key === 'Enter') {
      this.toggleExpanded(event);
    }
  };

  render() {
    const { isExpanded } = this.state;
    const { learningObjective } = this.props;
    return (
      <React.Fragment>
        <CollapseButton
          tabIndex={1}
          onKeyDown={(event: any) => this.onKeyDown(event)}
          onClick={(event: any) => this.toggleExpanded(event)}
        >
          <IconCircleObjective name="circle-objective" size={11} color="textColor" />
          <ButtonText>
            {localize(isExpanded ? '[hide learning objective]' : '[show learning objective]')}
          </ButtonText>
        </CollapseButton>

        <CollapseWrapper>
          <Collapse isExpanded={isExpanded}>
            <CollapseInnerWrapper>
              <IconClose
                role="button"
                tabIndex={1}
                onKeyDown={(event: any) => this.onKeyDown(event)}
                onClick={(event: any) => this.toggleExpanded(event)}
                name="close"
                size={7}
                color="textColor"
              />
              <LearningObjectiveText appearance={'p'}>
                {localize('[learning objective]')}
              </LearningObjectiveText>
              <CollapseContent appearance={'p'}>{learningObjective}</CollapseContent>
            </CollapseInnerWrapper>
          </Collapse>
        </CollapseWrapper>
      </React.Fragment>
    );
  }
}

export default SectionLearningObjective;
