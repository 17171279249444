import styled from 'styled-components';
import { transparentize } from 'utils/color';
import * as typography from 'components/mixins/typography';
import media from 'components/mixins/media';

export const Wrapper = styled.div`
  max-width: 292px;
  max-height: 362px;

  &::after {
    content: '';
    box-sizing: border-box;
    display: block;
    position: absolute;
    left: 23px;
    right: 23px;
    bottom: 7px;
    height: 3em;
    background: linear-gradient(
      to top,
      ${props => props.theme.colors.contentBodyColor},
      ${props => transparentize(props.theme.colors.contentBodyColor, 0.1)}
    );
    z-index: 2;
  }

  ${media.mobile`
    max-width: 252px;
    max-height: 200px;

    &::after {
      left: 20px;
      right: 20px;
    }
  `};
`;
type TooltipContentProps = {
  id: string;
}
export const TooltipContent = styled.div.attrs((props: TooltipContentProps) => ({
  id: props.id
}))`
  text-align: left;
  overflow: auto;
  max-width: inherit;
  max-height: inherit;
  padding: 25px 23px 0;
  background-color: ${props => props.theme.colors.contentBodyColor};
  color: ${props => props.theme.colors.textColor};
  ${typography.mainFontSmallText};
  line-height: 30px;
  white-space: pre-line;

  &::after {
    content: '';
    display: block;
    height: 3em;
  }

  ${media.mobile`
    padding: 15px 13px 0;
    margin: 0 auto;

    &::after {
    content: '';
    display: block;
    height: 2em;
  }

  `};
`;
