import initialState from 'store/initialState';
import { ActionTypes, SectionsActionTypes } from './types';

export default function sectionsReducer(state = initialState.sections, action: SectionsActionTypes) {
  switch (action.type) {
    case ActionTypes.SECTIONS_LOADED:
      return { ...state, ...action.payload };
    case ActionTypes.SECTION_SCORE_UPDATED:
      state[action.payload.id] = {
        ...state[action.payload.id],
        score: action.payload.score,
        isPassed: action.payload.isPassed
      };
      return { ...state };
    case ActionTypes.SECTION_IS_PASSED:
      state[action.payload.id] = {
        ...state[action.payload.id],
        isPassed: action.payload.isPassed
      };
      return { ...state };
    default:
      return state;
  }
}
