import * as React from 'react';
import { connect } from 'react-redux';
import { localize } from 'core/localization';
import { Icon } from 'components/common';
import { maxCountTocNavigationElements } from 'constants/common';
import CircularProgressbar from 'react-circular-progressbar';
import ProgressPopover from 'components/common/ProgressPopover';
import { getColors } from 'store/settings/selectors';
import { transparentize } from 'utils/color';
import Header from '../Header/Header';
import NavigationBlock from '../NavigationBlock/NavigationBlock';
import NavigationItem, { NavigationLearningItem } from '../NavigationItem/NavigationItem';
import ResultsBlock from '../ResultsBlock/ResultsBlock';

import { CollapsedTocContainer, SectionProgress, StatusIcon } from './CollapsedToc.styled';
import { RootAppState } from 'store/types';

const constants = {
  minimalShownProgress: 10
};

type CollapsedTocProps = {
  isExpanded: boolean;
  courseStructure: {
    title: string;
    sections: any[]
  };
  activeSectionId: string;
  activeQuestionId: string;
  navigateToIndex(): void;
  navigateToQuestion(sectionId: string, questionId: string): void;
  navigateToResults(): void;
  navigateToLearningObjective(id: any): void;
  isResultsActive: boolean;
  isLearningObjectiveView: boolean;
  colors: {[key: string]: any}
};
export class CollapsedToc extends React.Component<CollapsedTocProps, {}> {
  showProgressScore = (score: number) => {
    if (score <= constants.minimalShownProgress) {
      return constants.minimalShownProgress;
    }
    return score;
  };

  render() {
    const {
      courseStructure,
      isExpanded,
      activeQuestionId,
      activeSectionId,
      navigateToIndex,
      navigateToQuestion,
      navigateToResults,
      navigateToLearningObjective,
      isResultsActive,
      isLearningObjectiveView,
      colors
    } = this.props;
    let section: any = null;
    let questions: any[] = [];
    let activeQuestionIndex = -1;

    if (activeSectionId) {
      section = courseStructure.sections.find(item => item.id === activeSectionId) || {};
      questions = (section.questions || []).map((question: any, questionIndex: any) => ({
        key: `${questionIndex}`,
        question,
        isAnsweredCorrectly: question.isAnsweredCorrectly,
        affectsProgress: question.affectsProgress,
        sectionId: section.id
      }));

      activeQuestionIndex = questions.findIndex(item => item.question.id === activeQuestionId);
    }
    return (
      <CollapsedTocContainer isExpanded={isExpanded} ariaLabel={localize('[navigation section]')}>
        <Header collapsed navigateToIndex={navigateToIndex} tabIndex={isExpanded ? -1 : 0} />
        {activeSectionId && (
          <NavigationBlock
            activeItemIndex={activeQuestionIndex}
            navigationElementsCount={questions.length as any + !!section.learningObjective}
            role="menu"
          >
            {section.learningObjective && (
              <NavigationLearningItem
                onClick={() => navigateToLearningObjective(section.id)}
                onKeyDown={event => {
                  if (event.key === 'Enter') {
                    navigateToLearningObjective(section.id);
                  }
                }}
                role="menuitem"
                tabIndex={0}
                isActive={isLearningObjectiveView}
              />
            )}
            {questions.map(item => (
              <NavigationItem
                key={item.key}
                role="menuitem"
                tabIndex={0}
                question={item.question}
                isActive={item.question.id === activeQuestionId}
                {...(item.question.id !== activeQuestionId && {
                  onClick: () => navigateToQuestion(item.sectionId, item.question.id),
                  onKeyDown: event => {
                    if (event.key === 'Enter') {
                      navigateToQuestion(item.sectionId, item.question.id);
                    }
                  }
                })}
              />
            ))}
          </NavigationBlock>
        )}
        {section && section.affectsProgress && (
          <SectionProgress isNavigationExist={maxCountTocNavigationElements < questions.length}>
            <ProgressPopover
              popoverPosition={'right-end'}
              questions={questions}
              score={section.score}
              isPassed={section.isPassed}
              sectionId={section.id}
            />
            {section.isPassed ? (
              <StatusIcon>
                <Icon name="ok" size={6} color="buttonTextColor" />
              </StatusIcon>
            ) : (
              <CircularProgressbar
                strokeWidth={24}
                percentage={this.showProgressScore(section.score)}
                styles={{
                  path: {
                    stroke: colors.mainColor,
                    strokeLinecap: 'butt',
                    transition: 'stroke-dashoffset 0.5s ease 0s'
                  },
                  trail: {
                    stroke: transparentize(colors.textColor, 0.02),
                    transition: 'stroke 0.2s ease 0s'
                  }
                }}
              />
            )}
          </SectionProgress>
        )}
        <ResultsBlock
          ariaLabel={localize('[results]')}
          collapsed
          isActive={isResultsActive}
          navigateToResults={navigateToResults}
          tabIndex={isExpanded ? -1 : 0}
        />
      </CollapsedTocContainer>
    );
  }
}


function mapStateToProps(state: RootAppState) {
  return {
    colors: getColors(state)
  };
}

export default connect(mapStateToProps)(CollapsedToc);
