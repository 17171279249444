import * as React from 'react';
import { localize } from 'core/localization';

import { Container, Title, ResultsIcon } from './ResultsBlock.styled';

type ResultsBlockProps = {
  isActive: boolean;
  collapsed: boolean;
  navigateToResults(): void;
  ariaLabel: string;
  tabIndex: number;
};

export class ResultsBlock extends React.PureComponent<ResultsBlockProps, {}> {
  static defaultProps = {
    isActive: false,
    collapsed: false
  };

  render() {
    const { navigateToResults, isActive, collapsed, ariaLabel, tabIndex } = this.props;
    return collapsed ? (
      !isActive && (
        <ResultsIcon
          ariaLabel={ariaLabel}
          tabIndex={tabIndex}
          {...(!isActive && {
            onClick: navigateToResults,
            onKeyDown: (event: any) => {
              if (event.key === 'Enter') {
                navigateToResults();
              }
            }
          })}
          name="medal"
          size={27}
        />
      )
    ) : (
      <Container
        ariaLabel={ariaLabel}
        isActive={isActive}
        tabIndex={tabIndex}
        onClick={navigateToResults}
      >
        <Title isActive={isActive} appearance="h4" ellipsis>
          {localize('[results]')}
        </Title>
      </Container>
    );
  }
}

export default ResultsBlock;
