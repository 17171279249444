import TableWrapper from './table';
import AudioWrapper from './audio';
import VideoWrapper from './video';
import ImageWrapper from './image';

export default class ContentWrapper {
  static wrap(value: any, theme: any) {
    const finder = document.createElement('div');
    finder.innerHTML = value;

    TableWrapper.wrap(finder);
    ImageWrapper.wrap(finder);
    AudioWrapper.wrap(finder, theme);
    VideoWrapper.wrap(finder, theme);

    return { __html: finder.innerHTML };
  }
}
