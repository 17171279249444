import styled, { css } from 'styled-components';
import { Text, Icon } from 'components/common';
import { transparentize } from 'utils/color';
import { transition, pointerOnHover } from 'components/mixins/interactivity';
import buttonDefaultStyle from 'components/mixins/normalize';
import { defaultBoxShadowColor } from 'constants/common';
import { mainFontText } from 'components/mixins/typography';

type TitleProps = {
  isActive: boolean;
};
export const Title = styled(Text)<TitleProps>`
  ${mainFontText}
  && {
    font-size: 18px;
  }
  text-decoration: none;
  font-style: normal;
  && {
    ${props =>
      !props.isActive &&
      css`
        color: ${transparentize(props.theme.colors.textColor, 0.7)};
        ${transition('color')};
        ${pointerOnHover};
        &:hover,
        &:focus {
          color: ${props.theme.colors.textColor};
        }
      `};
  }
`;

type ContainerProps = {
  tabIndex: number;
  ariaLabel: string;
  isActive: boolean;
};
export const Container = styled.button.attrs((props: ContainerProps) => ({
  tabIndex: props.tabIndex,
  'aria-label': props.ariaLabel
}))<ContainerProps>`
  ${buttonDefaultStyle};
  position: relative;
  height: 57px;
  background: ${props => props.theme.colors.contentBodyColor};
  box-shadow: inset -2px 0 6px 0 ${transparentize(defaultBoxShadowColor, 0.04)};
  width: 100%;
  padding: 0 40px;
  text-align: left;

  &:focus {
    outline: none;
  }
  &:focus ${Title} {
    color: ${props => props.theme.colors.textColor};
  }

  &::after {
    left: 0;
    top: 0;
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    box-shadow: 0 0 10px 0 ${transparentize(defaultBoxShadowColor, 0.1)};
  }

  ${props =>
    props.isActive &&
    css`
      &::before {
        content: '';
        position: absolute;
        width: 2px;
        height: 100%;
        top: 0;
        left: 0;
        background-color: ${props.theme.colors.mainColor};
      }
    `};
`;

type ResultsIconProps = {
  tabIndex: number;
  ariaLabel: string;
  isCollapsed?: boolean;
};
export const ResultsIcon = styled(Icon).attrs((props: ResultsIconProps) => ({
  tabIndex: props.tabIndex,
  role: 'button',
  'aria-label': props.ariaLabel
}))<ResultsIconProps>`
  position: absolute;
  left: 17px;
  bottom: 35px;
  color: ${props => transparentize(props.theme.colors.textColor, 0.4)};
  ${pointerOnHover};
  ${props =>
    props.isCollapsed &&
    css`
      font-size: 25px;
    `};

  &:hover,
  &:focus {
    color: ${props => props.theme.colors.textColor};
  }
  &:focus {
    outline: none;
  }
`;
