import styled from 'styled-components';
import media from 'components/mixins/media';
import { transparentize } from 'utils/color';
import * as typography from 'components/mixins/typography';

const AudioWrapper = styled.div.attrs(props => ({
  'data-test': 'content-audio'
}))`
  [data-type='singleAudio'] {
    width: 100%;
    border: solid 2px ${props => transparentize(props.theme.colors.textColor, 0.08)};
    background: ${props => props.theme.colors.contentBodyColor};

    .audio-editor > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .audio-wrapper {
      margin-top: -26px;
      margin-bottom: -16px;
      margin-left: -20px;
      width: ~'calc(100% + 20px)';
      text-align: center;
    }

    .audio-title {
      ${typography.mainFontText}
      color: ${props => props.theme.colors.textColor};
      padding-bottom: 14px;
      margin-top: -8px;
      font-weight: bold;
      font-size: 20px;
      line-height: 1.8;
    }

    .audio-image {
      width: 200px;
      height: 133px;
      display: block;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      border-radius: 2px;
    }

    .row.audio-editor {
      padding: 30px;
    }

    .audio-image-container {
      margin-right: 30px;
      align-self: flex-start;
    }

    .audio-player-container {
      width: 100%;
    }

    .audio-player-container iframe {
      height: 64px;
      width: 100%;
    }

    ${media.small`
      .audio-image {
        width: 160px;
        height: 107px;
      }
    `};

    ${media.mobile`
      .row.audio-editor {
        padding: 20px;

        & > div {
          flex-direction: column;

          &[data-view-mode='0'] {
            .audio-title {
              text-align: center;
            }
          }
        }

        .audio-image-container {
          margin: 0 auto 20px;

          .audio-image {
            width: 220px;
            height: 133px;
          }

          .audio-title {
            margin-top: -10px;
          }
        }
      }
    `};

    ${media.xsmall`
      .row.audio-editor {
        .audio-image-container {
          .audio-image {
            width: 200px;
          }
        }
      }
    `};
  }
`;

export default AudioWrapper;
