export type TreeOfContentState = {
    isExpanded: boolean;
}

export enum ActionTypes {
    TOC_TOGGLE_VISIBILITY = 'TOC_TOGGLE_VISIBILITY'
}

type ToggleTOCVisibility = {
    type: ActionTypes.TOC_TOGGLE_VISIBILITY
}

export type TOCActionTypes = ToggleTOCVisibility;
