/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React from 'react';
import { localize } from 'core/localization';
import { DropdownButton, PlusIcon } from './DropdownInputButton.styled';

type DropdownInputButtonProps = {
  placeholder: string;
  answeredPlaceholder: string;
  disabled: boolean;
  selectedAnswer: any;
  matchingAnswer: string;
  getToggleButtonProps(options: any): void;
  getLabelProps(): void;
  getRootProps(options1: any, options2?: any): void;
  isOpen: boolean;
  isMatching: boolean;
  isMatchingOption: boolean;
  buttonAnimation: string;
};

export const DropdownInputButton = ({
  placeholder,
  answeredPlaceholder,
  selectedAnswer,
  matchingAnswer,
  disabled,
  getToggleButtonProps, // getToggleButtonProps, getLabelProps, getRootProps is required for correct work of Downshift.js
  getLabelProps,
  getRootProps,
  isOpen,
  isMatching,
  isMatchingOption,
  buttonAnimation
}: DropdownInputButtonProps) => {
  const renderPlusIcon = () =>
    isMatching && !isMatchingOption && <PlusIcon size={10} name="plus" />;

  const buttonClasses = {
    base: `dropdown-button ${!selectedAnswer ? 'empty' : 'dropdown-button'}`,
    matchingOption: isMatchingOption && !matchingAnswer ? 'empty-matching-option' : '',
    animation: buttonAnimation || ''
  };

  const buttonText = () => {
    if (isMatchingOption && matchingAnswer && selectedAnswer) {
      return selectedAnswer;
    }
    if (!isMatchingOption) {
      return selectedAnswer || placeholder;
    }
    if (isOpen) {
      return localize('[choose option]');
    }
    return placeholder;
  };

  return (
    <DropdownButton
      {...getToggleButtonProps({
        'aria-label': !selectedAnswer ? placeholder : answeredPlaceholder + selectedAnswer
      })}
      {...getLabelProps()}
      {...getRootProps({}, { suppressRefError: true })}
      tabIndex={1}
      disabled={disabled}
      className={`${buttonClasses.base} ${buttonClasses.matchingOption} ${buttonClasses.animation}`}
      isMatching={isMatching}
      isMatchingOption={isMatchingOption}
    >
      {buttonText()}
      <span className={isOpen ? 'dropdown-arrow is-open' : 'dropdown-arrow'} />
      {renderPlusIcon()}
    </DropdownButton>
  );
};

export default DropdownInputButton;
