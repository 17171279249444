import React, { Component } from 'react';
import { withTheme } from 'styled-components';
import { TransitionGroup, Transition } from 'react-transition-group';
import { isHomeView } from './viewChecker';
import { useMobileTransition, MOBILE_TRANSITION_TIMEOUT } from './mobile';
import { useDesktopTransition } from './desktop';
import { RouteContainer } from './RouteTransition.styled';

export const DESKTOP_TIMEOUT = 1000;

type RouteTransitionProps = {
  children: any;
  isLowResolution: boolean;
  location: { [key: string]: any };
  theme: { [key: string]: any };
  isTocExpanded: boolean;
  background: { [key: string]: any };

}
export class RouteTransition extends Component<RouteTransitionProps, {}> {
  getTransitionKey = (pathname: any) => {
    if (isHomeView(pathname)) {
      return 'sections';
    }
    return 'courseOverview';
  };

  onEnter = (node: any, isAppearing: any) => {
    const { isLowResolution, location } = this.props;
    if (isLowResolution && isAppearing) {
      return useMobileTransition(node).appear();
    }
    if (isLowResolution) {
      return useMobileTransition(node, location.pathname).enter();
    }
    if (!isLowResolution && isAppearing) {
      return useDesktopTransition(node).appear();
    }
    return useDesktopTransition(node).enter();
  };

  onExit = (node: any) => {
    const { isLowResolution, location, theme, isTocExpanded, background } = this.props;
    if (isLowResolution) {
      return useMobileTransition(node, location.pathname, background).exit();
    }
    return useDesktopTransition(node).exit(location, theme, isTocExpanded, background);
  };

  render() {
    const { children, location, isLowResolution } = this.props;
    return (
      <TransitionGroup component={RouteContainer}>
        <Transition
          in
          appear={true}
          onEnter={this.onEnter}
          onExit={this.onExit}
          key={this.getTransitionKey(location.pathname)}
          timeout={isLowResolution ? MOBILE_TRANSITION_TIMEOUT : DESKTOP_TIMEOUT}
        >
          {children}
        </Transition>
      </TransitionGroup>
    );
  }
}

export default withTheme(RouteTransition);
