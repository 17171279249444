import styled from 'styled-components';
import media from 'components/mixins/media';
import { secondaryFont, mainFontText } from 'components/mixins/typography';
import { Icon, Text, ProgressBar as ProgressComponent } from 'components/common';

export const ProgressBar = styled(ProgressComponent)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

export const SectionContentWrapper = styled.section`
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  height: inherit;
  min-width: 0;
  padding: 24px 30px;
  ${media.large`
    padding: 24px 12px;
  `};
  ${media.mobile`
    margin-top: 25px;
    padding: 0 0 50px;
  `};
`;

export const SectionTitle = styled(Text)`
  height: 85px;
  max-height: 85px;
  ${mainFontText}
  color: ${props => props.theme.colors.textColor};
  text-decoration: none;
  font-style: normal;
  && {
    font-size: 22px;
  }
  line-height: 1.4;
  overflow: hidden;

  ${media.medium`
    font-size: 20px;
  `}

  ${media.mobile`
    height: initial;
    max-height: initial;
    padding: 0 30px 50px;
  `};
`;

export const SectionSubText = styled(Text)`
  font-size: 11px;
`;

export const IconArrowRight = styled(Icon)`
  margin-left: 8px;
`;

export const Footer = styled.footer`
  position: absolute;
  right: 30px;
  left: 30px;
  bottom: 24px;
  display: flex;
  justify-content: flex-end;
  font-weight: 600;
  font-size: 12px;
  ${secondaryFont};
  opacity: 1;
  transition: bottom 0.2s, opacity 0.2s;
  &.hover-state {
    bottom: 0;
    justify-content: flex-end;
    align-items: center;
    opacity: 0;
  }
  ${media.large`
    right: 12px;
  `};
  ${media.mobile`
    right: auto;
    bottom: 60px;
    &.hover-state {
      bottom: -24px;
    }
  `};
`;

type SectionProgressStatusWrapperProps = {
  isPassed: boolean;
}
export const SectionProgressStatusWrapper = styled.div<SectionProgressStatusWrapperProps>`
  display: flex;
  align-items: center;
  color: ${props =>
    props.isPassed ? props.theme.colors.correctColor : props.theme.colors.textColor};
  opacity: ${props => (props.isPassed ? 1 : 0.4)};

  ${SectionSubText} {
    color: ${props =>
    props.isPassed ? props.theme.colors.correctColor : props.theme.colors.textColor};
  }
`;

type SectionProgressStatusIconWrapperProps = {
  isPassed: boolean;
}
export const SectionProgressStatusIconWrapper = styled.span<SectionProgressStatusIconWrapperProps>`
  display: ${props => (props.isPassed ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  font-size: 5px;
  color: ${props => props.theme.colors.buttonTextColor};
  background-color: ${props => props.theme.colors.correctColor};
  border-radius: 50%;
`;
