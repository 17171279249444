import * as React from 'react';
import { Collapse, Icon } from 'components/common';
import { localize } from 'core/localization';
import ProgressPopover from 'components/common/ProgressPopover';

import {
  SectionText,
  SectionItem,
  Header,
  TitleIcon,
  Footer,
  Status,
  StatusIcon,
  StatusText,
  QuestionList,
  ProgressPopoverWrapper
} from './SectionTreeNode.styled';

type SectionTreeNodeProps = {
  title: string;
  isActive: boolean;
  isPassed: boolean;
  score: number;
  affectsProgress?: boolean;
  sectionId: string;
  children: any[];
  ariaLabel?: string;
  tabIndex: number;
  questions: any[];
};

type SectionTreeNodeState = {
  isExpanded: boolean;
}

export default class SectionTreeNode extends React.Component<SectionTreeNodeProps, SectionTreeNodeState> {
  constructor(props: SectionTreeNodeProps) {
    super(props);
    this.state = {
      isExpanded: this.props.isActive
    }
  }

  componentDidUpdate(prevProps: SectionTreeNodeProps) {
    if (this.props.isActive !== prevProps.isActive) {
      this.setState({
        isExpanded: this.props.isActive
      });
    }
  }

  toggleIsExpanded = () => {
    this.setState(prevState => ({
      isExpanded: !prevState.isExpanded
    }));
  };

  render() {
    const {
      title,
      isPassed,
      affectsProgress,
      children,
      tabIndex,
      score,
      questions,
      sectionId
    } = this.props;
    const { isExpanded } = this.state;
    return (
      <SectionItem role="presentation">
        <Header
          ariaLabel={
            isExpanded
              ? `${localize('[close section items]')} ${title}`
              : `${localize('[open section items]')} ${title}`
          }
          tabIndex={tabIndex}
          role="menuitem"
          ariaExpanded={true}
          aria-haspopup={children.length > 0 ? 'true' : 'false'}
          onClick={this.toggleIsExpanded}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              this.toggleIsExpanded();
            }
          }}
        >
          <SectionText appearance="h4" maxLength={35}>
            {title}
          </SectionText>
          <TitleIcon size={5} name={isExpanded ? 'chevron-up' : 'chevron-down'} />
        </Header>
        <Collapse
          isExpanded={isExpanded}
          animateAppear={false}
          duration={200}
          opacityDuration="200"
        >
          <QuestionList role="menu">{children}</QuestionList>
        </Collapse>
        <Footer>
          {affectsProgress && (
            <ProgressPopoverWrapper>
              <ProgressPopover
                popoverPosition={'top'}
                questions={questions}
                score={score}
                isPassed={isPassed}
                sectionId={sectionId}
              />
              <Status>
                {isPassed && (
                  <StatusIcon>
                    <Icon name="ok" size={5} color="correctColor" />
                  </StatusIcon>
                )}
                <StatusText
                  isPassed={isPassed}
                  ellipsis
                  ariaLabel={`${localize('[aria label status text]')} ${title} ${localize(
                    isPassed ? '[passed]' : '[not passed yet]'
                  )}`}
                  tabIndex={-1}
                  role="status"
                >
                  {localize(isPassed ? '[passed]' : '[not passed yet]')}
                </StatusText>
              </Status>
            </ProgressPopoverWrapper>
          )}
        </Footer>
      </SectionItem>
    );
  }
}
