import { STATUS_OK } from 'constants/progressStorage';
import { getQuestionsStructure } from 'store/course/selectors';
import { isPublishMode } from 'store/settings/selectors';
import { RootAppState } from 'store/types';
import { shouldSaveCrossDevice } from 'store/user/selectors';
import { compress, decompress } from 'utils/compression';
import { isObject } from 'utils/object';
import ExternalStorage from './externalStorage';
import idMapper from './idMapper';
import LocalStorage from './localStorage';
import { responseMapper, urlMapper } from './progressMapper';
import StorageInterface from './storageInterface';

const defaultProgress = {
  url: null,
  user: null,
  answers: [],
  finished: false
};

class ProgressStorage extends StorageInterface {
  progress: any;

  storage: any;

  isScormMode: any;

  questionShortIds: any;

  questionsIndexToIdSchema: any;

  constructor() {
    super();
    this.progress = Object.assign({}, defaultProgress);
    this.storage = null;
    this.isScormMode = false;
    this.questionShortIds = null;
    this.questionsIndexToIdSchema = null;
  }

  initialize({ state, isScormMode }: any) {
    this.questionsIndexToIdSchema = getQuestionsStructure(state);
    this.isScormMode = isScormMode;
    if (shouldSaveCrossDevice(state) && isPublishMode(state)) {
      this.storage = new ExternalStorage(state);
      return;
    }

    this.storage = new LocalStorage(state);
  }

  set user(user: any) {
    this.progress.user = user;
  }

  set url(url: any) {
    this.progress.url = url;
  }

  set attemptId(attemptId: any) {
    this.progress.attemptId = attemptId;
  }

  async restoreProgress() {
    let progress = await this.storage.getProgress();
    progress = this.isScormMode ? decompress(progress) : progress;
    if (progress && progress.user && progress.user.email === this.progress.user.email) {
      if (isObject(progress.answers)) {
        progress.answers = this._mapObjectAnswersToArray(progress.answers);
      }
      this.progress = progress;
      return true;
    }
    return false;
  }

  updateQuestionProgress(question: any) {
    const progressIndex = this.questionsIndexToIdSchema.findIndex((id: any) => id === question.id);
    if (progressIndex === undefined) {
      throw new Error('Question does not exist.');
    }

    this.progress.answers[progressIndex] = responseMapper.map(question);
  }

  removeQuestionProgress(questionId: any) {
    const progressIndex = this.questionsIndexToIdSchema.findIndex((id: any) => id === questionId);
    if (progressIndex === undefined) {
      throw new Error('Question does not exist.');
    }

    this.progress.answers.slice(progressIndex, 0);
  }

  async saveProgress(state: RootAppState) {
    if (this.storage && this.storage.saveProgress) {
      const progress = this.isScormMode ? compress(this.progress) : this.progress;
      await this.storage.saveProgress(progress, state);
    }
  }

  async removeProgress() {
    await this.storage.removeProgress();
    this._clearProgress();
  }

  async authorizeUser(userData: any) {
    if (this.storage && this.storage.authorizeUser) {
      return this.storage.authorizeUser(userData);
    }

    return null;
  }

  async registerUser(userData: any) {
    if (this.storage && this.storage.registerUser) {
      return this.storage.registerUser(userData);
    }

    return null;
  }

  async resetPassword(email: any) {
    if (this.storage && this.storage.resetPassword) {
      return this.storage.resetPassword(email);
    }

    return STATUS_OK;
  }

  async logout() {
    if (this.storage && this.storage.logout) {
      await this.storage.logout();
    }

    this._clearProgress();
  }

  async sendSecretLink() {
    if (this.storage && this.storage.sendSecretLink) {
      return this.storage.sendSecretLink();
    }

    return STATUS_OK;
  }

  use(storage: any) {
    this.storage = storage;
  }

  get url() {
    const { url } = this.progress;
    return url ? urlMapper.unMap(url) : url;
  }

  get attemptId() {
    return this.progress.attemptId;
  }

  getAnswers(questions: any) {
    const progressAnswers = this.progress.answers;

    const answers: any[] = [];
    progressAnswers.forEach((answer: any, index: any) => {
      if (answer === null) {
        return;
      }

      const question = this._getProgressedQuestion({ questions, answer, answerIndex: index });
      if (!question) {
        return;
      }

      const processedAnswer = this._unMapProgress(question, answer);
      answers.push(processedAnswer);
    });

    return answers;
  }

  _getProgressedQuestion({ questions, answer, answerIndex }: { [key: string]: any }) {
    let questionId: string;
    if (this._hasFullProgressStructure(answer)) {
      questionId = this.isScormMode ? idMapper.unMap(answer.id) : answer.id;
    } else {
      questionId = this.questionsIndexToIdSchema[answerIndex];
    }

    return questions.find(({ id }: { [key: string]: any }) => id === questionId);
  }

  _unMapProgress(question: any, answer: any) {
    let userResponse;
    if (this._hasFullProgressStructure(answer)) {
      const { type, response } = answer;
      userResponse = this.isScormMode ? idMapper.unMapResponse(type, response) : response;
      this._rewriteAnswerToShortenStructure(question, userResponse);
    } else {
      userResponse = responseMapper.unMap(answer, question);
    }

    return {
      id: question.id,
      response: userResponse
    };
  }

  _hasFullProgressStructure(answer: any) {
    return answer && isObject(answer) && answer.id !== undefined;
  }

  _rewriteAnswerToShortenStructure(question: any, oldStructureResponse: any) {
    let entity = Object.assign({}, question);
    entity.response = oldStructureResponse;
    entity.isAnswered = true;

    this.updateQuestionProgress(entity);
  }

  async identifyUser() {
    if (this.storage && this.storage.identifyUser) {
      return this.storage.identifyUser();
    }

    return null;
  }

  async userExists(email: any) {
    if (this.storage && this.storage.userExists) {
      return this.storage.userExists(email);
    }

    return STATUS_OK;
  }

  getSocialNetworkAuthLink(socialNetwork: any) {
    if (this.storage && this.storage.getSocialNetworkAuthLink) {
      return this.storage.getSocialNetworkAuthLink(socialNetwork);
    }

    return '#';
  }

  isAuthenticated() {
    if (this.storage && this.storage.isAuthenticated) {
      return this.storage.isAuthenticated();
    }

    return null;
  }

  getHeaders({ contentType, bearerToken }: any) {
    if (this.storage && this.storage.getHeaders) {
      return this.storage.getHeaders({ contentType, bearerToken });
    }
    return {};
  }

  _clearProgress() {
    this.progress = Object.assign(defaultProgress, { answers: [] });
  }

  getToken() {
    if (this.storage && this.storage.getToken) {
      return this.storage.getToken();
    }
    return '';
  }

  _mapObjectAnswersToArray(originalAnswers: any) {
    let answers: any[] = [];
    Object.keys(originalAnswers).forEach((answerIndex: any) => {
      answers[answerIndex] = originalAnswers[answerIndex];
    });

    return answers;
  }
}

export default new ProgressStorage();
