import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { getUserAnswers } from 'store/questions/hotspot/selectors';
import * as actions from 'store/questions/hotspot/actions';
import CircleLoader from 'components/common/CircleLoader';
import imageLoader from 'core/http/imageLoader';
import { localize } from 'core/localization';
import { Wrapper, Image, ImageSpotsWrapper, Spot, SpotWrapper, CloseIcon } from './Hotspot.styled';
import { RootAppState } from 'store/types';

type HotspotProps = {
  id: string;
  type: string;
  answers?: { [key: string]: any };
  actions?: { [key: string]: any };
  isAnswered?: boolean;
};

type HotspotState = {
  answers: { [key: string]: any };
  isImageLoading: boolean;
  markers: any;
  isImageNotFound: boolean;
}

export class Hotspot extends PureComponent<HotspotProps, HotspotState> {

  constructor(props: HotspotProps) {
    super(props);
    const { answers = [] } = props;
    this.state = {
      answers,
      isImageLoading: true,
      markers: answers.markers,
      isImageNotFound: false
    };
  }


  static getDerivedStateFromProps(nextProps: HotspotProps, prevState: HotspotProps) {
    if (prevState.answers !== nextProps.answers && (nextProps.answers && !nextProps.answers.markers.length)) {
      return {
        answers: nextProps.answers,
        markers: []
      };
    }
    return null;
  }

  getCoordinates = (event: any) => {
    if (event.target.tagName !== 'IMG') {
      return;
    }
    const rect = event.target.getBoundingClientRect();
    const x = Math.round(event.clientX - rect.left);
    const y = Math.round(event.clientY - rect.top);
    const { id } = this.state.answers;
    this.setState({
      markers: this.addMarker({ x, y })
    });
    this.props.actions && this.props.actions.hotspotQuestionAnswer(this.addMarker({ x, y }), id);
  };

  addMarker = (marker: any) => {
    const { markers } = this.state;
    const { isMultiple } = this.state.answers;
    let newMarkers = Array.from(markers);
    if (!isMultiple) {
      newMarkers = [marker];
    } else {
      newMarkers.push(marker);
    }
    return newMarkers;
  };

  removeMarker = (index: any) => {
    const { id } = this.props;
    const { markers } = this.state;
    const newMarkers = Array.from(markers);
    newMarkers.splice(index, 1);
    this.setState({
      markers: newMarkers
    });
    this.props.actions && this.props.actions.hotspotQuestionAnswer(newMarkers, id);
  };

  async changeImageState(imageUrl: any) {
    const isImageExists = await imageLoader.isImageExists(imageUrl);
    let imageNotFound = false;
    if (!isImageExists) {
      imageNotFound = true;
    }
    this.setState({
      isImageNotFound: imageNotFound,
      isImageLoading: false
    });
  }

  render() {
    const { background } = this.state.answers;
    const { isImageLoading, markers, isImageNotFound } = this.state;
    return (
      <Wrapper>
        {background ? (
          <ImageSpotsWrapper isImageLoading={isImageLoading} isImageNotFound={isImageNotFound}>
            {isImageLoading && <CircleLoader />}
            <Image
              isImageNotFound={isImageNotFound}
              src={background}
              alt={localize('[hotspot content image alt]')}
              tabIndex={1}
              role="img"
              onLoad={() => this.changeImageState(background)}
              onClick={!isImageNotFound ? this.getCoordinates : null}
              style={isImageLoading ? { opacity: '0' } : null}
            />

            {markers.map((marker: any, index: any) => (
              <SpotWrapper
                key={index}
                tabIndex={1}
                role="banner"
                top={marker.y}
                left={marker.x}
                onClick={() => this.removeMarker(index)}
              >
                <Spot />
                <CloseIcon size={4} name="close-popup" />
              </SpotWrapper>
            ))}
          </ImageSpotsWrapper>
        ) : null}
      </Wrapper>
    );
  }
}

function mapStateToProps(state: RootAppState, ownProps: HotspotProps) {
  return {
    answers: getUserAnswers(state, ownProps.id)
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Hotspot);
