import styled, { css } from 'styled-components';
import { pointerOnHover, transition } from 'components/mixins/interactivity';
import { IconElement } from 'components/common/Icon/Icon.styled';
import { transparentize } from 'utils/color';
import { MEDIA_SMALL } from 'constants/screenResolutions';
import { NavigationItemIcon } from '../NavigationItem/NavigationItem.styled';

type ContainerProps = {
  role: string;
}
export const Container = styled.div.attrs((props: ContainerProps) => ({
  role: props.role,
  'data-test': 'navigation-block'
})) <ContainerProps>`
  display: flex;
  justify-content: center;
  position: relative;
  ${NavigationItemIcon} + ${NavigationItemIcon} {
    margin-top: 20px;
  }
  @media (max-height: ${MEDIA_SMALL}px){
    margin-top: 100px;
  }
`;

type ItemsLimiterProps = {
  height: number;
}
export const ItemsLimiter = styled.div<ItemsLimiterProps>`
  max-height: ${props => props.height + 10}px;
  padding: 5px;
  overflow: hidden;
`;

type NavButtonProps = {
  prev?: any;
  next?: any;
}
export const NavButton = styled.button<NavButtonProps>`
  ${pointerOnHover};
  position: absolute;
  padding: 10px;
  height: 30px;
  outline: none;
  background: none;
  border: none;
  width: 100%;
  left: 0;

  ${props => css`
    & ${IconElement} {
      color: ${transparentize(props.theme.colors.textColor, 0.7, {
  solidColor: true
})};
    }
  `};

  &:hover ${IconElement} {
    color: ${props => props.theme.colors.textColor};
  }

  ${props =>
    props.prev &&
    css`
      transform: translate(0, -100%);
      top: -20px;
    `};
  ${props =>
    props.next &&
    css`
      transform: translate(0, 100%);
      bottom: -20px;
    `};
`;

type ItemsProps = {
  topShift: number;
}
export const Items = styled.div<ItemsProps>`
  ${transition('margin-top')};
  margin-top: -${props => props.topShift}px;
`;
