import * as React from 'react';
import { SwitchBlock } from './Switch.styled';

type SwitchContainerProps = {
  children: any;
};

export const SwitchContainer = (props: SwitchContainerProps) => <SwitchBlock>{props.children}</SwitchBlock>;

export default SwitchContainer;
