import styled, { css } from 'styled-components';
import { transparentize } from 'utils/color';
import { Text, Button } from 'components/common';
import media from 'components/mixins/media';
import buttonDefaultStyle from 'components/mixins/normalize';
import { secondaryFont } from 'components/mixins/typography';
import {
  ButtonElement,
  TextElement,
  CheckIcon,
  ButtonWrapper
} from 'components/common/SwitchButton/SwitchButton.styled';

type CheckboxContainerProps = {
  children?: any;
};
export const CheckboxContainer = styled.div<CheckboxContainerProps>`
  user-select: none;
  padding: 0;
  cursor: pointer;

  ${ButtonWrapper} {
    padding: 0;
    align-items: center;

    ${ButtonElement} {
      align-self: center;
      background-color: transparent;
      border: 1px solid ${props => transparentize(props.theme.colors.textColor, 0.2)};
      ${props =>
        props.children &&
        props.children.props.selected &&
        css`
          background-color: ${props.theme.colors.correctColor};
          border-color: ${props.theme.colors.correctColor};
        `};
    }

    ${TextElement} {
      ${secondaryFont};
      font-size: 12px;
      font-weight: normal;
      line-height: normal;
      opacity: 0.7;
    }

    &[role='checkbox'] {
      padding: 3px 17px 3px 35px;
    }
    .show-on-hover {
      left: 14px;
      margin-top: -7px;
      width: 13px;
      height: 13px;
    }

    &:hover,
    &:focus {
      ${TextElement} {
        opacity: 0.8;
      }

      ${ButtonElement} {
        border-color: ${props => props.theme.colors.correctColor};

        ${props =>
          !props.children.props.selected &&
          css`
            ${CheckIcon} {
              display: none;
            }
          `};
      }
    }
  }
`;

export const ButtonSubmit = styled(Button)`
  ${buttonDefaultStyle};
  background-color: ${props => props.theme.colors.mainColor};
  color: ${props => props.theme.colors.buttonTextColor};
  font-weight: 600;
  min-width: 198px;
  max-width: 320px;
  text-overflow: ellipsis;
  ${media.mobile`
    min-width: 160px;
    max-width: 260px;
  `};
  & span {
    font-size: 14px;
  }
  & .icon {
    font-size: 8px;
  }
  &:hover,
  &:focus {
    background-color: ${props => transparentize(props.theme.colors.mainColor, 0.9)};
  }
  &:active {
    background-color: ${props => transparentize(props.theme.colors.mainColor)};
  }
`;

export const ControlContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 73px;
  margin-top: 14px;
  padding: 0 16px;
  line-height: normal;
`;

export const MessageText = styled(Text).attrs(props => {
  return {};
})`
  ${secondaryFont};
  && {
    font-size: 10px;
    font-weight: normal;
    line-height: 1.5;
    color: ${props => props.theme.colors.textColor};
  }
`;

export const MessageTextWrapper = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const LinkWrapper = styled.div``;
