import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { getUserAnswers, getUserResponse } from 'store/questions/textMatching/selectors';
import * as actions from 'store/questions/textMatching/actions';
import shuffle from 'utils/shuffle';
import { localize } from 'core/localization';
import { DropdownInput } from '../../common';
import {
  TextMatchingItem,
  TextMatchingItemKey,
  TextMatchingItemSelected,
  DropdownInputWrapper,
  DropdownInputContainer
} from './TextMatching.styled';
import { RootAppState } from 'store/types';

type TextMatchingProps = {
  id: string;
  type: string;
  answers?: Array<any>;
  actions?: { [key: string]: any };
  response?: Array<any>;
};

type TextMatchingState = {
  isShuffled: boolean;
  answers: Array<any>;
  shuffleAnswersKey: Array<any>;
}
export class TextMatching extends React.Component<TextMatchingProps, TextMatchingState> {
  constructor(props: TextMatchingProps) {
    super(props);
    const { response = [], answers = [] } = props;
    this.state = {
      isShuffled: false,
      answers: response.length ? response : answers,
      shuffleAnswersKey: response.length ? response : answers
    };
  }

  markItemInList = (list: any, selectedKey: any, option: any) => {
    let newList: Array<any> = Array.from(list);
    newList.forEach((item: any, index: any) => {
      if (item.selectedKey === selectedKey) {
        newList[index].selectedKey = '';
      }
      if (item.value === option.value) {
        newList[index].selectedKey = selectedKey;
      }
      if (item.shownSelectedValue === option.value) {
        newList[index].shownSelectedValue = '';
      }
      if (item.key === selectedKey) {
        newList[index].shownSelectedValue = option.value;
      }
    });
    return newList;
  };

  onSelectMatching(selectedKey: any, option: any) {
    const { answers, shuffleAnswersKey } = this.state;
    const { id } = this.props;
    if (option) {
      this.setState({
        answers: this.markItemInList(answers, selectedKey, option),
        shuffleAnswersKey: this.markItemInList(shuffleAnswersKey, selectedKey, option)
      });
      this.props.actions && this.props.actions.matchingAnswersAreSelected(answers, id);

      const activeElem = document.activeElement as HTMLElement;
      if (activeElem) {
        activeElem.blur();
      }
    }
  }

  isAnyValueSelected() {
    const { answers } = this.state;
    return answers
      .map(answer => answer && answer.selectedKey && answer.selectedKey.length)
      .some(value => value);
  }

  componentDidUpdate() {
    const { response = [], answers = [] } = this.props;
    if (!this.state.isShuffled) {
      this.setState({
        isShuffled: true,
        answers: shuffle(response.length ? response : answers),
        shuffleAnswersKey: shuffle(this.state.answers)
      });
    }
  }

  renderDropdownInput(data: any) {
    const {
      index,
      item,
      inputData,
      position = 'right',
      placeholder,
      buttonAnimation = '',
      isMatchingOption = false
    } = data;
    return (
      <DropdownInput
        popoverPosition={position}
        isMatching
        isMatchingOption={isMatchingOption}
        key={item.id + index}
        name={item.id + index}
        onChange={(event: any) => this.onSelectMatching(item.key, event)}
        value={''}
        placeholder={placeholder}
        answeredPlaceholder={`${localize('[label your choice is]')}`}
        matchingAnswer={item.shownSelectedValue}
        data={inputData}
        disabled={false}
        buttonAnimation={buttonAnimation}
      >
        {' '}
      </DropdownInput>
    );
  }

  render() {
    const { answers, shuffleAnswersKey } = this.state;
    return (
      <div data-test="text-matching">
        {shuffleAnswersKey.map((item, index) => {
          const mapAnswersToOptions = () =>
            answers.map(option => ({
              value: option.value || '',
              selected: option.selectedKey,
              current: option.selectedKey === item.key
            }));

          const buttonAnimation = index === 0 && !this.isAnyValueSelected() ? 'pulse' : '';
          const placeholders = {
            plus: `${localize('[placeholder dropdown for]')} ${item.key}`,
            matchingOption: `${localize('[click on the plus to define the match]')}`
          };

          return (
            <TextMatchingItem key={item.id}>
              <TextMatchingItemKey>{item.key}</TextMatchingItemKey>
              <DropdownInputWrapper>
                <DropdownInputContainer>
                  {this.renderDropdownInput({
                    index,
                    item,
                    inputData: mapAnswersToOptions(),
                    placeholder: placeholders.plus,
                    buttonAnimation
                  })}
                </DropdownInputContainer>
              </DropdownInputWrapper>
              <TextMatchingItemSelected shownSelectedValue={item.shownSelectedValue}>
                {this.renderDropdownInput({
                  index,
                  item,
                  inputData: mapAnswersToOptions(),
                  position: 'bottom',
                  placeholder: placeholders.matchingOption,
                  isMatchingOption: true
                })}
              </TextMatchingItemSelected>
            </TextMatchingItem>
          );
        })}
      </div>
    );
  }
}



function mapStateToProps(state: RootAppState, ownProps: TextMatchingProps) {
  return {
    answers: getUserAnswers(state, ownProps.id),
    response: getUserResponse(state, ownProps.id)
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TextMatching);
