import styled from 'styled-components';
import { transparentize } from 'utils/color';
import media from 'components/mixins/media';
import buttonDefaultStyle from 'components/mixins/normalize';

type SwitchOptionProps = {
  role: string;
  tabIndex: number;
  selected: any;
  ariaDisable: boolean;
  ariaLabel: string;
  ariaChecked: boolean;
}

export const SwitchOption = styled.button.attrs((props: SwitchOptionProps) => ({
  role: props.role,
  'aria-disabled': props.ariaDisable,
  'aria-label': props.ariaLabel,
  'aria-checked': props.ariaChecked,
  tabIndex: props.tabIndex
})) <SwitchOptionProps>`
  ${buttonDefaultStyle};
  flex-shrink: 0;
  align-self: self-start;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 29px;
  width: 30px;
  height: 30px;
  background-color: ${props =>
    props.selected
      ? props.theme.colors.mainColor
      : transparentize(props.theme.colors.textColor, 0.06)};
  border-radius: 50%;
  cursor: pointer;

  &:before {
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    background-color: ${props =>
    props.selected
      ? props.theme.colors.buttonTextColor
      : transparentize(props.theme.colors.textColor, 0.01)};
    border-radius: 50%;
  }
  &:hover,
  &:focus {
    &:before {
      background-color: ${props =>
    props.selected
      ? props.theme.colors.buttonTextColor
      : transparentize(props.theme.colors.textColor, 0.2)};
    }
  }

  ${media.mobile`
    margin-bottom: 16px;
    margin-right: 0;
    &:first-of-type {
      margin-right: 16px;
    }
    &:last-of-type {
      margin-left: 16px;
      margin-right: 6px;
    }
  `};
`;

export default SwitchOption;
