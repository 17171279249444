import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { localize } from 'core/localization';
import { RootAppState } from 'store/types';
import { Button } from 'components/common';
import { isScormMode, getLearnServiceUrl, isPreviewMode } from 'store/settings/selectors';
import * as navigationActions from 'store/navigation/actions';
import { TextElement, Wrapper, ButtonsWrapper } from './CloseCourse.styled';
import { isLtiInitialized } from 'store/modules/selectors';

type CloseCourseProps = {
  scormMode: boolean;
  isLti: boolean;
  previewMode: boolean;
  learnServiceUrl?: string;
  navigationActions: { [key: string]: any };
};

export class CloseCourse extends React.PureComponent<CloseCourseProps, {}> {
  render() {
    const { scormMode, navigationActions, learnServiceUrl, previewMode, isLti } = this.props;
    return (
      <Wrapper>
        <TextElement appearance="span">{isLti ? localize('[close lti course text]') : localize('[close course text]')}</TextElement>
        {!scormMode && !previewMode && !isLti && (
          <ButtonsWrapper>
            <Button onClick={() => navigationActions.goToUrl(learnServiceUrl)}>
              {localize('[my courses]')}
            </Button>
          </ButtonsWrapper>
        )}
      </Wrapper>
    );
  }
}

function mapStateToProps(state: RootAppState) {
  return {
    learnServiceUrl: getLearnServiceUrl(state),
    scormMode: isScormMode(state),
    isLti: isLtiInitialized(state),
    previewMode: isPreviewMode(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    navigationActions: bindActionCreators(navigationActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CloseCourse);
