import React, { useState, useEffect } from 'react';
import { withTheme } from 'styled-components';
import LinkCurationContentParser from './LinkCurationContentParser';
import {
  LinkCurationContainer,
  LinkContentWrapper,
  LinkImage,
  ImageWrapper,
  ImageContainer,
  CustomDescription,
  Title,
  TextWrapper,
  Text,
  StaticLink,
  LinkIcon,
  StaticLinkWrapper
} from './LinkCuration.styled';

type LinkCurationProps = {
  content: string,
  theme: { [key: string]: any }
};

export const LinkCuration = ({ content }: LinkCurationProps) => {
  const [data] = useState(LinkCurationContentParser(content));
  const { title, text, imgUrl, imgClasses, resourceUrl, customDescription } = data;
  let descriptionNode: any = React.createRef();

  useEffect(() => {
    descriptionNode.current && descriptionNode.current.appendChild(customDescription);
  }, [descriptionNode, customDescription]);

  return (
    <LinkCurationContainer>
      {customDescription && (
        <CustomDescription
          ref={descriptionNode}
        ></CustomDescription>
      )}
      <LinkContentWrapper href={resourceUrl} target="_blank">
        <ImageContainer>
          <ImageWrapper className={imgClasses}>
            <LinkImage src={imgUrl} alt={title} title={title} />
          </ImageWrapper>
        </ImageContainer>
        <TextWrapper>
          <Title title={title}>{title}</Title>
          <Text title={text}>{text}</Text>
          <StaticLinkWrapper title={resourceUrl}>
            <LinkIcon size={12} name="link" />
            <StaticLink>{resourceUrl}</StaticLink>
          </StaticLinkWrapper>
        </TextWrapper>
      </LinkContentWrapper>
    </LinkCurationContainer>
  );
};

export default withTheme(LinkCuration);
