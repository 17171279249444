import styled, { css } from 'styled-components';
import buttonDefaultStyle from 'components/mixins/normalize';
import { transparentize } from 'utils/color';
import media from 'components/mixins/media';
import { defaultBoxShadowColor } from 'constants/common';
import { Footer, SectionSubText } from '../SectionContent/SectionContent.styled';

export const SectionsList = styled.ul.attrs(props => ({
  'data-test': 'sections-list'
}))`
  display: flex;
  flex-flow: column nowrap;
`;

type SectionsListItemProps = {
  isDummy?: boolean;
}
export const SectionsListItem = styled.li<SectionsListItemProps>`
  position: relative;
  bottom: 0;
  width: 100%;
  max-width: 880px;
  margin: 0 auto 40px;
  padding: 0;

  ${media.mobile`
    height: auto;
    padding: 0 30px;
  `};

  ${props =>
    props.isDummy &&
    css`
      background-color: ${props.theme.colors.textColor};
      opacity: 0.02;
      ${media.mobile`
        display: none;
      `};
    `};

  ${props =>
    !props.isDummy &&
    css`
      transition: bottom 0.1s linear, box-shadow 0.1s linear;
      &:hover {
        bottom: 5px;
        box-shadow: 0 2px 20px 0 ${transparentize(defaultBoxShadowColor, 0.1)});
      }
    `};
  & > .reviewable-container {
    width: 100%;
  }
`;

type SectionsButtonProps = {
  tabIndex: number;
  ariaLabel: string;
  isDummy: boolean;
  isPassed: boolean;
}
export const SectionsButton = styled.button.attrs((props: SectionsButtonProps) => ({
  tabIndex: props.tabIndex,
  'aria-label': props.ariaLabel,
  disabled: props.isDummy,
  'aria-hidden': props.isDummy
})) <SectionsButtonProps>`
  ${buttonDefaultStyle};
  position: relative;
  bottom: 0;
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 880px;
  height: 140px;
  margin: 0 auto;
  padding: 0;
  background-color: ${props => props.theme.colors.textColor};
  text-align: left;
  opacity: 0.02;

  &:last-child {
    margin-bottom: 0;
  }
  ${media.mobile`
    align-items: initial;
    flex-direction: column;
    height: auto;
    padding: 30px 0 0;
  `};

  ${props =>
    props.isDummy &&
    media.mobile`
      display: none;
    `};

  ${props =>
    !props.isDummy &&
    css`
      box-shadow: 0 2px 10px 0 ${transparentize(defaultBoxShadowColor, 0.1)};
      transition: bottom 0.1s linear, box-shadow 0.1s linear;
      background-color: ${props.theme.colors.contentBodyColor};
      opacity: 1;
      cursor: pointer;
      ${SectionSubText} {
        color: ${props.isPassed ? props.theme.colors.correctColor : props.theme.colors.textColor};
      }
      &:hover ${Footer}, &:focus ${Footer} {
        bottom: 0;
        opacity: 0;
        &.hover-state {
          bottom: 24px;
          opacity: 1;
        }
        ${media.mobile`
          bottom: 60px;
          opacity: 1;
          &.hover-state {
            opacity: 0;
            bottom: 0;
          }
        `};
      }
    `};
`;
