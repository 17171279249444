import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import progressStorage from 'core/progressStorage';
import { INDEX_PATH } from 'constants/navigation';

type RouteWithRedirectProps = {
  component: any;
  shouldRedirect: boolean;
  history?: {[key: string]: any};
  exact: any;
  path: any;
};
export const RouteWithRedirect = ({ component: Component, shouldRedirect, ...rest }: RouteWithRedirectProps) => (
  <Route
    {...rest}
    render={props => {
      const routeTo = progressStorage.url || INDEX_PATH;
      let canRedirect = props.history.location.pathname !== routeTo;
      return shouldRedirect && canRedirect ? <Redirect to={routeTo} /> : <Component {...props} />;
    }}
  />
);

export default RouteWithRedirect;
