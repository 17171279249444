import styled, { css } from 'styled-components';
import { transition, bounce } from 'components/mixins/interactivity';
import { transparentize } from 'utils/color';
import media from 'components/mixins/media';
import Icon from 'components/common/Icon';
import Text from 'components/common/Text';
import buttonDefaultStyle from 'components/mixins/normalize';
import { secondaryFont } from 'components/mixins/typography';

type InputElement = {
  type: string;
  name: string;
  placeholder: string;
  tabIndex: number;
  id: string;
  onInvalid(): void;
  autoComplete: string;
  pattern?: string;
  validationStatusBeforeSubmit: boolean;
};
export const InputElement = styled.input.attrs((props: InputElement) => ({
  type: props.type,
  name: props.name,
  placeholder: props.placeholder,
  tabIndex: props.tabIndex,
  id: props.id,
  onInvalid: props.onInvalid,
  autoComplete: props.autoComplete,
  pattern: props.pattern,
  required: 'required'
}))<InputElement>`
  ${secondaryFont};
  position: relative;
  top: 0;
  left: 0;
  border-radius: 30px;
  font-weight: normal;
  font-size: 14px;
  width: 320px;
  height: 50px;
  border-radius: 30px;
  box-shadow: inset 0 2px 0 0 ${props => transparentize(props.theme.colors.textColor, 0.08)};
  background-color: ${props => transparentize(props.theme.colors.textColor, 0.02)};
  padding: 16px 40px 16px 24px;
  border: none;
  color: ${props => props.theme.colors.textColor};
  &::placeholder {
    color: transparent;
  }
  &:focus {
    box-shadow: inset 0 2px 0 0 ${props => transparentize(props.theme.colors.mainColor, 0.1)};
    border: solid 1px ${props => props.theme.colors.mainColor};
    background-color: ${props => props.theme.colors.contentBodyColor};
    outline: none;
  }
  &:focus::placeholder {
    color: ${props => transparentize(props.theme.colors.textColor, 0.7)};
  }
  ${media.mobile`
    width: 260px;
    height: 44px;
  `};
  &.full:invalid {
    background-color: ${props => transparentize(props.theme.colors.textColor, 0.02)};
    box-shadow: inset 0 2px 0 0 ${props => transparentize(props.theme.colors.incorrectColor, 0.06)};
    border: solid 1px ${props => props.theme.colors.incorrectColor};
    ${bounce};
  }
  &.full:valid {
    background-color: ${props => props.theme.colors.contentBodyColor};
    box-shadow: inset 0 2px 0 0 ${props => transparentize(props.theme.colors.incorrectColor, 0.1)};
    border: solid 1px ${props => props.theme.colors.correctColor};
  }
  ${props =>
    props.validationStatusBeforeSubmit === false &&
    css`
      & {
        background-color: ${transparentize(props.theme.colors.textColor, 0.02)};
        box-shadow: inset 0 2px 0 0 ${transparentize(props.theme.colors.incorrectColor, 0.06)};
        border: solid 1px ${props.theme.colors.incorrectColor};
        ${bounce};
      }
    `};
`;

export const InputLabel = styled.label`
  box-sizing: border-box;
  line-height: 1;
  position: absolute;
  padding: 16px 24px;
  top: -30px;
  left: 0;
  font-size: 10px;
  color: ${props => transparentize(props.theme.colors.textColor, 0.7)};
  ${InputElement}.is-empty + & {
    top: 0;
    font-size: 14px;
  }
  ${transition('top')};
  ${InputElement}.changed + &,
  ${InputElement}:focus + &,
  ${InputElement}:valid + & {
    top: -30px;
    font-size: 10px;
  }

  ${InputElement}:-webkit-autofill + &{
    top: -30px;
    font-size: 10px;
  }
`;

export const ValidIcon = styled(Icon)`
  color: ${props => props.theme.colors.correctColor};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 22px;
  visibility: hidden;
  ${InputElement}:invalid ~ & {
    visibility: hidden;
  }
  ${InputElement}:valid ~ & {
    visibility: visible;
  }
`;

export const ValidationMessageBlock = styled.div`
  display: flex;
  position: absolute;
  left: 25px;
  top: 55px;
  visibility: hidden;
  &.show {
    visibility: visible;
  }
`;

export const TextError = styled<any>(Text)`
  && {
    color: ${props => props.theme.colors.incorrectColor};
    position: relative;
    font-size: 10px;
    visibility: hidden;
    margin-right: 10px;
    line-height: 1;
    ${ValidationMessageBlock}.show & {
      visibility: visible;
      color: ${props => transparentize(props.theme.colors.textColor, 0.5)};
      margin-right: 15px;
    }
    ${ValidationMessageBlock}.show & .icon {
      color: ${props => transparentize(props.theme.colors.textColor, 0.5)};
      margin-right: 5px;
    }
    & .icon {
      color: ${props => props.theme.colors.incorrectColor};
    }
    &.active {
      visibility: visible;
    }
    ${ValidationMessageBlock}.show &.valid, ${ValidationMessageBlock}.show &.valid .icon {
      color: ${props => props.theme.colors.correctColor};
    }
    ${ValidationMessageBlock}.show &.invalid, ${ValidationMessageBlock}.show &.invalid .icon {
      color: ${props => props.theme.colors.incorrectColor};
    }
  }
`;

type EyeIconProps = {
  role: string;
  tabIndex: number;
  aiaLabel?: string;
  name: string;
  onClick: any;
  onKeyDown: any;
};
export const EyeIcon = styled(Icon).attrs((props: EyeIconProps) => ({
  role: props.role,
  tabIndex: props.tabIndex,
  'aria-label': props.aiaLabel
}))<EyeIconProps>`
  ${buttonDefaultStyle};
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  color: ${props => transparentize(props.theme.colors.textColor, 0.7)};
  font-size: 11px;
  cursor: pointer;
  &.icon-eye-close {
    font-size: 13px;
  }
  &:hover,
  &:focus {
    color: ${props => props.theme.colors.textColor};
  }
`;

type InputContainerProps = {
  isUserExist: boolean;
};
export const InputContainer = styled.div<InputContainerProps>`
  ${secondaryFont};
  position: relative;
  font-weight: normal;
  font-size: 14px;
  &.invalid-input ${InputElement} {
    background-color: ${props => transparentize(props.theme.colors.textColor, 0.02)};
    box-shadow: inset 0 2px 0 0 ${props => transparentize(props.theme.colors.incorrectColor, 0.06)};
    border: solid 1px ${props => props.theme.colors.incorrectColor};
    ${bounce};
  }
  ${props =>
    props.isUserExist === true &&
    css`
      ${InputElement}.full:valid {
        background-color: ${transparentize(props.theme.colors.textColor, 0.02)};
        box-shadow: inset 0 2px 0 0 ${transparentize(props.theme.colors.incorrectColor, 0.06)};
        border: solid 1px ${props.theme.colors.incorrectColor};
        ${bounce};
      }
      ${InputElement}:valid ~ ${ValidIcon} {
        visibility: hidden;
      }
      ${TextError} {
        visibility: visible;
      }
      ${ValidationMessageBlock} {
        visibility: visible;
      }
    `};
`;
