import initialState, { getInitialQuestionResponse } from 'store/initialState';

import responseReducers from './responseReducers';
import { QuestionActionTypes, ActionTypes, QuestionsState } from './types';

export default function questionReducer(
  state: QuestionsState = initialState.questions,
  action: QuestionActionTypes
) {
  switch (action.type) {
    case ActionTypes.QUESTIONS_LOADED:
      return { ...state, ...action.payload };
    case ActionTypes.QUESTION_ANSWERED:
    case ActionTypes.QUESTION_PROGRESS_RESTORE:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          isAnswered: true,
          score: action.payload.score,
          isAnsweredCorrectly: action.payload.score === 100
        }
      };
    case ActionTypes.QUESTION_OPENED:
      return {
        ...state,
        [action.payload.questionId]: {
          ...state[action.payload.questionId],
          hasBeenOpened: action.payload.hasBeenOpened
        }
      };
    case ActionTypes.QUESTION_PROGRESS_RESET:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          isAnswered: false,
          isAnsweredCorrectly: false,
          score: 0,
          response: getInitialQuestionResponse(action.payload.type)
        }
      };
    case ActionTypes.QUESTION_RESTORE_RESPONSE:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          response: action.payload.response,
          hasBeenOpened: true
        }
      };
    case ActionTypes.FEEDBACK_ANIMATING:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          isFeedbackAnimating: action.payload.isFeedbackAnimating
        }
      };
    default:
      return responseReducers(state, action);
  }
}
