import styled from 'styled-components';
import { transparentize } from 'utils/color';
import { transition } from 'components/mixins/interactivity';
import { secondaryFont } from 'components/mixins/typography';

type ListItemElementsProps = {
  ariaCurrent: string | boolean;
};
export const ListItemElements = styled.li.attrs((props: ListItemElementsProps) => ({
  'aria-current': props.ariaCurrent
}))<ListItemElementsProps>`
  ${secondaryFont};
  font-size: 14px;
  font-weight: normal;
  overflow: visible;
  margin: 0 21px;
  & a {
    display: inline-block;
    text-decoration: none;
    color: ${props => transparentize(props.theme.colors.textColor, 0.7)};
  }
  &.active a {
    color: ${props => props.theme.colors.mainColor};
  }
  &:hover {
    cursor: pointer;
  }
  & > a:focus {
    outline: none;
  }
  &::after {
    content: '';
    position: relative;
    top: 16px;
    display: block;
  }
  &.active {
    pointer-events: none;
  }
  &.inactive::after {
    width: 0;
    left: 50%;
    border-top: 1px solid ${props => transparentize(props.theme.colors.textColor, 0.5)};
    ${transition('width, left')};
  }
  &.inactive:hover::after {
    width: 100%;
    left: 0;
  }
  &.active::after {
    width: 100%;
    border-top: 1px solid ${props => props.theme.colors.mainColor};
  }
`;

export const ListElement = styled.ul`
  display: flex;
`;

export const LinksWrapper = styled.nav`
  display: flex;
  justify-content: center;
`;
