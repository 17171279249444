export type SectionsState = {
    [key: string]: {
        [key: string]: any
    }
}


export enum ActionTypes {
    SECTIONS_LOADED = 'SECTIONS_LOADED',
    SECTION_SCORE_UPDATED = 'SECTION_SCORE_UPDATED',
    SECTION_IS_PASSED = 'SECTION_PASSED'
}

type SectionScoreUpdated = {
    [key: string]: any,
    type: ActionTypes.SECTION_SCORE_UPDATED,
    payload: {
        id: string,
        score: number,
        isPassed: boolean
    }
}

type SectionPassed = {
    [key: string]: any,
    type: ActionTypes.SECTION_IS_PASSED,
    payload: {
        id: string,
        isPassed: boolean
    }
}

type SectionLoaded = {
    type: ActionTypes.SECTIONS_LOADED,
    payload: {
        [sections: string]: any
    }
}

export type SectionsActionTypes = SectionLoaded | SectionPassed | SectionScoreUpdated;

