export type AppState = {
    isLoading: boolean;
    isLoaded: boolean;
    isLoadingFailed: boolean;
    isLowResolution: boolean;
}

export enum ActionTypes {
    APP_LOADED = 'APP_LOADED',
    APP_LOADING_FAILED = 'APP_LOADING_FAILED',
    APP_RESOLUTION_CHANGED = 'APP_RESOLUTION_CHANGED'
}

type AppLoadedAction = {
    type: ActionTypes.APP_LOADED;
    payload?: { [key: string]: any }
}

type AppResolutionChangedAction = {
    type: ActionTypes.APP_RESOLUTION_CHANGED;
    payload: {
        isLowResolution: boolean
    }
}

type AppLoadingFailed = {
    type: ActionTypes.APP_LOADING_FAILED;
    reason: any;
    payload?: { [key: string]: any }
}

export type AppActionTypes =  AppLoadedAction | AppResolutionChangedAction | AppLoadingFailed;
