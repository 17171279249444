import requester from './requester';

class ResourceLoader {
  expires: any;
  constructor() {
    this.expires = +new Date();
  }

  set cacheBuster(cacheBuster) {
    if (!cacheBuster) {
      return;
    }
    this.expires = +Date.parse(cacheBuster);
  }

  get cacheBuster() {
    return this.expires;
  }

  getLocalResource(relativeUrl: string) {
    let { expires } = this;
    return requester.get(`${relativeUrl}`, {
      cacheBuster: expires
    });
  }

  async getLocalJsonResource(relativeUrl: string) {
    const response = await this.getLocalResource(relativeUrl);
    const responseText = await response.text(); // fix for preview
    if (!responseText || responseText.length === 0) {
      return {};
    }

    return JSON.parse(responseText);
  }
}

export default new ResourceLoader();
