import styled from 'styled-components';
import * as typography from 'components/mixins/typography';
import { transparentize } from 'utils/color';
import media from 'components/mixins/media';

export const AnswerContentWrapper = styled.div`
  ${typography.secondaryFontText};
  color: ${props => props.theme.colors.textColor};
  padding: 0;
  line-height: 60px;

  .dropdown > div {
    display: flex !important;
  }

  h1 {
    ${typography.mainFontH1};
  }
  h2 {
    ${typography.mainFontH2};
  }
  h3 {
    ${typography.mainFontH3};
  }
  h4 {
    ${typography.mainFontH4};
  }

  mark {
    ${typography.highlighted};
  }

  cite {
    display: block;
    margin: 54px 0 54px 30px;
    padding-left: 30px;
    border-left: 2px solid ${props => props.theme.colors.mainColor};
    ${typography.quote};
    ${media.mobile`
      margin: 44px 0;
      padding-left: 0;
      border-left: 0;
      text-align: center;
      &:before{
        content: '';
        display: block;
        width: 25%;
        height: 2px;
        margin: 0 auto 20px;
        background-color: ${(props: any) => props.theme.colors.mainColor};
      }
    `};
  }

  ol {
    list-style-type: decimal;
  }

  ul {
    list-style-type: disc;
  }

  b {
    font-weight: bolder;
  }

  em {
    font-style: italic;
  }

  u {
    text-decoration: underline;
  }

  sub {
    vertical-align: sub;
    font-size: smaller;
  }

  sup {
    vertical-align: super;
    font-size: smaller;
  }

  p {
    cite {
      img {
        &[align='left'],
        &[style*='float: left'],
        &[style*='float:left'],
        &[align='right'],
        &[style*='float: right'],
        &[style*='float:right'] {
          margin: auto 15px;
        }
      }
    }

    img {
      display: block;
      max-width: 100%;
      height: auto !important;
      margin: 15px auto;

      &[align='left'],
      &[style*='float: left'],
      &[style*='float:left'],
      &[align='right'],
      &[style*='float: right'],
      &[style*='float:right'] {
        margin: 15px;
        max-width: 60%;
      }

      ${media.xsmall`
        &[align='left'],
        &[align='right'],
        &[style*='float'] {
          margin: 15px auto;
          max-width: 100%;
        }
      `}
    }
  }

  .fib-table-wrapper {
    display: block;
    overflow: auto;
    text-align: center;
  }

  table {
    ${typography.mainFont};
    table-layout: fixed;
    margin: 15px auto;
    border: none;
    border-collapse: collapse;
    text-align: left;
    display: inline-table;
    line-height: normal;
    vertical-align: top;

    &[align='center'] {
      margin: 15px auto;
    }

    input {
      min-width: 0;
      max-width: 100%;
    }

    caption {
      margin-bottom: 16px;
      ${typography.mainFontH4};
    }

    thead {
      tr {
        th {
          padding: 13px 15px 11px;
          background-color: ${props => props.theme.colors.mainColor};
          color: ${props => props.theme.colors.contentBodyColor};
        }
      }
    }
    tbody {
      tr {
        td,
        th {
          padding: 21px 15px 21px;
          border-bottom: 1px solid ${props => transparentize(props.theme.colors.textColor, 0.08)};
          white-space: normal;
          word-break: break-word;
          overflow: hidden;
        }

        th {
          background-color: ${props => props.theme.colors.mainColor};
          color: ${props => props.theme.colors.contentBodyColor};
        }
      }
    }
  }
`;

export const AnswerContainer = styled.div``;
