import styled, { css } from 'styled-components';
import Icon from 'components/common/Icon';
import { transparentize } from 'utils/color';
import { secondaryFont } from 'components/mixins/typography';
import Text from 'components/common/Text';
import { centerAbsoluteElement } from 'components/mixins/placement';

type PopoverContentWrapperProps = {
  ariaModal: boolean;
}
export const PopoverContentWrapper = styled.div<PopoverContentWrapperProps>`
  ${secondaryFont};
  line-height: 2;
  padding: 4px 6px 6px;
  text-align: center;
  outline: none;
  color: ${props => {
    return props.theme.colors.textColor
  }};
  font-size: 14px;
  background: ${props => props.theme.colors.contentBodyColor};
  max-height: 70vh;
  overflow: hidden;
`;

export const PopoverContent = styled.div`
  width: 240px;
  min-height: 310px;
  padding: 35px 20px 19px;
`;

export const ClosePopoverIcon = styled(Icon)`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 24px;
  color: ${props => transparentize(props.theme.colors.textColor, 0.2)};
  transition: 0.2s;

  &:hover {
    color: ${props => props.theme.colors.textColor};
  }
`;

type QuestionsProgressProps = {
  isPassed: boolean;
}
export const QuestionsProgress = styled.div<QuestionsProgressProps>`
  width: 94px;
  height: 94px;
  margin: 0 auto 30px;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 1px;
    height: 4px;
    background: ${props => props.theme.colors.textColor};
    position: absolute;
    top: -1px;
    left: 50%;
  }

  ${props =>
    props.isPassed &&
    css`
      .CircularProgressbar-path {
        stroke: ${props.theme.colors.correctColor} !important;
      }
    `};
`;

export const CongratsMessageWrapper = styled.div``;

export const CongratsMessageTitle = styled(Text)`
  margin-bottom: 6px;
`;

export const NotPassedMessageWrapper = styled.div``;

export const PopoverButton = styled.button.attrs({
  'data-test': 'popover-button'
})`
  cursor: pointer;
  outline: none;
  background: none;
  border: none;
  width: 100%;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;

export const QuestionProgressText = styled.div`
  position: absolute;
  ${centerAbsoluteElement};
  font-size: 24px;
`;
