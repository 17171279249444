import styled, { css, keyframes } from 'styled-components';
import { ShapedIcon } from 'components/common';
import { pointerOnHover, transition } from 'components/mixins/interactivity';

export const scaleAnimation = keyframes`
  0% { transform: scale(0.5); }
  100% { transform: scale(1); }
`;

type NavigationItemIconProps = {
  role: string;
  ariaLabel:string;
  tabIndex: number;
  isActive?: boolean;
}
export const NavigationItemIcon = styled(ShapedIcon).attrs((props: NavigationItemIconProps) => ({
  role: props.role,
  tabIndex: props.tabIndex,
  'aria-label': props.ariaLabel
}))<NavigationItemIconProps>`
  flex-shrink: 0;
  ${transition('border, background-color, transform')};
  &:focus {
    border: none;
    outline: none;
  }
  ${props =>
    !props.isActive &&
    css`
      ${pointerOnHover};
      /* &:active {
        animation: ${scaleAnimation} 0.2s;
      } */
    `};
`;
