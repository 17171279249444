import * as React from 'react';
import { connect } from 'react-redux';
import { Text, Separator } from 'components/common';
import { getCourseTitle, getCourseIntroductions, getScore } from 'store/course/selectors';
import {
  getMasteryScoreValue,
  isOverallMasteryScore,
  getAlternativeLogo
} from 'store/settings/selectors';
import { getAffectProgressSectionsCount, getSectionsPassedCount } from 'store/sections/selectors';
import Contents from 'components/contents/Contents';
import { localize } from 'core/localization';
import Reviewable from 'components/review/Reviewable';
import { HINT_FOR_SPOT_COMMENT, REVIEW_COMMENT_PROPERTIES, REVIEW_COMMENT_TYPES } from 'constants/review';
import {
  Container,
  CourseInfoContainer,
  CourseInfoWrapper,
  ContentsContainer,
  LogoWrapper,
  CourseLogo,
  ProgressSectionsWrapper,
  ProgressPerSection,
  ProgressMasteryScore,
  ProgressCaption
} from './CourseInformation.styled';
import { RootAppState } from 'store/types';

type CourseInformationProps = {
  title?: string;
  introductions?: Array<string>;
  sections: Array<any>;
  courseProgress?: number;
  masteryScore?: number;
  isCourseProgress?: boolean;
  affectProgressSectionsCount?: number;
  sectionsPassedCount?: number;
  reviewMode?: boolean;
  alternativeLogoUrl: string;
};

export class CourseInformation extends React.Component<CourseInformationProps, {}> {
  refContainer: any;
  constructor(props: CourseInformationProps) {
    super(props);
    this.refContainer = React.createRef();
  }
  render() {
    const {
      title,
      introductions,
      alternativeLogoUrl,
      sections,
      courseProgress = 0,
      masteryScore = 0,
      isCourseProgress,
      sectionsPassedCount,
      affectProgressSectionsCount = 0
    } = this.props;

    return (
      <Container
        ref={this.refContainer}
      >
        <CourseInfoContainer>
          <CourseInfoWrapper>
            <LogoWrapper>
              <CourseLogo logoUrl={alternativeLogoUrl} alt={localize('[alt logo login]')} />
            </LogoWrapper>
            <Reviewable
              context={{ type: REVIEW_COMMENT_TYPES.COURSE, property: REVIEW_COMMENT_PROPERTIES.TITLE }}
              hintName={HINT_FOR_SPOT_COMMENT}
            >
              <Text title={title} appearance="h1" inverted>
                {title}
              </Text>
            </Reviewable>
            {affectProgressSectionsCount > 0 ? (
              isCourseProgress ? (
                <>
                  <ProgressMasteryScore
                    forwardedRef={this.refContainer}
                    progress={courseProgress}
                    passedMark={masteryScore}
                  />
                  <ProgressCaption>
                    {localize('[you complete x]', {
                      percentSuccess: courseProgress
                    })}
                  </ProgressCaption>
                </>
              ) : (
                  <>
                    <ProgressSectionsWrapper>
                      {sections.map(section => {
                        if (section.affectsProgress) {
                          const { id } = section;
                          return section.isPassed ? (
                            <ProgressPerSection key={id} progress={100} />
                          ) : (
                              <ProgressPerSection key={id} progress={0} />
                            );
                        }
                        return false;
                      })}
                    </ProgressSectionsWrapper>
                    <ProgressCaption>
                      {localize('[x out of y sections passed]', {
                        countSectionsSuccess: sectionsPassedCount,
                        countSections: affectProgressSectionsCount
                      })}
                    </ProgressCaption>
                  </>
                )
            ) : (
                <Separator height={2} opacity={0.3} margin={56} color="coverBgOverlayTextColor" />
              )}
            {renderCourseIntroduction(introductions)}
          </CourseInfoWrapper>
        </CourseInfoContainer>
      </Container>
    );
  }
}

function renderCourseIntroduction(introductions: any) {
  if (Array.isArray(introductions) && introductions.length !== 0) {
    return (
      <React.Fragment>
        <Reviewable context={{ type: REVIEW_COMMENT_TYPES.COURSE, property: REVIEW_COMMENT_PROPERTIES.INTRODUCTION }}>
          <ContentsContainer>
            <Contents inverted items={introductions} />
          </ContentsContainer>
        </Reviewable>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: RootAppState) {
  return {
    alternativeLogoUrl: getAlternativeLogo(state),
    title: getCourseTitle(state),
    introductions: getCourseIntroductions(state),
    courseProgress: getScore(state),
    masteryScore: getMasteryScoreValue(state),
    isCourseProgress: isOverallMasteryScore(state),
    affectProgressSectionsCount: getAffectProgressSectionsCount(state),
    sectionsPassedCount: getSectionsPassedCount(state)
  };
}
export default connect(mapStateToProps)(CourseInformation);
