import * as React from 'react';
import { withTheme } from 'styled-components';
import { getStyles } from 'utils/element';
import { getDOMNode, getAttributesNodeElement } from 'utils/dom';
import { addQueryStringParameter } from 'utils/window';
import { Container, IframeElement } from './VoiceOver.styled';


type VoiceOverProps = {
  embedCode: string;
  theme: { [key: string]: any };
  tabIndex: number
};

export class VoiceOver extends React.PureComponent<VoiceOverProps, {}> {
  static defaultProps = {
    tabIndex: 0
  };
  getIframeSrcWithStyleVariable(src: string) {
    return addQueryStringParameter(
      src,
      'style_variables',
      encodeURIComponent(getStyles(this.props.theme))
    );
  }

  renderIframeElement() {
    const iframeNode = getDOMNode(this.props.embedCode);
    const attrName = getAttributesNodeElement(iframeNode);
    const iframeAttr: { [key: string]: any } = {};

    attrName.forEach(name => {
      iframeAttr[name] = iframeNode.attributes[name].nodeValue;
    });

    iframeAttr.tabIndex = this.props.tabIndex;
    iframeAttr.src = this.getIframeSrcWithStyleVariable(iframeAttr.src);

    return <IframeElement {...iframeAttr} />;
  }

  render() {
    return <Container>{this.renderIframeElement()}</Container>;
  }
}

export default withTheme(VoiceOver);
