import styled from 'styled-components';
import * as typography from 'components/mixins/typography';
import { transparentize } from 'utils/color';
import media from 'components/mixins/media';

export const TextMatchingItem = styled.div.attrs({
  'data-test': 'text-matching-item'
})`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 10px;
  ${typography.secondaryFontText};
  color: ${props => props.theme.colors.textColor};

  ${media.small`
    margin-bottom: 48px;

    &:last-child {
      margin-bottom: 0;
    }
  `};
`;

export const TextMatchingItemKey = styled.div`
  width: calc(50% - 50px);
  padding: 3px 15px 5px;
  background: ${props => transparentize(props.theme.colors.textColor, 0.06)};

  ${media.small`
    width: calc(100% - 44px);
    margin-bottom: 8px;
  `};
`;

type TextMatchingItemSelectedProps = {
  shownSelectedValue: any;
}

export const TextMatchingItemSelected = styled.div.attrs(props => ({
  'data-test': 'item-selected'
})) <TextMatchingItemSelectedProps>`
  width: calc(50% - 50px);
  z-index: 1;
  background: ${props =>
    props.shownSelectedValue
      ? transparentize(props.theme.colors.textColor, 0.06)
      : transparentize(props.theme.colors.textColor, 0.02)};

  ${media.small`
    width: calc(100% - 44px);
    margin-bottom: 8px;
  `};
`;

export const DropdownInputWrapper = styled.div.attrs({
  'data-test': 'dropdown-input-wrapper'
})`
  width: 100px;
  display: flex;
  justify-content: center;

  ${media.small`
    width: 44px;
  `};
`;

export const DropdownInputContainer = styled.div`
  .dropdown-button {
    width: 40px;
    height: 40px;
    min-height: 40px;

    ${media.small`
      width: 32px;
      height: 32px;
      min-height: 32px;
    `};
  }

  .chosen-value {
    display: none;
  }

  .downshift-dropdown {
    max-width: 325px;
  }
`;
