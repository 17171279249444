import { TweenMax } from 'gsap';
import { isIndexView, isSectionsView, isCourseOverviewView } from './viewChecker';

export const MOBILE_TRANSITION_DURATION = 0.5;

export const MOBILE_TRANSITION_TIMEOUT = MOBILE_TRANSITION_DURATION * 2;

export const useMobileTransition = (node: any, pathname?: any, background?: any) => {
  TweenMax.to(node, 0, { opacity: 1 });
  return {
    appear: () => {},
    enter: () => {
      const windowWidth = window.innerWidth;
      if (isIndexView.test(pathname) || isSectionsView.test(pathname)) {
        TweenMax.fromTo(
          node,
          MOBILE_TRANSITION_DURATION,
          {
            x: -windowWidth
          },
          {
            x: 0
          }
        );
      } else if (isCourseOverviewView(pathname)) {
        TweenMax.fromTo(
          node,
          MOBILE_TRANSITION_DURATION,
          {
            x: windowWidth
          },
          {
            x: 0
          }
        );
      }
    },
    exit: () => {
      const windowWidth = window.innerWidth;
      const { body } = background;
      const getBodyColor = body.enabled && body.color ? body.color : 'transparent';

      if (isIndexView.test(pathname) || isSectionsView.test(pathname)) {
        TweenMax.fromTo(
          node,
          MOBILE_TRANSITION_DURATION,
          {
            x: 0,
            scale: 0.8
          },
          {
            x: windowWidth,
            scale: 1
          }
        );
        TweenMax.to(document.body, 0.1, { backgroundColor: getBodyColor });
      } else if (isCourseOverviewView(pathname)) {
        TweenMax.fromTo(
          node,
          MOBILE_TRANSITION_DURATION,
          {
            x: 0,
            scale: 0.8
          },
          {
            x: -windowWidth,
            scale: 1
          }
        );
        TweenMax.to(document.body, 0.1, { backgroundColor: getBodyColor });
      }
    }
  };
};
