import initialState from 'store/initialState';
import { ActionTypes, TOCActionTypes } from './types';

export default function treeOfContentReducer(state = initialState.treeOfContent, action: TOCActionTypes) {
  switch (action.type) {
    case ActionTypes.TOC_TOGGLE_VISIBILITY:
      return { ...state, ...{ isExpanded: !state.isExpanded } };
    default:
      return state;
  }
}
