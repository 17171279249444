import * as React from 'react';
import Aux from '../../../common/helpers/AuxWrapper';
import { SwitchOption } from './Option.styled';

type OptionProps = {
  onClick?(): void,
  selected: boolean;
  ariaRole: string;
  ariaLabel: string;
  tabIndex: number;
  ariaDisable: boolean;
};

export const Option = (props: OptionProps) => {
  const { onClick, selected, ariaRole, ariaLabel, tabIndex, ariaDisable } = props;
  return (
    <Aux>
      <SwitchOption
        role={ariaRole}
        ariaLabel={ariaLabel}
        ariaChecked={selected}
        onClick={onClick}
        selected={selected}
        tabIndex={tabIndex}
        ariaDisable={ariaDisable}
      />
    </Aux>
  );
};

export default Option;
