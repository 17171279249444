import styled, { css } from 'styled-components';
import { Text, Icon } from 'components/common';
import buttonDefaultStyle from 'components/mixins/normalize';
import { transparentize } from 'utils/color';
import { transition, pointerOnHover } from 'components/mixins/interactivity';
import { TOC } from 'constants/components';
import { mainFontText } from 'components/mixins/typography';

const colored = css`
  color: ${props => transparentize(props.theme.colors.textColor, 0.7)};
  ${transition('color')};
`;

type CourseBackIconProps = {
  isCollapsed?: boolean;
};
export const CourseBackIcon = styled(Icon)<CourseBackIconProps>`
  font-size: 40px;
  ${colored};
  ${pointerOnHover};
  ${props =>
    props.isCollapsed &&
    css`
      font-size: 25px;
    `};

  &:hover {
    color: ${props => props.theme.colors.textColor};
  }
`;

export const CourseTitleText = styled(Text)`
  ${mainFontText}
  && {
    font-size: 18px;
    ${colored};
  }
  font-style: normal;
  line-height: 1.4;
  text-decoration: none;
  align-self: center;
  margin-left: 16px;
  word-break: break-word;
`;

type CourseBackButtonProps = {
  ariaLabel: string;
  tabIndex: number;
  isCollapsed: boolean;
};

export const CourseBackButton = styled.button.attrs((props: CourseBackButtonProps) => ({
  tabIndex: props.tabIndex,
  'aria-label': props.ariaLabel
}))<CourseBackButtonProps>`
  ${buttonDefaultStyle};
  height: auto;
  ${props =>
    !props.isCollapsed &&
    css`
      display: flex;
      flex: 0 0 auto;
      margin: 0 40px;
      padding: 40px 0 0 0;
    `};
  ${props =>
    props.isCollapsed &&
    css`
      height: auto;
      margin: 0;
      padding: 0;
    `};
  width: auto;
  overflow: visible;
  text-align: left;
  ${pointerOnHover};

  @media (max-width: ${TOC.EXPANDED_WIDTH + TOC.TAIL_WIDTH}px) {
    margin: 0 10px;
    & ${CourseBackIcon} {
      display: none;
    }

    & ${CourseTitleText} {
      margin-left: 0;
    }
  }
  &:hover,
  &:focus {
    ${CourseTitleText}, ${CourseBackIcon} {
      color: ${props => props.theme.colors.textColor};
    }
  }
`;
