import * as React from 'react';
import { isTouchScreenDevice } from 'utils/window';
import { Tooltip } from 'components/common';
import { localize } from 'core/localization';
import SpotArea from './SpotArea.styled';
import { TooltipContent, Wrapper } from './TooltipContent.styled';
import InfoButton, { InfoIcon } from './InfoButton.styled';

type SpotProps = {
  id: string;
  width: string;
  height: string;
  left: string;
  top: string;
  naturalWidth: number;
  naturalHeight: number;
  text: string;
  theme?: { [key: string]: any }
}

export const Spot = ({ id, width, height, left, top, naturalWidth, naturalHeight, text, theme }: Readonly<SpotProps>) => {
  const [isDisabled, setDisabledState] = React.useState(false);
  const templateTheme = theme;

  React.useEffect(() => {
    if (isDisabled) {
      setDisabledState(false);
    }
  }, [isDisabled]);

  return (
    <SpotArea
      key={id}
      width={width}
      height={height}
      left={left}
      top={top}
      naturalWidth={naturalWidth}
      naturalHeight={naturalHeight}
    >
      <Tooltip
        disabled={isDisabled}
        tabIndex={1}
        style={{ outline: 'none' }}
        arrow={true}
        position="top"
        interactive
        duration={0}
        distance={20}
        tooltipTheme={'light'}
        html={
          <Wrapper theme={templateTheme}>
            <TooltipContent theme={templateTheme} id={`${id}-d`}>{text}</TooltipContent>
          </Wrapper>
        }
      >
        <InfoButton
          onClick={() => !isTouchScreenDevice() && setDisabledState(true)}
          aria-label={localize('[hotspot open popover]')}
          aria-describedby={`${id}-d`}
        >
          <InfoIcon size={12} name="info" />
        </InfoButton>
      </Tooltip>
    </SpotArea>
  );
};

export default Spot;
