import { MEDIA_SMALL } from 'constants/screenResolutions';

export const isLowerResolution = (resolution: number) => window.innerWidth < resolution;

export const getCurrentUrl = () =>
  `${window.location.protocol}//${window.location.host}${window.location.pathname}`;

export const getCurrentLocationHash = () => window.location.hash;

// eslint-disable-next-line
export const isTouchScreenDevice = () => {
  return (
    /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
    || (/Macintosh/i.test(navigator.userAgent) && 'ontouchend' in document)
    || window.innerWidth < MEDIA_SMALL
  );
}

export const hasQueryStringParameter = (key: any) => window.location.href.indexOf(key) !== -1;

export const addQueryStringParameter = (url: string, key: any, value: any) => {
  const regex = new RegExp(`([?&])${key}=.*?(&|$)`, 'i');
  const separator = url.indexOf('?') !== -1 ? '&' : '?';
  if (url.match(regex)) {
    return url.replace(regex, `$1${key}=${value}$2`);
  }
  return `${url}${separator}${key}=${value}`;
};

export const getValueFromUrl = (key: any) => {
  if (!key) {
    throw new Error('Parameter "key" should be specified');
  }

  const url = window.location.href;
  const regex = new RegExp(`[?&]${key}(=([^&#]*)|&|#|$)`, 'i');
  const result = regex.exec(url);

  if (!result || !result[2]) {
    return;
  }

  return decodeURIComponent(result[2].replace(/\+/g, ' '));
};

export const clearQueryString = () => {
  const currentHash = getCurrentLocationHash();
  const searchIndex = currentHash.indexOf('?');

  if (searchIndex !== -1) {
    const cleanHash = currentHash.slice(0, searchIndex);
    window.history.replaceState({}, document.title, `${getCurrentUrl()}${cleanHash}`);
  }
};

export const closeWindow = () => {
  if (window.top) {
    window.top.close();
    return true;
  }
  return false;
};

export const getObjectParsedQueryString = () => {
  const regex = /[?&]([^=#]+)=([^&#]*)/g;
  const url = window.location.search;
  const params = {} as any;
  let match = regex.exec(url);
  while (match && regex.lastIndex !== 0) {
    params[match[1]] = match[2];
    match = regex.exec(url);
  }

  return params;
};

export const getFilteredQueryParams = (queryKey: string) => {
  const parsedParams = getObjectParsedQueryString();
  const filteredParams = Object.keys(parsedParams)
    .filter(key => key !== queryKey)
    .reduce((acc: any, key) => acc.concat([`${key}=${parsedParams[key]}`]), [])
    .join('&');

  return filteredParams && filteredParams.length ? `?${filteredParams}` : '';
};

export const removeQueryStringParam = (paramName: string) => {
  if (window.location.search.indexOf(paramName) !== -1) {
    const url = `${getCurrentUrl()}${getFilteredQueryParams(paramName)}${getCurrentLocationHash()}`;

    window.history.replaceState({}, document.title, url);
  }
};
